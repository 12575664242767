import { TextField } from '@mui/material';

import React from 'react';

export default function Input(props){
    const {name, label, value, error=null, variant, onChange, ...other} = props;

     return (

         <TextField 

             //variant="outlined"
             variant={variant || "outlined"}
             label={label}
             name={name}
             value={value}
             onChange={onChange}
             {...other}
             {...(error && {error:true, helperText:error})}
             /**
              * if error is not null we will do the rest of the operations
              */
             //if not null the error will be true and helperText will have value
             //the for having ... so that the error and helperText can become a part of the <TextField />
         />
    );

}