import findPunktzahl from './findPunktzahl';
import findMainValue from './findMainValue';

export default function calculateFieldWithFactor(rows, field) {
    const punktzahl = findPunktzahl(rows, field.ziffer);
    const anzahl = field.anzahl;
    const faktor = field.faktor === null || field.faktor === '' ? 0 : parseFloat(String(field.faktor).replace(',', '.'));
    const main_value = findMainValue(rows, field.ziffer);
    //console.log("punktzahl " + punktzahl + " anzahl " + anzahl + " faktor " + faktor + " main_value " + main_value);
    return anzahl * faktor *  (punktzahl !== undefined && punktzahl !== null ? Number(punktzahl) : 0) *  main_value;
}