import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { List, ListItem, ListItemText, Card, CardContent } from '@mui/material';
import MuiDrawer from '../../components/MuiDrawer';
//import SubscriptionBox from './SubscriptionBox'; // Assuming you have a SubscriptionBox component
//import AccountSettings from './AccountSettings'; // Assuming you have an AccountSettings component
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Controls from '../../components/controls/Controls';

import * as DatabaseService from '../../services/DatabaseService';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
//import SaveIcon from '@mui/icons-material/Save';
import LoopIcon from '@mui/icons-material/Loop';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import TwoFactorAuthenticationConfiguration from './components/TwoFactorAuthenticationConfiguration';
import ContactInfoForm, {validateContactInfoForm} from '../../components/ContactInfoForm';
import setNestedField from '../../utils/setNestedField';

// Dummy user data
const user = {
  name: 'John Doe',
  email: 'john@example.com',
  username: 'johndoe',
  // Add more user details if necessary
};

// Dummy subscription data
const subscription = {
  plan: 'Premium',
  expirationDate: '2024-12-31',
  // Add more subscription details if necessary
};

const plans = [
  {
    id: 1,
    name: 'Basic',
    price: '$9.99',
    duration: 'per month',
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
  },
  {
    id: 2,
    name: 'Standard',
    price: '$19.99',
    duration: 'per month',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
  },
  {
    id: 3,
    name: 'Premium',
    price: '$29.99',
    duration: 'per month',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
  },
];

const currentlySubscribedPlanId = 3;

const SubscriptionPlans = ({ plans, currentlySubscribedPlanId }) => {
  return (
    <Grid container spacing={3}>
      {plans.map((plan) => (
        <Grid item key={plan.id} xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {plan.name}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {plan.price} {plan.duration}
              </Typography>
              <List>
                {plan.features.map((feature, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
              {plan.id === currentlySubscribedPlanId && (
                <Typography variant="subtitle2" color="primary">
                  Derzeit abonniert
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const ProfilePage = () => {
  const [userData, setUserData] = useState({});
  const [userDataError, setUserDataError] = useState({});
  const [formState, setFormState] = useState({
    loading: false,
    alert: null,
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    alert: null,
    loading: false
  });

  function handleChangeData(e) {
    const { name, value } = e.target;
    setUserData(setNestedField(userData, name, value));
  }

  async function handleUpdatePassword(e) {
    try {
        e.preventDefault();
        setPasswordData(prev => ({ ...prev, loading: true }));
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setPasswordData(prev => ({ ...prev, alert: { severity: 'error', text: 'Passwörter stimmen nicht überein' } }));
            return;
        }
        const res = await DatabaseService.userUpdatePasswordProfile(passwordData);
        if (res.ok) {
            setPasswordData(prev => ({ ...prev, alert: { severity: 'success', text: 'Passwort wurde erfolgreich aktualisiert!' } }));
            setPasswordData(prev => ({ ...prev, currentPassword: '', newPassword: '', confirmPassword: '' }));
            return;
        }

        return setPasswordData(prev => ({ ...prev,
            alert: { severity: 'error', text: 'Passwort konnte nicht aktualisiert werden.' }
        }));
    } finally {
        setPasswordData(prev => ({ ...prev, loading: false }));
    }
  }

  async function handleUpdateUser(e) {
    e.preventDefault();
    try {
        setFormState(prev => ({ ...prev, loading: true }));
        if (validateUser() === false) {
          return
        }
        const res = await DatabaseService.userUpdateProfile(userData);
        if (res.ok) {
            setFormState(prev => ({ ...prev, alert: { severity: 'success', text: 'User wurde erfoglreich aktualisiert' } }));
            fetchUserData();
        } else {
            setFormState(prev => ({ ...prev, alert: { severity: 'error', text: 'Benutzer konnte nicht aktualisiert werden' } }));
        }
    } finally {
        setFormState(prev => ({ ...prev, loading: false }));
    }
  }

  const validateUser = () => {
    const error = validateContactInfoForm(userData);
    setUserDataError(error);
    return Object.keys(error).every(k => !error[k])
  }

  useEffect(() => {
    fetchUserData();
  }, []);

  function fetchUserData() {
    return DatabaseService.getMe()
        .then((user) => {
            setUserData(user);
        })
        .catch((error) => {
            console.error('Benutzerdaten konnten nicht geladen werden:', error);
        });
  }

  useEffect(() => {
    if (passwordData.alert) {
        setTimeout(() => {
            setPasswordData(prev => ({ ...prev, alert: null }));
        }, 3000);
    }
  }, [passwordData.alert])

  useEffect(() => {
    if (formState.alert) {
        setTimeout(() => {
            setFormState(prev => ({ ...prev, alert: null }));
        }, 3000);
    }
  }, [formState.alert])

  return (
    <MuiDrawer>
      <Typography variant="h6" gutterBottom>
        Profileinstellungen
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="subtitle1" gutterBottom>
              Passwort ändern
            </Typography>

            <Box>
                <form onSubmit={handleUpdatePassword}>
                    <TextField
                        required
                        margin="dense"
                        id="currentPassword"
                        label="Aktuelles Passwort"
                        name="currentPassword"
                        value={passwordData.currentPassword}
                        onChange={(e) => setPasswordData(prev => ({ ...prev, currentPassword: e.target.value }))}
                        type="password"
                        fullWidth
                    />
                    <TextField
                        required
                        margin="dense"
                        id="newPassword"
                        label="Neues Passwort"
                        name="newPassword"
                        value={passwordData.newPassword}
                        onChange={(e) => setPasswordData(prev => ({ ...prev, newPassword: e.target.value }))}
                        type="password"
                        fullWidth
                    />
                    <TextField
                        required
                        margin="dense"
                        id="confirmPassword"
                        label="Passwort bestätigen"
                        name="password"
                        value={passwordData.confirmPassword}
                        onChange={(e) => setPasswordData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                        type="password"
                        fullWidth
                    />
                    {passwordData.alert && (
                        <Controls.Alert
                            severity={passwordData.alert.severity}
                            appear={passwordData.appear}
                            text={passwordData.alert.text}
                            sx={{ marginTop: '10px' }}
                        />
                    )}
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={passwordData.loading}
                    >
                        Passwort aktualiseren
                    </Button>
                </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={5}>
        <Paper style={{ padding: 20 }}>
          <Typography variant="subtitle1" gutterBottom>
            Benutzer-Details
          </Typography>
          <ContactInfoForm formData={userData} formError={userDataError} signup={false} update={true} handleChange={handleChangeData} />
          {formState.alert && (
                <Controls.Alert
                    severity={formState.alert.severity}
                    appear={formState.alert.appear}
                    text={formState.alert.text}
                    sx={{ marginTop: '10px' }}
                />
            )}
          <LoadingButton
            sx={{ mt: 2 }}
            onClick={handleUpdateUser}
            loading={formState.loading}
            loadingPosition="start"
            startIcon={<LoopIcon />}
            disabled={formState.loading}
            variant="contained"
          >
            <span>Speichern</span>
        </LoadingButton>
        </Paper>
      </Box>

      <br />
      <Typography variant="h6" gutterBottom>
        Zwei-Faktor-Authentifizierung
      </Typography>

      <TwoFactorAuthenticationConfiguration />
    </MuiDrawer>
  );
};

export default ProfilePage;
