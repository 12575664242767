export default function checkDeviationPercentColor(row_deviation_percent, input_anzahl){

    let deviation_percent = row_deviation_percent;
    let deviation_percent_color = "";

    //console.log("Inside the checkDeviationPercentColor function");
    //console.log("input_anzahl: " + input_anzahl);

    //if(input_anzahl !== 0){
      if(!isNaN(deviation_percent)){
        if (deviation_percent === 0 || deviation_percent === 100) {
          // No color for 100% deviation
          deviation_percent_color = "white";
        } else if (deviation_percent > 0.000001) {
            // Plus deviation, mark light green
            deviation_percent_color = "lightgreen";
        } else if (deviation_percent <= 0 && deviation_percent >= -20) {
            // Deviation between 0% and -20%, mark yellow
            deviation_percent_color = "yellow";
        } else if (deviation_percent < -20 && deviation_percent >= -50) {
            // Deviation between -20.000001% and -50%, mark light red
            deviation_percent_color = "lightcoral";
        } else if (deviation_percent < -50 && deviation_percent >= -99.9) {
            // Deviation between -50.000001% and -99.9%, mark dark red
            deviation_percent_color = "darkred";
        }
      }
    //}

    return deviation_percent_color;
}