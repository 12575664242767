import React, { useState, useEffect, useRef } from 'react';
import { useSingleUserData } from '../../services/userService';
import * as DatabaseService from '../../services/DatabaseService';
import { Link, TableRow } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, Paper } from '@mui/material';
import { TextField, Button } from '@mui/material';
import {NavLink} from 'react-router-dom';

import { dangerouslySetInnerHTML } from 'react-dom';

import Controls from '../../components/controls/Controls';

import ViewFormOutput from './components/ViewFormOuput';
import ProfileCard from './components/ProfileCard';
import DialogOutput from '../../components/DialogOutput';

import EBM_Abrechnungskompass_Results from './results/EBM_Abrechnungskompass_Results';

import {EBM_Loading_Input} from './components/Loading/LoadingSkeletonLayout';

import debugService from '../../services/debugService';

import findCONData from './utils/findCONData';

import InputTabletStyle from './styles/InputTableStyle';

import MissingGOPFeedBack from './components/MissingGOPFeedBackForm';
import ViewUpdateInformationNotice from './components/ViewUpdateInformationNotice';

import { useEnvData } from '../../services/envService';

const EBM_Abrechnungskompass = (props) => {

  const { user_input, user_input_scheine, user_input_state, view_only_mode, con_data=[], created_at="", pageUserInfo={} } = props;

  const [viewOnlyMode, setViewOnlyMode] = useState(false);
  
  const debug = debugService.isDebugMode();

  const [loadingData, setLoadingData] = useState({ hideFirstRow: false, hideSecondRow: false });
  const [loading, setLoading] = useState(true);

  const { userStatus, userRoleID, userKV, useUpdateInformation, sessionUserInfo } = useSingleUserData();

  const effectiveUserInfo = Object.keys(pageUserInfo).length === 0 ? sessionUserInfo : pageUserInfo;

  const [scheine, setScheine] = useState('');
  const [kv, setKv] = useState(userKV);
  const [gopNumbersInput, setGopNumbersInput] = useState({});
  const [input_rows, setInput_rows] = useState([]);
  const [tempGopNumbersInput, setTempGopNumbersInput] = useState({});

  const [listStates, setListStates] = useState([]);
  const [gopNumbersSet, setGopNumbersSet] = useState(false); // State to track if gopNumbersInput has been set
  
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "", dialog_type: "" });

  //const { isDevMode } = useEnvData();

  const [disableDropdownKV, setDisableDropdownKV] = useState(true);

  const groupedGopNumbers = input_rows.reduce((acc, row) => {
    const { header_no, header_description, gop, description } = row;
    if (!acc[header_no]) {
      acc[header_no] = {
        header_description,
        gops: []
      };
    }
    acc[header_no].gops.push({ gop, description });
    return acc;
  }, {});

  let columns = [[], [], [], []];
  
  const useManualAssignment = true; // Set this flag to true for manual assignment, false for dynamic assignment

  if (useManualAssignment) {
    // Manually assign specific items to columns
    Object.entries(groupedGopNumbers).forEach(([header_no, { header_description, gops }]) => {
      if (['1','2','3','4'].includes(header_no)) {
        columns[0].push({ header_no, header_description, gops }); // Assign to 1st column
      } else if (['5','6','7','8'].includes(header_no)) {
        columns[1].push({ header_no, header_description, gops }); // Assign to 2nd column
      } else if (['9','10','11','12','13','14','15'].includes(header_no)) {
        columns[2].push({ header_no, header_description, gops }); // Assign to 3rd column
      } else if (['16','17','18','19','20'].includes(header_no)) {
        columns[3].push({ header_no, header_description, gops }); // Assign to 4th column
      } else {
        // You can add additional logic here to assign other items to different columns if needed
        //columns[3].push({ header_no, header_description, gops }); // Default to 1st column
      }
    });
  } else {
    // Dynamically assign items to columns
    const totalItems = Object.keys(groupedGopNumbers).length;
    const itemsPerColumn = Math.ceil(totalItems / columns.length);
  
    let currentColumnIndex = 0;
    let currentItemCount = 0;
  
    Object.entries(groupedGopNumbers).forEach(([header_no, { header_description, gops }]) => {
      if (currentItemCount >= itemsPerColumn) {
        currentColumnIndex++;
        currentItemCount = 0;
      }
      if (currentColumnIndex >= columns.length) {
        currentColumnIndex = columns.length - 1; // Ensure we don't exceed the number of columns
      }
      columns[currentColumnIndex].push({ header_no, header_description, gops });
      currentItemCount++;
    });
  }
  
  //console.log('Grouped GOP Numbers:', groupedGopNumbers);
  //console.log('Columns:', columns);

  const getEBMInputRowsData = async (kv) => {
    try {
      const defaultScheine = 0;
      const JSONData = await DatabaseService.getEBMAbrechnungskompassSearchByStates(defaultScheine, kv);
      if (JSONData && JSONData.rows) {
        setInput_rows(JSONData.rows);
        // Only set initial values if gopNumbersInput is empty
        if (Object.keys(gopNumbersInput).length === 0) {
          const initialGopNumbers = JSONData.rows.reduce((acc, row) => {
            acc[`fallzahl_${row.gop}`] = '';
            return acc;
          }, {});
          setGopNumbersInput(initialGopNumbers);
        }
      }
      if (JSONData && JSONData.stateRows) {
        setListStates(JSONData.stateRows);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
      setLoadingData({ hideFirstRow: true, hideSecondRow: true });
    }
  };
  
  useEffect(() => {
    if (userKV && !gopNumbersSet) {  // Only set KV if gopNumbers haven't been set yet
      setKv(userKV);
    }
  }, [userKV, gopNumbersSet]);

  useEffect(() => {
    if(parseInt(userRoleID) === 1 || parseInt(userRoleID) === 4) {
      setDisableDropdownKV(false);
    }
  }, [userRoleID]);

  useEffect(() => {
    if (userKV && !kv) {
      setKv(userKV);
    }
  }, [userKV, kv]);

  useEffect(() => {
    getEBMInputRowsData(kv); // Fetch initial data
  }, [kv]);

  const handleScheineChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setScheine(value);
    }

    setOpen(false);
  };

  const handleKvChange = (e) => {
    const newKv = e.target.value;
    setTempGopNumbersInput(gopNumbersInput);
    setKv(newKv);
    setLoadingData({ hideFirstRow: true, hideSecondRow: false });
    setLoading(true);
    setOpen(false);
  };

  const handleGopNumberChange = (key, e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setGopNumbersInput((prevGopNumbers) => ({
        ...prevGopNumbers,
        [key]: value,
      }));
    }

    setOpen(false);
  };

  const calculate = () => {
    // Implement your calculation logic here
    /*console.log('Scheine:', scheine);
    console.log('KV:', kv);
    console.log('GOP Numbers:', gopNumbersInput);*/

    setDialogInformation({ title: "", information: "", status: "view", dialog_type: "results" });
    setOpen(true);
  };

  const handleViewForm = (gop, description) => {
    setDialogInformation({ title: gop, information: description, status: "view", dialog_type: "gop_information" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    if(view_only_mode) {
      setViewOnlyMode(true);
      setScheine(user_input_scheine || '');
      setKv(user_input_state);
      setGopNumbersInput(user_input);
      setDisableDropdownKV(true);
    }

  }, [view_only_mode, user_input_scheine, user_input_state, user_input, gopNumbersInput]);

  //const filteredListStates = getFilteredStates(userRoleID, listStates);

  useEffect(() => {
    if (con_data.length > 0 && input_rows.length > 0) {
      const gopNumbersCountFromCONData = input_rows.reduce((acc, row) => {
        acc[`fallzahl_${row.gop}`] = findCONData(con_data, row.gop);
        return acc;
      }, {});
      setGopNumbersInput(gopNumbersCountFromCONData);
      setScheine(user_input_scheine || '');
      setKv(user_input_state || userKV);
      setGopNumbersSet(true);
    }
  }, [con_data, input_rows, user_input_scheine]);

  useEffect(() => {
    if (Object.keys(tempGopNumbersInput).length > 0) {
      setGopNumbersInput(tempGopNumbersInput); // Restore gopNumbersInput from temp state
      setTempGopNumbersInput({});
    }
  }, [input_rows]);

  const filteredListStates = listStates;

  return (
    <div>

        { useUpdateInformation && <div style={{ display: 'flex', justifyContent: 'center' }}><ViewUpdateInformationNotice /></div> }

        { !useUpdateInformation && (
          <>
            { 
              !loadingData.hideFirstRow ? <EBM_Loading_Input loadingData={loadingData} rowType={1} con_data={con_data} /> : <>
                <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                  <div>
                    <TextField
                      type="text"
                      id="scheine"
                      label="Scheine"
                      value={scheine}
                      onChange={handleScheineChange}
                      variant="outlined"
                      fullWidth
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: 'black',
                          color: 'black',
                        },
                        width: '100%'
                      }}
                      InputProps={{
                        readOnly: viewOnlyMode,
                      }}
                      disabled={viewOnlyMode}
                    />
                  </div>
                  <div>
                    <Controls.Select
                        name="state_id"
                        label="KV"
                        value={kv}
                        onChange={handleKvChange}
                        showNoneOption={false}
                        options={filteredListStates}
                        disabled={disableDropdownKV}
                        sx={{ '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black', color: 'black' }}}
                    />
                  </div>

                  { 
                    con_data.length <= 0 && 
                      <div>
                        <Button variant="contained" size='large' color="primary" sx={{padding: '15px 22px', borderRadius: '30px'}} component={NavLink} to="/ebm-abrechnungskompass/eingabe-via-con-datei">
                          Zur Eingabe via Con-Datei
                        </Button>
                      </div>
                  }
                </div>
              </>
            }
            
            {/* Main content area with columns layout */}

            { 
              !loadingData.hideSecondRow ? <EBM_Loading_Input loadingData={loadingData} rowType={2} /> : <>
              <InputTabletStyle>
                {columns.map((column, columnIndex) => (
                  <div key={columnIndex} style={{ flex: 1 }}>
                    <TableContainer component={Paper} className="table-container">
                      <Table size="small" aria-label="simple table" sx={{ border: ' ' }}>
                        <TableHead>
                          <TableRow className="table-head-row">
                            <TableCell className="table-head-cell">EBM-Ziffer</TableCell>
                            <TableCell className="table-head-cell">Anzahl</TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                    {column.map(({ header_no, header_description, gops }, rowIndex) => (
                      <div key={header_no} className="content-div">
                        <TableContainer component={Paper} className="table-container">
                          <Table size="small" aria-label="simple table" className="table">
                            <TableHead>
                              <TableRow className="table-body-row">
                                <TableCell colSpan={2} className="table-body-cell-header-description">
                                  <div dangerouslySetInnerHTML={{ __html: header_description }} />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {gops.sort((a, b) => a.gop - b.gop).map(({ gop, description }) => (
                                  <TableRow
                                    key={gop}
                                    className="last-child-cell"
                                  >
                                    
                                    <TableCell width={"50%"} className="table-body-cell">
                                      {
                                        (description !== null && description !== '') ?
                                          <>
                                            <Link className="gop-link" onClick={() => { handleViewForm(gop, description) }}>{gop}</Link>
                                          </> : <>{gop}</>
                                      }
                                    </TableCell>

                                    <TableCell width={"50%"} className="table-body-cell">
                                      <TextField
                                        id={`fallzahl_${gop}`}
                                        type="text"
                                        value={gopNumbersInput[`fallzahl_${gop}`] || ''}
                                        onChange={(e) => handleGopNumberChange(`fallzahl_${gop}`, e)}
                                        placeholder="0"
                                        size="small"
                                        variant="filled"
                                        hiddenLabel
                                        className="text-field"
                                        InputProps={{
                                          readOnly: viewOnlyMode,
                                        }}
                                        disabled={viewOnlyMode}
                                      />
                                    </TableCell>
                                  </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ))}
                    {columnIndex === columns.length - 1 && (
                      <>

                      <MissingGOPFeedBack state_no={kv} />
                      <br />

                      <div>
                        <ProfileCard onClick={calculate} />
                      </div>
                      </>
                    )}
                  </div>
                ))}
              </InputTabletStyle>
              </>
            }
          </>)
      }

      {
        dialogData.dialog_type === "gop_information" || dialogData.dialog_type === "results" ? (
          <DialogOutput
            fullScreen={dialogData.dialog_type === "results"}
            open={open}
            dialogData={dialogData}
            handleClose={handleClose}
            backgroundColor={dialogData.dialog_type === "results" ? '#F1F2F4' : undefined}
            output={
              dialogData.dialog_type === "gop_information" ? (
                <ViewFormOutput description={dialogData.information} />
              ) : (
                <EBM_Abrechnungskompass_Results scheine={scheine} kv={kv} gopNumbersInput={gopNumbersInput} viewOnlyMode={viewOnlyMode} userStatus={userStatus.outputEBMAccess} debug={debug} userInfo={effectiveUserInfo} created_at={created_at} />
              )
            }
          />
        ) : null
      }

      {
        /*dialogData.dialog_type === "results" && open && (
          <EBM_Abrechnungskompass_Results scheine={scheine} kv={kv} gopNumbersInput={gopNumbersInput} viewOnlyMode={viewOnlyMode} userStatus={userStatus.outputEBMAccess} debug={debug} userInfo={effectiveUserInfo} created_at={created_at} />
        )*/
      }
      
    </div>
  );
};

export default EBM_Abrechnungskompass;