import React, { useState, useEffect } from 'react';
import { useSingleUserData } from '../../services/userService';
import * as DatabaseService from '../../services/DatabaseService';
import { Link, TableRow } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, Paper } from '@mui/material';
import { TextField } from '@mui/material';

import { dangerouslySetInnerHTML } from 'react-dom';

import Controls from '../../components/controls/Controls';
import getFilteredStates from './utils/getFilteredStates';

import ViewFormOutput from './components/ViewFormOuput';
import ProfileCard from './components/ProfileCard';
import DialogOutput from '../../components/DialogOutput';

import EBM_Abrechnungskompass_Results from './results/EBM_Abrechnungskompass_Results';

import {EBM_Loading_Input} from './components/Loading/LoadingSkeletonLayout';

const EBM_Abrechnungskompass = (props) => {

  const { user_input, user_input_scheine, user_input_state, view_only_mode } = props;

  const [viewOnlyMode, setViewOnlyMode] = useState(false);
  
  const [loadingData, setLoadingData] = useState({ hideFirstRow: false, hideSecondRow: false });
  const [loading, setLoading] = useState(true);

  const { userStatus, userRoleID } = useSingleUserData();

  const [scheine, setScheine] = useState('');
  const [kv, setKv] = useState('0');
  const [gopNumbersInput, setGopNumbersInput] = useState({});
  const [input_rows, setInput_rows] = useState([]);

  const [listStates, setListStates] = useState([]);

  
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "", dialog_type: "" });

  const groupedGopNumbers = input_rows.reduce((acc, row) => {
    const { header_no, header_description, gop, description } = row;
    if (!acc[header_no]) {
      acc[header_no] = {
        header_description,
        gops: []
      };
    }
    acc[header_no].gops.push({ gop, description });
    return acc;
  }, {});

  let columns = [[], [], [], []];
  
  const useManualAssignment = true; // Set this flag to true for manual assignment, false for dynamic assignment

  if (useManualAssignment) {
    // Manually assign specific items to columns
    Object.entries(groupedGopNumbers).forEach(([header_no, { header_description, gops }]) => {
      if (['1','2','3','4'].includes(header_no)) {
        columns[0].push({ header_no, header_description, gops }); // Assign to 1st column
      } else if (['5','6','7','8'].includes(header_no)) {
        columns[1].push({ header_no, header_description, gops }); // Assign to 2nd column
      } else if (['9','10','11','12','13','14','15'].includes(header_no)) {
        columns[2].push({ header_no, header_description, gops }); // Assign to 3rd column
      } else if (['16','17','18','19','20'].includes(header_no)) {
        columns[3].push({ header_no, header_description, gops }); // Assign to 4th column
      } else {
        // You can add additional logic here to assign other items to different columns if needed
        //columns[3].push({ header_no, header_description, gops }); // Default to 1st column
      }
    });
  } else {
    // Dynamically assign items to columns
    const totalItems = Object.keys(groupedGopNumbers).length;
    const itemsPerColumn = Math.ceil(totalItems / columns.length);
  
    let currentColumnIndex = 0;
    let currentItemCount = 0;
  
    Object.entries(groupedGopNumbers).forEach(([header_no, { header_description, gops }]) => {
      if (currentItemCount >= itemsPerColumn) {
        currentColumnIndex++;
        currentItemCount = 0;
      }
      if (currentColumnIndex >= columns.length) {
        currentColumnIndex = columns.length - 1; // Ensure we don't exceed the number of columns
      }
      columns[currentColumnIndex].push({ header_no, header_description, gops });
      currentItemCount++;
    });
  }
  
  //console.log('Grouped GOP Numbers:', groupedGopNumbers);
  //console.log('Columns:', columns);

  const getEBMInputRowsData = async (kv) => {
    try {
      
      const defaultKV = 2; // KV is also called state
      const defaultScheine = 0;

      const JSONData = await DatabaseService.getEBMAbrechnungskompassSearchByStates(defaultScheine,kv);
      if (JSONData && JSONData.rows) {
        setInput_rows(JSONData.rows);

        const initialGopNumbers = JSONData.rows.reduce((acc, row) => {
          acc[`fallzahl_${ row.gop }`] = ''; // Assuming each row has a gopNumber field
          return acc;
        }, {});
        setGopNumbersInput(initialGopNumbers);
      }

      if(JSONData && JSONData.stateRows){
        setListStates(JSONData.stateRows);
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
    }finally {
      setLoading(false); // Set loading to false when data fetch is complete
      setLoadingData({ hideFirstRow: true, hideSecondRow: true });
    }
  };

  useEffect(() => {
    getEBMInputRowsData(kv); // Fetch initial data
  }, [kv]);

  const handleScheineChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setScheine(value);
    }

    setOpen(false);
  };

  const handleKvChange = (e) => {
    setKv(e.target.value);

    setLoadingData({ hideFirstRow: true, hideSecondRow: false });
    setLoading(true);
    setOpen(false);
  };

  const handleGopNumberChange = (key, e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setGopNumbersInput((prevGopNumbers) => ({
        ...prevGopNumbers,
        [key]: value,
      }));
    }

    setOpen(false);
  };

  const calculate = () => {
    // Implement your calculation logic here
    console.log('Scheine:', scheine);
    console.log('KV:', kv);
    console.log('GOP Numbers:', gopNumbersInput);

    setDialogInformation({ title: "", information: "", status: "view", dialog_type: "results" });
    setOpen(true);
  };

  const handleViewForm = (gop, description) => {
    setDialogInformation({ title: gop, information: description, status: "view", dialog_type: "gop_information" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    if(view_only_mode) {
      setViewOnlyMode(true);
      setScheine(user_input_scheine);
      setKv(user_input_state);
      setGopNumbersInput(user_input);
    }

  }, [view_only_mode, user_input_scheine, user_input_state, user_input, gopNumbersInput]);

  //const filteredListStates = getFilteredStates(userRoleID, listStates);

  const filteredListStates = listStates;

  return (
    <div>

        { !loadingData.hideFirstRow ? <EBM_Loading_Input loadingData={loadingData} rowType={1} /> : <>

        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          <div>
            <TextField
              type="text"
              id="scheine"
              label="Scheine"
              value={scheine}
              onChange={handleScheineChange}
              variant="outlined"
              fullWidth
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                  color: 'black',
                },
                width: '100%'
              }}
              InputProps={{
                readOnly: viewOnlyMode,
              }}
              disabled={viewOnlyMode}
            />
          </div>
          <div>
            <Controls.Select
                name="state_id"
                label="KV"
                value={kv}
                onChange={handleKvChange}
                showNoneOption={false}
                options={filteredListStates}
                disabled={viewOnlyMode}
                sx={{ '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black', color: 'black' }}}
            />
          </div>
        </div>
        </>}
        
        {/* Main content area with columns layout */}

        { !loadingData.hideSecondRow ? <EBM_Loading_Input loadingData={loadingData} rowType={2} /> : <>
      <div style={{ 
        display: 'flex',
        gap: '20px',
        margin: '20px 0'
      }}>
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} style={{ flex: 1 }}>
            <TableContainer component={Paper} sx={{borderRadius:0}}>
              <Table size="small" aria-label="simple table" sx={{ border: ' ' }}>
                <TableHead>
                  <TableRow sx={{backgroundColor: '#006400'}}>
                    <TableCell sx={{ border: '1px solid #949494', color: '#ffffff', textAlign: 'center' }}>EBM-Ziffer</TableCell>
                    <TableCell sx={{ border: '1px solid #949494', color: '#ffffff', textAlign: 'center' }}>Anzahl</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            {column.map(({ header_no, header_description, gops }, rowIndex) => (
              <div key={header_no} style={{ 
                backgroundColor: '#fff',
                marginBottom: '20px',
                width: '100%'
              }}>

                <TableContainer component={Paper} sx={{borderRadius:0}}>
                  <Table size="small" aria-label="simple table" sx={{ border: '1px solid #949494' }}>
                    <TableHead>
                      <TableRow sx={{backgroundColor: '#CAE3B6'}}>
                        <TableCell colSpan={2} sx={{color: '#134020', border: '1px solid  #949494'}}>
                          <div dangerouslySetInnerHTML={{ __html: header_description }} />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {gops.sort((a, b) => a.gop - b.gop).map(({ gop, description }) => (
                          <TableRow
                            key={gop}
                            sx={{ '&:last-child td, &:last-child th': { border: '1px solid #949494' } }}
                          >
                            
                            <TableCell width={"50%"} sx={{ border: '1px solid #949494', textAlign: 'center' }}>
                              {
                                (description !== null && description !== '') ?
                                  <>
                                    <Link sx={{cursor:'pointer'}} onClick={() => { handleViewForm(gop, description) }}>{gop}</Link>
                                  </> : <>{gop}</>
                              }
                            </TableCell>

                            <TableCell width={"50%"} sx={{ border: '1px solid #949494', textAlign: 'center' }}>
                              <TextField
                                id={`fallzahl_${gop}`}
                                type="text"
                                value={gopNumbersInput[`fallzahl_${gop}`] || ''}
                                onChange={(e) => handleGopNumberChange(`fallzahl_${gop}`, e)}
                                placeholder="0"
                                size="small"
                                variant="filled"
                                hiddenLabel
                                sx={{marginTop:0, '& .MuiInputBase-input': {
                                                    textAlign: 'center', // Change this to 'left' or 'right' for other alignments
                                                    borderRadius: 0,
                                                  },
                                                  '& .MuiInputBase-root':{
                                                    borderTopLeftRadius: 0,
                                                    borderTopRightRadius: 0
                                                  },
                                                  '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: 'black',
                                                    color: 'black',
                                                  },
                                    }}
                                InputProps={{
                                  readOnly: viewOnlyMode,
                                }}
                                disabled={viewOnlyMode}
                              />
                            </TableCell>
                          </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
            ))}
            {columnIndex === columns.length - 1 && (
              <div>
                <ProfileCard onClick={calculate} />
              </div>
            )}
          </div>
        ))}
      </div>
      </>

      }

      {
        dialogData.dialog_type === "gop_information" || dialogData.dialog_type === "results" ? (
          <DialogOutput
            fullScreen={dialogData.dialog_type === "results"}
            open={open}
            dialogData={dialogData}
            handleClose={handleClose}
            backgroundColor={dialogData.dialog_type === "results" ? '#F1F2F4' : undefined}
            output={
              dialogData.dialog_type === "gop_information" ? (
                <ViewFormOutput description={dialogData.information} />
              ) : (
                <EBM_Abrechnungskompass_Results scheine={scheine} kv={kv} gopNumbersInput={gopNumbersInput} viewOnlyMode={viewOnlyMode} userStatus={userStatus} />
              )
            }
          />
        ) : null
      }

      {
        /*dialogData.dialog_type === "results" && open && (
          <EBM_Abrechnungskompass_Results scheine={scheine} kv={kv} gopNumbersInput={gopNumbersInput} viewOnlyMode={viewOnlyMode} userStatus={userStatus} />
        )*/
      }
      
    </div>
  );
};

export default EBM_Abrechnungskompass;