import React, { useState, useEffect } from "react";
import { Autocomplete, Grid, Typography, styled } from "@mui/material";
import Controls from "./controls/Controls";
import { softwareList } from "../constants/softwareList";
import getNestedField from '../utils/getNestedField';
import {usePLZ_OrtData} from '../constants/plz_ort';
import { userSalutationList, userTitleList } from "../constants/userOptionsList";

const GridContainer = styled(Grid)({
    '.MuiFormControl-root': {
        width: '100%',
    }
});

export const validateContactInfoForm = (formData) => {
    const error = {};
    const requiredFieldsMap = {
        fname: 'Vorname',
        lname: 'Name',
        email: 'E-Mail',
        'profile.phone': 'Telefon',
        'profile.iban': 'IBAN',
        'profile.postalCode': 'PLZ',
        'profile.city': 'Ort',
    }
    Object.keys(requiredFieldsMap).forEach((key) => {
        if (!getNestedField(formData, key)) {
            error[key] = `${requiredFieldsMap[key]} ist erforderlich`;
        }
    });
    // IBAN needs to starts with DE and 20<=len<=22
    if (
        formData.profile?.iban &&
        (
            !formData.profile.iban.startsWith('DE') ||
            formData.profile.iban.length < 20 ||
            formData.profile.iban.length > 22
        )
    ) {
        error['profile.iban'] = 'Bitte geben Sie eine korrekte IBAN an';
    }

    const isFullMembership = formData.profile?.isFullMembership || formData.roleId === "5";
    if (!isFullMembership) {
        delete error['profile.iban'];
    }
    return error;
}

export default function ContactInfoForm({ formData = {}, handleChange, formError = {}, handleValidation, signup = true, update = false }) {
  const showPracticeType = ['niedergelassen', 'angestellt', 'jobsharing'].includes(formData.profile?.currentStatus);
  const showInsuranceSeats = showPracticeType && ['bag', 'ubag', 'mvz'].includes(formData.profile?.practiceType);
  const showFullMembershipData = formData.profile?.isFullMembership || formData.roleId === "5";

  const { plzList, searchByPLZ } = usePLZ_OrtData();
  const [ortOptions, setOrtOptions] = useState([]);

  useEffect(() => {
    const plzResult = searchByPLZ(formData.profile?.postalCode);
    const newOrtOptions = plzResult ? plzResult.plz_ort_data.ort.map(ort => ({ ort })) : [];
    setOrtOptions(newOrtOptions);
  }, [formData.profile?.postalCode, searchByPLZ]);

  return (
    <GridContainer id="contact-form" container item spacing={2}>
      <Grid mt={2} item xs={12} sm={6}>
        <Controls.Select
            name="profile.salutation"
            label="Anrede"
            value={formData.profile?.salutation || ''}
            onChange={handleChange}
            showNoneOption={false}
            options={userSalutationList}
        />
      </Grid>
      <Grid mt={2} item xs={12} sm={6}>
        <Controls.Select
            name="profile.title"
            label="Titel"
            value={formData.profile?.title || ''}
            onChange={handleChange}
            showNoneOption={false}
            options={userTitleList}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controls.Input
            name="fname"
            label="Vorname*"
            value={formData.fname || ''}
            onChange={handleChange}
            error={formError?.fname}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controls.Input
            name="lname"
            label="Name*"
            value={formData.lname || ''}
            onChange={handleChange}
            error={formError?.lname}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controls.Input
            name="email"
            label="E-Mail*"
            value={formData.email || ''}
            disabled={!signup}
            onChange={handleChange}
            error={formError.email}
            onKeyUp={() => handleValidation && handleValidation()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controls.Input
            name="profile.phone"
            label="Telefon*"
            value={formData.profile?.phone || ''}
            onChange={handleChange}
            error={formError?.['profile.phone']}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
            * = erforderlich
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Controls.RadioGroup
            name="profile.currentStatus"
            label="Aktuell bin ich:"
            values={formData.profile?.currentStatus || ''}
            onChange={handleChange}
            items={[
            { id: 'niedergelassen', title: 'niedergelassen in eigener Praxis' },
            { id: 'angestellt', title: 'angestellt in einer Praxis' },
            { id: 'jobsharing', title: 'Jobsharingpartner/in' },
            { id: 'klinik', title: 'angestellt in einer Klinik' },
            { id: 'sonstiges', title: 'Sonstiges' },
            ]}
        />
      </Grid>
      {formData.profile?.currentStatus === 'niedergelassen' && (
      <Grid item xs={12}>
          <Controls.Input
          name="profile.establishmentDate"
          label="Zeitpunkt der Niederlassung (Monat/Jahr)"
          type="month"
          value={formData.profile?.establishmentDate || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          />
      </Grid>
      )}
      {showPracticeType && (
      <Grid item xs={12}>
          <Controls.Select
            name="profile.practiceType"
            label="Praxisform"
            value={formData.profile?.practiceType || ''}
            onChange={handleChange}
            showNoneOption={false}
            options={[
                { id: 'einzelpraxis', title: 'Einzelpraxis' },
                { id: 'praxisgemeinschaft', title: 'Praxisgemeinschaft' },
                { id: 'bag', title: 'Berufsausübungsgemeinschaft (BAG)' },
                { id: 'ubag', title: 'Überörtliche Berufsausübungsgemeinschaft (ÜBAG)' },
                { id: 'mvz', title: 'MVZ' },
            ]}
          />
      </Grid>
      )}
      {showInsuranceSeats && (
      <Grid item xs={12}>
          <Controls.Select
            name="profile.insuranceSeats"
            label="Anzahl Kassensitze"
            value={formData.profile?.insuranceSeats || ''}
            onChange={handleChange}
            showNoneOption={false}
            options={[
                { id: 'full', title: 'Voller Kassensitz' },
                { id: 'two', title: '2 Kassensitze' },
                { id: 'moreThanTwo', title: 'Mehr als 2 Kassensitze*' },
                { id: 'threeQuarters', title: 'Dreiviertel Kassensitz' },
                { id: 'half', title: 'Hälftiger Kassensitz' },
            ]}
          />
      </Grid>
      )}
      {showInsuranceSeats && formData.profile?.insuranceSeats === 'moreThanTwo' && (
        <Grid item xs={12}>
            <Typography sx={{ color: 'red' }} variant="body2">
                Aufgrund der von Ihnen angegebenen Praxisgröße möchten wir Sie bitten, für eine zukünftige volle Mitgliedschaft ein individuelles Angebot bei uns einzuholen, da wir bei mehr als zwei vollen Kassensitzen (Versorgungsaufträgen) die monatliche Gebühr neu kalkulieren.
            </Typography>
        </Grid>
      )}
      {formData.profile?.currentStatus === 'sonstiges' && (
      <Grid item xs={12}>
          <Controls.Input
          name="profile.otherStatus"
          label="Bitte spezifizieren"
          value={formData.profile?.otherStatus || ''}
          onChange={handleChange}
          />
      </Grid>
      )}
      {showPracticeType && (
      <Grid item xs={12}>
          <Autocomplete
            id="software"
            options={softwareList}
            value={softwareList.find((item) => item.id === formData.profile?.practiceManagementSystem) || null}
            onChange={(_, newValue) => {
                handleChange({ target: { name: 'profile.practiceManagementSystem', value: newValue?.id } });
            }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <Controls.Input {...params} label="Welches PVS System nutzen Sie in der Praxis" />}
            noOptionsText="Keine Optionen"
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <Controls.Input
            name="profile.address"
            label="Praxis-/Klinik-/Privatadresse: Straße, Hausnummer"
            value={formData.profile?.address || ''}
            onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="postalCode"
          options={formData.profile?.postalCode ? plzList : plzList.slice(0, 10)} // Limit initial display
          value={plzList.find((item) => item.plz === formData.profile?.postalCode) || null}
          onChange={(_, newValue) => {
            const newPostalCode = newValue?.plz || '';
            handleChange({ target: { name: 'profile.postalCode', value: newPostalCode } });

            // Update city based on the new postal code
            const plzResult = searchByPLZ(newPostalCode);
            const newCity = plzResult && plzResult.plz_ort_data.ort.length === 1 ? plzResult.plz_ort_data.ort[0] : '';
            handleChange({ target: { name: 'profile.city', value: newCity } });

            // Update ortOptions based on the new postal code
            const newOrtOptions = plzResult ? plzResult.plz_ort_data.ort.map(ort => ({ ort })) : [];
            setOrtOptions(newOrtOptions);
          }}
          getOptionLabel={(option) => option?.plz || ''}
          renderOption={(props, option) => {
            const { key, ...otherProps } = props;
            return <li key={option.id} {...otherProps}>{option.plz}</li>;
          }}
          renderInput={(params) => (
            <Controls.Input 
              {...params} 
              label="PLZ*" 
              error={formError?.['profile.postalCode']}
            />
          )}
          isOptionEqualToValue={(option, value) => option.plz === value.plz}
          filterOptions={(options, { inputValue }) => 
            inputValue ? plzList.filter(option => 
              option.plz?.toLowerCase().includes(inputValue.toLowerCase())
            ) : options // Show full filtered results when searching
          }
          noOptionsText="Keine Optionen"
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="city"
          options={ortOptions}
          value={ortOptions.find((item) => item.ort === formData.profile?.city) || null}
          onChange={(_, newValue) => {
            handleChange({ target: { name: 'profile.city', value: newValue?.ort || '' } });
          }}
          getOptionLabel={(option) => option?.ort || ''}
          renderOption={(props, option) => {
            const { key, ...otherProps } = props;
            return <li key={option.ort} {...otherProps}>{option.ort}</li>;
          }}
          renderInput={(params) => (
            <Controls.Input 
              {...params} 
              label="Ort*" 
              error={formError?.['profile.city']}
            />
          )}
          isOptionEqualToValue={(option, value) => option.ort === value.ort}
          filterOptions={(options, { inputValue }) => 
            options.filter(option => 
              option.ort?.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
          noOptionsText="Keine Optionen"
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
            name="profile.website"
            label="Praxiswebseite"
            value={formData.profile?.website || ''}
            onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
            name="profile.comments"
            label="Anmerkungen/Fragen"
            value={formData.profile?.comments || ''}
            onChange={handleChange}
            multiline
            rows={4}
        />
      </Grid>
      {(signup || (update && formData.roleId === '2')) && (
        <Grid item xs={12}>
            <Controls.Checkbox
                name="profile.isFullMembership"
                label="Vollmitgliedschaft"
                value={formData.profile?.isFullMembership || false}
                onChange={handleChange}
            />
        </Grid>
      )}
      {showFullMembershipData && (
      <>
          <Grid item xs={12} sm={6}>
            <Controls.Input
                name="profile.bsnr"
                label="Betriebsstättennummer (BSNR)"
                value={formData.profile?.bsnr || ''}
                onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
                name="profile.lanr"
                label="lebenslange Arztnummer (LANR)"
                value={formData.profile?.lanr || ''}
                onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
                name="profile.iban"
                label="IBAN (Lastschrift)*"
                value={formData.profile?.iban || ''}
                    error={formError?.[`profile.iban`]}
                onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
                name="profile.accountHolder"
                label="Kontoinhaber"
                value={formData.profile?.accountHolder || ''}
                onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input
                name="profile.billingDetails"
                label="Ihre Rechnungsdaten"
                value={formData.profile?.billingDetails || ''}
                onChange={handleChange}
                multiline
                rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Checkbox
                name="profile.sendInvoiceEmail"
                label="Sollen wir die Rechnung noch per E-Mail zusenden?"
                value={formData.profile?.sendInvoiceEmail || false}
                onChange={handleChange}
            />
          </Grid>
          {formData.profile?.sendInvoiceEmail && (
          <Grid item xs={12}>
              <Controls.Input
              name="profile.billingEmail"
              label="E-Mail-Adresse für Rechnungen"
              value={formData.profile?.billingEmail || ''}
              onChange={handleChange}
              />
          </Grid>
          )}
          <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary">
              Mit der Angabe Ihrer IBAN und der Bestätigung dieser Zahlung ermächtigen Sie GynSuccess GmbH und unseren Zahlungsdienstleister, Ihrer Bank Anweisungen zur Belastung Ihres Kontos gemäß diesen Anweisungen zu erteilen. Sie haben Anspruch auf Rückerstattung von Ihrer Bank gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank.
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Eine Rückerstattung muss innerhalb von 4 Wochen ab dem Datum der Belastung Ihres Kontos beantragt werden.
          </Typography>
          </Grid>
      </>
      )}
  </GridContainer>
  )
}