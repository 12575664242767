import React from 'react';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

export function checkDeviationPercentColor(row_deviation_percent, row_gop){

    let deviation_percent = parseFloat(row_deviation_percent).toFixed(2);
    let deviation_percent_color = "";
    let setlightRed = false;
    //01640 01737 01740 01760 01761 01764 01770 01820 01821 01822 01823 01824 01831

    setlightRed = ['01640',
                     '01737',
                     '01740',
                     '01760',
                     '01761',
                     '01764',
                     '01770',
                     '01820',
                     '01821',
                     '01822',
                     '01823',
                     '01824',
                     '01831'].includes(row_gop);

      if(!isNaN(deviation_percent)){
        if(deviation_percent > 50){
          
          if(!setlightRed){
            deviation_percent_color = 'red';
          }else{
            deviation_percent_color = 'lightred';
          }
            

        }else if(deviation_percent > 30){
          if(!setlightRed){
            deviation_percent_color = 'orange';
          }else{
            deviation_percent_color = 'lightred';
          }
        }
      }
    return deviation_percent_color;
}

export function checkDeviationPercentStatus(row_deviation_percent_status, tableType){

    let transparent = '#00000000', red = '#b2102f', orange = '#FF9800', black = '#000000', white = '#ffffff', lightred = '#FF7276';

    let backgroundColor = transparent;
    let color = 'inherit';
    let color_href = '#1976D2'
    let icon = '';
    let info = '';

    if(typeof row_deviation_percent_status !== 'undefined'){
      if(parseInt(tableType) === 2){
        if(row_deviation_percent_status === "red"){
            backgroundColor = red;
            color = white;
            color_href = white;
            icon = (<><ReportProblemOutlinedIcon /></>);
            info = "Achtung! Mehr als 50 % Abweichung!";
        }else if(row_deviation_percent_status === "orange"){
            backgroundColor = orange;
            color = white;
            color_href = white;
            icon = (<><ReportProblemOutlinedIcon /></>);
            info = "Achtung! Mehr als 30 % Abweichung!"
        }else if(row_deviation_percent_status === "lightred"){
            backgroundColor = lightred;
            color = white;
            color_href = white;
            icon = (<></>);
            info = ""
        }
      }
    }
  
    return { backgroundColor, color, color_href, icon, info };
}