import { Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useState } from 'react'

export default function OtpForm({ handleComplete }) {
  const [otp, setOtp] = useState('')

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  return (
    <>
      <Typography variant="h6" component="div" align="center">
        Einmalcode eingeben
      </Typography>
      <MuiOtpInput length={6} value={otp} onChange={handleChange} onComplete={handleComplete} validateChar={ch => !isNaN(ch)} />
    </>
  )
}