import React, { useEffect, useState, useRef } from 'react';
import * as DatabaseService from '../../../../services/DatabaseService';
import MuiDrawer from '../../../../components/MuiDrawer';
import Table_Section from './output/Table_Section';
import DialogOutput from '../../../../components/DialogOutput';
import ViewMissingGOPData from './output/View_Missing_GOP_Data';
import EBM_Abrechnungskompass from '../../EBM_Abrechnungskompass';

export default function MissingGopFeedbackPage() {
    const [listJSONData, setJSONData] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogData, setDialogInformation] = useState({ title: "", information: "", status: "" });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);

    const getGOPMissingData = async (page, rowsPerPage, search) => {
        try {
            const JSONData = await DatabaseService.getGOPMissingData((page + 1), rowsPerPage, search, '', '');
            if (JSONData && JSONData.rows) {
                setJSONData(JSONData.rows);
            }
            if (JSONData && JSONData.totalCount) {
                setTotalRowsCount(JSONData.totalCount);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {
        setDialogInformation({ information: "Loading...", status: "loading" });
        setLoading(true);
        getGOPMissingData(page, rowsPerPage, search)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        const updatedRows = listJSONData.map(item => ({
            id: item.id,
            fname: item.fname,
            lname: item.lname,
            state_name: item.state_name,
            gop_missing_data_json: item.gop_missing_data_json,
            created_at: item.created_at
        }));
        setRows(updatedRows);
    }, [listJSONData]);

    const handleMissingGopDataView = (json_data) => {

        //console.log("json_data: ");
        //console.log(json_data);

        setDialogInformation({ title: "", information: json_data, status: "view" });
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onDelete = (event) => {
        setDialogInformation({ information: "Löschend...", status: "loading" });
        setOpen(true);
        DatabaseService.delGOPMissingData(selected)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to delete row');
                }
            })
            .then(result => {
                setOpen(false);
                getGOPMissingData(page, rowsPerPage, search);
                setSelected([]);
            })
            .catch(error => {
                console.error('Error deleting row:', error);
                setOpen(false);
            });
    };

    return (
        <MuiDrawer>

            <br/>

            <Table_Section 
                tableTitle="Fehlende EBM-Ziffern"
                loading={loading}
                rows={rows}
                order={order}
                orderBy={orderBy}
                selected={selected}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                dense={dense}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSelected={setSelected}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setDense={setDense}
                onDelete={onDelete}
                setSearch={setSearch}
                search={search}
                handleMissingGopDataView={handleMissingGopDataView}
            />

            {
                dialogData.status === "loading" || dialogData.status === "view" ? (
                    <DialogOutput
                        fullScreen={(dialogData.status === "view") ? false: false}
                        open={open}
                        dialogData={dialogData}
                        handleClose={handleClose}
                        backgroundColor={dialogData.status === "view" ? '#F1F2F4' : undefined}
                        output={dialogData.status === "view" ? (
                            <ViewMissingGOPData data={dialogData.information} />
                        ) : null}
                        maxWidth='lg'
                    />
                ) : null
            }

        </MuiDrawer>
    );
}