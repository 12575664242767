import React, { useEffect, useState } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import * as DatabaseService from '../../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';
import { dangerouslySetInnerHTML } from 'react-dom';

import MuiDrawer from '../../../components/MuiDrawer';

import { useAuth } from '../../../contexts/AuthContext';

import GynSuccess_Home_Header from '../../../components/assets/images/template/GynSuccess_Home_Header.webp';
import GynSuccess_Leistungen_Bild2 from '../../../components/assets/images/template/GynSuccess_Leistungen_Bild2.webp';
import GynSuccess_Team_Header from '../../../components/assets/images/template/GynSuccess_Team_Header.webp';
import GynSuccess_Home_Bild3 from '../../../components/assets/images/template/GynSuccess_Home_Bild3.webp';
import Gynsuccess_Workshop1 from '../../../components/assets/images/template/Workshop1.webp';
import Gynsuccess_Workshop2 from '../../../components/assets/images/template/Workshop2.webp';
import Gynsuccess_January_Workshop from '../../../components/assets/images/template/January10_Workshop.webp';
import Gynsuccess_December_Workshop from '../../../components/assets/images/template/December13_Workshop.webp';
import Gynsuccess_Placeholder from '../../../components/assets/images/template/placeholder.webp';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '20px',
  padding: '0.3em 1em',
}));

const StyledButtonsmall = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '14px',
  padding: '0.3em 1em',
}));

const LightTypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'BR Firma Light',
  lineHeight: '1.8em',
  marginBottom: 0,
}));

const GynSuccessComponent1 = () => {
  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            GynSuccess Workshops
          </Typography>
          {/*<LightTypographyText variant="p" component="div" sx={{fontWeight: '300'}}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.​
                        </LightTypographyText>*/}
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ borderRadius: '8px', overflow: 'hidden', display: 'flex' }}
          >
            <img src={GynSuccess_Home_Bild3} alt="" style={{ width: '100%' }} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const GynSuccessComponent3 = (props) => {
  const { title, description, buttonName, buttonLink, bgColor } = props;

  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: bgColor, // Dark green background
    color: 'white',
    borderRadius: '16px',
    padding: theme.spacing(3),
  }));

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ mb: 2, marginBottom: 0 }}>
          <LightTypographyText
            variant="p"
            paragraph
            sx={{
              fontWeight: '300',
              '& div p:last-child': { marginBottom: 0 },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </LightTypographyText>
        </Box>
        {buttonName !== '' ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton variant="contained">{buttonName}</StyledButton>
            </Box>
          </>
        ) : (
          ''
        )}
      </CardContent>
    </StyledCard>
  );
}; /*
        <GynSuccessComponent3
          title="EBM-Abrechnungskompass​"
          description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ​"
          buttonName="Zustieg zum  EBM-Abrechnungstool"
          buttonLink=""
          bgColor="#006400"
        />
    */

const GynSuccessComponent5 = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0,0.36)',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: 'white',
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} fullWidth>
      <StyledCard>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Sie haben Fragen zur GynSuccess Plattform? Dann wenden Sie sich
            gerne direkt an uns!
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            variant="contained"
            component="a"
            href="https://www.gynsuccess.de/kontakt/#kontaktformular"
            target="_blank"
          >
            Kontakt
          </StyledButton>
        </Box>
      </StyledCard>
    </Box>
  );
};

const images = [
  {
    id: 1,
    url: Gynsuccess_December_Workshop, buttonUrl: 'https://events.teams.microsoft.com/event/e4f6059b-614d-4ecf-b88a-9c92bb648bb0@cb9d7a3d-9568-440c-af58-933a331fcac6',
    description: 'Zum Workshop'
  },
  {
    id: 2,
    url: Gynsuccess_January_Workshop, buttonUrl: 'https://events.teams.microsoft.com/event/d3047a91-530a-4127-be62-48362be033e6@cb9d7a3d-9568-440c-af58-933a331fcac6',
    description: 'Zum Workshop'
  },
  {
    id: 3,
    url: Gynsuccess_Placeholder, buttonUrl: '#', description: 'Kommt in Kürze'
  },
  // {
  //   id: 4,
  //   url: Gynsuccess_Placeholder, buttonUrl: '#', description: 'Kommt in Kürze'
  // },
  // {
  //   id: 5,
  //   url: Gynsuccess_Placeholder, buttonUrl: '#', description: 'Kommt in Kürze'
  // },
  // {
  //   id: 6,
  //   url: Gynsuccess_Placeholder, buttonUrl: '#', description: 'Kommt in Kürze'
  // },
  // {
  //   id: 7,
  //   url: Gynsuccess_Placeholder, buttonUrl: '#', description: 'Kommt in Kürze'
  // },
];

const ImageGrid = () => {
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: 8,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: 4,
          },
        }}
      >
        {images.map((image) => (
          <Card
            key={image.id}
            sx={{
              minWidth: 439,
              height: 'auto',
              m: 1,
              flexShrink: 0,
              position: 'relative',
              width: '50%',
            }}
          >
          <a href={image.buttonUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%' }}>
            <Box
              component="img"
              src={image.url}
              alt={`Lorem ipsum ${image.id}`}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            </a>
            <CardContent
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                backgroundColor: 'rgba(255, 255, 255, 0%)',
                transform: 'translateY(17px)',

              }}
            >
          <StyledButtonsmall
            variant="contained"
            component="a"
            href={image.buttonUrl} target="_blank"
          >
             {image.description}
          </StyledButtonsmall>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

const FullWidthSection1 = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F1F2F4',
          paddingTop: '8px',
          paddingBottom: '8px',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '80%',
            margin: 'auto',
            zIndex: '1',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default function Workshops() {
  const { login, currentUser, currentRole, getSession } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiDrawer>
      <Box sx={{ marginLeft: '-24px', marginRight: '-24px' }}>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <GynSuccessComponent1 />

          <Box sx={{ height: '24px' }}></Box>

          <GynSuccessComponent3
            title="Workshops für kontinuierliche Weiterbildung und Austausch mit Kolleginnen und Kollegen​"
            description="<p>Sie profitieren von unserer Umsetzungsexpertise, denn wir betreuen Optimierungsmaßnahmen nicht nur betriebswirtschaftlich sondern setzen in Praxisworkshops alle empfohlenen Entwicklungsschritte gemeinsam mit Ihnen und Ihrem Praxisteam um.</p>
                                   <p>Unterschiedliche Workshopformate, wie PeerGroups, Expertentreffen oder CoCreation sichern Ihnen den produktiven Austausch mit Gleichgesinnten und Gyn-Experten. </p>
                                   <p>Durch die entstehenden Netzwerkpartnerschaften werden Potenziale erkannt und Verständnis für betriebswirtschaftliche Zusammenhänge gefördert, um den Praxiserfolg nachhaltig und langfristig zu sichern.</p>
                                   <p>In regelmäßigen Online F&A Sessions werden Ihre Fragen schnell und unkompliziert beantwortet – Ihre monatliche Videohotline zu den Expertinnen und Experten der GynSuccess.</p>"
            buttonName=""
            buttonLink=""
            bgColor="#761448"
          />

          <Box sx={{ height: '24px' }}></Box>
        </Box>

        <FullWidthSection1>
          <ImageGrid />
        </FullWidthSection1>

        <Box sx={{ height: '24px' }}></Box>

        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            paddingTop: '25%',
            paddingBottom: '20%',
            backgroundImage: `url(${GynSuccess_Team_Header})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box
            sx={{
              width: '80%',
              margin: 'auto',
              zIndex: '1',
              position: 'relative',
            }}
          >
            <GynSuccessComponent5 />
          </Box>
        </Box>
      </Box>
    </MuiDrawer>
  );
}
