import { styled } from '@mui/system';

const ViewFormOutputStyle = styled('div')(({ theme }) =>
  theme.unstable_sx({
    //maxWidth: 1080, 
    //paddingBottom: 5, 
    '& table': {
        width: '100%',
        maxWidth: '100%!important',
        borderCollapse: 'collapse',  
    },
    '& td, & th': {
      border: '1px solid #dddddd',
      padding: '8px'
    },
    '& div.table':{
      overflowX: 'auto'
    },
    '& .space':{
      width: '14px',
      display: 'inline-block'
    },
    '& .displaynone':{
      display: 'none'
    },
    '& thead': {
      textWrap: 'nowrap',
      backgroundColor: '#006400',
      color: '#ffffff'
    },
    '& .table .darkgreen':{
      backgroundColor: '#006400',
      color:'#ffffff'
    },
    '& .table .lightgreen':{
      backgroundColor: '#d9ead3'
    },
    '& .table .lightgray':{
      backgroundColor: '#e3e3e3'
    },
    '& .table .lightorange':{
      backgroundColor: '#fce5cd'
    } 
  }),
);

export default ViewFormOutputStyle;