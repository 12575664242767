import { Box } from '@mui/material';
import { dangerouslySetInnerHTML } from 'react-dom';
import { CardComponent } from "./CardComponent";

const ViewFormOutputMembership = ({description}) => {

    const fixEncodingErrors = (str) => {
      return str
        .replace(/Ã¼/g, 'ü')
        .replace(/Ã¤/g, 'ä')
        .replace(/Ã¶/g, 'ö')
        .replace(/Ã/g, 'ß')
        .replace(/Ã©/g, 'é')
        .replace(/Ã¨/g, 'è');
    };
    
    let fixedDescription = "";
  
    try{
      fixedDescription = fixEncodingErrors(description);
    }catch(e){
      fixedDescription = description;
    }
  
    return (<>
  
            <Box sx={{
                        maxWidth: 1080, 
                        paddingBottom: 5, 
                        '& table': {
                            width: '100%',
                            maxWidth: 650,
                            borderCollapse: 'collapse',  
                        },
                        '& td, & th': {
                          border: '1px solid #dddddd',
                          padding: '8px'
                        },
                        '& div.table':{
                          overflowX: 'auto'
                        },
                        '& .space':{
                          width: '14px',
                          display: 'inline-block'
                        },
                        '& .displaynone':{
                          display: 'none'
                        } 
                    }}>
              
                <CardComponent 
                    title="Werden Sie volles Mitglied und schalten Sie alle Funktionen unseres EBM-Abrechnungskompasses frei!" 
                    description={
                                    `Der Zugang in die Welt von GynSuccess<br />
                                    <ul>
                                    <li>Unser Leistungsversprechen: Ihr möglicher Mehrumsatz (EBM, GOÄ) gleicht Ihre 6 Monats-Einstiegsmitgliedschaft mindestens aus. Sie gehen kein finanzielles Risiko ein!</li>
                                    <li>Einmaliges Starterpaket: Analyse, Gutachten, persönliche Beratung und Coaching</li>
                                    <li>Live Teilnahme an GynSuccess Webinaren</li>
                                    <li>Voller Zugriff auf alle Bonusinhalte</li>
                                    <li>Monatlicher Boxenstopp: persönlicher Coachingtermin – Ihre Ziele sind Programm – passgenau und nutzbringend</li>
                                    <li>Ab dem 6. Monat jederzeit monatlich kündbar</li>
                                    </ul>
                                `} 
                    buttonName="Jetzt Mitglied werden" 
                    buttonLink="https://www.gynsuccess.de/mitglied-werden/" 
                    onClick={() => {}} 
                    bgColor="#12401F" 
                    openInNewTab={true}
                />

            </Box>
    </>);
  
}

export default ViewFormOutputMembership;