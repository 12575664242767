import React, { useEffect, useState, createRef, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid, { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';

import TablePaginationActions from '../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../components/SearchInput';

import MuiDrawer from '../../components/MuiDrawer';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Controls from '../../components/controls/Controls';

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
/* End of Dialog Box */

import DialogOutput_PDFFiles from '../../components/DialogOutput';
import ViewPDFFiles from '../SubmitFiles/SubmitFiles'

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import Button from '@mui/material/Button';
// Table Libraries

/* Table Icons for Pagination */
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import FirstPageIcon from '@mui/icons-material/FirstPage';
/* Table Icons for Pagination */

// Search Icon
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import DirectionsIcon from '@mui/icons-material/Directions';
import ContactInfoForm, { validateContactInfoForm } from '../../components/ContactInfoForm';
// Seach Icon

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import setNestedField from '../../utils/setNestedField';


const colorDescriptions = [
    { color: '#fcf388', description: 'Mehr als 2 Kassensitze*' },
];

export default function UsersPage() {

    function createData(id, username, email, fname, lname, roleId, roleName, rowColor) {
        return {
            id,
            username,
            email,
            fname,
            lname,
            roleId,
            roleName,
            rowColor
        };
    }

    //let rows = [];

    const [listAllUsers, setUsersData] = useState([]);

    const [listAllUserRoles, setUserRolesListData] = useState([]);

    const [rows, setRows] = useState([]);

    const [totalRowsCount, setTotalRowsCount] = useState(0);

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    
    const [loading, setLoading] = useState(false); // State to manage loading indication
    //const [arr, setArr] = useState([])

    const [search, setSearch] = React.useState("");

    
    // Fetch users data and update the state
    const getUsersData = async (page, rowsPerPage, search) => {

        //const usersData = await DatabaseService.getAllUsersData();
        //setUsersData(usersData);

        try {
            const usersData = await DatabaseService.getAllUsersData((page + 1), rowsPerPage, search);
            if (usersData && usersData.rows) {
                setUsersData(usersData.rows); // Set the user data array to listAllUsers
            }

            if (usersData && usersData.totalCount) {
                setTotalRowsCount(usersData.totalCount); // Set the user data array to listAllUsers
            }

            /*if (usersData && usersData.search) {
                setSearchInput(usersData.search); // Set the user data array to listAllUsers
            }*/

        } catch (error) {
            console.error('Error fetching user data:', error);
        }

        //setLoading(false); // Set loading to false after data is fetched
    }

    const getUsersRolesData = async (page, rowsPerPage, search) => {
        try {

            const userRolesData = await DatabaseService.getAllUsersRolesData();

            if (userRolesData && userRolesData.rows) {
                setUserRolesListData(userRolesData.rows);
            }
            
        } catch (error) {
            console.error('Error fetching user roles data:', error);
        }
    }

    useEffect(() => {

        setLoading(true); // Set loading to true when fetching data

        getUsersData(page, rowsPerPage, search)
            .then(() => setLoading(false))
            .catch(error => {   
                console.error('Error fetching user data:', error);
                setLoading(false);
            });

    }, [page, rowsPerPage, search]);

    useEffect(() => {
        // Map listAllUsers to create rows and update the state
        const updatedRows = listAllUsers.map(item => {
            let rowColor = '';
            if (item.profile?.insuranceSeats === 'moreThanTwo') {
                rowColor = colorDescriptions[0].color;
            }
            return createData(
                item.id,
                item.username,
                item.email,
                item.fname,
                item.lname,
                item.role.id,
                item.role.roleName,
                rowColor
            );
        });

        // Update the rows state
        setRows(updatedRows);

    }, [listAllUsers]); // Watch for changes in listAllUsers


    useEffect(() => {
        getUsersRolesData();
    }, []);


    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }


    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const headCells = [
        {
            id: 'username',
            numeric: false,
            disablePadding: true,
            label: 'Username',
        },
        {
            id: 'fname',
            numeric: false,
            disablePadding: true,
            label: 'Firstname',
        },
        {
            id: 'lname',
            numeric: false,
            disablePadding: true,
            label: 'Lastname',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: 'roleName',
            numeric: false,
            disablePadding: true,
            label: 'Role',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: true,
            label: 'Action',
        },
        {
            id: 'action1',
            numeric: false,
            disablePadding: true,
            label: '',
        }
    ];
    
    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    /*Dialog Box*/
    const LoadingContainer = styled('div')(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }),
    );

    const [open, setOpen] = React.useState(false);

    const [user_id_view_pdf, setUserIDViewPDF] = useState({});
    const [openPDFFiles, setOpenPDFFiles] = React.useState(false);

    const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });

    const navigate = useNavigate();

    const theme = useTheme();
    /*Dialog Box*/
    
    /*const SearchDiv = styled('div')(({ theme }) =>
        theme.unstable_sx({
            display: 'inline-flex',
            position: 'relative',
            '& .MuiInputBase-input': {
                padding: '6.5px 14px',
                paddingRight: '41px',

            },
            '& .MuiInputBase-root': {
                borderColor: 'rgba(25, 118, 210, 0.5)'
            },
            '& .MuiButtonBase-root': {
                position: 'absolute',
                right: 0,
                minWidth: '42px',
                border: 0,
                padding: '6px 0px',
                borderRadius: '0 4px 4px 0'
            },
            label: {
                top: "-3px",
                '&[data-shrink="true"]': {
                    // Add your styles here for the label when data-shrink is true
                    top: 0,
                },
            },
        
        }),
    );
 
    const SearchInput = () => {
        
        const [searchTerm, setSearchTerm] = useState(search);
        
        // useCallback to memoize the handleChange function
        const handleChange = useCallback((event) => {
            setSearchTerm(event.target.value);
        }, []);

        
        // useCallback to memoize the handleSearch function
        const handleSearch = useCallback((e) => {
            e.preventDefault();
            // Perform search logic here

            setSearch(searchTerm)

            setPage(0)

        }, [searchTerm]); 

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                //handleSearch();
                setSearch(searchTerm)
                setPage(0)
            }
        };
        
        return (
            <>
                <SearchDiv>
                    <div>
                        <TextField
                                id="search"
                                label="Search"
                                name="search"
                                fullWidth
                                type="text"
                                size="small"
                                value={searchTerm}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress} // Trigger search on Enter key press
                        />
                    </div>
                    <div>
                        <Button variant="outlined" onClick={handleSearch}>
                            <SearchIcon />
                        </Button>
                    </div>
                </SearchDiv>
            </>
        );
    };*/

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;
        
        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                    paddingRight: '16px!important',
                    display: { xs: 'block', sm: 'flex' },
                    paddingTop: { xs: '8px', sm: '0' },
                    paddingBottom: { xs: '14px', sm: '0' },
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >

                            Users {/*<Button variant="outlined" onClick={handleAddUser}><AddIcon /></Button>*/}

                    </Typography>
                )}


                {

                    numSelected > 0 ? (<>
                        
                        <Tooltip title="Delete">
                            <Button color="error" variant="contained" onClick={onDelete}>
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                        
                    </>
                    ) : (
                        <>
                            <Box sx={{width:'10px', padding: { xs: '3px', sm: '0' } }}></Box>
                            <SearchInput setSearch={setSearch} setPage={setPage} search={search} />

                                {/*<Tooltip title="Filter list">
                              <IconButton>
                                  <FilterListIcon />
                              </IconButton>
                          </Tooltip>*/}
                        </>
                    )
                }
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    //const [selectedFid, setFidSelected] = React.useState([]);
    
    const [dense, setDense] = React.useState(false);
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        
    };

    const handleChangePage = (event, newPage) => {

        console.log(newPage);

        setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    /*const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;*/

    /*const visibleRows = React.useMemo(
      () =>
        stableSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [order, orderBy, page, rowsPerPage],
    );*/

    /*const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    );*/

    const visibleRows = stableSort(rows, getComparator(order, orderBy));
    
    /*TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }*/

    //POST Database Service
    function delUser_func(data) {
        return DatabaseService.delUserData(data)
    }

    function insertUserData_func(data) {
        return DatabaseService.insertUserData(data)
    }

    function updateUserData_func(id, data) {
        return DatabaseService.updateUserData(id, data)
    }
    //END of POST Database Service

    const onDelete = (event) => {

        //console.log(selected);

        setDialogInformation({ information: "Deleting...", status: "loading" });
        setOpen(true);

        delUser_func(selected)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to delete user');
                }
            })
            .then(result => {
                console.log(result);
                setOpen(false);
                // Update table data after successful deletion

                getUsersData(page, rowsPerPage, search);
                setSelected([]); // Clear selected array
            })
            .catch(error => {
                console.error('Error deleting user:', error);
                setOpen(false);
                // Handle error here, show message or take appropriate action
            });
    };
    
    /* Dialog Box */
    const handleUpdate = (rowData) => {
        // Now you have access to the rowData object associated with the clicked row
        console.log('Update button clicked for row:', rowData);
        // You can further pass this rowData to your update form or perform any necessary action
        const user = listAllUsers.find(user => user.id === rowData.id);
        setDialogInformation({ title: "Update User", information: user, status: "update" });
       
        setOpen(true);
    }

    const handleAddUser = (event) => {
        //const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "add" });

        setDialogInformation({ title: "Add User", information: "Test", status: "add" });
        setOpen(true);
    }

    const handleRedirectToDataList = () => {
        navigate('/');
    }

    const handleClose = () => {
        setOpen(false);
        setOpenPDFFiles(false);
    };
    /* Dialog Box */

    const handleViewPDFFile = (user_id) => {
        setUserIDViewPDF(user_id);
        setDialogInformation({ title: "PDF-Dateien", information: "", status: "view" });
        setOpenPDFFiles(true);
    }
  
    const UpdateUserFormOutput = ({ user }) => {

        const [userData, setUserData] = useState({
            user_id: user.id || '',
            username: user.username || '',
            currentusername: user.username || '',
            email: user.email || '',
            currentemail: user.email || '',
            fname: user.fname || '',
            lname: user.lname || '',
            roleId: user.roleId || '',
            password: '',
            profile: {
                salutation: user.profile?.salutation || '',
                title: user.profile?.title || '',
                phone: user.profile?.phone || '',
                currentStatus: user.profile?.currentStatus || '',
                establishmentDate: user.profile?.establishmentDate || null,
                practiceType: user.profile?.practiceType || '',
                insuranceSeats: user.profile?.insuranceSeats || '',
                otherStatus: user.profile?.otherStatus || '',
                practiceManagementSystem: user.profile?.practiceManagementSystem || '',
                address: user.profile?.address || '',
                postalCode: user.profile?.postalCode || '',
                city: user.profile?.city || '',
                website: user.profile?.website || '',
                comments: user.profile?.comments || '',
                isFullMembership: user.profile?.isFullMembership || false,
                bsnr: user.profile?.bsnr || '',
                lanr: user.profile?.lanr || '',
                iban: user.profile?.iban || '',
                accountHolder: user.profile?.accountHolder || '',
                billingDetails: user.profile?.billingDetails || '',
                sendInvoiceEmail: user.profile?.sendInvoiceEmail || !!user.profile?.billingEmail || false,
                billingEmail: user.profile?.billingEmail || '',
                upgradeFeatures: user.profile?.upgradeFeatures || false,
                upgradeFeaturesGoae: user.profile?.upgradeFeaturesGoae || false,
            }
        });
        
        const [errorUpdate, setErrorUpdate] = useState({});
        const [enableValidationUpdate, setEnableValidationUpdate] = useState(false);
        const [alertUpdate, setAlertUpdate] = useState({ text: '', appear: false, severity: '' });
        const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
        const [emailExistOnUpdate, setEmailExistOnUpdate] = useState(0);
        const [usernameExistOnUpdate, setUsernameExistOnUpdate] = useState(0);

        const [showPasswordUpdate, setShowPasswordUpdate] = React.useState(false);
        const handleClickShowPasswordUpdate = () => setShowPasswordUpdate((show) => !show);
        

        const checkEmailOnUpdate = async (data) => {
            return await DatabaseService.checkEmail(data);
        }

        const checkUsernameOnUpdate = async (data) => {
            return await DatabaseService.checkUsername(data);
        }

        const changeUpdateData = (e) => {
            const { name, value } = e.target;

            if (name === 'currentusername' || name === 'currentemail') {
                // Do not change currentusername and currentemail
                return;
            }

            setUserData(setNestedField(userData, name, value));
        };
        
        async function onUpdateUser() {

            console.log(userData);

            setEnableValidationUpdate(true);

            if (!validationUpdate(userData, true)) {

                setUpdateButtonDisabled(true);

                updateUserData_func(userData.user_id, userData)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('Failed to update user');
                        }
                    })
                    .then(result => {
                        console.log(result);

                        setAlertUpdate(
                            {
                                text: result.message,
                                appear: true,
                                severity: 'success'
                            }
                        )

                        setTimeout(() => {

                            setOpen(false);

                            // Update table data after successful deletion

                            getUsersData(page, rowsPerPage, search);
                            setSelected([]); // Clear selected array

                        }, 2000);
                        
                    })
                    .catch(error => {
                        console.error('Error updating user:', error);
                        setOpen(false);
                        // Handle error here, show message or take appropriate action
                    });
                    
            }

        }
        
        function onUpdateValidation() {

            checkEmailOnUpdate(userData.email).then(r => r.json()).then(result => {
                setEmailExistOnUpdate(result.rows);
            });

            checkUsernameOnUpdate(userData.username).then(r => r.json()).then(result => {
                setUsernameExistOnUpdate(result.rows);
            });

            validationUpdate(userData, enableValidationUpdate);

        }

        function onUpdateValidationFocusOut() {

            validationUpdate(userData, enableValidationUpdate);

        }

        function validationUpdate(userData, validate) {

            let errorUpdate = {}
            
            if (validate) {
                if (!(/$^|.+@.+..+/).test(userData.email)) {
                    errorUpdate.email = "Email is not valid.";
                } else {
                    if (userData.currentemail === userData.email) {
                        errorUpdate.email = "";
                        setEmailExistOnUpdate(0);
                    } else {

                        // Email is provided and valid
                        if (emailExistOnUpdate) {
                            errorUpdate.email = "Email already exists";
                        } else {
                            errorUpdate.email = "";
                        }
                    }

                }
                
                if (userData.username === "") {
                    errorUpdate.username = "Username is required";
                } else {

                    if (userData.currentusername === userData.username) {

                        errorUpdate.username = "";
                        setUsernameExistOnUpdate(0);

                    } else {
                        if (usernameExistOnUpdate) {
                            errorUpdate.username = "Username already exists";
                        } else {
                            errorUpdate.username = "";
                        }
                    }
                    
                }

                errorUpdate.password = (userData.password !== "")
                                        ? (userData.password.length < 8) ? "Password length must be 8 characters" : ""
                               : "";

                errorUpdate.roleId = (userData.roleId === "") ? "Role is required" : "";

            }


            errorUpdate = {...errorUpdate, ...validateContactInfoForm(userData)}

            setErrorUpdate(errorUpdate)

            return Object.keys(errorUpdate).some(k => !!errorUpdate[k]);
        }

        return (
            <>
                <DialogContent>

                    <TextField
                        hidden
                        disabled
                        margin="dense"
                        id="id"
                        label="ID"
                        name="id"
                        fullWidth
                        type="text"
                        variant="standard"
                        value={userData.user_id}
                        style={{ display: 'none' }} // Apply inline style to hide the TextField
                    />

                    <TextField
                        hidden
                        disabled
                        margin="dense"
                        id="currentusername"
                        label="currentusername"
                        name="currentusername"
                        fullWidth
                        type="text"
                        variant="standard"
                        value={userData.currentusername}
                        style={{ display: 'none' }}
                    />

                    <TextField
                        hidden
                        disabled
                        margin="dense"
                        id="currentemail"
                        label="currentemail"
                        name="currentemail"
                        fullWidth
                        type="text"
                        variant="standard"
                        value={userData.currentemail}
                        style={{ display: 'none' }}
                    />

                    <TextField
                        required
                        margin="dense"
                        id="username"
                        label="Benutzername"
                        name="username"
                        fullWidth
                        type="text"
                        variant="standard"
                        value={userData.username}
                        error={(enableValidationUpdate) ? (errorUpdate.username !== "") ? true : false : false}
                        helperText={errorUpdate.username}
                        onChange={changeUpdateData}
                        onKeyUp={onUpdateValidation}
                        onBlur={onUpdateValidationFocusOut}
                    />

                    <TextField
                        margin="dense"
                        id="password"
                        label="Password (leave blank to keep current)"
                        name="password"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        error={(enableValidationUpdate) ? (errorUpdate.password !== "") ? true : false : false}
                        helperText={errorUpdate.password}
                        onChange={changeUpdateData}
                        onKeyUp={onUpdateValidation}
                        onBlur={onUpdateValidationFocusOut}

                        type={showPasswordUpdate ? 'text' : 'password'}

                        InputProps={{
                        endAdornment :
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordUpdate}
                                >
                                    {showPasswordUpdate ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        
                        }}
                        sx={{display:'none'}}
                    />
                    <TextField
                        id="roleId"
                        select
                        name="roleId"
                        margin="dense"
                        label="Role"
                        defaultValue=""
                        variant="standard"
                        fullWidth
                        value={userData.roleId}
                        error={(enableValidationUpdate) ? (errorUpdate.roleId !== "") ? true : false : false}
                        helperText={errorUpdate.role}
                        onChange={changeUpdateData}
                        onKeyUp={onUpdateValidation}
                        onBlur={onUpdateValidationFocusOut}
                    >
                        { listAllUserRoles.map(
                            (option) => (
                                <MenuItem key={option.id} value={option.id} >
                                    {option.role_name}
                                </MenuItem>
                            )
                        )}
                    </TextField>

                    <Accordion sx={{ marginTop: '10px', boxShadow: 'none', '::before': { height: 0 } }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="contact-info-content"
                            id="contact-info-header"
                            sx={{ padding: 0 }}
                        >
                            Kontaktinformationen anzeigen
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                            <ContactInfoForm signup={false} formError={errorUpdate} formData={userData || {}} handleChange={changeUpdateData} />
                        </AccordionDetails>
                    </Accordion>

                    <Controls.Checkbox
                        name="profile.upgradeFeatures"
                        label="Vollständiger Funktionsumfang EBM"
                        value={userData?.profile?.upgradeFeatures}
                        onChange={changeUpdateData}
                    />

                    <Controls.Checkbox
                        name="profile.upgradeFeaturesGoae"
                        label="Vollständiger Funktionsumfang GOÄ"
                        value={userData?.profile?.upgradeFeaturesGoae}
                        onChange={changeUpdateData}
                    />

                    <Controls.Alert severity={alertUpdate.severity} appear={alertUpdate.appear} text={alertUpdate.text} sx={{ marginTop: "10px" }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onUpdateUser} disabled={updateButtonDisabled}>Update</Button>

                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </>
        );
    };

    const AddUserFormOutput = () => {
        
        const [registrationData, setRegistrationData] = useState({ username: '', password: '', password1: '', email: '', fname: '', lname: '', role: '' });
        const [errorRegistration, setErrorRegistration] = useState({});
        const [enableValidation, setEnableValidation] = useState(false);
        const [alertRegistration, setAlertRegistration] = useState({ text: '', appear: false, severity: '' });
        const [registerButtonDisabled, setRegisterButtonDisabled] = useState(false);
        const [emailExist, setEmailExist] = useState(0);
        const [usernameExist, setUsernameExist] = useState(0);

        const [showPassword, setShowPassword] = React.useState(false);
        const handleClickShowPassword = () => setShowPassword((show) => !show);

        const checkEmail = async (data) => {
            return await DatabaseService.checkEmail(data);
        }

        const checkUsername = async (data) => {
            return await DatabaseService.checkUsername(data);
        }

        function changeRegistrationData(e) {
            setRegistrationData({ ...registrationData, [e.target.name]: e.target.value });
        }

        async function onRegistration() {

            setEnableValidation(true);

            if (!validation(registrationData, true)) {

                setRegisterButtonDisabled(true);
                
                insertUserData_func(registrationData)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('Failed to add user');
                        }
                    })
                    .then(result => {
                        //console.log(result);

                        setAlertRegistration(
                            {
                                text: result.message,
                                appear: true,
                                severity: 'success'
                            }
                        )

                        setTimeout(() => {

                            setOpen(false);

                            // Update table data after successful deletion

                            getUsersData(page, rowsPerPage, search);
                            setSelected([]); // Clear selected array

                        }, 2000);
                        
                    })
                    .catch(error => {
                        console.error('Error adding user:', error);
                        setOpen(false);
                        // Handle error here, show message or take appropriate action
                    });

            }

        }

        function onRegistrationValidation() {

            checkEmail(registrationData.email).then(r => r.json()).then(result => {
                setEmailExist(result.rows);
            });
    
            checkUsername(registrationData.username).then(r => r.json()).then(result => {
                setUsernameExist(result.rows);
            });
    
            validation(registrationData, enableValidation);
            
        }

        function onRegistrationValidationFocusOut() {

            validation(registrationData, enableValidation);

        }

        function validation(registrationData, validate) {

            let error = {}

            if (validate) {

                error.email = (/$^|.+@.+..+/).test(registrationData.email) 
                ? (emailExist) ? "E-Mail existiert bereits" : ""
                    : "E-Mail ist ungültig";

                error.username = (registrationData.username === "") 
                    ? "Benutzername ist erforderlich" : (usernameExist) ? "Benutzername existiert bereits" : "";

                error.password = (registrationData.password === "")
                    ? "Password is required" :
                    (registrationData.password.length < 8) ? "Password length must be 8 characters"
                        : "";

                error.role = (registrationData.role === "") ? "Role is required" : "";

            }

            setErrorRegistration(error)

            if (Object.values(error).every(v => !v)) {
                return false;
            } else {
                return true;
            }

        }

        return (<>
            <DialogContent>
            <TextField
                    required
                    margin="dense"
                    id="fname"
                    label="First Name"
                    name="fname"
                    fullWidth
                    type="text"
                    variant="standard"
                    error={ (enableValidation) ? (errorRegistration.fname !== "") ? true : false : false }
                    onChange={changeRegistrationData}
                    onKeyUp={onRegistrationValidation}
                    helperText={errorRegistration.fname}
            />
            <TextField
                required
                margin="dense"
                id="lname"
                label="Last Name"
                name="lname"
                fullWidth
                type="text"
                variant="standard"
                error={(enableValidation) ? (errorRegistration.lname !== "") ? true : false : false }
                helperText={errorRegistration.lname}    
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}    
            />

            <TextField
                required
                margin="dense"
                id="email"
                label="Email Address"
                name="email"
                fullWidth
                type="email"
                variant="standard"
                error={(enableValidation) ? (errorRegistration.email !== "") ? true : false : false }
                helperText={errorRegistration.email}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                onBlur={onRegistrationValidationFocusOut}
            />

            <TextField
                required
                margin="dense"
                id="username"
                label="Benutzername"
                name="username"
                fullWidth
                type="text"
                variant="standard"
                error={(enableValidation) ? (errorRegistration.username !== "") ? true : false : false }
                helperText={errorRegistration.username}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                onBlur={onRegistrationValidationFocusOut}
            />

            <TextField
                required
                margin="dense"
                id="password"
                label="Passwort"
                name="password"
                fullWidth
                autoComplete="off"
                variant="standard"
                error={(enableValidation) ?(errorRegistration.password !== "") ? true : false : false}
                helperText={errorRegistration.password}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                    onBlur={onRegistrationValidationFocusOut}

                type={showPassword ? 'text' : 'password'}

                InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>

                }}
            />

            <TextField
                id="role"
                select
                name="role"
                margin="dense"
                label="Role"
                defaultValue=""
                variant="standard"
                fullWidth
                error={(enableValidation) ? (errorRegistration.role !== "") ? true : false : false}
                helperText={errorRegistration.role}
                onChange={changeRegistrationData}
                onKeyUp={onRegistrationValidation}
                onBlur={onRegistrationValidationFocusOut}
            >
                { listAllUserRoles.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.role_name}
                    </MenuItem>
                ))}
                </TextField>
                <Controls.Alert severity={alertRegistration.severity} appear={alertRegistration.appear} text={alertRegistration.text} sx={{ marginTop: "10px" }} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onRegistration} disabled={registerButtonDisabled}>
                    Insert
                </Button>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </>);

    }

    const DialogOutput = ({ fullScreen, open, dialogData, handleClose, handleRedirectToDataList }) => {

        const DialogAllContent = ({ dialogData }) => {

            if (dialogData.status === "loading") {

                return (<>
                    <DialogContent>
                        <LoadingContainer> <CircularProgress sx={{ mr: 2 }} /> {dialogData.information} </LoadingContainer>
                    </DialogContent>
                </>);

            } else if (dialogData.status === "add") {

                return (<>

                    <DialogTitle id="responsive-dialog-title">
                        {dialogData.title}
                    </DialogTitle>

                    <AddUserFormOutput />

                </>);
            } else if (dialogData.status === "update") {

                return (<>

                    <DialogTitle id="responsive-dialog-title">
                        {dialogData.title}
                    </DialogTitle>

                    <UpdateUserFormOutput user={dialogData.information} />

                </>);
            }
        }

        return (
            <>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogAllContent dialogData={dialogData} />
                    
                </Dialog>
            </>
        );
    };

    const LoadingTable = styled('div')(({ theme }) =>
        theme.unstable_sx({
            position:'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            zIndex: 1,
            paddingTop: '2.7em'
        }),
    );

    return (
        <MuiDrawer>     
            <br></br>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                   
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer sx={{ position:'relative' }}>
                        { /*Table Wrap*/}

                        {
                            loading ? (

                                <LoadingTable>
                                    <div>
                                        <CircularProgress />
                                    </div>
                                </LoadingTable>
                            ) : ""
                        }

                        <Box 
                            sx={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            alignItems: 'center', 
                            gap: 2,
                            p: 2,
                            bgcolor: 'background.paper',
                            }}
                        >
                            {colorDescriptions.map((item, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                        width: 16,
                                        height: 16,
                                        backgroundColor: item.color,
                                        mr: 1,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: '2px',
                                        }}
                                    />
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                        {item.description}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>

                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                    <TableBody>
                                    {
                                        (visibleRows.length > 0) ?
                                            visibleRows.map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer', backgroundColor: row.rowColor || 'inherit' }}
                                                        className='checkboxId'
                                                        data-id={row.id}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                onClick={(event) => handleClick(event, row.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.username}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.fname}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.lname}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.email}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.roleName}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            <Button variant="outlined" onClick={() => handleViewPDFFile(row.id)}>Dateien anzeigen</Button>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            <Button variant="outlined" onClick={() => handleUpdate(row)}><EditIcon /></Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        : loading ? (
                                                <TableRow
                                                    style={{
                                                        height: '120px',
                                                    }}
                                                ><TableCell colSpan={6} />
                                                </TableRow>
                                            ) : null
                                    }
                                    </TableBody>
                            </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={parseInt(totalRowsCount)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dichte Polsterung"
                />
            </Box>

            <DialogOutput
                fullScreen={false} // Set the value according to your requirement
                open={open}
                dialogData={dialogData}
                handleClose={handleClose}
                handleRedirectToDataList={handleRedirectToDataList}
            />

            <DialogOutput_PDFFiles
                      fullScreen={true} // Set the value according to your requirement
                      open={openPDFFiles}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      backgroundColor='#F1F2F4'
                      output={<ViewPDFFiles paramater_user_id={user_id_view_pdf} />}
            />

        </MuiDrawer>
    );
}