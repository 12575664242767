const unsortedSoftwareList = [
  { id: 'medical_office', label: 'Medical Office' },
  { id: 't2med', label: 'T2med' },
  { id: 'medatixx', label: 'medatixx' },
  { id: 'tomedo', label: 'tomedo' },
  { id: 'epikur', label: 'Epikur' },
  { id: 'cliniccentre_billing_opd', label: 'ClinicCentre Billing (OPD)' },
  { id: 'smarty', label: 'SMARTY' },
  { id: 'duria', label: 'DURIA' },
  { id: 'elefant', label: 'Elefant' },
  { id: 'red_medical', label: 'RED Medical' },
  { id: 'insuite', label: 'inSuite' },
  { id: 'rescuepro', label: 'RescuePro' },
  { id: 'medavis_ris', label: 'medavis RIS' },
  { id: 'imedone', label: 'IMedOne' },
  { id: 'nexus_kis', label: 'NEXUS / KIS' },
  { id: 'principa', label: 'principa' },
  { id: 'doctorly', label: 'Doctorly' },
  { id: 'praxis_programm', label: 'Praxis-Programm MediSoftware' },
  { id: 'medvision', label: 'MEDVISION' },
  { id: 'apris', label: 'APRIS' },
  { id: 'medi10', label: 'Medi10' },
  { id: 'kvdt_adt', label: 'KVDT (ADT)' },
  { id: 'xplore_ris', label: 'Xplore RIS' },
  { id: 'eris', label: 'eRIS' },
  { id: 'clinisys_vianova_labor', label: 'Clinisys vianova Labor' },
  { id: 'medibit', label: 'medibit' },
  { id: 'molis', label: 'MOLIS' },
  { id: 'labcentre', label: 'LabCentre' },
  { id: 'arkandus', label: 'Arkandus' },
  { id: 'nexus_ris', label: 'NEXUS RIS' },
  { id: 'david', label: 'David' },
  { id: 'praxis4more', label: 'Praxis4More' },
  { id: 'lx_labsolution', label: 'LX labsolution' },
  { id: 'z1', label: 'Z1' },
  { id: 'eva', label: 'EVA' },
  { id: 'opus_l', label: 'Opus::L' },
  { id: 'prolab', label: 'proLAB' },
  { id: 'classy_rt', label: 'CLASSY RT' },
  { id: 'mkis', label: 'M-KIS' },
  { id: 'ds_win_plus', label: 'DS-WIN-PLUS (DS-WIN-MED)' },
  { id: 'cgm_soul', label: 'CGM SOUL' },
  { id: 'iatropro', label: 'Iatropro' },
  { id: 'ekm_lab', label: 'EKM-LAB' },
  { id: 'pegasus', label: 'Pegasus' },
  { id: 'glims', label: 'GLIMS' },
  { id: 'lamis', label: 'LAMIS' },
  { id: 'evident', label: 'EVIDENT' },
  { id: 'eladis', label: 'ELADIS' },
  { id: 'eishisto', label: 'EISHISTO' },
  { id: 'is_l_seelig', label: 'I.S.-L-SEELIG' },
  { id: 'pathoflow', label: 'PathoFlow' },
  { id: 'acetomed', label: 'ACETOmed' },
  { id: 'cgm_vt', label: 'CGM VT' },
  { id: 'map_software', label: 'MAP Software' },
  { id: 'zymed_expert', label: 'ZyMed Expert' },
  { id: 'quickcon', label: 'QuickCON' },
  { id: 'med4winplus', label: 'Med4WinPlus' },
  { id: 'melos_labor_system', label: 'Melos-Labor-System' },
  { id: 'hs_laborsoftware', label: 'H&S Laborsoftware' },
  { id: 'pallidoc', label: 'PalliDoc' },
  { id: 'lis_plus', label: 'LIS++' },
  { id: 'co_fox', label: 'Co-Fox' },
  { id: 'medicus', label: 'MEDICUS' },
  { id: 'medipx', label: 'MEDIPX' },
  { id: 'profimed', label: 'PROFIMED' },
  { id: 'lina', label: 'LINA' },
  { id: 'imp_system', label: 'IMP::System' },
  { id: 'medi_line', label: 'MEDI_LINE' },
  { id: 'ashvins_xis', label: 'Ashvins xIS' },
  { id: 'imassense', label: 'imassense' },
  { id: 'smartlis', label: 'smartLIS' },
  { id: 'rad_plus_ris', label: 'RAD+ RIS' },
  { id: 'indication', label: 'INDICATION' },
  { id: 'c_lab', label: 'C-LAB' },
  { id: 'qmed_praxis', label: 'QMED.PRAXIS' },
  { id: 'crosshealth', label: 'CROSSHEALTH' },
  { id: 'vault_suite', label: 'VAULT Suite' },
  { id: 'genlab8', label: 'GenLAB8' },
  { id: 'apraxos', label: 'apraxos' },
  { id: 'fidus', label: 'FIDUS' },
  { id: 'unisolo_poesy', label: 'UNISOLO®-POESY' },
  { id: 'sylab', label: 'SYLAB' },
  { id: 'dc_pathos_dc_ross', label: 'dc-pathos/dc-ross' },
  { id: 'promed_open', label: 'PROMED-open / X-PROMED' },
  { id: 'win_radiolog', label: 'WIN-RADIOLOG' },
  { id: 'carw', label: 'CARW' },
  { id: 'imed_billing', label: 'i/med Billing' },
  { id: 'nexus_swisslab', label: 'NEXUS / SWISSLAB' },
  { id: 'melos', label: 'MELOS' },
  { id: 'disweb', label: 'DISweb' },
  { id: 'pdv_fr', label: 'PDV-FR' },
  { id: 'medos', label: 'MEDOS' },
  { id: 'kiwi_kind', label: 'KiWi - KIND' },
  { id: 'amasys', label: 'amasys' },
  { id: 'cgm_clinical', label: 'CGM CLINICAL' },
  { id: 'intermedinet', label: 'InterMediNet' },
  { id: 'arztpraxis_wiegand', label: 'Arztpraxis Wiegand' },
  { id: 'j_med', label: 'J-MED' },
  { id: 'arzt_2000', label: 'ARZT 2000' },
  { id: 'pro_medico', label: 'Pro_Medico' },
  { id: 'ifa_augenarzt', label: 'IFA-AUGENARZT' },
  { id: 's3_win', label: 'S3-Win' },
  { id: 'psychodat', label: 'PsychoDat' },
  { id: 'interarzt', label: 'InterARZT' },
  { id: 'nexus_pathologie_zytologie', label: 'NEXUS / PATHOLOGIE und NEXUS / ZYTOLOGIE' },
  { id: 'centricity_ris_i', label: 'Centricity RIS-i' },
  { id: 'pegamed', label: 'PegaMed' },
  { id: 'sed_doc', label: 'SED-DOC' },
  { id: 'sap_ambulatory_care_management', label: 'SAP Ambulatory Care Management' },
  { id: 'orbis', label: 'ORBIS®' },
  { id: 'cgm_medico', label: 'CGM MEDICO' },
  { id: 'psyprax', label: 'psyprax' },
  { id: 'rst_med_win', label: 'RST-MED Win' },
  { id: 'med7', label: 'Med7' },
  { id: 'data_al', label: 'Data-AL' },
  { id: 'data_vital', label: 'DATA VITAL' },
  { id: 'medys', label: 'MEDYS' },
  { id: 'quincy_win', label: 'QUINCY WIN' },
  { id: 'albis', label: 'ALBIS' },
  { id: 'cgm_m1_pro', label: 'CGM M1 PRO' },
  { id: 'el_elaphe_longissima', label: 'EL - Elaphe Longissima' },
  { id: 'x_comfort', label: 'x.comfort' },
  { id: 'radcentre_billing_opd', label: 'RadCentre Billing (OPD)' },
  { id: 'x_concept', label: 'x.concept' },
  { id: 'x_isynet', label: 'x.isynet' },
  { id: 'esqlab_online', label: 'esQlab.online' },
  { id: 'turbomed', label: 'Turbomed' },
  { id: 'cgm_medistar', label: 'CGM MEDISTAR' },
  { id: 'other', label: 'Sonstiges' }
];

export const softwareList = unsortedSoftwareList.sort((a, b) => a.label.localeCompare(b.label));
