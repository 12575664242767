export default function setNestedField (obj, path, value) {
  const keys = path.split('.');
  const lastKey = keys.pop();
  const deepObj = keys.reduce((o, key) => {
    if (!o[key]) {
      o[key] = {}; 
    }
    return o[key];
  }, obj);
  deepObj[lastKey] = value;

  return { ...obj };
};