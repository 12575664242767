import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './App/index.css';
import App from './App/App';

//import {BrowserRouter} from 'react-router-dom';

/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/

/*ReactDOM.render(
     <BrowserRouter><App></App></BrowserRouter>,
   document.getElementById('root')
 );*/


/* createRoot.render(
     <App />,
    document.getElementById('root')
 );*/

//New implementation for the ReactJS version 18
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render( <App />);
 
/*ReactDOM.render(
  <>index.js</>,
  document.getElementById('root')
);*/