import MuiDrawer from '../../components/MuiDrawer';
import SubmitFiles from './SubmitFiles';

export default function EBM_Abrechnungskompass_Main() {

  return (
            <MuiDrawer>
                <SubmitFiles />
            </MuiDrawer>
          );
}