function countIhrPotencialNoTrap(rows, debug){
  let ihrPotencialIncluded = 0;

    let testListIhrPotencialNoTrap = "gop,ihrpotenzial_notrap\n";

      rows.forEach((row, index) => {
        //const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        //const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        /*if(debug){
          
          console.log("ihrPotencialIncluded")
          console.log("fallzahl " + fallzahl)

          console.log("idealNumberOfTreatments")
          console.log("idealNumberOfTreatments " + idealNumberOfTreatments)

          console.log("Euro potenzial")
          console.log(row.gop)
          console.log("row.euro_potencial")
          console.log(row.euro_potencial)
          console.log("row.adjusted_euro_potencial")
          console.log(row.adjusted_euro_potencial)
        }*/

          //if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
              if (parseInt(row.include_chart) === 1) {
                if (row.gop !== '01773' && row.gop !== '01774') { 
                  if (typeof row.adjusted_euro_potencial === 'undefined') {
                      ihrPotencialIncluded += parseFloat(row.euro_potencial);
                      
                      if(debug){
                        //testListIhrPotencialNoTrap.push(...{row.gop, row.euro_potencial});
                        testListIhrPotencialNoTrap += row.gop + "," + row.euro_potencial + "\n";
                      }

                  } else {
                      ihrPotencialIncluded += parseFloat(row.adjusted_euro_potencial);
                      
                      if(debug){
                        //testListIhrPotencialNoTrap.push(...[row.gop, row.adjusted_euro_potencial]);
                        testListIhrPotencialNoTrap += row.gop + "," + row.adjusted_euro_potencial + "\n";
                      }
                  }
                  
                }else{
                  ihrPotencialIncluded += 0;

                  if(debug){
                    //testListIhrPotencialNoTrap.push(parseFloat(...[row.gop, 0]));
                    testListIhrPotencialNoTrap += row.gop + "," + 0 + "\n";
                  }
                }

              } else {
                  ihrPotencialIncluded += 0;

                  if(debug){
                    //testListIhrPotencialNoTrap.push(parseFloat(...[row.gop, 0]));
                    testListIhrPotencialNoTrap += row.gop + "," + 0 + "\n";
                  }
              }
          //} else {
          //    ihrPotencialIncluded += 0;
          //}
    });

    if(debug){
      //console.log("testListIhrPotencialNoTrap")
      //console.log(testListIhrPotencialNoTrap)
    }

    return ihrPotencialIncluded;
}

export default countIhrPotencialNoTrap;