import BarChart from '../../../components/charts/BarChart';
import germanFormatCurrency from "../../../utils/germanFormatCurrency";

const BarChartFilter = (props) => {

    const { rowsResult } = props;

      let gopsToSearch = ['01640', '01771', '01772', '01775', '33041']; // Array of ids you're searching for

      let BarChartRows = rowsResult.filter(row => gopsToSearch.includes(row.gop));

      let row01641 = rowsResult.find(row => ['01641'].includes(row.gop));

      /*
        only show Ihr Potenzial in € if 0
        if they enter any amount for 01640, 01771, 01772 01775 or 33041 => no potencial at all (0)
        if 0 for 01640
        => potencial of 01640 = potencial of 01640 + potencial of 01641
      */

      let transformedData = [
        ['Wert', 'Ihr Potenzial in €', { role: "tooltip", type: "string" }], // Header row
        ...BarChartRows.map(row => {
          let ihrPotenzial;
          
            if (row.euro_potencial_show === 1) {

                ihrPotenzial = row.adjusted_euro_potencial;
                 
                    if(parseInt(row.anzahl) > 0){
                        ihrPotenzial = 0;
                    }else{
                      
                      if(row.gop === '01640'){
                 
                        ihrPotenzial = ihrPotenzial + parseFloat((typeof row01641.adjusted_euro_potencial === 'undefined') ? row01641.euro_potencial : row01641.adjusted_euro_potencial);

                      }
                    }
                

                ihrPotenzial = parseFloat(ihrPotenzial.toFixed(2));
            
            } else {
              ihrPotenzial = 0;
            }

          return [row.gop, ihrPotenzial, row.gop + "\n" + germanFormatCurrency(ihrPotenzial)];
        })
      ]; 

      return (<>

          <BarChart data={transformedData} headerTitle="Zusätzliches Potenzial" titleLeft="EBM-Ziffer" titleBottom="Ihr Potenzial in €" />

      </>);

}

export default BarChartFilter;