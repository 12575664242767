export default function calculateUngenutzPotenzialAndGenutztesPotenzial(total_ihr_potenzial_included, gesamtpotenzial_included) {
    
    let ungenutztesPotenzial = parseFloat(total_ihr_potenzial_included.toFixed(2));

    let genutztesPotenzial = parseFloat(gesamtpotenzial_included - total_ihr_potenzial_included);

    let speedometerPercentage = parseFloat(genutztesPotenzial / gesamtpotenzial_included)  * 100;

    genutztesPotenzial = parseFloat(genutztesPotenzial).toFixed(2);
  
    if (isNaN(speedometerPercentage)) {
      speedometerPercentage = 0;
    }

    speedometerPercentage = parseFloat(speedometerPercentage).toFixed(2);

    return {
      ungenutztesPotenzial,
      genutztesPotenzial,
      speedometerPercentage
    }
}