import StatusChart from '../../../components/charts/StatusChart';
//import checkDeviationPercentStatus from '../calculations/checkDeviationPercentStatus';
import { checkDeviationPercentStatus } from '../calculations/ebmDeviationConditions';

const StatusChartFilter = (props) => {

    const { rowsResult } = props;

    //let gopsToSearch = ['01640', '01771', '01772', '01775', '33041']; // Array of ids you're searching for

    //let StatusChartRows = rowsResult.filter(row => gopsToSearch.includes(row.gop));

    let StatusChartRows = rowsResult.filter(row => 
      typeof row.deviation_in_percent_status !== "undefined" && row.deviation_in_percent_status !== ""
    );

    let redCount = 0;
    let orangeCount = 0;

    StatusChartRows.map((row, index) => {
        
        // Count the number of red and orange statuses
        if (row.deviation_in_percent_status === 'red') {
          redCount++;
        } else if (row.deviation_in_percent_status === 'orange') {
          orangeCount++;
        }
    })
    
    let data = [];

    if (redCount > 0) {
      data.push({ color: "red", count: redCount });
    }

    if (orangeCount > 0) {
      data.push({ color: "orange", count: orangeCount });
    }

    /*if(debug){
      console.log("StatusChartRows");
      console.log(StatusChartRows)

      console.log("row.deviation_in_percent_status")
      //console.log(StatusChartRows[0].deviation_in_percent_status)

      console.log("RED " + redCount)
      console.log("ORANGE " + orangeCount)
      console.log("Data")
      console.log(data)
    }*/

    return (<>
    
        <StatusChart 
                      data={data} 
                      headerTitle="Anzahl an EBM-Ziffern mit Warnhinweisen" 
                      titleLeft="" 
                      titleBottom=""
                      checkDeviationPercentStatus={checkDeviationPercentStatus}  
        />

    </>);
}

export default StatusChartFilter;