import React, { useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, TableSortLabel, Toolbar, Typography } from '@mui/material';

const ViewMissingGOPData = (props) => {

    const { data } = props;

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('ebm_ziffer');

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const filteredData = data.filter(item => 
        item.ebm_ziffer.toString().toLowerCase().includes(search.toLowerCase()) || 
        item.anzahl && item.anzahl.toString().includes(search)
    );

    const sortedData = filteredData.sort((a, b) => {
        if (orderBy === 'ebm_ziffer') {
            const aId = isNaN(a.ebm_ziffer) ? a.ebm_ziffer : parseInt(a.ebm_ziffer, 10);
            const bId = isNaN(b.ebm_ziffer) ? b.ebm_ziffer : parseInt(b.ebm_ziffer, 10);
            return (order === 'asc' ? aId - bId : bId - aId);
        } else {
            return (order === 'asc' ? a.anzahl - b.anzahl : b.anzahl - a.anzahl);
        }
    });

    const visibleRows = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ width: '100%' }}>
             <Paper sx={{ width: 600, mb: 2, margin: '0 auto' }}>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        paddingRight: '16px!important',
                        display: { xs: 'block', sm: 'flex' },
                        paddingTop: { xs: '8px', sm: '0' },
                        paddingBottom: { xs: '14px', sm: '0' },
                    }}
                >
                    <Typography
                        sx={{ flex: '0 13 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Fehlende EBM-Ziffern
                    </Typography>
                    <TextField
                        label="Suchen"
                        variant="outlined"
                        value={search}
                        size="small"
                        onChange={handleSearchChange}
                    />
                </Toolbar>
                <TableContainer sx={{ position: 'relative' }}>
                    <Table aria-labelledby="tableTitle" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={orderBy === 'ebm_ziffer' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'ebm_ziffer'}
                                        direction={orderBy === 'ebm_ziffer' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'ebm_ziffer')}
                                    >
                                        EBM-Ziffer
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={orderBy === 'anzahl' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'anzahl'}
                                        direction={orderBy === 'anzahl' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'anzahl')}
                                    >
                                        Anzahl
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows.length > 0 ? visibleRows.map((item) => (
                                <TableRow hover key={item.id}>
                                    <TableCell>{item.ebm_ziffer}</TableCell>
                                    <TableCell>{item.anzahl}</TableCell>
                                </TableRow>
                            )) : (
                                <TableRow style={{ height: '120px' }}>
                                    <TableCell colSpan={2} align="center">
                                        Keine Daten gefunden
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Zeilen pro Seite"
                />
            </Paper>
        </Box>
    );
};

export default ViewMissingGOPData;