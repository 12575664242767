import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import * as DatabaseService from '../../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';
import { dangerouslySetInnerHTML } from 'react-dom';

import MuiDrawer from '../../../components/MuiDrawer';

import { useAuth } from '../../../contexts/AuthContext';

import GynSuccess_Home_Header from '../../../components/assets/images/template/GynSuccess_Home_Header.webp';
import GynSuccess_Leistungen_Bild2 from '../../../components/assets/images/template/GynSuccess_Leistungen_Bild2.webp';
import GynSucces_Backend_1 from '../../../components/assets/images/template/GynSucces_Backend_1.webp';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '20px',
  padding: '0.3em 1em',
}));

const LightTypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'BR Firma Light',
  lineHeight: '1.8em',
  marginBottom: 0,
}));

const GynSuccessComponent1 = () => {
  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            GynSuccess Digitalisierungssupport
          </Typography>
          {/*<LightTypographyText variant="p" component="div" sx={{fontWeight: '300'}}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.​
                        </LightTypographyText>*/}
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ borderRadius: '8px', overflow: 'hidden', display: 'flex' }}
          >
            <img src={GynSucces_Backend_1} alt="" style={{ width: '100%' }} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const GynSuccessComponent3 = (props) => {
  const { title, description, buttonName, buttonLink, bgColor } = props;

  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: bgColor, // Dark green background
    color: 'white',
    borderRadius: '16px',
    padding: theme.spacing(3),
  }));

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ mb: 2, marginBottom: 0 }}>
          <LightTypographyText
            variant="p"
            paragraph
            sx={{
              fontWeight: '300',
              '& div p:last-child': { marginBottom: 0 },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </LightTypographyText>
        </Box>
        {buttonName !== '' ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton
                variant="contained"
                component={NavLink}
                to={buttonLink}
              >
                {buttonName}
              </StyledButton>
            </Box>
          </>
        ) : (
          ''
        )}
      </CardContent>
    </StyledCard>
  );
}; /*
        <GynSuccessComponent3
          title="EBM-Abrechnungskompass​"
          description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ​"
          buttonName="Zustieg zum  EBM-Abrechnungstool"
          buttonLink=""
          bgColor="#006400"
        />
    */

const GynSuccessComponent5 = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0,0.36)',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: 'white',
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} fullWidth>
      <StyledCard>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
          Sie haben spezifische Fragen zur Digitalisierung? Dann wenden Sie sich gerne direkt an uns!
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            variant="contained"
            component="a"
            href="https://www.gynsuccess.de/kontakt/#kontaktformular"
            target="_blank"
          >
            Kontakt
          </StyledButton>
        </Box>
      </StyledCard>
    </Box>
  );
};

export default function Platform() {
  const { login, currentUser, currentRole, getSession } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiDrawer>
      <Box sx={{ marginLeft: '-24px', marginRight: '-24px' }}>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <GynSuccessComponent1 />

          <Box sx={{ height: '24px' }}></Box>

          <GynSuccessComponent3
            title="Digitalisierungssupport für die Optimierung digitaler Tools​​"
            description="<p>Evaluieren und implementieren Sie neue Technologien zur Verbesserung der Praxisabläufe und der Patientinnenversorgung.<br>Integrieren Sie Telemedizinlösungen, um die Reichweite der Praxis zu erhöhen und Patientinnen flexibel betreuen zu können.<br>Generieren Sie Wissen zur KI-Anwendung in Ihrer Praxis immer dann, wenn es Ihnen persönlichen Nutzen bietet oder Sie beispielsweise administrative Aufgaben automatisieren möchten. Unser KI-Team, bestehend aus IT- Experten und visionären Gynäkologen stellen die Praxisrelevanz sicher!</p>
                                   <p>Wir unterstützen Sie und Ihr Praxismanagement dabei, dass diese Lösungen in den täglichen Betrieb integriert und effektiv genutzt werden.</p>
                                   <p>Zukünftig: Unser unternehmenseigener Datenpool mit aktuellen Benchmarks aus Ihrer Fachgruppe garantiert eine präzise Bewertung und Vergleichbarkeit Ihrer Praxiskennzahlen mit ähnlichen Praxisgrößen und -strukturen.​ Dieses Tool gewinnt immer mehr an Bedeutung, umso mehr Mitglieder auf der Plattform sind.</p>"
            buttonName=""
            buttonLink=""
            bgColor="#014017"
          />

          <Box sx={{ height: '24px' }}></Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            paddingTop: '200px',
            paddingBottom: '200px',
            backgroundImage: `url(${GynSuccess_Leistungen_Bild2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box
            sx={{
              width: '80%',
              margin: 'auto',
              zIndex: '1',
              position: 'relative',
            }}
          >
            <GynSuccessComponent5 />
          </Box>
        </Box>
      </Box>
    </MuiDrawer>
  );
}
