import ScheineChart from "../../../components/charts/ScheineChart";

const ScheineChartFilter = (props) => { 

    const { scheine } = props;

    let data = (isNaN(parseFloat(scheine))) ? 0 * parseFloat(0.175) : parseFloat(scheine) * parseFloat(0.175);

    return (<>

      <ScheineChart data={data} headerTitle="Anzahl der Patientinnen für die offene Sprechstunde" />
    
    </>);

}

export default ScheineChartFilter;