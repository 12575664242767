import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import MuiDrawer from '../../../components/MuiDrawer';

import { useAuth } from '../../../contexts/AuthContext';

import GynSuccess_Home_Header from '../../../components/assets/images/template/GynSuccess_Home_Header.webp';
import GynSuccess_Team_Bild2 from '../../../components/assets/images/template/GynSuccess_Team_Bild2.webp';
import GynSuccess_Backend_Mitgliedschaft from '../../../components/assets/images/template/GynSuccess_Backend_Mitgliedschaft.svg';
import GynSuccess_Backend_Testmitgliedschaft from '../../../components/assets/images/template/GynSuccess_Backend_Testmitgliedschaft.svg';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '20px',
  padding: '0.3em 1em',
}));

const LightTypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'BR Firma Light',
  lineHeight: '1.8em',
}));

const GynSuccessComponent1 = () => {
  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            Willkommen in der Welt von GynSuccess​
          </Typography>
          <LightTypographyText
            variant="p"
            component="div"
            sx={{ fontWeight: '300' }}
          >
            Hier finden Sie alle Leistungen der Plattform mit Zustiegen zum
            Abrechnungskompass, Inhalten zu Coaching & Beratung,
            Digitalisierungssupport, Workshops sowie die Mediathek.​
          </LightTypographyText>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ borderRadius: '8px', overflow: 'hidden', display: 'flex' }}
          >
            <img
              src={GynSuccess_Home_Header}
              alt="GynSuccess_Home_Header"
              style={{ width: '100%' }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const GynSuccessComponent2 = (props) => {
  const {
    title,
    description,
    image,
    textColor,
    backgroundColor,
    cardPadding = '',
    cardContentPadding = '',
  } = props;

  /*
        backgroundColor: '#E3E3E3',
        color: '#014017',
        GynSuccess_Backend_Mitgliedschaft
    */

  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: backgroundColor,
    color: textColor,
    borderRadius: '16px',
    padding: cardPadding !== '' ? cardPadding : theme.spacing(2),
  }));

  return (
    <StyledCard>
      <CardContent
        sx={{ padding: cardContentPadding !== '' ? cardContentPadding : '' }}
      >
        {title !== '' ? (
          <>
            <Typography variant="h4" component="h2" gutterBottom>
              {/*Starterpaket Mitgliedschaft*/}
              {title}
            </Typography>
          </>
        ) : (
          <></>
        )}

        {description !== '' ? (
          <>
            <LightTypographyText variant="p" paragraph>
              {/*Individuelle Boxenstopps*/}
              {description}
            </LightTypographyText>
          </>
        ) : (
          <></>
        )}

        <Box
          sx={{
            mt: title !== '' || description !== '' ? 2 : 0,
            textAlign: 'center',
            display: 'flex',
          }}
        >
          <img src={image} width={'100%'} />
        </Box>
      </CardContent>
    </StyledCard>
  );
};

const GynSuccessComponent3 = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#006400', // Dark green background
    color: 'white',
    borderRadius: '16px',
    padding: theme.spacing(3),
  }));

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          Der Abrechnungskompass
        </Typography>
        <Box sx={{ mb: 2 }}>
          <LightTypographyText variant="p" paragraph sx={{ fontWeight: '300' }}>
            Ihre Toollandschaft für EBM-,GOÄ- und IGeL-Abrechnungsoptimierung.
            <br />
            Profitieren Sie von Echtzeitverbesserung, Benchmarks und
            vollumfänglicher Potentialausschöpfung!
          </LightTypographyText>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            variant="contained"
            component={NavLink}
            to="/plattform/abrechnungskompass"
          >
            Hier finden Sie Ihren Abrechnungskompass
          </StyledButton>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

const GynSuccessComponent4 = () => {
  const StyledBox = styled(Box)(({ theme }) => ({
    color: 'white',
    borderRadius: '16px',
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <StyledBox sx={{ backgroundColor: '#76B900' }}>
          <div>
            <Typography variant="h5" gutterBottom>
              Coaching & Beratung
            </Typography>
            <LightTypographyText variant="body1">
            Basierend auf der initialen Analyse und Bewertung Ihrer Abrechnungen mit dem Abrechnungskompass, zielt die Praxisberatung auf eine nachhaltige und effiziente Praxisführung ab. In Kombination mit systemischem Business Coaching schaffen wir eigenverantwortliche Entwicklung individueller Lösungskompetenz.
            </LightTypographyText>
          </div>
          <StyledButton
            variant="contained"
            fullWidth
            sx={{ marginTop: '1em' }}
            component={NavLink}
            to="/plattform/coaching-praxisberatung"
          >
            Hier finden Sie Ihr Coaching- und Beratungspaket
          </StyledButton>
        </StyledBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <StyledBox sx={{ backgroundColor: '#761448' }}>
          <div>
            <Typography variant="h5" gutterBottom>
              Digitalisierungssupport
            </Typography>
            <LightTypographyText variant="body1">
            Moderne, effiziente und zukunftssichere Patientinnenversorgung garantieren Sie durch die Nutzung einer individualisierten Telematik- und Telemedizininfrastruktur. GynSuccess ebnet Ihnen diesen Weg und behält den Einsatz von KI-Technologien sowie IT-sicherheitsrelevante Kriterien stehts im Auge.
            </LightTypographyText>
          </div>
          <StyledButton
            variant="contained"
            fullWidth
            sx={{ marginTop: '1em' }}
            component={NavLink}
            to="/plattform/digitalisierungssupport"
          >
            Hier finden Sie Ihren Digitalisierungssupport
          </StyledButton>
        </StyledBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <StyledBox sx={{ backgroundColor: '#0F3F93' }}>
          <div>
            <Typography variant="h5" gutterBottom>
              Workshops
            </Typography>
            <LightTypographyText variant="body1">
              Ihre GynSuccess Workshops schaffen die Basis für kontinuierliche
              Weiterentwicklung und den Austausch mit Kolleginnen und Kollegen
              in der Neuniederlassung.
            </LightTypographyText>
          </div>
          <StyledButton
            variant="contained"
            sx={{ marginTop: '1em' }}
            component={NavLink}
            to="/plattform/workshop"
          >
            Hier finden Sie Ihre Workshops
          </StyledButton>
        </StyledBox>
      </Grid>
    </Grid>
  );
};

const GynSuccessComponent5 = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0,0.36)',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: 'white',
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} fullWidth>
      <StyledCard>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            GynSuccess Mediathek
          </Typography>
          <LightTypographyText variant="body1" paragraph>
            Hier finden Sie eine Übersicht über aktuelle Podcast-Episoden,
            vergangene Webinare sowie einführende Videos in die
            Plattformnutzung.
          </LightTypographyText>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton variant="contained" component={NavLink} to="/mediathek">
            Ihr Zugang zur Mediathek
          </StyledButton>
        </Box>
      </StyledCard>
    </Box>
  );
};

export default function Platform() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiDrawer>
      <Box sx={{ marginLeft: '-24px', marginRight: '-24px' }}>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <GynSuccessComponent1 />
          <Box sx={{ height: '24px' }}></Box>

          <GynSuccessComponent2
            title=""
            description=""
            image={GynSuccess_Backend_Mitgliedschaft}
            textColor="#014017"
            backgroundColor="#E3E3E3"
            cardPadding="0"
            cardContentPadding="0 0 0 0!important"
          />

          <Box sx={{ height: '24px' }}></Box>

          <GynSuccessComponent2
            title=""
            description=""
            image={GynSuccess_Backend_Testmitgliedschaft}
            textColor="#761448"
            backgroundColor="#E3E3E3"
            cardPadding="0"
            cardContentPadding="0 0 0 0!important"
          />

          <Box sx={{ height: '24px' }}></Box>

          <GynSuccessComponent3 />
          <Box sx={{ height: '24px' }}></Box>
          <GynSuccessComponent4 />
          <Box sx={{ height: '24px' }}></Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            paddingTop: '200px',
            paddingBottom: '200px',
            backgroundImage: `url(${GynSuccess_Team_Bild2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box
            sx={{
              width: '80%',
              margin: 'auto',
              zIndex: '1',
              position: 'relative',
            }}
          >
            <GynSuccessComponent5 />
          </Box>
        </Box>
      </Box>
    </MuiDrawer>
  );
}
