import React, { useEffect, useState, createRef, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';
import TablePaginationActions from '../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../components/SearchInput';

import MuiDrawer from '../../components/MuiDrawer';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
/* End of Dialog Box */

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import Button from '@mui/material/Button';
// Table Libraries

import { CSVLink, CSVDownload } from "react-csv";
import FileSaver from 'file-saver';
import { CloseFullscreen } from '@mui/icons-material';

/*const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  canvasSize:{
    [theme.breakpoints.up(981)]: {
      height: '30vw',
      width: '100%',
    },
    [theme.breakpoints.down(981)]: {
      height: '41vw',
    },
    [theme.breakpoints.down(960)]: {
      height: '46vw',
    },
    [theme.breakpoints.down(768)]:{
      height: '59vw',
    },
    [theme.breakpoints.down(526)]:{
      height: '70vw',
    },
    [theme.breakpoints.down(413)]:{
      height: '78vw',
    },
  },
  gridCategoryCounterChart:{
    marginTop: '11px'
  },
  gridApplicationCounter:{
    [theme.breakpoints.down(600)]:{
      '& .MuiGrid-item':{
        paddingBottom: 0
      }
    }
  }
}));*/

// FullScreen Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//--table style

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
//--end of table style

//End of FullScreen Dialog


export default function ComparisonPage() {

    // Table for FullScreen Dialog
    /*function createData1(id, id_number, number_text, anzahl, soll, abweichung, abweichung_percent, median, score, potential) {
      return { id, id_number, number_text, anzahl, soll, abweichung, abweichung_percent, median, score, potential };
    }*/

    const [rowsTableFullScreenDialog, setRowsTableFullScreenDialog] = useState([]);
    // End of Table for FullScreen Dialog

    function createData(id, filename, fid, status, csv_directory, download_csv) {
        return {
            id,
            filename,
            fid,
            status,
            csv_directory, 
            download_csv
        };
    }

    //let rows = [];

    const [listCSVFiles, setPDFFiles] = useState([]);

    const [csv_data, setCSVData] = useState("");

    const [rows, setRows] = useState([]);

    const [totalRowsCount, setTotalRowsCount] = useState(0);

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [loading, setLoading] = useState(false); // State to manage loading indication

    const [search, setSearch] = React.useState("");

    const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data


    //FullScreen Dialog
    const [openFullScreenDialog, setOpenFullScreenDialog] = React.useState(false);
    //End of FullScreen Dialog

    //const [rows, setRows] = useState([]);
    //const [arr, setArr] = useState([])
    
    const getCSV = async (id, filename) => {
      try{

        const CSVData = await DatabaseService.getCSV(id);

        if(CSVData.ok) {
          const blob = await CSVData.blob();
          FileSaver.saveAs(blob, filename + '.csv');
        } else {
            console.error('Server responded with status: ' + CSVData.status);
        }

        console.log("CSVData");
        console.log(CSVData);
        
        /*if(CSVData){ setCSVData(CSVData[0].csv_data); }*/

      }catch(e){}
    }

    const getCSVFilesData = async (page, rowsPerPage, search) => {

        try {
            const PDFFileData = await DatabaseService.getCSVFilesData((page + 1), rowsPerPage, search);
            if (PDFFileData && PDFFileData.rows) {
                setPDFFiles(PDFFileData.rows);
            }

            if (PDFFileData && PDFFileData.totalRowsCount) {
                setTotalRowsCount(PDFFileData.totalRowsCount);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {

        setLoading(true); // Set loading to true when fetching data

        getCSVFilesData(page, rowsPerPage, search)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching user data:', error);
                setLoading(false);
            });

    }, [page, rowsPerPage, search]);

    useEffect(() => {
        // Map listAllUsers to create rows and update the state
        const updatedRows = listCSVFiles.map(item => createData(
            item.fid,
            item.filename,
            item.fid,
            item.status,
            item.csv_directory,
            item.download_csv
        ));

        // Update the rows state
        setRows(updatedRows);

        console.log("updatedRows")
        console.log(updatedRows)

    }, [listCSVFiles]); // Watch for changes in listAllUsers
    
    //console.log("csv_data")
    //console.log(csv_data)

    useEffect(() => {
      console.log(csv_data)
      if (csv_data !== "") { /*csvLink.current.link.click();*/ }
    }, [csv_data]);

    /*listCSVFiles.map((item, index) => ( rows.push(createData(item.fid, item.filename, item.fid, item.status)) ));*/

    //const getDelPDFFile =  async () => { setDelPDFFile(await DatabaseService.delPDFFile(data)); }

    

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }


    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const headCells = [
        {
            id: 'filename',
            numeric: false,
            disablePadding: true,
            label: 'File Name',
        },
        {
            id: 'csv_directory',
            numeric: false,
            disablePadding: true,
            label: 'CSV Files',
        }
    ];

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    /*Dialog Box*/
    const LoadingContainer = styled('div')(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }),
    );

const [open, setOpen] = React.useState(false);
const [dialogData, setDialogInformation] = React.useState({title:"", information:"", status:""});

const navigate = useNavigate();

const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
/*Dialog Box*/

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        paddingRight: '16px!important', 
        display: { xs: 'block', sm: 'flex' },
        paddingTop: { xs: '8px', sm: '0' },
        paddingBottom: { xs: '14px', sm: '0' },
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Comparison Tool
        </Typography>
      )}


      {
        
      numSelected > 0 ? (<>
        <Tooltip title="Delete">
          <Button color="error" variant="contained" onClick={onDelete}>
            <DeleteIcon />
          </Button>
        </Tooltip>
        </>
      ) : (
            <>
                <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
            </>
      )
      
      }
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  //const [selectedFid, setFidSelected] = React.useState([]);

  const [dense, setDense] = React.useState(false);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      //const newFidSelected = rows.map((n) => n.fid);
      setSelected(newSelected);
      //setFidSelected(newFidSelected);
      return;
    }
    setSelected([]);
    //setFidSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    console.log(selected);
    console.log(event);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    
    setSelected(newSelected);
    console.log(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  /*const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );*/

  /*const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );*/

  const visibleRows = stableSort(rows, getComparator(order, orderBy));
  
  //POST Database Service
  function delPDFFile_func(data){
    return DatabaseService.delPDFFile(data)
  }

  /*function extractPDF_func(data){
    return DatabaseService.extractPDF(data)
  }*/

  /*function PDFtoCSV_func(data){
    return DatabaseService.PDFtoCSV(data)
  }*/

  /*function CONtoCSV_func(data){
    return DatabaseService.CONtoCSV(data)
  }*/

  function CSVComparison_func(data){
    return DatabaseService.getKundeListComparison(data);
  }
  //END of POST Database Service

  const onDelete = (event) => {
      
    console.log(selected);

    setDialogInformation({ information: "Deleting...", status: "loading" });
    setOpen(true);

      delPDFFile_func(selected)
          .then(response => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error('Failed to delete file');
              }
          })
          .then(result => {
              console.log(result);
              setOpen(false);
              // Update table data after successful deletion

              getCSVFilesData(page, rowsPerPage, search);
              setSelected([]); // Clear selected array
          })
          .catch(error => {
              console.error('Error deleting file:', error);
              setOpen(false);
              // Handle error here, show message or take appropriate action
          });

    /*Disable Temporarily*/
    /*delPDFFile_func(selected).then(r => r.json()).then(result => {
        console.log(result);
        setOpen(false);
    });*/
    /*End of Disable Temporarily*/

    /*setTimeout(() => {
      getCSVFilesData();
    }, 100);
    
    setTimeout(()=>{
      setSelected([]);
    }, 500);*/

    /*console.log(selectedFid);

    */

    /*Dialogbox Test*/

    /*End of Dialogbox Test*/
    

  };

  /*const onExtract = (event) => {

    console.log(selected);

    setDialogInformation({ information: "Extracting...", status: "loading" });
    setOpen(true);

    extractPDF_func(selected)
        .then(r => r.json())
        .then(result => {
          console.log(result);
          //setOpen(false);
          //setDialogInformation({ title: "", information: result, status: "" });
          //setOpen(true);
            
          if (result.success === true) {
              setDialogInformation({ title: result.message, information: "You may now check the data list table.", status: "" });
          } else {
              setDialogInformation({ title: "", information: result.message, status: "" });
          }

          getCSVFilesData(page, rowsPerPage, search);

          setOpen(true);
    });
  }*/

  const handleCompareData = async (id, filename) => {
      
    console.log("handleCompareData");
    console.log(filename);
    console.log("id");
    console.log(id);
    console.log([id]);

    setDialogInformation({ information: "Comparing please wait...", status: "loading" });
    setOpen(true);

    setTimeout(() => {
      
    }, 1000);

    console.log({id:1})

    console.log({id:id})

    CSVComparison_func({id:id})
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to add data');
          }
        })
        .then(result => {
          console.log(result);

          /*setAlert(
            {
              text: result.message,
              appear: true,
              severity: 'success'
            }
          );

          setOpenDialogLoading(false);

          setTimeout(() => {
            setAlert({ ...alert, appear: false });
          }, 2000);*/

          /*const updatedRows = result.rows.map(item => createData1(
            item.id, 
            item.id_number, 
            item.number_text, 
            item.anzahl, 
            item.soll, 
            item.abweichung, 
            item.abweichung_percent, 
            item.median, 
            item.score, 
            item.potential
          ));*/

        // Update the rows state
          setRowsTableFullScreenDialog(result.rows);

          console.log("rowsTableFullScreenDialog");
          console.log(rowsTableFullScreenDialog);

          setOpen(false);
          setOpenFullScreenDialog(true);
                        
      })
      .catch(error => {
          console.error('Error adding data:', error);
          setOpen(false);
          // Handle error here, show message or take appropriate action
      });

    
    /*CSVComparison_func("1").then(r => r.json()).then(result => {
      console.log(result);
      //setOpen(false);
      //setDialogInformation({ title: "", information: result, status: "" });
      //setOpen(true);
    
      /*if (result.success === true) {
          setDialogInformation({ title: result.message, information: "CON file has been successfully converted to CSV.", status: "" });
      } else {
          setDialogInformation({ title: "", information: result.message, status: "" });
      }*/

      //getCSVFilesData(page, rowsPerPage, search);

      //setSelected([]);

      //setOpen(true);

    /*  setOpen(false);
    });*/

      //getCSV(id, filename);
      
        /*getCSV(id)  
            .then(blob => {
              FileSaver.saveAs(blob, 'data.txt');
            })
            .catch(error => console.error(error));*/

            /*.then(response => response.blob())
            .then(blob => {
                //FileSaver.saveAs(blob, 'data.csv');
                console.log("blob");
                console.log(blob);
            })
            .catch(error => console.error(error));*/
          
          //.then(result => {
              //console.log(result);
              //setOpen(false);
              //setDialogInformation({ title: "", information: result, status: "" });
              //setOpen(true); 
              /*if (result.success === true) {
                  setDialogInformation({ title: result.message, information: "You may now check the data list table.", status: "" });
              } else {
                  setDialogInformation({ title: "", information: result.message, status: "" });
              }
              setOpen(true);*/

          //});

      //console.log("csv_data");
      //console.log(csv_data);
  }

  /*const onCompare = (event) => {

    console.log(selected);

    setDialogInformation({ information: "Converting to CSV...", status: "loading" });
    setOpen(true);

    /*CONtoCSV_func(selected).then(r => r.json()).then(result => {
        console.log(result);
        //setOpen(false);
        //setDialogInformation({ title: "", information: result, status: "" });
        //setOpen(true);
      
        if (result.success === true) {
            setDialogInformation({ title: result.message, information: "CON file has been successfully converted to CSV.", status: "" });
        } else {
            setDialogInformation({ title: "", information: result.message, status: "" });
        }

        getCSVFilesData(page, rowsPerPage, search);

        setSelected([]);

        setOpen(true);

    });*/

  /*  setOpen(false);
  }*/

  /* Dialog Box */
  /*const handleClickOpen = () => {
      //setOpen(true);
      navigate('/');  
    };*/

  const handleRedirectToDataList = () => {
      navigate('/');
  }

  const handleClose = () => {
      setOpen(false);
  };
  /* Dialog Box */

//Table

  //=========== Upload Files


  const fileInput = createRef();
  const fn = createRef();
  const ln = createRef();

  const [records, setRecords] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    
    /*
    formData.append("fn", fn.current.value);
    formData.append("ln", ln.current.value);
    */

    for(let i=0; i<fileInput.current.files.length; i++){
      //console.log(fileInput.current.files);
      formData.append("files", fileInput.current.files[i]);
    }

    console.log(...formData);

    //formData.set("avatar", fileInput.current.files[0]);
    //console.log("test");
    //console.log(formData);

    try{
      const response = await fetch('/api/uploadcsvfile', {
        method: "POST",
        body: formData
      });

      const parsedResponse = await response.json();

      //console.log(formData);

      setOpen(true);
      setDialogInformation({ information: "Uploading files...", status: "loading" });

      if(response.ok){

        //alert("File uploaded");
        //fileInput.value="";
        //console.log("File uploaded");

        setOpen(false);
        fileInput.current.value = ""; //make upload input empty after uploading files
        getCSVFilesData(page, rowsPerPage, search)

      }else{
        console.error("Some error occured");
      }

    }catch(e){
      console.error(e.message);
    }
  }

  function CloseFullscreenDialog(){
    setOpenFullScreenDialog(false);
  }

  //Table for FullScreen Dialog

  /*const rowsTableForFullScreenDialog = [
    createData1('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData1('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData1('Eclair', 262, 16.0, 24, 6.0),
    createData1('Cupcake', 305, 3.7, 67, 4.3),
    createData1('Gingerbread', 356, 16.0, 49, 3.9),
  ];*/

  //End of Table for FullScreen Dialog

  //==== Upload Files

    const LoadingTable = styled('div')(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            zIndex: 1,
            paddingTop: '2.7em'
        }),
    );
  
  return (
            <MuiDrawer>
                  <br></br>
                  <form onSubmit={onSubmit}>

                    <input type="file" name="file" id="files" accept=".csv" multiple ref={fileInput}/>

                    <button type="submit">Upload</button>

                  </form>

                  <br></br>

                  <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <EnhancedTableToolbar numSelected={selected.length} />
                      <TableContainer sx={{ position: 'relative' }}>

                      {
                          loading ? (

                              <LoadingTable>
                                  <div>
                                      <CircularProgress />
                                  </div>
                              </LoadingTable>
                          ) : ""
                      }

                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                              {
                                  (visibleRows.length > 0) ?
                                      visibleRows.map((row, index) => {
                                          const isItemSelected = isSelected(row.id);
                                          const labelId = `enhanced-table-checkbox-${index}`;
                                          return (
                                                <TableRow
                                                          hover
                                                          role="checkbox"
                                                          aria-checked={isItemSelected}
                                                          tabIndex={-1}
                                                          key={row.id}
                                                          selected={isItemSelected}
                                                          sx={{ cursor: 'pointer' }}
                                                          className='checkboxId'
                                                          data-id={row.id}
                                                >
                                                  <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                          'aria-labelledby': labelId,
                                                        }}
                                                        onClick={(event) => handleClick(event, row.id)}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{paddingRight: '1em'}}
                                                  >
                                                        {row.filename}
                                                  </TableCell>
                                                  <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{paddingRight: '1em'}}
                                                  >
                                                      <Button
                                                          variant="contained"
                                                          color="info"
                                                          sx={{ whiteSpace: 'nowrap' }}
                                                          onClick={() => {
                                                            handleCompareData(row.id, row.filename);
                                                          }}
                                                      >
                                                        Compare
                                                      </Button>  
                                                  </TableCell>
                                                </TableRow>
                                          );
                                      })
                                    : loading ? (
                                          <TableRow
                                              style={{
                                                  height: '120px',
                                              }}
                                          ><TableCell colSpan={6} />
                                          </TableRow>
                                      ) : null
                              }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={parseInt(totalRowsCount)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </Paper>
                    <FormControlLabel
                      control={<Switch checked={dense} onChange={handleChangeDense} />}
                      label="Dense padding"
                    />
                  </Box>
   

                  {/*Full Screen Dialog*/}
                  <Dialog
                    fullScreen
                    open={openFullScreenDialog}
                    onClose={CloseFullscreenDialog}
                    TransitionComponent={Transition}
                  >
                    <AppBar sx={{ position: 'relative' }}>
                      <Toolbar>
                        <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                          Comparison Results
                        </Typography>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={CloseFullscreenDialog}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Toolbar>
                    </AppBar>

                    <Box sx={{ p: 2 }}> 
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Ziffer</StyledTableCell>
                              <StyledTableCell align="right">Anzahl</StyledTableCell>
                              <StyledTableCell align="right">Soll</StyledTableCell>
                              <StyledTableCell align="right">Abweichung</StyledTableCell>
                              <StyledTableCell align="right">Abweichung %</StyledTableCell>
                              <StyledTableCell align="right">Median</StyledTableCell>
                              <StyledTableCell align="right"></StyledTableCell>
                              <StyledTableCell align="right">€-Potential</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rowsTableFullScreenDialog.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                  {row.id_number}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.anzahl}</StyledTableCell>
                                <StyledTableCell align="right">{row.soll}</StyledTableCell>
                                <StyledTableCell align="right">{row.abweichung}</StyledTableCell>
                                <StyledTableCell align="right">{row.abweichung_percent}</StyledTableCell>
                                <StyledTableCell align="right">{row.median}</StyledTableCell>
                                <StyledTableCell align="right">{row.score}</StyledTableCell>
                                <StyledTableCell align="right">{row.potential}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      
                    </Box>
                  </Dialog>


                  {/*Progress Dialog*/}
                  <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      aria-labelledby="responsive-dialog-title"
                    >
                        
            
                    {
                        (dialogData.status === "") ? 
                            (dialogData.title !== "") ?  
                                <DialogTitle id="responsive-dialog-title">
                                  { dialogData.title }
                                </DialogTitle> : ""
                            : ""
                    }
                      <DialogContent>
                          {/*<DialogContentText>*/}
                          {/*Let Google help apps determine location. This means sending anonymous
                                              location data to Google, even when no apps are running.*/}
                            
                          {
                            /*
                              <LoadingContainer>
                                <CircularProgress sx={{ mr: 2 }} /> {dialogData.information}
                              </LoadingContainer>
                            */
                          }

                         {
                            (dialogData.status === "loading") ?
                                <LoadingContainer> <CircularProgress sx={{ mr: 2 }} /> {dialogData.information} </LoadingContainer>
                                : dialogData.information
                         }

                          {/*</DialogContentText>*/}
                      </DialogContent>

                    {
                        (dialogData.status === "") ? 
                            <DialogActions>
                                {/*<Button autoFocus onClick={handleRedirectToDataList}>
                                    Redirect to datalist tables
                                    </Button>*/}
                                <Button onClick={handleClose} autoFocus>
                                    Close
                                </Button>
                            </DialogActions> : ""
                    }
                  </Dialog>
            </MuiDrawer>
          );
}