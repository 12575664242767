import PieChart from '../../../components/charts/PieChart';
import calculateUnusedPotenzialAndGenutztesPotenzial from '../calculations/calculateUnusedPotenzialAndGenutztesPotenzial';
import germanFormatCurrency from "../../../utils/germanFormatCurrency";

const PieChartFilter = (props) => {

    const { gesamtpotenzial_included, ihrPotencialIncluded } = props;

    let result = calculateUnusedPotenzialAndGenutztesPotenzial(gesamtpotenzial_included, ihrPotencialIncluded);

    /*if(debug){
      console.log("ungenutztesPotenzial");
      console.log(result.ungenutztesPotenzial);
      console.log("genutztesPotenzial");
      console.log(result.genutztesPotenzial);
    }*/

    let dataName = ["Ungenutztes Potenzial", "Genutztes Potenzial"];
  
    let headerTitle = `€-Potenzial bei den freien Leistungen: ${germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))}`;

    return (<>

              <PieChart 
                        headerTitle={headerTitle}
                        data={
                              [
                                [dataName[0],dataName[1], { role: 'tooltip', type: 'string' }],
                                [dataName[0], parseFloat(result.ungenutztesPotenzial), dataName[0] + ": \n" + germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))],
                                [dataName[1], parseFloat(result.genutztesPotenzial), dataName[1] + ": \n" + germanFormatCurrency(parseFloat(result.genutztesPotenzial))],
                              ]
                            }
                        backgroundColor={['#7A2856','#006400']} 
                />


    </>);

  }

  export default PieChartFilter;