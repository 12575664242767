import React, { useEffect, useState } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import * as DatabaseService from '../../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';
import { dangerouslySetInnerHTML } from 'react-dom';

import MuiDrawer from '../../../components/MuiDrawer';

import { useAuth } from '../../../contexts/AuthContext';

import GynSuccess_Home_Header from '../../../components/assets/images/template/GynSuccess_Home_Header.webp';
import GynSuccess_Team_Bild2 from '../../../components/assets/images/template/GynSuccess_Team_Bild2.webp';
import GynSuccess_Team_Bild4 from '../../../components/assets/images/template/GynSuccess_Team_Bild4.webp';
import GynSuccess_Leistungen_Bild5 from '../../../components/assets/images/template/GynSuccess_Leistungen_Bild5.webp';
import GynSuccess_Kontakt_Header from '../../../components/assets/images/template/GynSuccess_Kontakt_Header.webp';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '20px',
  padding: '0.3em 1em',
}));

const LightTypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'BR Firma Light',
  lineHeight: '1.8em',
  marginBottom: 0,
}));

const GynSuccessComponent1 = () => {
  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            GynSuccess Coaching- und Beratungsleistungen
          </Typography>
          {/*<LightTypographyText variant="p" component="div" sx={{fontWeight: '300'}}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                        </LightTypographyText>*/}
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ borderRadius: '8px', overflow: 'hidden', display: 'flex' }}
          >
            <img
              src={GynSuccess_Kontakt_Header}
              alt=""
              style={{ width: '100%' }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const GynSuccessComponent3 = (props) => {
  const { title, description, buttonName, buttonLink, bgColor } = props;

  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: bgColor, // Dark green background
    color: 'white',
    borderRadius: '16px',
    padding: theme.spacing(3),
  }));

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ mb: 2, marginBottom: 0 }}>
          <LightTypographyText
            variant="p"
            paragraph
            sx={{
              fontWeight: '300',
              '& div p:last-child': { marginBottom: 0 },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </LightTypographyText>
        </Box>
        {buttonName !== '' ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton variant="contained">{buttonName}</StyledButton>
            </Box>
          </>
        ) : (
          ''
        )}
      </CardContent>
    </StyledCard>
  );
}; /*
        <GynSuccessComponent3
          title="EBM-Abrechnungskompass​"
          description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ​"
          buttonName="Zustieg zum  EBM-Abrechnungstool"
          buttonLink=""
          bgColor="#006400"
        />
    */

const GynSuccessComponent5 = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0,0.36)',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: 'white',
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} fullWidth>
      <StyledCard>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Sie wollen Coaching oder Beratung in Anspruch nehmen? Dann wenden
            Sie sich gerne direkt an uns!
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            variant="contained"
            component="a"
            href="https://www.gynsuccess.de/kontakt/#kontaktformular"
            target="_blank"
          >
            Kontakt
          </StyledButton>
        </Box>
      </StyledCard>
    </Box>
  );
};

export default function Platform() {
  const { login, currentUser, currentRole, getSession } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiDrawer>
      <Box sx={{ marginLeft: '-24px', marginRight: '-24px' }}>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <GynSuccessComponent1 />

          <Box sx={{ height: '24px' }}></Box>

          <GynSuccessComponent3
            title="Systemisches Business Coaching für die eigenverantwortliche Entwicklung individueller Lösungskompetenz"
            description="<p>Im Unterschied zur klassischen Beratung liefere ich keine direkten Lösungsvorschläge, Systemisches Business Coaching ist ein individueller, personenzentrierter und ergebnisorientierter Unterstützungsprozess mit beruflichem Schwerpunkt unter Einbezug Ihres privaten Kontexts. Ich erarbeite mit Ihnen gemeinsam, anhand Ihrer konkreten Fragestellungen persönliche und individuelle Lösungen, die auf Ihre aktuelle Lebenssituation, berufliche- und zukünftige Lebensplanung abgestimmt sind.​</p>
                                   <p>Ich begleite die Entwicklung Ihrer eigenen Lösungen. Sehen Sie mich gerne als professioneller Sparringspartner in schwierigen oder herausfordernden Situationen. Die Lösungshoheit liegt im Gegensatz zur klassischen Beratung bei Ihnen, die Prozesshoheit obliegt mir. Eine Art Detektiv, Geburtshelfer oder Forscher, um Veränderungsprozesse in der Praxis kompetent und bedürfnisorientiert zu begleiten.</p>
                                   <p>Als ‚Coach‘ fördere ich Ihre Selbstkenntnis, Selbstverantwortung und das Selbstwirksamkeitserleben und Ihre eigene Lösungskompetenz – statt Abhängigkeit und Heilsgläubigkeit einzufordern. Coaching hilft einen vielmals eingeengten Blickwinkel zu weiten, Ereignisse neu und anders zu bewerten und die eigene, optimierte Lösungskompetenz führt nachhaltig zu einer positiven Veränderung.</p>
                                   <p>Kurz und knapp: Coaching ist die Kunst, Menschen erfolgreicher zu machen! </p>"
            buttonName=""
            buttonLink=""
            bgColor="#761448"
          />

          <Box sx={{ height: '24px' }}></Box>

          <GynSuccessComponent3
            title="Praxisberatung für die zukunfts- und gewinnoptimierte Steuerung​​"
            description="<p>Neben dem gewinnorientierten Aspekt werden wir Sie dabei unterstützen ihre Praxis effizienter zu gestalten. Das beinhaltet die Optimierung von Arbeitsprozessen oder die Implementierung von Technologien, die den Praxisalltag erleichtern. Die Fokussierung auf eine effiziente Praxisführung kann nicht nur die Arbeitsbelastung reduzieren, sondern auch die Patientinnenzufriedenheit erhöhen.</p>
                                   <p>Durch die Optimierung von Abrechnungsprozessen und die Implementierung effektiver Einnahmestrategien können wir sicherstellen, dass sie angemessen für ihre Dienstleistungen vergütet werden. Wir helfen dabei Gebührenstrukturen zu überprüfen, abrechnungstechnische Fehler zu minimieren und sicherzustellen, dass alle abrechenbaren Leistungen erfasst werden. Dies trägt direkt zur Steigerung der Einnahmen und zur Sicherung der finanziellen Gesundheit Ihrer Praxis bei.</p>
                                   <p>Hierzu setzen wir verstärkt auf unsere Videoberatung:<br> Treten Sie mit uns in Kontakt - Führen Sie Ihr persönliches Beratungsgespräch einfach, schnell und bequem von zu Hause oder unterwegs.<br>
                                   <ul><li>Sparen Sie Zeit: Meetings und Beratungsgespräche ortsunabhängig & kosteneffektiv führen​</li>
                                      <li>Attraktive Beratungszeiten: Einfach zum gewünschten Zeitpunkt mit dem PC oder dem Tablet mit uns in Kontakt treten​</li>
                                      <li>Transparente Beratung: Lassen Sie sich komplexe Sachverhalte direkt auf Ihrem Bildschirm erläutern​</li></ul>
                                   </p>"
            buttonName=""
            buttonLink=""
            bgColor="#014017"
          />

          <Box sx={{ height: '24px' }}></Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            paddingTop: '25%',
            paddingBottom: '20%',
            backgroundImage: `url(${GynSuccess_Leistungen_Bild5})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box
            sx={{
              width: '80%',
              margin: 'auto',
              zIndex: '1',
              position: 'relative',
            }}
          >
            <GynSuccessComponent5 />
          </Box>
        </Box>
      </Box>
    </MuiDrawer>
  );
}
