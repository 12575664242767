
function updateBallNumbers(rows) {

    let nonZeroCount = 0;
    let zeroCount = 0;

    rows.map((row, index) => {
        
        const inputValue = row.anzahl;

        // If the value is greater than 0, increment non-zero count
        if (inputValue > 0) {
            nonZeroCount++;
        } else {
            zeroCount++;
        }
    })

    return { nonZeroCount, zeroCount };
}

export default function countBallNumbers(rows) {
    
    return updateBallNumbers(rows)

}