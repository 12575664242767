import { Box } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';

export default function FloatingIcons() {

    const topIcon = '33%';

    return (
                <>
                    <Box sx={{
                                '& .sticky-element-icons':{ right: 0, zIndex: 9999 },
                                '& .sticky-element-icons .row':{ marginBottom: '5px', position: 'relative', transition: '0.4s right ease-in-out' },
                                '& .sticky-element-icons .row .col':{ position: 'relative', borderRadius: '18px 0 0 18px', paddingRight: '8px', alignItems: 'center', display: 'flex' },
                                '& .sticky-element-icons .row:first-of-type':{ right: '-135px', position: 'fixed', top: 'calc(' + topIcon + ')' },
                                '& .sticky-element-icons .row:last-of-type':{ right: '-60px',  position: 'fixed', top: 'calc(' + topIcon + ' - -45px)' },
                                '& .sticky-element-icons .row .col:before':{ content: '""', width: '100%', height: '100%', backgroundColor: 'white', position: 'absolute', zIndex: '-1', borderRadius: '23px 0 0 23px', opacity:0, transition: '0.4s opacity ease-in' },
                                '& .sticky-element-icons .row:hover:first-of-type':{ right: 0 },
                                '& .sticky-element-icons .row:hover:last-of-type':{ right: 0 },
                                '& .sticky-element-icons .col:hover:before':{ opacity:1 },
                                '& .sticky-element-icons img':{  width: '45px', marginRight: '10px', borderRadius: '100%' },
                                'a': { textDecoration: 'none', color: '#1c75b8' },
                                '& .MuiButtonBase-root': { backgroundColor: '#1B75B8', marginRight: '10px' },
                                '& svg': { fill: '#ffffff' }
                            }}>
                        <div class="sticky-element-icons">

                            <div class="row">
                                    <a href="tel:+4915679495400">
                                        <div class="col">
                                            {/*<img src="/wp-content/uploads/2024/08/mobile_round_icon_blue.webp" alt="" />*/}
                                            <IconButton disableRipple><PhoneIphoneIcon /></IconButton> 015679/495400
                                        </div>
                                        </a>
                                </div>

                                <div class="row">
                                    <a href="https://www.gynsuccess.de/kontakt/#kontaktformular" target="_blank" rel="noreferrer">
                                    <div class="col">
                                        {/*<img src="/wp-content/uploads/2024/08/envelope-line-icon_optimised.webp" alt="" />*/}
                                        <IconButton disableRipple ><EmailIcon /></IconButton> Email
                                    </div>
                                    </a>
                                </div>
                        </div>
                    </Box>
                </>
            );
  }