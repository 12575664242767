import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useSingleUserData } from '../../services/userService';

import * as DatabaseService from '../../services/DatabaseService';

import MuiDrawer from '../../components/MuiDrawer';



// Table Libraries
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import Controls from '../../components/controls/Controls';

import TextField from '@mui/material/TextField';

import { dangerouslySetInnerHTML } from 'react-dom';
import Link from '@mui/material/Link';

import PieChart from '../../components/charts/PieChart';
import BarChart from '../../components/charts/BarChart';
import Speedometer from '../../components/charts/Speedometer';
import ScheineChart from '../../components/charts/ScheineChart';
import StatusChart from '../../components/charts/StatusChart';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import germanFormatCurrency from '../../utils/germanFormatCurrency';
import ViewFormOutput from './components/ViewFormOuput';
import ProfileCard from './components/ProfileCard';
//import DialogOutput from './components/DialogOutput';
import DialogOutput from '../../components/DialogOutput';
import { NumbersOutlined } from '@mui/icons-material';


import { ContentComponent, MembershipStatus, MembershipStatusDanke } from './components/ContentForFullMemberShip';
import ViewFormOutputMembership from './components/ViewFormOuputMembership';
import { width } from '@mui/system';

function TableResults(props) {

  const { 
          scheine,
          showResult, 
          total_ihr_potenzial_included, 
          gesamtpotenzial_included,  
          ihrpotenzial_notrap,
          gesamtpotenzial_notrap,
          rows1,
          debug,
          setOuputCSV,
          userStatus
        } = props;

  /*Dialog Box*/
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });

  const [openDialogFeatures, setOpenDialogFeatures] = React.useState(false);
  /*End of Dialog Box*/ 

  //const [processOutputCSV, setProcessOutputCSV] = useState("");

  let processOutputCSV = "";

  /* Dialog Box */
  const handleViewForm = (gop, description) => {
      setDialogInformation({ title: gop, information: description, status: "view" });
      setOpen(true);
  }

  const handleViewFormDialogFeatures = () => {
    setDialogInformation({ title: "", information: "", status: "view" });
    setOpenDialogFeatures(true);
  }

  const handleClose = () => {
      setOpen(false);
      setOpenDialogFeatures(false);
  };
  /* Dialog Box */

  /*useEffect(() => {

    console.log(processOutputCSV);

  }, [processOutputCSV]);*/

  useEffect(() => {

    setOuputCSV(processOutputCSV);

  }, [processOutputCSV])

  function calculateUnusedPotenzialAndGenutztesPotenzial() {
    let ungenutztesPotenzial = parseFloat(total_ihr_potenzial_included.toFixed(2));
    //let genutztesPotenzial = parseFloat(gesamtpotenzial_included - total_ihr_potenzial_included.toFixed(2));

    let genutztesPotenzial = parseFloat(gesamtpotenzial_included - total_ihr_potenzial_included);


    ////genutztes Potenzial / Gesamtpotenzial (included) = percentage for speedometer
    //let speedometerPercentage = parseFloat(genutztesPotenzial / gesamtpotenzial_included);
    let speedometerPercentage = parseFloat(genutztesPotenzial / gesamtpotenzial_included)  * 100;


    genutztesPotenzial = parseFloat(genutztesPotenzial).toFixed(2);

  
    speedometerPercentage = parseFloat(speedometerPercentage).toFixed(2);

    return {
      ungenutztesPotenzial,
      genutztesPotenzial,
      speedometerPercentage
    }
  }

  function calculateTotalNoTrapPotenzialAndGenutztesPotenzial() {
    
    let ungenutztesPotenzial = parseFloat(ihrpotenzial_notrap.toFixed(2));
    
    let genutztesPotenzial = parseFloat(gesamtpotenzial_notrap - ihrpotenzial_notrap.toFixed(2));

    genutztesPotenzial = parseFloat(genutztesPotenzial).toFixed(2);

    /*if(debug){
      console.log("calculateTotalNoTrapPotenzialAndGenutztesPotenzial")
      console.log("ihrpotenzial_notrap " + ihrpotenzial_notrap)
      console.log("gesamtpotenzial_notrap " + gesamtpotenzial_notrap)
    }*/

    return {
      ungenutztesPotenzial,
      genutztesPotenzial
    }
  }

  const BarChartFilter = (props) => {

    /* Bar Chart*/
      //let rows = [];

      let gopsToSearch = ['01640', '01771', '01772', '01775', '33041']; // Array of ids you're searching for

      let BarChartRows = rows1.filter(row => gopsToSearch.includes(row.gop));

      let row01641 = rows1.find(row => ['01641'].includes(row.gop));

      /*
      
      only show Ihr Potenzial in € if 0
if they enter any amount for 01640, 01771, 01772 01775 or 33041 => no potencial at all (0)
if 0 for 01640
=> potencial of 01640 = potencial of 01640 + potencial of 01641

      */

      /**
       let transformedData = [
          ['Wert', 'Ihr Potenzial in €'], // Header row
          ...BarChartRows.map(row => {
            let ihrPotenzial;
            
            if (row.euro_potencial_show === 1) {
              
              if (typeof row.adjusted_euro_potencial === 'undefined') {
                ihrPotenzial = row.euro_potencial;
              } else {
                ihrPotenzial = row.adjusted_euro_potencial;
              }

              ihrPotenzial = parseFloat(ihrPotenzial.toFixed(2));
            
            } else {
              ihrPotenzial = 0;
            }

            return [row.gop, ihrPotenzial];
          })
        ]; 
       */

      // Convert BarChartRows to the desired structure
      /*let transformedData = [
        ['Wert', 'Ihr Potenzial in €'], // First sub-array
        ...BarChartRows.map(row => [
                                      row.gop, 
                                      (row.euro_potencial_show === 1) 
                                        ? parseFloat(((typeof row.adjusted_euro_potencial === 'undefined') ? 
                                            row.euro_potencial : row.adjusted_euro_potencial).toFixed(2)) : 0
                                   ]
                            ) // Rest of the sub-arrays
      ];*/

      let transformedData = [
        ['Wert', 'Ihr Potenzial in €', { role: "tooltip", type: "string" }], // Header row
        ...BarChartRows.map(row => {
          let ihrPotenzial;
          
          
            if (row.euro_potencial_show === 1) {
              
                if (typeof row.adjusted_euro_potencial === 'undefined') {
                  ihrPotenzial = row.euro_potencial;
                } else {
                  ihrPotenzial = row.adjusted_euro_potencial;
                }

                if(typeof row.anzahl !== 'undefined'){
                    if(parseInt(row.anzahl) > 0){
                        ihrPotenzial = 0;
                    }else{
                      
                      if(row.gop === '01640'){
                        
                        /*if(debug){
                          console.log("Inside tranformedData");
                          
                          //console.log("ihrPotenzial: " + ihrPotenzial);
                          console.log("row01641 euro potenzial")
                          console.log((typeof row01641.adjusted_euro_potencial === 'undefined') ? row01641.euro_potencial : row01641.adjusted_euro_potencial)
                          console.log("ihrPotenzial");
                          console.log(ihrPotenzial);

                          console.log("ihrPotenzial + row01641");
                          console.log(ihrPotenzial + parseFloat((typeof row01641.adjusted_euro_potencial === 'undefined') ? row01641.euro_potencial : row01641.adjusted_euro_potencial));
                        }*/

                        ihrPotenzial = ihrPotenzial + parseFloat((typeof row01641.adjusted_euro_potencial === 'undefined') ? row01641.euro_potencial : row01641.adjusted_euro_potencial);

                      }
                    }
                }

                ihrPotenzial = parseFloat(ihrPotenzial.toFixed(2));
            
            } else {
              ihrPotenzial = 0;
            }

          return [row.gop, ihrPotenzial, row.gop + "\n" + germanFormatCurrency(ihrPotenzial)];
        })
      ]; 

      //if (debug) {

        /*
          only show Ihr Potenzial in € if 0 
          if they enter any amount for 01640, 01771, 01772 01775 or 33041 => no potencial at all (0) 
          if 0 for 01640 
          => potencial of 01640 = potencial of 01640 + potencial of 01641 
        */

        /*console.log("BarChartRows");
        console.log(BarChartRows); // BarChartRows

        console.log("row01641")
        console.log(row01641)*/

        /*console.log("transformed data");
        console.log(transformedData);*/  
      //}

      /*const [barChartData, setBarChartData] = useState([]);

      useEffect(() => {
        if (transformedData.length > 0) {
          setBarChartData(transformedData);
        }
      }, [transformedData]);*/

      /* END of Bar Chart */

      return (<>
      
          {/*<BarChart data={[
                                  ['016440', 'Total'],
                                  ['Category 1', 100],
                                  ['Category 2', 80],
                                  ['Category 3', 50],
                                ]} 
                />*/}

          <BarChart data={transformedData} headerTitle="Zusätzliches Potenzial" titleLeft="EBM-Ziffer" titleBottom="Ihr Potenzial in €" />

      </>);

  }

  function checkDeviationPercentStatus(row_deviation_percent_status, tableType){


    let transparent = '#00000000', red = '#b2102f', orange = '#FF9800', black = '#000000', white = '#ffffff', lightred = '#FF7276';

    let backgroundColor = transparent;
    let color = 'inherit';
    let color_href = '#1976D2'
    let icon = '';
    let info = '';

    if(typeof row_deviation_percent_status !== 'undefined'){
      if(parseInt(tableType) === 2){
        if(row_deviation_percent_status === "red"){
            backgroundColor = red;
            color = white;
            color_href = white;
            icon = (<><ReportProblemOutlinedIcon /></>);
            info = "Achtung! Mehr als 50 % Abweichung!";
        }else if(row_deviation_percent_status === "orange"){
            backgroundColor = orange;
            color = white;
            color_href = white;
            icon = (<><ReportProblemOutlinedIcon /></>);
            info = "Achtung! Mehr als 30 % Abweichung!"
        }else if(row_deviation_percent_status === "lightred"){
            backgroundColor = lightred;
            color = white;
            color_href = white;
            icon = (<></>);
            info = ""
        }
      }
    }
  
    return { backgroundColor, color, color_href, icon, info };

    /*let deviation_percent = parseFloat(row_deviation_percent_status).toFixed(2);

    let transparent = '#00000000', red = '#b2102f', orange = '#FF9800', black = '#000000', white = '#ffffff';

    let backgroundColor = transparent;
    let color = 'inherit';
    let color_href = '#1976D2'
    let icon = '';
    let info = '';

    if(tableType === '2'){
      if(!isNaN(deviation_percent)){
        if(deviation_percent > 50){
          backgroundColor = red;
          color = white;
          color_href = white;
          icon = (<><ReportProblemOutlinedIcon /></>);
          info = "Achtung! Mehr als 50 % Abweichung!";
        }else if(deviation_percent > 30){
          backgroundColor = orange;
          color = white;
          color_href = white;
          icon = (<><ReportProblemOutlinedIcon /></>);
          info = "Achtung! Mehr als 30 % Abweichung!"
        }
      }
    }

    return { backgroundColor, color, color_href, icon, info };*/
  
  }
  
  const PieChartFilter = (props) => {

    let result = calculateUnusedPotenzialAndGenutztesPotenzial();

    /*if(debug){
      console.log("ungenutztesPotenzial");
      console.log(result.ungenutztesPotenzial);
      console.log("genutztesPotenzial");
      console.log(result.genutztesPotenzial);
    }*/

    let dataName = ["Ungenutztes Potenzial", "Genutztes Potenzial"];
  
    let headerTitle = `€-Potenzial bei den freien Leistungen: ${germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))}`;

    return (<>

              <PieChart 
                        headerTitle={headerTitle}
                        data={
                              [
                                [dataName[0],dataName[1], { role: 'tooltip', type: 'string' }],
                                [dataName[0], parseFloat(result.ungenutztesPotenzial), dataName[0] + ": \n" + germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))],
                                [dataName[1], parseFloat(result.genutztesPotenzial), dataName[1] + ": \n" + germanFormatCurrency(parseFloat(result.genutztesPotenzial))],
                              ]
                            }
                        backgroundColor={['#7A2856','#006400']} 
                />


    </>);

  }

  const SpeedometerFilter = (props) => {
    
    let result = calculateUnusedPotenzialAndGenutztesPotenzial();

    /*console.log("speedometerPercentage")
    console.log(result.speedometerPercentage)*/

    return (<>
      <Speedometer value={result.speedometerPercentage} headerTitle="Ihr GynSuccess-Score" />
    </>);

  }

  /* Another Pie Chart */
  const PieChartFilter2 = (props) => {

    let result = calculateTotalNoTrapPotenzialAndGenutztesPotenzial();

    /*if(debug){
      console.log("ungenutztesPotenzial");
      console.log(result.ungenutztesPotenzial);
      console.log("genutztesPotenzial");
      console.log(result.genutztesPotenzial);
    }*/

    let dataName = ["Ungenutztes Potenzial", "Genutztes Potenzial"];
  
    let headerTitle = `Gesamtpotenzial: ${germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))}`;

    //Ø Potenzial bei den freien Leistungen

    return (<>

              <PieChart 
                        headerTitle={headerTitle}
                        data={
                              [
                                [dataName[0],dataName[1], { role: 'tooltip', type: 'string' }],
                                [dataName[0], result.ungenutztesPotenzial, dataName[0] + ": \n" + germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))],
                                [dataName[1], parseFloat(result.genutztesPotenzial), dataName[1] + ": \n" + germanFormatCurrency(parseFloat(result.genutztesPotenzial))],
                              ]
                            }
                        backgroundColor={['#7A2856','#006400']} 
                />


    </>);

  }



  const ScheineChartFilter = (props) => { 

    let data = (isNaN(parseFloat(scheine))) ? 0 * parseFloat(0.175) : parseFloat(scheine) * parseFloat(0.175);

    return (<>

      <ScheineChart data={data} headerTitle="Anzahl der Patientinnen für die offene Sprechstunde" />
    
    </>);

  }

  const StatusChartFilter = (props) => {

    //let gopsToSearch = ['01640', '01771', '01772', '01775', '33041']; // Array of ids you're searching for

    //let StatusChartRows = rows1.filter(row => gopsToSearch.includes(row.gop));

    let StatusChartRows = rows1.filter(row => 
      typeof row.deviation_in_percent_status !== "undefined" && row.deviation_in_percent_status !== ""
    );

    let redCount = 0;
    let orangeCount = 0;

    StatusChartRows.map((row, index) => {
        
        // Count the number of red and orange statuses
        if (row.deviation_in_percent_status === 'red') {
          redCount++;
        } else if (row.deviation_in_percent_status === 'orange') {
          orangeCount++;
        }
    })
    
    let data = [];

    if (redCount > 0) {
      data.push({ color: "red", count: redCount });
    }

    if (orangeCount > 0) {
      data.push({ color: "orange", count: orangeCount });
    }

    /*if(debug){
      console.log("StatusChartRows");
      console.log(StatusChartRows)

      console.log("row.deviation_in_percent_status")
      //console.log(StatusChartRows[0].deviation_in_percent_status)

      console.log("RED " + redCount)
      console.log("ORANGE " + orangeCount)
      console.log("Data")
      console.log(data)
    }*/

    return (<>
    
        <StatusChart 
                      data={data} 
                      headerTitle="Anzahl an EBM-Ziffern mit Warnhinweisen" 
                      titleLeft="" 
                      titleBottom=""
                      checkDeviationPercentStatus={checkDeviationPercentStatus}  
        />

    </>);
  }

  const TableFilter = (props) => {

    const { only_list_include_chart = false, titleHeader="", tableType = "", deviation, setOuputCSV } = props;

    let rows = [];

    /*console.log("only_list_include_chart")
    console.log(only_list_include_chart)*/

    if (only_list_include_chart) {
      rows = rows1.filter(row => row.include_chart !== null);  // Exclude rows where row.include_chart is null
    } else {
      //rows = rows1;  // No filter applied
      rows = rows1.filter(row => {
        // Add your filter condition here
        // For example, to include all rows, you can return true
        return true;
      }).sort((a, b) => a.gop - b.gop);
    }

    let currentHeader = null;
    let backgroundColorRow = '#00000000';
    let countRowForBgColor = 0; //this will help start with a white row

    processOutputCSV += '"' + titleHeader + '"\n';

    processOutputCSV += "EBM-Ziffer,Leistung,Anzahl,Soll,Abweichung,Abweichung %" + ((tableType === "1" || debug) ? ",Gesamtpotenzial,Ihr Potenzial in €" : "") + "\n"

    return (<>
    
      {
        (userStatus !== 3) ?  <Typography variant="h6" gutterBottom>
                                {titleHeader} 
                              </Typography> 
                            : ""
      }

      <TableContainer component={Paper} sx={{borderRadius:0, maxHeight: 500}}>
              <Table size="small" aria-label="simple table" sx={{tableLayout: 'auto'}}>
                <TableHead>
                  {
                    (userStatus !== 3) ?
                      <TableRow sx={{
                                      backgroundColor: '#006400', 
                                      '& .MuiTableCell-root': { color: 'white', textAlign: 'right', whiteSpace: 'nowrap'},
                                      position: 'sticky',
                                      top: 0, // Ensures the header sticks to the top
                                      zIndex: 1, // Keeps it above other content when scrolling
                                    }}>
                        <TableCell sx={{textAlign: 'left!important'}}>EBM-Ziffer</TableCell>   
                        <TableCell sx={{textAlign: 'left!important'}}>Leistung</TableCell>
                        <TableCell>Anzahl</TableCell>
                        <TableCell>Soll</TableCell> {/*Ideal number of treatments*/}
                        <TableCell>Abweichung</TableCell>
                        <TableCell>Abweichung %</TableCell>
                        { (debug) ? <TableCell>EBM-Koeffizient</TableCell> : <></> }
                        { 
                          (tableType === "1" || debug) ? 
                            <>
                              <TableCell>Gesamtpotenzial in €</TableCell>
                              <TableCell>Ihr Potenzial in €</TableCell>
                            </> 
                            : 
                            "" 
                        }
                        { (tableType === "2") ? <TableCell>{/* Caution */}</TableCell> : "" }
                      </TableRow>
                    : ""
                  }
                </TableHead>
                <TableBody sx={{color: '#134020',  position: 'relative', '& .MuiTableCell-root': { border:0 }}}>

                  <MembershipStatus 
                    userStatus={userStatus}
                    onClick={()=>{ handleViewFormDialogFeatures() }}
                  />

                  {/*<MembershipComponent 
                    content={ 
                              <CardComponent 
                                title="Become a Member and Elevate Your Experience!"
                                description={`Join our vibrant community and unlock a world of exclusive benefits designed just for you. As a member, you'll gain access to:<br/>
                                                <ul>
                                                  <li>Exclusive Content: Enjoy members-only articles, videos, and resources that keep you ahead of the curve.</li>
                                                  <li>Priority Support: Get fast-tracked assistance from our dedicated support team.</li>
                                                  <li>Special Events: Receive invitations to exclusive events, webinars, and meetups.</li>
                                                  <li>Member Discounts: Save on products, services, and event tickets with special member pricing.</li>
                                                  <li>Community Access: Connect with fellow members in our private forums and groups.</li>
                                                  <li>Early Access: Be the first to know about new features, updates, and releases.</li>
                                                </ul>
                                                Don’t wait! Join us today and start enjoying the perks of membership. Your journey to a more enriched experience begins here.<br/>
                                            `}
                                buttonName="Sign up now and become a valued member of our community!"
                                onClick={()=>{ handleViewForm('01100', "Test") }}
                                openInNewTab={true}
                              />
                            } 
                    userStatus={userStatus} 
                  />*/}

                  {rows.map((row, index) => {
                      
                      const tableRows = [];

                      let outputGop = row.gop;
                      let outputTitle = row.title;
                      let ouputAnzahl = row.anzahl;
                      let outputIdealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);
                      let outputDeviation = ((!isNaN(parseFloat(row.deviation))) ? parseFloat(row.deviation).toFixed(0).toString().replace('.', ',') : 0);
                      let ouputDeviationInPercent = isNaN(parseFloat(row.deviation_in_percent)) ? '0' : parseFloat(row.deviation_in_percent).toFixed(2).toString().replace('.', ',') + '%';
                      let outputGezamtpotenzial = (row.exclude_scheine === 1) ? (
                                                                                   (typeof row.euro_potencial_mutterschaftsvorsorge_ziffern === 'undefined') ? 
                                                                                   parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern).toFixed(2).toString().replace('.', ',')
                                                                                 ) : (
                                                                                   parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',')
                                                                                 );
                      let outputIhrPotenzialInEuro =  (typeof row.euro_potencial_show === 'undefined') ? 
                                                             parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : 
                                                               (row.euro_potencial_show) ? 
                                                                   (typeof row.adjusted_euro_potencial === 'undefined') ? 
                                                                       parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',') 
                                                               : '-';
                       
                      let outputDeviationInPercentStatus = checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).info; 

                     

                        if(debug){
                            //console.log("row.length")
                            //console.log(row.length)
                        }

                        if (row.header_description !== currentHeader) {
                          
                          currentHeader = row.header_description;
                          
                          countRowForBgColor = 0;

                          processOutputCSV += '"' + currentHeader + '"\n';

                          
                            tableRows.push(
                              <TableRow key={index} sx={{backgroundColor: '#CAE3B6', '& .MuiTableCell-root': { color: '#134020'}}}>
                                <TableCell colSpan={10}>
                                  {/*<div dangerouslySetInnerHTML={{ __html: currentHeader }} />*/}
                                  <ContentComponent 
                                    userStatus={userStatus} 
                                    content={
                                      <div dangerouslySetInnerHTML={{ __html: currentHeader }} />
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ) 
                        }

                        if((checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).backgroundColor !== '#00000000')){
                          backgroundColorRow = checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).backgroundColor;
                        }else{
                          backgroundColorRow = (countRowForBgColor % 2 === 0 ? 'white' : '#F1F2F4')
                        }

                          //if(userStatus){
                            tableRows.push(
                              <TableRow
                                  key={row.id}
                                  sx={{ 
                                        '&:last-child td, &:last-child th': { border: 0 }, 
                                        backgroundColor: backgroundColorRow,
                                        '& .MuiTableCell-root': {color: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color, textAlign: 'right'},
                                        '& .MuiTableCell-root a': {color: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color_href, textDecorationColor: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color_href }
                                    }} 
                                >
                                  <TableCell sx={{textAlign: 'left!important'}}>
                                    

                                    <ContentComponent 
                                      userStatus={userStatus} 
                                      content={
                                        
                                        (row.description !== null) 
                                          ? <>
                                              <Link 
                                                  sx={{cursor:'pointer'}} 
                                                  onClick={()=>{ handleViewForm(row.gop, row.description) }}
                                              >{outputGop}
                                              </Link>
                                            </> : <>{outputGop}</>

                                      }
                                    />

                                  </TableCell> {/*EBM-Ziffer*/}
                                  {/*<TableCell sx={{textAlign: 'left!important'}}><Tooltip title={outputTitle} placement="top"><StyledTextArea defaultValue={row.title} disabled></StyledTextArea></Tooltip></TableCell>*} {/*Leistung*/}
                                  <TableCell sx={{textAlign: 'left!important'}}>
                                    <ContentComponent 
                                      userStatus={userStatus} 
                                      content={
                                        
                                          <Tooltip title={outputTitle} placement="top">
                                          <TextField 
                                            defaultValue={outputTitle} 
                                            size="small" 
                                            fullWidth  
                                            InputProps={{
                                              readOnly: true,
                                              sx: {
                                                    fontSize: '14px',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                      borderColor: 'transparent', // Removes the border color
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                      borderColor: 'transparent', // Removes border color on hover
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                      borderColor: 'transparent', // Removes border color on focus
                                                    },
                                                  }
                                            }}
                                            inputProps={{
                                              style: {
                                                padding: '3px',   // Inner padding of the input element
                                                overflow: 'hidden',         // Hide overflowing content
                                                whiteSpace: 'nowrap',       // Prevent text from wrapping
                                                textOverflow: 'ellipsis',   // Show ellipsis (...) when text overflows
                                                color: (checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color)
                                              },
                                            }}
                                          />
                                          </Tooltip>

                                      }
                                    />
                                  </TableCell> {/*Leistung*/}
                                  <TableCell> 
                                    <ContentComponent 
                                      userStatus={userStatus} 
                                      content={ouputAnzahl} 
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <ContentComponent 
                                      userStatus={userStatus} 
                                      content={ outputIdealNumberOfTreatments } 
                                    />
                                  </TableCell> {/*Ideale Anzahl an Behandlungen (Soll)*/}
                                  {/*<TableCell>{ parseFloat(row.deviation).toFixed(2).toString().replace('.', ',') }</TableCell>*/} {/*Abweichung*/}  
                                  <TableCell>
                                    <ContentComponent 
                                      userStatus={userStatus} 
                                      content={ outputDeviation } 
                                    />
                                    </TableCell> {/*Abweichung*/}
                                  <TableCell> 
                                    <ContentComponent 
                                      userStatus={userStatus} 
                                      content={ ouputDeviationInPercent }
                                    />
                                  </TableCell>{/*Abweichung %*/}    
                                  { (debug) ? <TableCell>{ row.ebm_koeffizient.toString().replace('.', ',') }</TableCell>: <></> } {/*EBM-Koeffizient*/}                                            
                                  
                                  { 
                                    (tableType === "1" || debug) ? 
                                    <>
                                      <TableCell>
                                        <ContentComponent 
                                          userStatus={userStatus} 
                                          content={ outputGezamtpotenzial } 
                                        />
                                      </TableCell> {/*Gesamtpotenzial*/}
                                      <TableCell> 
                                        <ContentComponent 
                                          userStatus={userStatus} 
                                          content={ outputIhrPotenzialInEuro } 
                                        />
                                      </TableCell> {/*Ihr Potenzial in €*/}
                                    </>
                                    : 
                                    <></>
                                  }

                                  {
                                    (tableType === "2") ? 
                                      <TableCell sx={{textAlign: 'left!important'}}>
                                        <ContentComponent 
                                          userStatus={userStatus} 
                                          content={
                                            <Box sx={{display: 'flex', alignItems: 'center', fontWeight: 'bold'}}><Box>{checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).icon}</Box><Box sx={{ml: 1}}>{checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).info}</Box></Box>
                                          } 
                                        />
                                      </TableCell> : ""
                                  } 
                                </TableRow>

                            )
                          //}

                          processOutputCSV +=  '"' + outputGop + '",' +
                                                '"' + outputTitle + '",' +
                                                '"' + ouputAnzahl  + '",' +
                                                '"' + outputIdealNumberOfTreatments + '",' +
                                                '"' + outputDeviation + '",' +
                                                '"' + ouputDeviationInPercent + '"' +
                                                ((tableType === '1' || debug) ? ',"' + outputGezamtpotenzial + '","' + outputIhrPotenzialInEuro + '"' : '') +
                                                ((tableType === '2') ? ',"' + outputDeviationInPercentStatus + '"' : '') + '\n';

                        countRowForBgColor++;
                        
                        return (userStatus !== 3) ? tableRows : "";
                    }
                  )}
                  
                </TableBody>
              </Table>
            </TableContainer>
    </>);

  }

  return (<>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                  <PieChartFilter />
              </Grid>
              <Grid item xs={4}>
                <SpeedometerFilter />                 
              </Grid>
              <Grid item xs={4}>
                <BarChartFilter />
              </Grid>
            </Grid>
            
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                  <PieChartFilter2 />
                </Grid>
                <Grid item xs={4}>
                  <ScheineChartFilter />
                </Grid>
                <Grid item xs={4}>
                  <StatusChartFilter />
                </Grid>
            </Grid>

            { (userStatus !== 3) ? <br /> : "" }
              
            <TableFilter only_list_include_chart={true} titleHeader="EBM-Ziffern der freien Leistungen" tableType="1" setOuputCSV={setOuputCSV} />

            <br />
            
            <TableFilter titleHeader="Gesamttabelle" tableType="2" setOuputCSV={setOuputCSV} />

            {/*<MembershipStatus 
                    userStatus={userStatus}
                    onClick={()=>{ handleViewFormDialogFeatures() }}
                  />*/}

            <MembershipStatusDanke userStatus={userStatus} />

            <DialogOutput
                      fullScreen={false} // Set the value according to your requirement
                      open={open}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      output={<ViewFormOutput description={dialogData.information} />}
            />

            <DialogOutput
                      fullScreen={false} // Set the value according to your requirement
                      open={openDialogFeatures}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      maxWidth='lg'
                      output={<ViewFormOutputMembership />}
            />

            <br/><br />
  </>);
}

function TableSeparate(props) { /* This is for multiple input inside a single table */

  const {
          rows1, 
          setRows1,
          scheine, 
          fallzahl1, 
          inputCalculationData, 
          setInputCalculationData, 
          handleViewForm,
          filter1,
          title1, 
          title2,
          info,
          user_input,
          viewOnlyMode  
  } = props;

  const filteredRows = rows1?.filter((row) => filter1.includes(row.gop)) // Check for rows1 and id property
                                                     .sort((a, b) => (a.gop > b.gop ? 1 : -1)); //Order by ASC

    return (<>
       <br/>
       <TableContainer component={Paper} sx={{borderRadius:0}}>
            <Table size="small" aria-label="simple table" sx={{ border: '1px solid #949494' }}>
              <TableHead>                
                {
                  (title1 !== "" && title2 !== "") ? 
                    <TableRow sx={{backgroundColor: '#006400'}}>
                      <TableCell sx={{ border: '1px solid #949494', color: '#ffffff', textAlign: 'center' }}>{(title1 !== "") ? "EBM-Ziffer": ""} </TableCell>
                      <TableCell sx={{ border: '1px solid #949494', color: '#ffffff', textAlign: 'center' }}>{(title2 !== "") ? "Anzahl": ""}</TableCell>
                    </TableRow> : ""
                }

                {                
                  (info !== "") ?
                    <TableRow sx={{backgroundColor: '#CAE3B6'}}>
                      <TableCell colSpan={2} sx={{color: '#134020', border: '1px solid  #949494'}}>
                        <div dangerouslySetInnerHTML={{ __html: info }} />
                      </TableCell>
                    </TableRow> : ""
                }
              </TableHead>
              <TableBody>
                {filteredRows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: '1px solid #949494' } }}
                  >
                    <TableCell width={"50%"} sx={{ border: '1px solid #949494', textAlign: 'center' }}>
                      {
                        (row.description !== null) 
                          ? <>
                              <Link 
                                  sx={{cursor:'pointer'}} 
                                  onClick={()=>{ handleViewForm(row.gop, row.description) }}
                              >{row.gop}
                              </Link>
                            </> : <>{row.gop}</>
                      }
                    </TableCell> {/*EBM-Ziffer*/}
                    <TableCell width={"50%"} sx={{ border: '1px solid #949494', textAlign: 'center' }}>
                      <InputFieldTable1 
                          key={row.id} 
                          row={row} 
                          rows={rows1} 
                          setRows={setRows1} 
                          scheine={scheine} 
                          fallzahl={fallzahl1} 
                          index={row.gop} 
                          inputCalculationData={inputCalculationData} 
                          setInputCalculationData={setInputCalculationData} 
                          user_input={user_input}
                          viewOnlyMode={viewOnlyMode}
                      />
                    </TableCell> {/*Anzahl*/}
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
    </>);
}

function TableCompute1(props) { 
  
  const {
    rows, 
    scheine, 
    fallzahl,
    state_id,
    getInputValue, 
    gesamtpotenzial, 
    total_ihr_potenzial, 
    setTotalIhrPotencial, 
    handleViewForm,
    gesamtpotenzial_included,
    total_ihr_potenzial_included,
    setTotalIhrPotencialIncluded,
    setGesamtpotenzialIncluded,
    gesamtpotenzial_notrap,
    ihrpotenzial_notrap,
    setGesamtpotenzialNotrap,
    setIhrpotenzialNotrap,
    debug,
    userStatus,
    viewOnlyMode,
    user_input
  } = props;

  const [inputCalculationData, setInputCalculationData] = useState({});

  const [showResult, setShowResult] = useState(false);

  const [rows1, setRows1] = useState([]);

  const [fallzahl1, setFallzahl1] = useState(fallzahl);

  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });
  /*End of Dialog Box*/

  const [outputCSV, setOuputCSV] = useState("");

  useEffect(() => {

    setRows1(rows);
    setFallzahl1(fallzahl);

  }, [rows]);

  function insertAbrechnungskompassCSVData_func(data, input, scheine, state_id) {
    return DatabaseService.insertAbrechnungskompassCSVData(data, input, scheine, state_id)
  }

  useEffect(() => {
    
    /*console.log("scheine")
    console.log(scheine)*/
    
    /*
    console.log("fallzahl1")
    console.log(fallzahl1)
    */

    /*console.log("inputCalculationData")
    console.log(inputCalculationData)

    console.log("inputCalculationData JSON string")
    console.log(JSON.stringify(inputCalculationData))*/

    if(viewOnlyMode === false){
      if(open){
        /*console.log("outputCSV")
        console.log(outputCSV)*/

        insertAbrechnungskompassCSVData_func(outputCSV, inputCalculationData, scheine, state_id);
      }
    }

  }, [open, outputCSV]);

  /*useEffect(() => {

    if(viewOnlyMode){
      //setInputCalculationData({ ...user_input })

      setInputCalculationData(prevData => {
        const newData = { ...prevData };
        
        // Directly apply all inputs from user_input
        Object.keys(user_input).forEach(key => {
          if (key.startsWith('fallzahl_')) {
            newData[key] = user_input[key];
          }
        });

        return newData;
      });

    }

  }, [viewOnlyMode, user_input])*/

  /*console.log(fallzahl1)
  console.log("rows1")
  console.log(rows1)
  console.log("Total Ihr Potenzial")*/

  /*console.log("user_input")
  console.log(user_input)

  console.log("inputCalculationData")
  console.log(inputCalculationData)*/

  useEffect(() => {
    // Ensure user_input is valid and has values before proceeding
    if (viewOnlyMode && user_input && Object.keys(user_input).length > 0) {
      setInputCalculationData(prevData => {
        // Merge prevData and user_input, with user_input overwriting the existing values
        const newData = { ...prevData, ...user_input };
  
        // Log for debugging purposes
        /*console.log("Updating inputCalculationData with user_input:");
        console.log("Previous Data:", prevData);
        console.log("User Input:", user_input);
        console.log("New Merged Data:", newData);*/
  
        return newData;  // Return the updated state
      });
    }
  }, [viewOnlyMode, user_input]);

  /*console.log("user_input")
  console.log(user_input)

  console.log("inputCalculationData")
  console.log(inputCalculationData)*/

  function onCalculateEachRowAbove(){
    
    if(!debug){

      setDialogInformation(
        { title: '', 
          information: 'Bitte warten!', 
          status: "loading" 
        }
      );

      setOpen(true);
    }

    executeCalculation();
    executeCalculation();

    /*console.log("fallzahl1")
    console.log(fallzahl1)

    console.log("inputCalculationData")
    console.log(inputCalculationData)*/
    
    let gesamtpotenzial_notrap = countGesamtpotenzialNoTrap(rows1);

    setGesamtpotenzialNotrap(gesamtpotenzial_notrap);

    let ihrpotenzial_notrap = countIhrPotencialNoTrap(rows1);

    setIhrpotenzialNotrap(ihrpotenzial_notrap);

    if(!debug){

      setTimeout(() => {
        setOpen(false);

        setDialogInformation(
          { title: '', 
            information: '', 
            status: "view" 
          }
        );

      },500)

      setTimeout(() => {
        setOpen(true);
      },501)
    }

    
  }

  const handleClose = () => {
    setOpen(false);
  };

  function countIhrPotencial(rows){

    let ihrPotencial = 0;

    rows.forEach((row) => {
      if (typeof row.adjusted_euro_potencial === 'undefined') {
          ihrPotencial += parseFloat(row.euro_potencial);
      } else {
          ihrPotencial += parseFloat(row.adjusted_euro_potencial);
      }
    });

    return ihrPotencial;
  }

  function countIhrPotencialIncluded(rows){
    let ihrPotencialIncluded = 0;

    let testListIhrPotencial_included = "gop,ihrpotenzial_included\n";

      rows.forEach((row, index) => {
        const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        /*if(debug){
          
          console.log("ihrPotencialIncluded")
          console.log("fallzahl " + fallzahl)

          console.log("idealNumberOfTreatments")
          console.log("idealNumberOfTreatments " + idealNumberOfTreatments)

          console.log("Euro potenzial")
          console.log(row.gop)
          console.log("row.euro_potencial")
          console.log(row.euro_potencial)
          console.log("row.adjusted_euro_potencial")
          console.log(row.adjusted_euro_potencial)
        }*/


          if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
              if (parseInt(row.include_chart) === 1) {
                  
                  if (typeof row.adjusted_euro_potencial === 'undefined') {
                      ihrPotencialIncluded += parseFloat(row.euro_potencial);

                      if(debug){
                        testListIhrPotencial_included += row.gop + "," + row.euro_potencial + "\n";
                      }

                  } else {
                      ihrPotencialIncluded += parseFloat(row.adjusted_euro_potencial);

                      if(debug){
                        testListIhrPotencial_included += row.gop + "," + row.adjusted_euro_potencial + "\n";
                      }
                  }

              } else {
                  ihrPotencialIncluded += 0;

                  if(debug){
                    testListIhrPotencial_included += row.gop + "," + 0 + "\n";
                  }
              }
          } else {
              ihrPotencialIncluded += 0;
              
              if(debug){
                testListIhrPotencial_included += row.gop + "," + 0 + "\n";
              }
          }
    });

    /*if(debug){
      console.log("testListIhrPotencial_included")
      console.log(testListIhrPotencial_included)
    }*/

    return ihrPotencialIncluded;
  }

  function countIhrPotencialNoTrap(rows){
    let ihrPotencialIncluded = 0;

    let testListIhrPotencialNoTrap = "gop,ihrpotenzial_notrap\n";

      rows.forEach((row, index) => {
        //const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        //const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        /*if(debug){
          
          console.log("ihrPotencialIncluded")
          console.log("fallzahl " + fallzahl)

          console.log("idealNumberOfTreatments")
          console.log("idealNumberOfTreatments " + idealNumberOfTreatments)

          console.log("Euro potenzial")
          console.log(row.gop)
          console.log("row.euro_potencial")
          console.log(row.euro_potencial)
          console.log("row.adjusted_euro_potencial")
          console.log(row.adjusted_euro_potencial)
        }*/

          //if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
              if (parseInt(row.include_chart) === 1) {
                if (row.gop !== '01773' && row.gop !== '01774') { 
                  if (typeof row.adjusted_euro_potencial === 'undefined') {
                      ihrPotencialIncluded += parseFloat(row.euro_potencial);
                      
                      if(debug){
                        //testListIhrPotencialNoTrap.push(...{row.gop, row.euro_potencial});
                        testListIhrPotencialNoTrap += row.gop + "," + row.euro_potencial + "\n";
                      }

                  } else {
                      ihrPotencialIncluded += parseFloat(row.adjusted_euro_potencial);
                      
                      if(debug){
                        //testListIhrPotencialNoTrap.push(...[row.gop, row.adjusted_euro_potencial]);
                        testListIhrPotencialNoTrap += row.gop + "," + row.adjusted_euro_potencial + "\n";
                      }
                  }
                  
                }else{
                  ihrPotencialIncluded += 0;

                  if(debug){
                    //testListIhrPotencialNoTrap.push(parseFloat(...[row.gop, 0]));
                    testListIhrPotencialNoTrap += row.gop + "," + 0 + "\n";
                  }
                }

              } else {
                  ihrPotencialIncluded += 0;

                  if(debug){
                    //testListIhrPotencialNoTrap.push(parseFloat(...[row.gop, 0]));
                    testListIhrPotencialNoTrap += row.gop + "," + 0 + "\n";
                  }
              }
          //} else {
          //    ihrPotencialIncluded += 0;
          //}
    });

    if(debug){
      //console.log("testListIhrPotencialNoTrap")
      //console.log(testListIhrPotencialNoTrap)
    }

    return ihrPotencialIncluded;
  }

  function countGesamtpotenzialIncluded(rows){
    let gesamtpotenzial_included = 0;
    
    let testListgesamtpotenzial_included = "gop,gesamtpotenzial_included\n";

    rows.forEach((row, index) => {
        const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        //const idealNumberOfTreatments = (row.exclude_scheine === 1) ? parseFloat(row.adjusted_ideal_number_of_treatments).toFixed(0) : parseFloat(row.ideal_number_of_treatments).toFixed(0);

        if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
            if (parseInt(row.include_chart) === 1) {
                
                
              if(row.euro_potencial_mutterschaftsvorsorge_ziffern !== 0){
                gesamtpotenzial_included += row.euro_potencial_mutterschaftsvorsorge_ziffern;

                if(debug){
                  testListgesamtpotenzial_included += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                }
                
              }else{
                gesamtpotenzial_included += row.euro_potencial;

                if(debug){
                  testListgesamtpotenzial_included += row.gop + "," + row.euro_potencial + "\n";
                }

              }

                /*if (typeof row.adjusted_euro_potencial === 'undefined') {
                  gesamtpotenzial_included += parseFloat(row.euro_potencial);
                } else {
                  gesamtpotenzial_included += parseFloat(row.adjusted_euro_potencial);
                }*/

            } else {
                gesamtpotenzial_included += 0;

                if(debug){
                  testListgesamtpotenzial_included += row.gop + "," + 0 + "\n";
                }
            }
        } else {
           gesamtpotenzial_included += 0;

           if(debug){
            testListgesamtpotenzial_included += row.gop + "," + 0 + "\n";
          }
        }
    });

    /*if(debug){
      console.log("testListgesamtpotenzial_included")
      console.log(testListgesamtpotenzial_included)
    }*/

    return gesamtpotenzial_included;
  }

  function countGesamtpotenzialNoTrap(rows){
    let gesamtpotenzial_notrap = 0;
    
    let testListgesamtpotenzial_notrap = "gop,gesamtpotenzial_notrap\n";
    
    rows.forEach((row, index) => {
        //const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        //const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        //const idealNumberOfTreatments = (row.exclude_scheine === 1) ? parseFloat(row.adjusted_ideal_number_of_treatments).toFixed(0) : parseFloat(row.ideal_number_of_treatments).toFixed(0);

        //if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
            if (parseInt(row.include_chart) === 1) {
                
              if (row.gop !== '01773' && row.gop !== '01774') {  

                if(row.exclude_scheine === 1){

                    /*if(row.euro_potencial_mutterschaftsvorsorge_ziffern !== 0) {
                      gesamtpotenzial_notrap += parseFloat(row.euro_potencial);

                      if(debug){
                        testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial + "\n";
                      }

                    }else{
                      gesamtpotenzial_notrap += parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern);
                      
                      if(debug){
                        testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                      }
                    }*/

                    //'01771'

                    gesamtpotenzial_notrap += parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern);

                    if(debug){
                      testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                    }

                }else{
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial);
                  
                  if(debug){
                    testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial + "\n";
                  }
                }

                /*if(row.euro_potencial_mutterschaftsvorsorge_ziffern !== 0){
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern);

                  if(debug){
                    testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                  }

                }else{
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial);

                  if(debug){
                    testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial + "\n";
                  }

                }*/
              }else{
                gesamtpotenzial_notrap += 0;

                if(debug){
                  testListgesamtpotenzial_notrap += row.gop + "," + 0 + "\n";
                }
              }
                /*if (typeof row.adjusted_euro_potencial === 'undefined') {
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial);
                } else {
                  gesamtpotenzial_notrap += parseFloat(row.adjusted_euro_potencial);
                }*/

            } else {
              gesamtpotenzial_notrap += 0;
              
              if(debug){
                testListgesamtpotenzial_notrap += row.gop + "," + 0 + "\n";
              }
            }
        //} else {
        //   gesamtpotenzial_NoTrap += 0;
        //}
    });

    if(debug){
      //console.log("testListgesamtpotenzial_notrap")
      //console.log(testListgesamtpotenzial_notrap)
    }

    return gesamtpotenzial_notrap;
  }

  function checkDeviationPercentColor(row_deviation_percent, row_gop){

    let deviation_percent = parseFloat(row_deviation_percent).toFixed(2);
    let deviation_percent_color = "";
    let setlightRed = false;
    //01640 01737 01740 01760 01761 01764 01770 01820 01821 01822 01823 01824 01831

    setlightRed = ['01640',
                     '01737',
                     '01740',
                     '01760',
                     '01761',
                     '01764',
                     '01770',
                     '01820',
                     '01821',
                     '01822',
                     '01823',
                     '01824',
                     '01831'].includes(row_gop);

      if(!isNaN(deviation_percent)){
        if(deviation_percent > 50){
          
          if(!setlightRed){
            deviation_percent_color = 'red';
          }else{
            deviation_percent_color = 'lightred';
          }
            

        }else if(deviation_percent > 30){
          if(!setlightRed){
            deviation_percent_color = 'orange';
          }else{
            deviation_percent_color = 'lightred';
          }
        }
      }
    return deviation_percent_color;
  }

  function executeCalculation(){
   
        /*console.log("inputCalculationData")
        console.log(inputCalculationData)*/

        

        let anzahl_input_01770 = 0;

        /*let ihrPotencial = 0;
        
        rows1.forEach((row) => {
            if (typeof row.adjusted_euro_potencial === 'undefined') {
                ihrPotencial += parseFloat(row.euro_potencial);
            } else {
                ihrPotencial += parseFloat(row.adjusted_euro_potencial);
            }
        });*/

        let ihrPotencial = countIhrPotencial(rows1);

        setTotalIhrPotencial(ihrPotencial);

        /*let ihrPotencialIncluded = 0;

        rows.forEach((row, index) => {
          const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
          
          const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

          if(debug){
            
            console.log("ihrPotencialIncluded")
            console.log("fallzahl " + fallzahl)

            console.log("idealNumberOfTreatments")
            console.log("idealNumberOfTreatments " + idealNumberOfTreatments)

            console.log("Euro potenzial")
            console.log(row.gop)
            console.log("row.euro_potencial")
            console.log(row.euro_potencial)
            console.log("row.adjusted_euro_potencial")
            console.log(row.adjusted_euro_potencial)
          }


            if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
                if (parseInt(row.include_chart) === 1) {
                    
                    if (typeof row.adjusted_euro_potencial === 'undefined') {
                        ihrPotencialIncluded += parseFloat(row.euro_potencial);
                    } else {
                        ihrPotencialIncluded += parseFloat(row.adjusted_euro_potencial);
                    }

                } else {
                    ihrPotencialIncluded += 0;
                }
            } else {
                ihrPotencialIncluded += 0;
            }
        });*/

        let ihrPotencialIncluded = countIhrPotencialIncluded(rows1);

        setTotalIhrPotencialIncluded(ihrPotencialIncluded)

        /*let gesamtpotenzial_included = 0;
        
        rows1.forEach((row, index) => {
            const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
            
            const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

            //const idealNumberOfTreatments = (row.exclude_scheine === 1) ? parseFloat(row.adjusted_ideal_number_of_treatments).toFixed(0) : parseFloat(row.ideal_number_of_treatments).toFixed(0);

            if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
                if (parseInt(row.include_chart) === 1) {
                    gesamtpotenzial_included += row.euro_potencial;
                } else {
                    gesamtpotenzial_included += 0;
                }
            } else {
              gesamtpotenzial_included += 0;
            }
        });*/

        let gesamtpotenzial_included = countGesamtpotenzialIncluded(rows1);

        setGesamtpotenzialIncluded(gesamtpotenzial_included)

        /*let ihrpotenzial_notrap = countIhrPotencialNoTrap(rows1);

        setIhrpotenzialNotrap(ihrpotenzial_notrap);*/

        const updatedRows = rows1.map((row, index) => {
          
          let euro_potencial = 0;

          let euro_potencial_mutterschaftsvorsorge_ziffern = 0;

          let ideal_number_of_treatments = 0;

          if(row.gop === '01770' && parseInt(inputCalculationData[`fallzahl_${row.gop}`]) > 0){
            
            /*console.log("GOP " + row.gop)
            console.log("Anzahl Treatments " + inputCalculationData[`fallzahl_${row.gop}`])*/
            
            anzahl_input_01770 = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
          }

          if(anzahl_input_01770 > 0){ //Mutterschaftsvorsorge-Ziffern formula

            ideal_number_of_treatments = (parseInt(row.exclude_scheine) === 1) 
                ? (anzahl_input_01770 * row.ebm_koeffizient) 
                : row.ideal_number_of_treatments;

            //row.euro_potencial = Math.abs(parseFloat(row.deviation) * parseFloat(row.euro_value_of_a_treatment));
            //soll -> 
            //euro_value_of_a_treatment
            //Soll * Gesamt (EUR)
            
            euro_potencial_mutterschaftsvorsorge_ziffern = ideal_number_of_treatments * row.euro_value_of_a_treatment;


          }else{

            ideal_number_of_treatments = (parseInt(row.exclude_scheine) === 1) 
                ? (inputCalculationData[`fallzahl_${row.gop}`] * row.ebm_koeffizient) 
                : row.ideal_number_of_treatments;
          
          }

          let deviation = inputCalculationData[`fallzahl_${row.gop}`] - ideal_number_of_treatments;
          let deviation_in_percent = (deviation / ideal_number_of_treatments) * 100;
          

          /*if(row.exclude_scheine === 1){
            euro_potencial_mutterschaftsvorsorge_ziffern =
          }*/ //euro_potencial = (row.exclude_scheine !== 1) ? Math.abs(parseFloat(deviation)) * parseFloat(row.euro_value_of_a_treatment) : euro_potencial_mutterschaftsvorsorge_ziffern;

          euro_potencial = Math.abs(parseFloat(deviation)) * parseFloat(row.euro_value_of_a_treatment);
          
          //let euro_potencial_show = (parseFloat(inputCalculationData[`fallzahl_${row.gop}`]) <= parseFloat(ideal_number_of_treatments).toFixed(0)) ? 1 : 0;
          let euro_potencial_show = 1;

          
          //if anzahl <= ideal number treatments

          /*
          if(debug){
            if(row.gop === '01771'){
              console.log("01771")
              console.log("input " + parseInt(inputCalculationData[`fallzahl_${row.gop}`]))
              console.log("ideal_number_of_treatments " + parseFloat(ideal_number_of_treatments).toFixed(0));

              console.log("parseInt(inputCalculationData[`fallzahl_${row.gop}`]) <= parseFloat(ideal_number_of_treatments).toFixed(0)")
              console.log(parseInt(inputCalculationData[`fallzahl_${row.gop}`]) <= parseFloat(ideal_number_of_treatments).toFixed(0))
            }
          }*/
            
          if(parseInt(inputCalculationData[`fallzahl_${row.gop}`]) <= parseInt(ideal_number_of_treatments)){
            euro_potencial_show = 1;
          }else{
            euro_potencial_show = 0;
          }

          let deviation_in_percent_status = checkDeviationPercentColor(deviation_in_percent, row.gop);

          //if the Abweichung (deviation) is plus -> don't show "Ihr Potenzial in €"
          /*if(row.deviation_in_percent > 0){
            euro_potencial_show = 0;

            console.log("row.deviation_in_percent")
            console.log(row.deviation_in_percent)

          }else{
            euro_potencial_show = 1;
          }*/

          row.anzahl = inputCalculationData[`fallzahl_${row.gop}`];
          row.deviation = deviation;
          row.deviation_in_percent = deviation_in_percent;
          row.euro_potencial_show = euro_potencial_show;
          row.adjusted_euro_potencial = euro_potencial;
          row.ideal_number_of_treatments = ideal_number_of_treatments;
          row.euro_potencial_mutterschaftsvorsorge_ziffern = euro_potencial_mutterschaftsvorsorge_ziffern;
          row.deviation_in_percent_status = deviation_in_percent_status;
          return { ...row };
        });
        
        setRows1(updatedRows);
        setShowResult(true);
  }

  /*console.log("inputCalculationData TEST")
  console.log(inputCalculationData)*/

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} sx={{position: 'relative'}}>

          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1}
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01100', '01101', '01102']}
            title1="true"
            title2="true"
            info="1 Allgemeine Gebührenordnungspositionen <br/>1.1 Aufwandserstattung für die besondere Inanspruchnahme des Vertragsarztes durch einen Patienten"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />

          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1} 
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01210','01212','01218']}
            title1=""
            title2=""
            info="1.2 Gebührenordnungspositionen für die Versorgung im Notfall und im organisierten ärztlichen Not(-fall)dienst"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />

          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1} 
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01415','01430','01435','01436']}
            title1=""
            title2=""
            info="1.4 Besuche, Visiten, Prüfung der häuslichen Krankenpflege, Verordnung besonderer Behandlungsmaßnahmen, Verwaltungskomplex, telefonische Beratung, Konsultationspauschale, Verweilen, Beratung zur Organ- und Gewebespende"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />
          
          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1} 
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01621','01622','01640','01641']}
            title1=""
            title2=""
            info="1.6 Schriftliche Mitteilungen, Gutachten, Telekonsilien"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />

          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1} 
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01735','01737','01740']}
            title1=""
            title2=""
            info="1.7.2 Früherkennung von Krankheiten bei Erwachsenen"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />

        </Grid>
        <Grid item xs={3} sx={{position: 'relative'}}>

          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1} 
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01770','01771','01772','01773','01775', '01776', '01777', '01784', '01785', '01786', '01788', '01789', '01815']}
            title1="true"
            title2="true"
            info="1.7.4 Mutterschaftsvorsorge"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />

          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1} 
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01820','01821','01822','01823','01824','01825','01827','01830','01831','01832']}
            title1=""
            title2=""
            info="1.7.5 Empfängnisregelung"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />

        </Grid>

        <Grid item xs={3} sx={{position: 'relative'}}>
          
          <TableSeparate 
            rows1={rows1} 
            setRows1={setRows1} 
            scheine={scheine} 
            fallzahl1={fallzahl1} 
            inputCalculationData={inputCalculationData} 
            setInputCalculationData={setInputCalculationData}
            handleViewForm={handleViewForm}
            filter1={['01850']}
            title1="EBM-Ziffer"
            title2="Anzahl"
            info="1.7.6 Sterilisation"
            user_input={user_input}
            viewOnlyMode={viewOnlyMode}
          />
          
          <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['01900','01902']}
              title1=""
              title2=""
              info="1.7.7 Schwangerschaftsabbruch"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['02100']}
              title1=""
              title2=""
              info="2.1 Infusionen, Transfusionen, Retransfusion, Programmierung von Medikamentenpumpen"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['02300','02301','02310','02340']}
              title1=""
              title2=""
              info="2.3 Kleinchirurgische Eingriffe, Allgemeine therapeutische Leistungen"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['08210','08211','08212','08220']}
              title1=""
              title2=""
              info="8.2 Frauenärztliche Grundpauschalen"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['08315','08330','08332','08340','08345']}
              title1=""
              title2=""
              info="8.3 Diagnostische und therapeutische Gebührenordnungspositionen"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['08521']}
              title1=""
              title2=""
              info="8.5 Reproduktionsmedizin"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

        </Grid>

        <Grid item xs={3} sx={{position: 'relative'}}>

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['31695','31697']}
              title1="true"
              title2="true"
              info="31 Gebührenordnungspositionen für ambulante Operationen, Anästhesien, präoperative, postoperative und orthopädisch-chirurgisch konservative Leistungen"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['32030','32031','32032','32033','32045','32132','32135','32151']}
              title1=""
              title2=""
              info="32 In-vitro-Diagnostik der Laboratoriumsmedizin, Mikrobiologie, Virologie und Infektionsepidemiologie sowie Transfusionsmedizin"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['33041','33044','33074']}
              title1=""
              title2=""
              info="33 Ultraschalldiagnostik"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['35100','35110']}
              title1=""
              title2=""
              info="35 Leistungen gemäß der Richtlinie des Gemeinsamen Bundesausschusses über die Durchführung der Psychotherapie (Psychotherapie-Richtlinie)"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />

            <TableSeparate 
              rows1={rows1} 
              setRows1={setRows1} 
              scheine={scheine} 
              fallzahl1={fallzahl1} 
              inputCalculationData={inputCalculationData} 
              setInputCalculationData={setInputCalculationData}
              handleViewForm={handleViewForm}
              filter1={['40110']}
              title1=""
              title2=""
              info="40 Kostenpauschalen"
              user_input={user_input}
              viewOnlyMode={viewOnlyMode}
            />
            <br/>  
            <ProfileCard getInputValue={getInputValue} onCalculateEachRowAbove={onCalculateEachRowAbove} />
            
        </Grid>
      </Grid>

      {
        (debug) ? <>
                <TableResults 
                  scheine={scheine}
                  showResult={showResult} 
                  total_ihr_potenzial_included={total_ihr_potenzial_included} 
                  gesamtpotenzial_included={gesamtpotenzial_included}
                  ihrpotenzial_notrap={ihrpotenzial_notrap}
                  gesamtpotenzial_notrap={gesamtpotenzial_notrap}
                  rows1={rows1}
                  debug={debug}
                  setOuputCSV={setOuputCSV}
                  userStatus={userStatus}
              />  
        </>:<></>
      }  

        <DialogOutput
            fullScreen={true} // Set the value according to your requirement
            open={open}
            dialogData={dialogData}
            handleClose={handleClose}
            backgroundColor='#F1F2F4'
            output={
                      <TableResults 
                          scheine={scheine}
                          showResult={showResult} 
                          total_ihr_potenzial_included={total_ihr_potenzial_included} 
                          gesamtpotenzial_included={gesamtpotenzial_included}
                          ihrpotenzial_notrap={ihrpotenzial_notrap}
                          gesamtpotenzial_notrap={gesamtpotenzial_notrap}
                          rows1={rows1}
                          debug={debug}
                          setOuputCSV={setOuputCSV}
                          userStatus={userStatus}
                      />
                  }
        />          
    </>
  );
}

function InputFieldTable1(props){ /* This is used for the input and this will be duplicated on TableSeparate component */
  
  const { row, rows, setRows, scheine, fallzahl, index, inputCalculationData, setInputCalculationData, user_input, viewOnlyMode } = props;

  const [enableValidation, setEnableValidation] = useState(false);  

  const [hasTyped, setHasTyped] = useState(false);

  const [errorInputCalculation, setErrorInputCalculation] = useState({});

  //const currentValue = inputCalculationData[`fallzahl_${index}`] || '';

  const currentValue = (viewOnlyMode) ? user_input[`fallzahl_${index}`] || '' : inputCalculationData[`fallzahl_${index}`] || '';   
  
  //const currentValue = user_input[`fallzahl_${index}`] || '';

  function validation(inputCalculationData, validate) {

    let error = { fallzahl: '' }

    if (validate) {
        error.fallzahl = (inputCalculationData[`fallzahl_${index}`] === "") ? "Fallzahl ist erforderlich" : "";
    }

    setErrorInputCalculation(error)

    if (error.fallzahl === "") {
        return false;
    } else {
        return true;
    }
  }

  function changeInputCalculationData(e) {
   
    const { name, value } = e.target;
  
    let newValue = value.replace(/[^0-9\.]/g, ''); // Only allows numbers, period(.) and comma(,)

    /*console.log("name")
    console.log(name)*/

    // If the input starts with '0' and the user types for the first time, remove the leading zero
    if (!hasTyped && newValue.startsWith('0') && newValue.length > 1) {
      newValue = newValue.substring(1);
      setHasTyped(true); // Mark that the user has started typing
    }

    //setInputCalculationData({ ...inputCalculationData, [name]: newValue });
    setInputCalculationData(prevState => ({ ...prevState, [name]: newValue }));
    //console.log(inputCalculationData);

  }

  /*useEffect(() => {
    
    if(viewOnlyMode){
      setInputCalculationData(prevState => ({ ...prevState, [`fallzahl_${index}`]: currentValue }));
    }
    
  }, [viewOnlyMode, currentValue]);*/

  useEffect(() => {
    if (viewOnlyMode && user_input && Object.keys(user_input).length > 0) {
      setInputCalculationData(prevData => {
        const newData = { ...prevData };

        // Update the relevant keys from user_input
        Object.keys(user_input).forEach(key => {
          const value = user_input[key].replace(/[^0-9\.]/g, ''); // Sanitize the input value
          newData[key] = value;
        });

        // Log for debugging
        /*console.log("Updating inputCalculationData based on user_input:");
        console.log("New Merged Data:", newData);*/

        return newData; // Update the state with merged data
      });
    }
  }, [viewOnlyMode, user_input]);

  function onInputCalculationValidation(){
    validation(inputCalculationData, enableValidation);
  }

  function onInputCalculationValidationFocusOut() {
    validation(inputCalculationData, enableValidation);
  }

  function onCalculateEachRow(index, id){
      /*console.log("index");
      console.log(index);

      console.log("row id")
      console.log(id);

      console.log("rows[index]")
      console.log(rows[index]);

      console.log("rows")
      console.log(rows);
      
      console.log("row")
      console.log(row)

      console.log("scheine")
      console.log(scheine)

      console.log("inputCalculationData")
      console.log(inputCalculationData)

      console.log("JSON.stringify(rows[0])")
      console.log(JSON.stringify(rows[0]));*/

      /*
      {
        "id":"5458",
        "gop":"01100",
        "ebm_koeffizient":"0.01426",
        "points":196,
        "euro_value_of_a_treatment":"23.390444",
        "scheine":"1000",
        "ebm_ziffer":"0",
        "fallzahl":"0",
        "ideal_number_of_treatments":"14.26000",
        "deviation":"-14.26000",
        "deviation_in_percent":-100,
        "euro_potencial":333.54773143999995,
        "description":""
      }
      */

      let deviation = inputCalculationData[`fallzahl_${index}`] - rows[index].ideal_number_of_treatments;
      let deviation_in_percent = (deviation / rows[index].ideal_number_of_treatments) * 100;

      let euro_potencial = Math.abs(parseFloat(deviation)) * parseFloat(rows[index].euro_value_of_a_treatment);

      //let euro_potencial = 0;

      /*console.log('fallzahl_'+ index + ' ' + inputCalculationData[`fallzahl_${index}`]);
      console.log('rows[index].ideal_number_of_treatments ' + rows[index].ideal_number_of_treatments);
      console.log('parseFloat(rows[index].ideal_number_of_treatments).toFixed(0) ' + parseFloat(rows[index].ideal_number_of_treatments).toFixed(0));*/

      let euro_potencial_show = (parseFloat(inputCalculationData[`fallzahl_${index}`]) <= parseFloat(rows[index].ideal_number_of_treatments).toFixed(0)) ? 1 : 0;
      
      /*console.log("deviation " + deviation);
      console.log("deviation_in_percent " + deviation_in_percent);*/

      // Create a new row object with the calculated results
      const updatedRow = {
        ...row,
        //ideal_number_of_treatments, 
        deviation, 
        deviation_in_percent,
        euro_potencial,
        euro_potencial_show
      };

      // Update the specific row in the `rows` array
      const updatedRows = [...rows];
      updatedRows[index] = updatedRow;

      // Assuming setRows is a state setter function
      setRows(updatedRows);
  }

  useEffect(() => {
    
  }, [rows]);

  useEffect(() => {

  }, [inputCalculationData]);

  useEffect(() => {
    
    setInputCalculationData(prevState => ({ ...prevState, [`fallzahl_${index}`]: fallzahl }));

  }, [fallzahl]);

  return (<>
    
              <TextField
                      required
                      margin="dense"
                      id={`fallzahl_${index}`}
                      name={`fallzahl_${index}`}
                      type="text"
                      variant="filled"
                      size="small"
                      hiddenLabel
                      sx={{marginTop:0, '& .MuiInputBase-input': {
                                          textAlign: 'center', // Change this to 'left' or 'right' for other alignments
                                          borderRadius: 0,
                                        },
                                        '& .MuiInputBase-root':{
                                          borderTopLeftRadius: 0,
                                          borderTopRightRadius: 0
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          WebkitTextFillColor: 'black',
                                          color: 'black',
                                        },
                          }}
                      InputProps={{
                        readOnly: viewOnlyMode,
                      }}
                      disabled={viewOnlyMode}
                      value={currentValue}
                      onChange={changeInputCalculationData}
                    />
  </>);

}

function InputFields(props){

  const { 
          setInputNumber, 
          inputCalculationData, 
          setInputCalculationData, 
          getEBMAbrechnungskompassData, 
          page, 
          rowsPerPage,
          viewOnlyMode,
          user_input_scheine,
          user_input_state,
          listStates,
          userStatus, 
          userRoleID
  } = props;

  const [enableValidation, setEnableValidation] = useState(false);  

  const [errorInputCalculation, setErrorInputCalculation] = useState({});

  async function onCalculate() {
    
    setEnableValidation(true);

    if (!validation(inputCalculationData, true)) {

        /*console.log("inputCalculationData");
        console.log(inputCalculationData);*/

    }    
  }

  function onInputCalculationValidation(){
    validation(inputCalculationData, enableValidation);
  }

  function validation(inputCalculationData, validate) {

    let error = { scheine: "", fallzahl: "" }

    if (validate) {
        error.scheine = (inputCalculationData.scheine === "") ? "Scheine ist erforderlich" : "";
        error.fallzahl = (inputCalculationData.fallzahl === "") ? "Fallzahl ist erforderlich" : "";
    }

    setErrorInputCalculation(error)

    if (error.scheine === "" && error.fallzahl === "") {
        return false;
    } else {
        return true;
    }
  }

  function onInputCalculationValidationFocusOut() {
    validation(inputCalculationData, enableValidation);
  }

  function changeInputCalculationData(e) {
   
    const { name, value } = e.target;
  
    const newValue = value.toString().replace(/[^0-9\.]/g, ''); // Only allows numbers, period(.) and comma(,)

    setInputCalculationData({ ...inputCalculationData, [name]: newValue });
    //console.log("inputCalculationData");
    //console.log(inputCalculationData);

    setInputNumber(inputCalculationData);
  }

  useEffect(() => {

    if(viewOnlyMode){
      setInputCalculationData({...inputCalculationData, scheine: user_input_scheine, state_id: user_input_state});
    }

  }, [viewOnlyMode, user_input_scheine, user_input_state]);

  const getFilteredStates = (userRoleID, listStates) => {
    // Define roles that should see only Bayern and Berlin
    const restrictedRoles = [2, 5];
    
    // Define roles that should see all states
    const fullAccessRoles = [1, 4];
    
    // Restricted access - only Bayern and Berlin
    if (restrictedRoles.includes(parseInt(userRoleID))) {
      return listStates.filter(state => 
        ['Bayern', 'Berlin'].includes(state.title)
      );
    }
    
    // Full access - all states
    if (fullAccessRoles.includes(parseInt(userRoleID))) {
      return listStates;
    }
    
    // No access - empty array
    return [];
  };

  const filteredListStates = getFilteredStates(userRoleID, listStates);

  return (<>

                  <Typography variant="h5" component="h1" gutterBottom sx={{color: '#014017'}}>
                    Bitte geben Sie Ihre Scheinzahl und die Anzahl der jeweiligen EBM-Ziffern an:
                  </Typography>

                  <Grid container spacing={1}>
                    <Grid mt={0} item xs={12} sm={2}>
                      <TextField
                          required
                          margin="dense"
                          id="scheine"
                          label="Scheine"
                          name="scheine"
                          type="text"
                          variant="outlined"
                          value={inputCalculationData.scheine}
                          error={(enableValidation) ? (errorInputCalculation.scheine !== "") ? true : false : false}
                          helperText={errorInputCalculation.scheine}
                          onChange={changeInputCalculationData}
                          onKeyUp={onInputCalculationValidation}
                          onBlur={onInputCalculationValidationFocusOut}
                          sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                  WebkitTextFillColor: 'black',
                                  color: 'black',
                                },
                                width: '100%'
                          }}
                          InputProps={{
                            readOnly: viewOnlyMode,
                          }}
                          disabled={viewOnlyMode}
                      />
                    </Grid>

                    <Grid mt={1} item xs={12} sm={2}  sx={{'& .MuiFormControl-root': { width: '100%'}}}>
                      <Controls.Select
                          name="state_id"
                          label="KV"
                          value={inputCalculationData.state_id}
                          onChange={changeInputCalculationData}
                          showNoneOption={false}
                          options={filteredListStates}
                          disabled={viewOnlyMode}
                          sx={{ '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black', color: 'black' }}}
                      />
                    </Grid>
                  </Grid>
  </>)
}

export default function EBM_Abrechnungstool(props) {

  const { user_input, user_input_scheine, user_input_state, view_only_mode } = props;

  function createData(
                      id,
                      gop,
                      ebm_koeffizient,
                      points,
                      euro_value_of_a_treatment,
                      scheine,
                      ebm_ziffer,
                      fallzahl,
                      ideal_number_of_treatments,
                      deviation,
                      deviation_in_percent,
                      euro_potencial,
                      title,
                      description,
                      include_chart,
                      exclude_scheine,
                      header_no,
                      header_description
                      ) {
    return {
        id,
        gop,
        ebm_koeffizient,
        points,
        euro_value_of_a_treatment,
        scheine,
        ebm_ziffer,
        fallzahl,
        ideal_number_of_treatments,
        deviation,
        deviation_in_percent,
        euro_potencial,
        title,
        description,
        include_chart,
        exclude_scheine,
        header_no,
        header_description
    };
  }

  const { userStatus, userRoleID } = useSingleUserData();

  const location = useLocation();

  const [viewOnlyMode, setViewOnlyMode] = useState(false);

  const [debug, setDebug] = useState(false);

  const [inputCalculationData, setInputCalculationData] = useState({ scheine: '', ebm_ziffer: '', fallzahl: '0', state_id: '2' });

  const [inputNumber, setInputNumber] = useState({ scheine: '', ebm_ziffer: '', fallzahl: '0', state_id: '2' });

  const [listDataFromDB, setRowDataFromDB] = useState([]);

  const [rows, setRows] = useState([]);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(1048576);

  const [gesamtpotenzial, setGesamtpotenzial] = useState(0);

  const [gesamtpotenzial_included, setGesamtpotenzialIncluded] = useState(0);

  const [total_ihr_potenzial, setTotalIhrPotencial] = useState(gesamtpotenzial);

  const [total_ihr_potenzial_included, setTotalIhrPotencialIncluded] = useState(0);

  const [gesamtpotenzial_notrap, setGesamtpotenzialNotrap] = useState(0);

  const [ihrpotenzial_notrap, setIhrpotenzialNotrap] = useState(0);

  const [listStates, setListStates] = useState([]);

  /*Dialog Box*/
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });
  /*End of Dialog Box*/

  //console.log("userStatus");
  //console.log(userStatus)

  const getEBMAbrechnungskompassData = async (page, rowsPerPage, inputCalculationData) => {

    //console.log("inputCalculationData")
    //console.log(inputCalculationData)

    try {
        const JSONData = await DatabaseService.getEBMAbrechnungskompass3((page + 1), rowsPerPage, inputCalculationData.ebm_ziffer, inputCalculationData.scheine, inputCalculationData.fallzahl, inputCalculationData.state_id, userRoleID);
        if (JSONData && JSONData.rows) {
          setRowDataFromDB(JSONData.rows); // Set the user data array to listAllUsers
        }

        /*if (JSONData && JSONData.totalCount) {
            setTotalRowsCount(JSONData.totalCount); // Set the user data array to listAllUsers
            //setRowsPerPage(JSONData.totalCount)
        }*/

        /*if (JSONData && JSONData.all_gops) {
          setAllGOPS(JSONData.all_gops);
        }*/

        if(JSONData && JSONData.gesamtpotenzial) {
          setGesamtpotenzial(JSONData.gesamtpotenzial);
        }

        /*if(JSONData && JSONData.userSession){
          setUserSession(JSONData.userSession);
        }*/

        /*if(JSONData && JSONData.gesamtpotenzial_included){
          setGesamtpotenzialIncluded(JSONData.gesamtpotenzial_included);
        }*/
        /*if (JSONData && JSONData.search) {
            setSearchInput(JSONData.search); // Set the user data array to listAllUsers
        }*/

        //setEnableValidation(false)

        if(JSONData && JSONData.stateRows){
          setListStates(JSONData.stateRows);
        }

    } catch (error) {
        console.error('Error fetching user data:', error);
    }

    //setLoading(false); // Set loading to false after data is fetched
  }

  /*useEffect(() => {
  
    //if(enableValidation === true) {
      //getEBMAbrechnungskompassData(page, rowsPerPage, inputNumber);  
    //}
    
    if(displayResult === true) {
      getEBMAbrechnungskompassData(page, rowsPerPage, inputNumber)
        .then(() => {
          setDisplayResult(false)
        })
        .catch(error => {
            console.log('Error fetching user data:', error);
        });
    }

  }, [page, rowsPerPage, displayResult]);*/

  useEffect(() => {
    
      if(viewOnlyMode){

        getEBMAbrechnungskompassData(page, rowsPerPage, {scheine: user_input_scheine, ebm_ziffer: '', fallzahl: '0', state_id: user_input_state})
          .then(() => {})
          .catch(error => {
              console.log('Error fetching user data:', error);
          });
        
      }else{
        
        getEBMAbrechnungskompassData(page, rowsPerPage, inputCalculationData)
          .then(() => {})
          .catch(error => {
              console.log('Error fetching user data:', error);
          });
      
      }
      
    
  }, [page, rowsPerPage, inputCalculationData, viewOnlyMode]);


  useEffect(() => {
    
    // Map listAllUsers to create rows and update the state
    const updatedRows = listDataFromDB.map(item => createData(
        item.id,
        item.gop,
        item.ebm_koeffizient,
        item.points,
        item.euro_value_of_a_treatment,
        item.scheine,
        item.ebm_ziffer,
        item.fallzahl,
        item.ideal_number_of_treatments,
        item.deviation,
        item.deviation_in_percent,
        item.euro_potencial, 
        item.title,
        item.description,
        item.include_chart,
        item.exclude_scheine,
        item.header_no,
        item.header_description
    ));

    // Update the rows state
    setRows(updatedRows);

  }, [listDataFromDB]);

  useEffect(() => {

    if(new URLSearchParams(location.search).get('debug') !== null) {
      setDebug(true);  
    }

  }, [])

  /*useEffect(() => {

    if(view_only_mode === true) {
      setViewOnlyMode(true);
      setInputCalculationData({...inputCalculationData, scheine: user_input_scheine});
      //setInputNumber(inputCalculationData);
    }

  }, [view_only_mode, user_input_scheine]);*/


  useEffect(() => {

    if(view_only_mode === true) {
      setViewOnlyMode(true);
      //setInputCalculationData({...inputCalculationData, scheine: user_input_scheine});
      //setInputNumber(inputCalculationData);
    }

  }, [view_only_mode]);

  /*useEffect(() => {
    
    getEBMAbrechnungskompassData(page, rowsPerPage, inputCalculationData)
      .then(() => {})
      .catch(error => {
          console.log('Error fetching user data:', error);
      });
  
  }, [page, rowsPerPage, inputCalculationData, view_only_mode, user_input_scheine]);*/

  /*useEffect(() => {

    if(open) {
      
      setViewOnlyMode(true);
      setInputCalculationData({...inputCalculationData,scheine: user_input_scheine});

    }

  }, [open, view_only_mode, user_input_scheine]);*/

  /* Dialog Box */
  const handleViewForm = (gop, description) => {
      setDialogInformation({ title: gop, information: description, status: "view" });
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  };
  /* Dialog Box */

  const getInputValue = () => {

    setInputNumber(inputCalculationData);
  
  }

  return (
          <>
                   
                   <InputFields 
                        setInputNumber={setInputNumber} 
                        inputCalculationData={inputCalculationData}
                        setInputCalculationData={setInputCalculationData}
                        getEBMAbrechnungskompassData={getEBMAbrechnungskompassData}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        viewOnlyMode={viewOnlyMode}
                        user_input_scheine={user_input_scheine}
                        user_input_state={user_input_state}
                        listStates={listStates}
                        userStatus={userStatus}
                        userRoleID={userRoleID}
                    /> 
                 
                   <TableCompute1 
                        rows={rows} 
                        scheine={inputNumber.scheine} 
                        fallzahl={inputNumber.fallzahl}
                        state_id={inputNumber.state_id}
                        getInputValue={getInputValue}
                        gesamtpotenzial={gesamtpotenzial}
                        total_ihr_potenzial={total_ihr_potenzial}
                        setTotalIhrPotencial={setTotalIhrPotencial}
                        handleViewForm={handleViewForm}
                        gesamtpotenzial_included={gesamtpotenzial_included}
                        total_ihr_potenzial_included={total_ihr_potenzial_included}
                        setTotalIhrPotencialIncluded={setTotalIhrPotencialIncluded}
                        setGesamtpotenzialIncluded={setGesamtpotenzialIncluded}
                        gesamtpotenzial_notrap={gesamtpotenzial_notrap}
                        ihrpotenzial_notrap={ihrpotenzial_notrap}
                        setGesamtpotenzialNotrap={setGesamtpotenzialNotrap}
                        setIhrpotenzialNotrap={setIhrpotenzialNotrap}
                        debug={debug}
                        userStatus={userStatus}
                        viewOnlyMode={viewOnlyMode}
                        user_input={user_input}
                   />

                  {
                    (debug) ? <>
                    
                      <br></br>
                      {/*For Testing <br /> 
                      Gesamtpotenzial in € : {parseFloat(gesamtpotenzial).toFixed(2).toString().replace('.', ',')} = 100% <br /> 
                      Total Ihr Potenzial in € : {parseFloat(total_ihr_potenzial).toFixed(2).toString().replace('.', ',')} = {((parseFloat(total_ihr_potenzial) / parseFloat(gesamtpotenzial)) * 100).toFixed(2).toString().replace('.', ',') } % <br /> 
                      Difference : { parseFloat(gesamtpotenzial - total_ihr_potenzial).toFixed(2).toString().replace('.', ',') } = { (((parseFloat(gesamtpotenzial) - parseFloat(total_ihr_potenzial)) / parseFloat(gesamtpotenzial)) * 100).toFixed(2).toString().replace('.', ',') } % <br /> <br /> 
                      ===================================== <br /> <br />
                      Gesamtpotenzial (included) : {parseFloat(gesamtpotenzial_included).toFixed(2).toString().replace('.', ',')} <br />
                      Total Ihr Potenzial (included) : {parseFloat(total_ihr_potenzial_included).toFixed(2).toString().replace('.', ',')} <br />
                      genutztes Potenzial : {parseFloat(gesamtpotenzial_included - total_ihr_potenzial_included).toFixed(2).toString().replace('.', ',')} <br />
                      ===================================== <br /> <br />
                      Gesamtpotenzial (No Trap) : {parseFloat(gesamtpotenzial_notrap).toFixed(2).toString().replace('.', ',')} <br />
                      Total Ihr Potenzial (No Trap) : {parseFloat(ihrpotenzial_notrap).toFixed(2).toString().replace('.', ',')} <br />
                      genutztes Potenzial : {parseFloat(gesamtpotenzial_notrap - ihrpotenzial_notrap).toFixed(2).toString().replace('.', ',')} <br />*/}
                    </> : <></>
                  }

                   <DialogOutput
                      fullScreen={false} // Set the value according to your requirement
                      open={open}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      output={<ViewFormOutput description={dialogData.information} />}
                   />

          </>
        );
}