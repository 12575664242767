import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, Typography, IconButton } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Chip from '@mui/material/Chip';

const StatusChart = (props) => {
  const { data, headerTitle = "Bar Chart", titleBottom = "Total", titleLeft = "Category", backgroundColor = '#761448', checkDeviationPercentStatus } = props;

  const formatNumberGerman = (num) => {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <Paper style={{ height: '500px', padding: '20px', backgroundColor: 'white' }}>
        <Typography variant="h6" component="div" fontWeight={'bold'} textAlign={'center'} sx={{paddingBottom: '20px'}}>
        {headerTitle}
        </Typography>
      {/*<Bar data={chartData} options={options} /*/}
        <Box sx={{ height: 'calc(100% - 57px)', overflowY: 'auto' }}>
            <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Anzahl</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        <Chip 
                            label={<Box sx={{ zIndex: 999, position: 'relative' }}>
                                      {row.count}
                                  </Box>} 
                            sx={{
                                fontSize: '20px',
                                height: '60px',
                                width: '60px',
                                borderRadius: '60px',
                                backgroundColor: checkDeviationPercentStatus(row.color, 2).backgroundColor,
                                color: checkDeviationPercentStatus(row.color, 2).color,
                                zIndex: 1
                            }} 
                        />
                      </TableCell> {/*EBM-Ziffer*/}
                      <TableCell>{ checkDeviationPercentStatus(row.color, 2).info }</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
            </Table>
        </Box>        
    </Paper>
  );
};

export default StatusChart;
