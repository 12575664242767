import React, { useEffect, useState, createRef, useRef } from 'react';
import MuiDrawer from '../../components/MuiDrawer';
import Flmngr from "@flmngr/flmngr-react";
import Button from '@mui/material/Button';

export default function Filemanager() {

    const handleClick = () => {
        // Create a MutationObserver to watch for changes in the DOM
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                // Check if the target element has been added to the DOM
                const titleElement = document.querySelector('.n1ed-dlg__resize__content__header__title');
                if (titleElement) {
                    // Replace the text content with German translation
                    titleElement.textContent = "Dateimanager";
                    // Disconnect the observer once the element is found and updated
                    observer.disconnect();
                }
            });
        });

        // Start observing the body for changes in the child list (subtree)
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    };

    useEffect(() => {
        handleClick(); // Attach the observer when the component mounts
    }, []);
    
  return (
            <MuiDrawer>
                 <Button 
                        variant="contained"
                        onClick={() => {

                            Flmngr.open({
                                apiKey: "FLMNFLMN",                                  // default free key
                                urlFileManager: '/api/fileManager', // demo server
                                urlFiles: '/api/filemanager',             // demo file storage
                                isMultiple: false,                                   // let selecting a single file
                                //acceptExtensions: ["png", "jpg", "jpeg", "gif", "webp"],
                                acceptExtensions: ["pdf", "csv", "docx"],
                                onFinish: (files) => {
                                    console.log("User picked:");
                                    console.log(files);
                                    
                                    /*if (files.length > 0) {
                                        const filePath = files[0].url.replace('/api/filemanager', '');  // Get the file path
                            
                                        // Trigger the download
                                        fetch('http://localhost:5000/api/download', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({ filePath })
                                        })
                                        .then(response => {
                                            if (!response.ok) {
                                                throw new Error(`HTTP error! status: ${response.status}`);
                                            }
                                            return response.blob();
                                        })
                                        .then(blob => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = filePath.split('/').pop(); // Extract the file name
                                            document.body.appendChild(a);
                                            a.click();
                                            a.remove();
                                        })
                                        .catch(error => {
                                            console.error('Error during file download:', error);
                                        });
                                    }*/


                                }
                            });

                            handleClick();
                            
                        }}
                    >
                        Dateimanager öffnen
                    </Button>

            </MuiDrawer>
          );
}