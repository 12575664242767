import React, { useState, useRef } from 'react';
import { Button, Box } from '@mui/material';
import { padding } from '@mui/system';

const fileUploadBoxStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    '& ul': {
        marginTop: 0,
        marginRight: '17px',
        marginBottom: 0,
        listStyleType: 'none',
        paddingLeft: '14px'
    }
};

const FileUploadForm = ({ onSubmit }) => {
    const [fileNames, setFileNames] = useState([]);
    const fileInput = useRef(null);

    const handleFileClick = () => {
        fileInput.current.click();
    };

    const handleFileChange = () => {
        const files = fileInput.current.files;
        const fileNameArray = Array.from(files).map((file) => file.name);
        setFileNames(fileNameArray);
    };

    return (
        <form onSubmit={onSubmit}>
            {/* Hidden file input */}
            <input
                type="file"
                name="fileInput"
                accept=".con"
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />

            <Box sx={fileUploadBoxStyle}>
                {/* Custom button to choose files */}
                <Button variant="contained" type="button" onClick={handleFileClick}>
                    Dateien auswählen
                </Button>

                <div>
                    {fileNames.length > 0 ? (
                        <ul>
                            {fileNames.map((fileName, index) => (
                                <li key={index}>{fileName}</li>
                            ))}
                        </ul>
                    ) : (
                        <Box sx={{ padding: '7px 19px' }}>keine ausgewählt</Box>
                    )}
                </div>

                {/* Submit button */}
                <Button variant="contained" type="submit">In Datenbank einfügen</Button>
            </Box>
        </form>
    );
};

export default FileUploadForm;