import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';

import TableSeparateStyle from '../styles/TableSeparateStyle';
import germanFormatCurrency from '../../../utils/germanFormatCurrency';

const GOAE_Factor_Table_Result = () => {

    const rows = [
        { GOÄ: 23, Text: 'Schwangerschaftserstuntersuchung', Faktor: 2.3, Betrag: 40.22 },
        { GOÄ: 1070, Text: 'Kolposkopie', Faktor: 2.3, Betrag: 9.78 },
        { GOÄ: 1075, Text: 'Vaginale Behandlung', Faktor: 2.3, Betrag: 6.03 },
        { GOÄ: 298, Text: 'Abstrichentn. z. mikrobiolog. Untersuchung', Faktor: 2.3, Betrag: 16.08 },
        { GOÄ: 3508, Text: 'Mikroskopische Unters. e. Nativpräparates', Faktor: 1.15, Betrag: 5.36 },
        { GOÄ: 3509, Text: 'Mikrosk. Unters. n. einf. Färbung je Material', Faktor: 1.15, Betrag: 6.7 },
        { GOÄ: 3511, Text: 'Unters. e. Körpermat. u. vis. Auswert. je Unters.', Faktor: 1.15, Betrag: 3.35 },
        { GOÄ: 3532, Text: 'Phasenkontrastmikrosk. Unters. d. Urinsediments', Faktor: 1.15, Betrag: 6.04 },
        { GOÄ: 3736, Text: 'Albumin m. vorgef. Reagenzträgern', Faktor: 1.15, Betrag: 8.04 },
        { GOÄ: 3714, Text: 'Ph Bestimmung', Faktor: 1.15, Betrag: 2.68 },
        { GOÄ: 4711, Text: 'Lichtmikrosk. Unters. z. Nachw. v. Pilzen/Präparat.', Faktor: 1.15, Betrag: 8.04 },
        { GOÄ: 403, Text: 'Zuschl. sonograph. Leist. transkavit. Unters.', Faktor: 1.8, Betrag: 15.73 },
        { GOÄ: 415, Text: 'Ultraschallunters. i. Rahmen Mutterschaftsunters.', Faktor: 2.3, Betrag: 40.23 },
        { GOÄ: 4525, Text: 'Ähnliche Untersuchung, Ligandenassay qual. (Chlamydienausschluss)', Faktor: 14.57, Betrag: 16.76 },
      ];

    return (
        <>
        <TableSeparateStyle>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="TableCell">GOÄ</TableCell>
                            <TableCell className="TableCell">Text/Legende</TableCell>
                            <TableCell className="TableCell">Faktor</TableCell>
                            <TableCell className="TableCell">Betrag</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            const tableRows = [];
                            tableRows.push(
                                <TableRow key={index}>
                                    <TableCell width={"30%"} className="TableCell">
                                        {row.GOÄ}
                                    </TableCell>
                                    <TableCell width={"30%"} className="TableCell">
                                        { row.Text }
                                    </TableCell>
                                    <TableCell width={"20%"} className="TableCell">
                                        { row.Faktor }
                                    </TableCell>
                                    <TableCell width={"20%"} className="TableCell">
                                        { germanFormatCurrency(row.Betrag) }
                                    </TableCell>
                                </TableRow>
                            );
                            if (index === rows.length - 1) {
                                const totalBetrag = rows.reduce((acc, currentRow) => acc + currentRow.Betrag, 0);
                                tableRows.push(
                                    <TableRow key={`total-${index}`}>
                                        <TableCell className="TableCell" sx={{borderRight:"0!important"}}><Box sx={{p:'17px'}}></Box></TableCell>
                                        <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                        <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                        <TableCell className="TableCell">{germanFormatCurrency(totalBetrag)}</TableCell>
                                    </TableRow>);
                            }
                            return tableRows;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableSeparateStyle>
        <br /><br />
        </>
    );
};

export default GOAE_Factor_Table_Result;