import React, { useState, useEffect } from 'react';
import * as DatabaseService from './DatabaseService';

export const useEnvData = () => {
 
  const [envVariables, setEnvVariables] = useState({});
  const [isDevMode, setIsDevMode] = useState(false);

  const getEnvVariables = async () => {
    try {
      const JSONData = await DatabaseService.getEnvVariables();
      if (JSONData) {
        setEnvVariables(JSONData); // Set the environment variables
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getEnvVariables();
  }, []);

  useEffect(() => {

    try{
        if(envVariables.DEV_MODE === true){
            setIsDevMode(true);
        }
    }catch(e){}
    
  }, [envVariables]);

  return { envVariables, isDevMode };
};