import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, styled, Box, IconButton, PlayArrowIcon } from '@mui/material';

import MuiDrawer from '../../../components/MuiDrawer';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography } from '@mui/material';

import * as DatabaseService from '../../../services/DatabaseService';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';

import SearchInput from '../../../components/SearchInput';

import LoadingSpecificComponent from '../../../components/LoadingSpecificComponent';

//STYLE
import Container from '../../../components/styles/ContainerStyle';
//END OF STYLE

import MusicPlayer from '../../../components/MusicPlayer';

import VideoPlayer from '../../../components/VideoPlayer';

const Article = () => {
    const { id } = useParams(); // Get the article ID from the URL

	console.log(id)

	const [content, setContent] = useState("");

    // Fetch the article data here using the ID
	const [listPostArticle, setPostArticle] = useState([]);

	//const [rows, setRows] = useState([]);

	const [totalRowsCount, setTotalRowsCount] = useState(0);

	const [page, setPage] = React.useState(0);

	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const [loading, setLoading] = useState(false); // State to manage loading indication

	const [search, setSearch] = React.useState("");

	const navigate = useNavigate();

	const getPostArticleData = async (id) => {

		try {
			const postArticleData = await DatabaseService.getPostGetForUpdate(id);

			if (postArticleData) {
				setPostArticle(postArticleData[0]);
			}

			/*if (postsListData && postsListData.totalCount) {
				setTotalRowsCount(postsListData.totalCount);
			} else {
				setTotalRowsCount(0)
			}*/

		} catch (error) {
			console.error('Error fetching posts list data:', error);
		}

	}

	useEffect(() => {

		setLoading(true); // Set loading to true when fetching data

		getPostArticleData(id)
			.then(() => setLoading(false))
			.catch(error => {
				console.log('Error fetching set posts list data:', error);
				setLoading(false);
			});

	}, [id]);

	//console.log(listPostArticle);

	/*useEffect(() => {
		if (page === 0) {
			setPage(1);
		}

	}, [page]);*/

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	useEffect(() => {

		// Map listAllUsers to create rows and update the state
		//const updatedContent = convertBBCodeToHTML("the field of Obstetrics and Gynecology, acknowledging the cultural, social, and economic factors that influence women's health outcomes worldwide.</span></p><p><br></p><p><strong style="color: rgb(13, 13, 13);">Cultural Perspectives on Women's Health:</strong></p><p><br></p><p>&nbsp;[center][img src=�� width=�� height=�� alt=��][/center]</p><p><br></p><p>Cultural beliefs, traditions, and societal norms significantly influence perceptions of women's health and reproductive care across different communities. In many cultures, childbirth is viewed as a sacred and communal experience, with customs and rituals surrounding pregnancy, labor, and postpartum care deeply rooted in cultural")
		//let updatedContent = listPostArticle.description;

		// Update the rows state
		//setContent(updatedContent);

		//console.log(listPostArticle.description)

		//const updatedContent = "the field of Obstetrics and Gynecology, acknowledging the cultural, social, and economic factors that influence women's health outcomes worldwide.</span></p><p><br></p><p><strong style="color: rgb(13, 13, 13); ">Cultural Perspectives on Women's Health:</strong></p><p><br></p><p>&nbsp;[center][img src=�� width=�� height=�� alt=��][/center]</p><p><br></p><p>Cultural beliefs, traditions, and societal norms significantly influence perceptions of women's health and reproductive care across different communities. In many cultures, childbirth is viewed as a sacred and communal experience, with customs and rituals surrounding pregnancy, labor, and postpartum care deeply rooted in cultural";



		//const escapedText = updatedContent.replace(/</g, "&lt;").replace(/>/g, "&gt;");

		//console.log(escapedText)

		//console.log("typeof updatedContent")

		//console.log(typeof updatedContent)

		//console.log(listPostArticle);

		//console.log(JSON.stringify(listPostArticle.description));


		/*setContent(prevPostData => {

			let updatedContent = listPostArticle.description;

			try {
				console.log(updatedContent.replace(/a/gi, "test"))
			} catch (e) {

			}

			

			return updatedContent;
		});*/


		try {

			let updatedContent = convertBBCodeToHTML(listPostArticle.description);

			//let updatedContent = listPostArticle.description;

			// Update the rows state
			setContent(updatedContent);

		} catch (e) {
			console.log(e)
		}





	}, [listPostArticle]);

	/*console.log("typeof content");
	console.log(typeof content);
	console.log(content);*/

	//console.log(JSON.stringify(listPostArticle.description))



	/*function convertBBCodeToHTML(bbcode) {
		// Replace [center] with <span style="text-align:center;display:block;">
		let html = bbcode.replaceAll(/\[center\]/g, '<span style="text-align:center;display:block;">');
		// Replace [/center] with </span>
		html = html.replaceAll(/\[\/center\]/g, '</span>');
		// Use a regular expression to match the [img] tag and its attributes
		let imgTagRegex = /\[img src=�(.*?)� width=�(.*?)� height=�(.*?)� alt=�(.*?)�\]/g;
		// Replace the [img] tag with an <img> tag, using the matched attributes
		html = html.replaceAll(imgTagRegex, '<img src="$1" width="$2" height="$3" alt="$4">');
		return html;
	}*/

	/*function convertBBCodeToHTML(bbcode) {
		// Replace [center] with <span style="text-align:center;display:block;">
		let html = bbcode.replace(/\[center\]/g, '<span style="text-align:center;display:block;">');
		// Replace [/center] with </span>
		html = html.replace(/\[\/center\]/g, '</span>');
		// Replace [img src="" width="" height="" alt="" style=""] with <img src="https://www.w3schools.com/tags/img_girl.jpg">
		html = html.replace(/\[img src="" width="" height="" alt="" style=""\]/g, '<img src="https://www.w3schools.com/tags/img_girl.jpg">');
		return html;
	}*/

	/*function convertBBCodeToHTML(bbcode) {
		if (!bbcode) {
			return '';
		}
		// Replace [center] with <span style="text-align:center;display:block;">
		let html = bbcode.replace(/\[center\]/g, '<span style="text-align:center;display:block;">');
		// Replace [/center] with </span>
		html = html.replace(/\[\/center\]/g, '</span>');
		// Replace [img src="" width="" height="" alt="" style=""] with <img src="https://www.w3schools.com/tags/img_girl.jpg">
		html = html.replace(/\[img src="" width="" height="" alt="" style=""\]/g, '<img src="https://www.w3schools.com/tags/img_girl.jpg">');
		return html;
	}*/

	/*function convertBBCodeToHTML(bbcode) {
		// Replace [center] with <span style="text-align:center;display:block;">
		let html = bbcode.replaceAll(/\[center\]/g, '<span style="text-align:center;display:block;">');
		// Replace [/center] with </span>
		html = html.replaceAll(/\[\/center\]/g, '</span>');
		// Replace [img src=�� width=�� height=�� alt=��] with <img src="https://www.w3schools.com/tags/img_girl.jpg">
		html = html.replaceAll(/\[img src=�� width=�� height=�� alt=��\]/g, '<img src="https://www.w3schools.com/tags/img_girl.jpg">');
		return html;
	}*/

	/*function convertBBCodeToHTML(bbcode) {
		// Replace [center] with <span style="text-align:center;display:block;">
		let html = bbcode.replace(/\[center\]/g, '<span style="text-align:center;display:block;">');
		// Replace [/center] with </span>
		html = html.replace(/\[\/center\]/g, '</span>');
		// Replace [img src=�� width=�� height=�� alt=��] with <img src="https://www.w3schools.com/tags/img_girl.jpg">
		html = html.replace(/\[img src=�� width=�� height=�� alt=��\]/g, '<img src="https://www.w3schools.com/tags/img_girl.jpg">');
		return html;
	}*/

	function convertBBCodeToHTML(bbcode) {
		//if (!bbcode) {
		//return '';
		//}
		// Replace [center] with <span style="text-align:center;display:block;">
		//let html = bbcode.replace(/\[center\]/g, '<span style="text-align:center;display:block;">');
		// Replace [/center] with </span>
		//html = html.replace(/\[\/center\]/g, '</span>');
		// Replace [img src=�� width=�� height=�� alt=��] with <img src="https://www.w3schools.com/tags/img_girl.jpg">
		//let html = bbcode.replaceAll('[img src=�� width=�� height=�� alt=��]', '<img src="https://www.w3schools.com/tags/img_girl.jpg">');


		//const bbCode = "[center][img src='' width='' height='' alt='' style=''][/center]";
		//let html = bbcode.replaceAll('�', '"').replaceAll('�', '"');

		//let html = bbcode.replace(/\[center\](.*?)\[\/center\]/g, "<div style='text-align:center'>$1</div>").replace(/\[img (.*?)\]/g, "<img $1 />");

		//let html = bbcode.replace(/\[center\](.*?)\[\/center\]/g, "<div style='text-align:center'>$1</div>").replace(/\[img (.*?)\]/g, "<img $1 />").replace(/"��"/g, "");

		//let html = bbcode.replace(/\[center\](.*?)\[\/center\]/g, "<div style='text-align:center'>$1</div>").replace(/\[img (.*?)\]/g, match => match.replace(/""/g, "")).replace(/"��"/g, "");

		let html = bbcode.replace(/\[center\](.*?)\[\/center\]/g, "<span style='text-align:center;display:block;'>$1</span>").replace(/\[img (.*?)\]/g, "<img $1 />").replace(/"��"/g, "").replace(/"([^"]*?)"/g, (match, group) => group === "" ? "" : match);

		//console.log(htmlCode);

		/*const html = bbcode
			.replace(/\[center\]/g, '<div style="text-align: center;">')
			.replace(/\[\/center\]/g, '</div>')
			.replace(/\[img src=�(.*?)� width=�(.*?)� height=�(.*?)� alt=�(.*?)� style=�(.*?)�\]/g, '<img src="$1" width="$2" height="$3" alt="$4" style="$5">');*/


		return html;
	}



	return (
		<>
		{console.log("listPostArticle")}
		{console.log(listPostArticle)}
		<MuiDrawer>
			<Container maxWidth="md" component="main" sx={{ flexGrow: 1, position: 'relative'}}>

				<LoadingSpecificComponent loading={loading} />

				<Typography variant="h4" component="h1" gutterBottom>
					{listPostArticle.title}
				</Typography>

				<Box>
					{(parseInt(listPostArticle.post_type) === 3) ? <><br/><VideoPlayer src={listPostArticle.video_dir} /></> : ""}
				</Box>

				<Box>
					{(parseInt(listPostArticle.post_type) === 2) ? <><br/><MusicPlayer src={listPostArticle.audio_dir} /></> : ""}
				</Box>
				
				{/*<Typography variant="subtitle1" component="h2" gutterBottom>
					{article.author}
				</Typography>
				<Typography variant="subtitle2" component="h2" gutterBottom>
					{article.date}
				</Typography>*/}

				<Typography variant="p" component="div"	my={4} sx={
																			{
																				"& p": {
																					margin: 0
																				}
																			}
																		}
																		dangerouslySetInnerHTML={{ __html: content }}
				></Typography>
			</Container>
        </MuiDrawer>
		</>
    );
};

export default Article;
