import React from 'react';
import Paper from '@mui/material/Paper';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const BarChart = (props) => {
  
  const { data, headerTitle = "Bar Chart", titleBottom = "Total", titleLeft = "Category", backgroundColor = '#761448' } = props;

  const formatNumberGerman = (num) => {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  
  const chartData = {
    labels: data.slice(1).map(row => row[0]),
    datasets: [
      {
        data: data.slice(1).map(row => row[1]),
        backgroundColor: backgroundColor,
      },
    ],
  };

  const fontFamily = 'BR Firma Medium,Roboto,Arial,sans-serif';

  const options = {
    indexAxis: 'y', // This makes the bars horizontal
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: headerTitle,
        font: {
          size: 18,
          family: fontFamily,
        },
        color: '#000000',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.x !== null) {
              label += formatNumberGerman(context.parsed.x);
            }
            return label + ' €';
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: titleBottom,
          font: {
            size: 15,
            family: fontFamily,
            weight: 'bold', // Set the font weight to bold
          },
          color: '#000000', // Set the color to black
        },
        beginAtZero: true,
        ticks: {
          callback: function(value, index, values) {
            return formatNumberGerman(value);
          },
          color: '#000000', // Set the color to black
          font: {
            weight: 'bold', // Set the font weight to bold
          }
        }
      },
      y: {
        title: {
          display: true,
          text: titleLeft,
          font: {
            size: 15,
            family: fontFamily,
            weight: 'bold', // Set the font weight to bold
          },
          color: '#000000', // Set the color to black
        },
        beginAtZero: true,
        ticks: {
          color: '#000000', // Set the color to black
          font: {
            weight: 'bold', // Set the font weight to bold
          }
        }
      },
    },
  };

  return (
    <Paper style={{ height: '500px', padding: '20px', backgroundColor: 'white' }}>
      <Bar data={chartData} options={options} />
    </Paper>
  );
};

export default BarChart;