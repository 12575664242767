import { useState, useEffect } from 'react';
import * as DatabaseService from './DatabaseService'; // Adjust the path based on your project structure


function accessCheck(role_id, upgrade_features) {
  let output = 2;
  if (role_id === 1 || role_id === 4) {
    // Admin can see all the features
    output = 1;
  } else if (role_id === 2) {
    // If not a full member, show only the chart and lock
    output = 2;
  } else if (role_id === 5 && !upgrade_features) {
    // If full membership is true but upgrade features is false, show chart and info on the table
    output = 3;
  } else {
    // If the user has full membership and upgrade features, show all the features
    output = 1;
  }
  return output;
}

const userSessionMembershipCheck = (userSession) => {
  let outputEBMAccess = 2;
  let outputGoaeAccess = 2;
  // Ensure that we have valid data for role_id and upgrade_features
  if (!userSession || typeof userSession.user_role_id === 'undefined') {
    return { outputEBMAccess, outputGoaeAccess }; // Return the default output (2) if data is missing
  }

  const upgrade_features_ebm = !!userSession.upgrade_features;
  const upgrade_features_goae = !!userSession.upgrade_features_goae;
  const role_id = parseInt(userSession.user_role_id, 10);
  
  outputEBMAccess = accessCheck(role_id, upgrade_features_ebm);
  outputGoaeAccess = accessCheck(role_id, upgrade_features_goae);

  return {outputEBMAccess, outputGoaeAccess};
};

// Add KV validation helper
const isValidKV = (kv) => {
  return kv && (typeof kv === 'string' || typeof kv === 'number');
};

export const useSingleUserData = () => {
  const [listSingleUser, setSingleUserData] = useState(null); // Initialize as null to avoid premature checks
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [userStatus, setUserStatus] = useState({outputEBMAccess: 2, outputGoaeAccess: 2});
  const [userRoleID, setUserRoleID] = useState(0);
  const [userKV, setUserKV] = useState('1');
  const [useUpdateInformation, setUpdateInformation] = useState(false);

  const [sessionUserInfo, setSessionUserInfo] = useState({salutation: '', user_title: '', fname: '', lname: '', kv_name: ''});

  const getSingleUserData = async () => {
    try {
      const singleUserData = await DatabaseService.getSingleUserData();
      
      if (singleUserData?.rows?.[0]) {
        const userData = singleUserData.rows[0];
        setSingleUserData(userData);

        if (userData.user_role_id !== undefined && userData.user_role_id !== null) {
          setUserRoleID(userData.user_role_id);
        }

        // Check if userData.kv is null and user is not admin
          if (userData.kv === null && 
            parseInt(userData.user_role_id) !== 1 && 
            parseInt(userData.user_role_id) !== 4) {
          setUpdateInformation(true);
        }

         // Handle user information
        const { salutation = '', user_title = '', fname = '', lname = '', kv_name = '' } = userData;
        setSessionUserInfo({
          salutation: salutation || '',
          user_title: user_title || '',
          fname: fname || '',
          lname: lname || '',
          kv_name: kv_name || ''
        });


        // Handle KV with validation
        if (isValidKV(userData.kv)) {
          setUserKV(userData.kv.toString());
        }

        // Handle total count
        if (typeof singleUserData.totalCount === 'number') {
          setTotalRowsCount(singleUserData.totalCount);
        }
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    getSingleUserData().catch(error => {
      console.error('Error fetching user data:', error);
    });
  }, []);

  useEffect(() => {
    if (listSingleUser) {
      // Ensure listSingleUser has valid data before calling userSessionMembershipCheck
      const userStatusOutput = userSessionMembershipCheck(listSingleUser);
      setUserStatus(userStatusOutput);

    }
  }, [listSingleUser]);

  return { userRoleID, userStatus, totalRowsCount, userKV, useUpdateInformation, sessionUserInfo };
};
