import StatusChartGoae from '../../../components/charts/StatusChartGoae';
import countBallNumbers from '../calculations/countBallNumbers';

export default function StatusChartFilter(props){

    const { rows } = props;

    let backgroundColor = {
        darkgreen: "#006400",
        darkred: "#8B0000"
    }

    let data = [
      { count: countBallNumbers(rows).nonZeroCount, color: "white", backgroundColor: backgroundColor.darkgreen, info: "Abgerechnete GOÄ-Ziffern" },
      { count: countBallNumbers(rows).zeroCount, color: "white", backgroundColor: backgroundColor.darkred, info: "Nicht-abgerechnete GOÄ-Ziffern" },
    ];

    return (<>
    
        <StatusChartGoae 
                      data={data} 
                      headerTitle="Abgerechnete und nicht-abgerechnete GOÄ-Ziffern" 
                      titleLeft="" 
                      titleBottom=""
        />
     
    </>);
}