import React from 'react';
import { Link } from 'react-router-dom';

const FooterSideMenu = () => {
    return (
        <footer style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '15px' }}>
                <Link to="https://www.gynsuccess.de/datenschutz/" style={{ textDecoration: 'none', color: 'black' }} target="_blank">Datenschutz</Link> | <Link to="https://www.gynsuccess.de/impressum/" style={{ textDecoration: 'none', color: 'black' }} target="_blank">Impressum</Link>
            </p>
        </footer>
    );
};

export default FooterSideMenu;