import { Avatar, Box, Button, Card, CardContent, Typography } from '@mui/material';
import sebastian_zuber_profile_pic from '../../../components/assets/images/template/dr_med_sebastian_zuber_profile_pic.webp';

const ProfileCard = (props) => {
  
    const { onClick } = props;
  
    return (
      <Card sx={{backgroundColor: '#f0f0f0'}}>
        <CardContent sx={{textAlign: 'center'}}>
  
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
            <Avatar
              alt="Dr. med. Sebastian Zuber"
              src={sebastian_zuber_profile_pic}
              sx={{ width: 160, height: 160 }}
            />
          </Box>
  
          <Typography variant='h5' component="div" sx={{ textAlign: 'center', marginBottom: '1em', color: '#364713', fontWeight: 600 }}>Dr. med. Sebastian Zuber </Typography>
        
          <Button variant="contained" onClick={()=>{ onClick() }} sx={{textTransform: "none"}}>Jetzt berechnen und individuelle Analyse anfordern</Button>   
        </CardContent>
      </Card>
    );
};

export default ProfileCard;