import Input from "./Input";
import Button from "./Button";
import Alert from "./Alert";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";

/*import ActionButton from "./ActionButton";
//import DatePicker from "./DatePicker";
//import DateRangePicker from "./DateRangePicker";
*/

/*const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    //DatePicker,
    //DateRangePicker,
    Button,
    ActionButton,
    Alert
}*/

const Controls = {
    Input,
    Button,
    Alert,
    RadioGroup,
    Select,
    Checkbox,
    //DatePicker,
    //DateRangePicker,
    // ActionButton,
}

export default Controls;