import React, { useState, useEffect } from 'react';
import * as DatabaseService from '../services/DatabaseService';

export const usePLZ_OrtData = () => {
 
  const [plzList, setPLZ] = useState([]);
  const [ortList, setOrt] = useState([]);

  const getPLZ_OrtData = async () => {
    try {
      const JSONData = await DatabaseService.getPLZ_OrtData();

      if (JSONData && JSONData.rows) {
        const plzArray = [];

        JSONData.rows.forEach((row, index) => {
          plzArray.push({ id: (index + 1).toString(), plz: row.plz_ort_data.id });
        });

        setPLZ(plzArray);
        setOrt(JSONData.rows);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function searchByPLZ(id) {
    return ortList.find(entry => entry.plz_ort_data.id === id);
  }

  useEffect(() => {
    getPLZ_OrtData();
  }, []);

  return { plzList, ortList, searchByPLZ };
};