import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import * as DatabaseService from '../../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';

import MuiDrawer from '../../../components/MuiDrawer';

import { useAuth } from '../../../contexts/AuthContext';

import GynSuccess_Home_Header from '../../../components/assets/images/template/GynSuccess_Home_Header.webp';
import GynSuccess_Team_Bild2 from '../../../components/assets/images/template/GynSuccess_Team_Bild2.webp';
import GynSuccess_Team_Bild4 from '../../../components/assets/images/template/GynSuccess_Team_Bild4.webp';
import GynSuccess_Leistungen_Bild3 from '../../../components/assets/images/template/GynSuccess_Leistungen_Bild3.webp';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '20px',
  padding: '0.3em 1em',
}));

const LightTypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'BR Firma Light',
  lineHeight: '1.8em',
}));

const GynSuccessComponent1 = () => {
  return (
    <Box>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ color: '#014017' }}
          >
            GynSuccess Abrechnungskompass​{' '}
          </Typography>{' '}
          {/*<LightTypographyText variant="p" component="div" sx={{fontWeight: '300'}}>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.​
                                    </LightTypographyText>*/}{' '}
        </Grid>{' '}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{ borderRadius: '8px', overflow: 'hidden', display: 'flex' }}
          >
            <img
              src={GynSuccess_Leistungen_Bild3}
              alt=""
              style={{ width: '100%' }}
            />{' '}
          </Paper>{' '}
        </Grid>{' '}
      </Grid>{' '}
    </Box>
  );
};

const GynSuccessComponent3 = (props) => {
  const { title, description, buttonName, buttonLink, bgColor } = props;

  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: bgColor, // Dark green background
    color: 'white',
    borderRadius: '16px',
    padding: theme.spacing(3),
  }));

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {' '}
          {title}{' '}
        </Typography>{' '}
        <Box sx={{ mb: 2 }}>
          <LightTypographyText variant="p" paragraph sx={{ fontWeight: '300' }}>
            {' '}
            {description}{' '}
          </LightTypographyText>{' '}
        </Box>{' '}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton variant="contained" component={NavLink} to={buttonLink}>
            {' '}
            {buttonName}{' '}
          </StyledButton>{' '}
        </Box>{' '}
      </CardContent>{' '}
    </StyledCard>
  );
};
/*
           <GynSuccessComponent3
             title="EBM-Abrechnungskompass​"
             description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ​"
             buttonName="Zustieg zum  EBM-Abrechnungstool"
             buttonLink=""
             bgColor="#006400"
           />
       */

const GynSuccessComponent5 = () => {
  const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgba(0,0,0,0.36)',
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    color: 'white',
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} fullWidth>
      <StyledCard>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Sie haben Fragen zur GynSuccess Plattform ? Dann wenden Sie sich
            gerne direkt an uns!​
          </Typography>{' '}
        </CardContent>{' '}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton
            variant="contained"
            // component={NavLink}
            // to="tel:+4915679495400"
            component="a"
            href="https://www.gynsuccess.de/kontakt/#kontaktformular"
            target="_blank"
          >
            Kontakt{' '}
          </StyledButton>{' '}
        </Box>{' '}
      </StyledCard>{' '}
    </Box>
  );
};

export default function Platform() {
  const { login, currentUser, currentRole, getSession } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MuiDrawer>
      <Box sx={{ marginLeft: '-24px', marginRight: '-24px' }}>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <GynSuccessComponent1 />

          <Box sx={{ height: '24px' }}> </Box>

          <GynSuccessComponent3
            title="EBM-Abrechnungskompass​"
            description="Aufgrund der Programmierung ist es möglich nicht nur 'expost' Abrechnungen zu analysieren sondern erstmalig 'ex ante' eine zukunfts- und gewinnoptimierte Steuerung einer gynäkologischen Praxis zu ermöglichen und damit einhergehend eine optimierte Praxisführung und -entwicklung zu realisieren.​"
            buttonName="Zustieg zum  EBM-Abrechnungstool"
            buttonLink="/ebm-abrechnungskompass"
            bgColor="#006400"
          />

          <Box sx={{ height: '24px' }}> </Box>

          <GynSuccessComponent3
            title="GOÄ-Abrechnungskompass​"
            description="Analyse Ihrer GOÄ-Abrechnung unter Berücksichtigung eines Fachgruppenvergleichs und vergessener Ziffern zur Optimierung der Rechnungsstellung."
            buttonName="Kommt in Kürze!​"
            buttonLink=""
            bgColor="#76B900"
          />

          <Box sx={{ height: '24px' }}> </Box>

          <GynSuccessComponent3
            title="IGeL-Abrechnungskompass​"
            description="Behandlungsvertrag und optimierte Rechnungsstellung sowie zukünftig ein bundesweites und regionales Benchmark, das sowohl absolute - wie häufig igelt die Praxis pro Patientin - als auch relative Vergleiche ermöglicht - was kostet die IGeL Leistung​.​​"
            buttonName="Kommt in Kürze!"
            buttonLink=""
            bgColor="#761448"
          />

          <Box sx={{ height: '24px' }}> </Box>
        </Box>{' '}
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            paddingTop: '25%',
            paddingBottom: '20%',
            backgroundImage: `url(${GynSuccess_Team_Bild4})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box
            sx={{
              width: '80%',
              margin: 'auto',
              zIndex: '1',
              position: 'relative',
            }}
          >
            <GynSuccessComponent5 />
          </Box>{' '}
        </Box>{' '}
      </Box>{' '}
    </MuiDrawer>
  );
}
