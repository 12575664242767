import { Dialog, DialogTitle, DialogContent, IconButton, styled, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { maxWidth } from "@mui/system";
import { Box } from "@mui/system";
const LoadingContainer = styled('div')(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }),
);
  
const DialogOutput = ({ fullScreen, open, dialogData, handleClose, handleRedirectToDataList, backgroundColor='white', output, maxWidth='sm' }) => {
  
    const DialogAllContent = ({ dialogData }) => {
  
        if (dialogData.status === "loading") {
  
            return (<>
                <DialogContent>
                    <LoadingContainer> <CircularProgress sx={{ mr: 2 }} /> {dialogData.information} </LoadingContainer>
                </DialogContent>
            </>);
  
        } else if (dialogData.status === "view"){
  
            return (<>
                
                <DialogTitle id="responsive-dialog-title" sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    
                    <Box>{dialogData.title}</Box>
                    
                    <IconButton onClick={handleClose} autoFocus>
                        <CloseIcon />
                    </IconButton>
                    
                </DialogTitle>
                <DialogContent dividers sx={{backgroundColor: backgroundColor, visibility: (open) ? 'visible' : 'hidden'}}>
                    { output }
                </DialogContent>
            </>);
        }
    }
  
    return (
        <>
            <Dialog
                fullScreen={(dialogData.status === "loading") ? false : fullScreen}
                open={open}
                aria-labelledby="responsive-dialog-title"
                maxWidth={maxWidth}
            >
  
                <DialogAllContent dialogData={dialogData} />
                
            </Dialog>
        </>
    );
};

export default DialogOutput;