import {FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText} from '@mui/material';
import React from 'react';

export default function Select(props){

    const {name, label, value, error=null, onChange, showNoneOption = true, options, disabled=false, sx} = props;

    return(
        <FormControl variant="outlined" {...(error && {error:true})} disabled={disabled}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                sx={sx}
            >
                { showNoneOption && (<MenuItem value="">None</MenuItem>) }
                {
                    options.map(
                        (item) => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}