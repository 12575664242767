import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function LoadingSkeletonLayout(){
    return (
        <>
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={500} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={500} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={500} />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={4}>
                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={500} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={500} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={500} />
                </Grid>
            </Grid>
            <Table>
                <TableBody>
                    {Array.from(new Array(5)).map((_, index) => (
                        <TableRow key={index}>
                            {Array.from(new Array(10)).map((_, cellIndex) => (
                                <TableCell key={cellIndex}>
                                    <Skeleton animation="wave" variant="text" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
        </>
    );
};

export function renderLoadingTable(loading, debug, tableType){
    if (loading) {
        return (
            <>
            <TableContainer component={Paper} sx={{borderRadius:0, maxHeight: 500}}>
                <Table size="small" aria-label="simple table" sx={{tableLayout: 'auto'}}>
                    <TableHead>
                        <TableRow sx={{backgroundColor: '#006400', '& .MuiTableCell-root': { color: 'white', textAlign: 'right', whiteSpace: 'nowrap'}, position: 'sticky', top: 0, zIndex: 1}}>
                            <TableCell sx={{textAlign: 'left!important'}}>EBM-Ziffer</TableCell>   
                            <TableCell sx={{textAlign: 'left!important'}}>Leistung</TableCell>
                            <TableCell>Anzahl</TableCell>
                            <TableCell>Soll</TableCell>
                            <TableCell>Abweichung</TableCell>
                            <TableCell>Abweichung %</TableCell>
                            { (debug) ? <TableCell>EBM-Koeffizient</TableCell> : <></> }
                            { (tableType === "1" || debug) ? <><TableCell>Gesamtpotenzial in €</TableCell><TableCell>Ihr Potenzial in €</TableCell></> : "" }
                            { (tableType === "2") ? <TableCell>{/* Caution */}</TableCell> : "" }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(5)].map((_, index) => (
                            <TableRow key={index}>
                                <TableCell><Skeleton /></TableCell>
                                <TableCell><Skeleton /></TableCell>
                                <TableCell><Skeleton /></TableCell>
                                <TableCell><Skeleton /></TableCell>
                                <TableCell><Skeleton /></TableCell>
                                <TableCell><Skeleton /></TableCell>
                                { (debug) ? <TableCell><Skeleton /></TableCell> : <></> }
                                { (tableType === "1" || debug) ? <><TableCell><Skeleton /></TableCell><TableCell><Skeleton /></TableCell></> : "" }
                                { (tableType === "2") ? <TableCell><Skeleton /></TableCell> : "" }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
        );
    }
    return null;
};

export function EBM_Loading_Input(props) {

    const { loadingData, rowType=1, con_data } = props;

    return (
        <React.Fragment>
            
            { 
                (rowType === 1 && !loadingData.hideFirstRow) ? <>
                    <Box display="flex" flexDirection="row" gap={2}>
                            <Skeleton animation="wave" variant="rectangular" width={215} height={56} />                            
                            <Skeleton animation="wave" variant="rectangular" width={209.38} height={56} />
                            {
                                con_data <= 0 && 
                                <>
                                    <Skeleton animation="wave" variant="rectangular" width={237.86} height={56} />
                                </>
                            }
                    </Box>
                <br/>
                </> : <></>
            }

            { 
               (rowType === 2 && !loadingData.hideSecondRow) ? <>
                    <Box>
                        <Grid container spacing={2}>
                        {
                        Array.from(new Array(3)).map((_, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                                    </Grid>
                                </React.Fragment>
                            ))
                        }
                        </Grid>
                    </Box>
                    </> : <></>
            }
        </React.Fragment>
    );
}