import MuiDrawer from '../../components/MuiDrawer';
import EBM_Abrechnungskompass from './EBM_Abrechnungskompass';

export default function EBM_Abrechnungskompass_Main() {

  return (
            <MuiDrawer>
                <EBM_Abrechnungskompass />
            </MuiDrawer>
          );
}