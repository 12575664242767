import React, { useEffect, useState, useContext, createContext } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, styled, Box, IconButton, PlayArrowIcon, Chip } from '@mui/material';
import { useNavigate, NavLink } from 'react-router-dom';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography } from '@mui/material';

import * as DatabaseService from '../../../services/DatabaseService';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';

import MuiDrawer from '../../../components/MuiDrawer';

import SearchInput from '../../../components/SearchInput';

import LoadingSpecificComponent from '../../../components/LoadingSpecificComponent';

//STYLE
import Container from '../../../components/styles/ContainerStyle';
//END OF STYLE



import MusicPlayer from '../../../components/MusicPlayer';

import PlayArrowThumbnailIcon from '@mui/icons-material/PlayArrow';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { useAuth } from '../../../contexts/AuthContext';

//const AudioContext = createContext();

export default function PostsList({post_type}) {

	const [currentAudio, setCurrentAudio] = useState(null);

	console.log("post_type")
	console.log(post_type)

	const { currentUser, currentRole } = useAuth();

	const [listPosts, setPostsList] = useState([]);

	const [listFilteredPosts, setFilteredPostsList] = useState([]);

	//const [rows, setRows] = useState([]);

	const [totalRowsCount, setTotalRowsCount] = useState(0);

	const [page, setPage] = React.useState(0);

	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const [loading, setLoading] = useState(false); // State to manage loading indication

	const [search, setSearch] = React.useState("");

	const navigate = useNavigate();

	const getPostsData = async (page, rowsPerPage, search, post_type, status) => {

		console.log("getPostsData post_type");
		console.log(post_type)

		try {

			const postsListData = await DatabaseService.getPostList(page, rowsPerPage, search, post_type, status);

			if (postsListData && postsListData.rows) {
				setPostsList(postsListData.rows);
			}


			if (postsListData && postsListData.totalCount) {
				setTotalRowsCount(postsListData.totalCount);
			} else {
				setTotalRowsCount(0)
			}

		} catch (error) {
			console.error('Error fetching posts list data:', error);
		}

	}

	useEffect(() => {

		setLoading(true); // Set loading to true when fetching data

		/* (currentRole === "Subscriber") ? 2 : 0 

		if (currentRole === "Subscriber") {
			if (post.status !== "1") { //1 is draft
				return post;
			} else {
				// Return null or some default value when post.status === "1"
				return null; // Replace null with your default value
			}
		} else {
			// Return the post as is if no conditions are met
			return post;
		}*/

		console.log("post_type");
		console.log(post_type);

		getPostsData(page, rowsPerPage, search, ((parseInt(post_type) === 2 || parseInt(post_type) === 3) ? post_type : 1), ((currentRole === "Subscriber") ? 2 : 0))
			.then(() => setLoading(false))
			.catch(error => {
				console.log('Error fetching set posts list data:', error);
				setLoading(false);
			});

	}, [page, rowsPerPage, search, currentRole, post_type]);

	useEffect(() => {
		if (page === 0) {
			setPage(1);
		}

	}, [page]);

	console.log("listPosts");
	console.log(listPosts);


	/*useEffect(() => {
		setFilteredPostsList(
			listPosts.map((post) => {
				if (currentRole === "Subscriber") {
					if (post.status !== "1") { //1 is draft
						return post;
					} else {
						// Return null or some default value when post.status === "1"
						return null; // Replace null with your default value
					}
				} else {
					// Return the post as is if no conditions are met
					return post;
				}
			}).filter(Boolean) // This will remove all falsy values, including undefined
		)
		console.log(currentRole)
	}, [listPosts, currentRole]);*/

	console.log("listFilteredPosts");
	console.log(listFilteredPosts);

	const handlePaginationChange = (event, value) => {

		console.log(value)
		setPage(value);

	};

	const handleGoToPage = (postId) => {

		//navigate('/articles/1');

		console.log(postId)

		navigate('/article/' + postId);

	}

	const handleDelete = (postId) => {
		//navigate('/post-new?edit=315');
		console.log("To be added");



		//setOpen(true);
		//setDialogInformation("Deleting...");
		setLoading(true);

		DatabaseService.delPosts(postId)
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Failed to delete lanr');
				}
			})
			.then(result => {
				console.log(result);

				

				//setOpen(false);
				// Update table data after successful deletion

				//getPostsData(page, rowsPerPage, search);
				//setSelected([]); // Clear selected array
				//setLoading(false);


				getPostsData(page, rowsPerPage, search, ((parseInt(post_type) === 2 || parseInt(post_type) === 3) ? post_type : 1), ((currentRole === "Subscriber") ? 2 : 0))
					.then(() => setLoading(false))
					.catch(error => {
						console.log('Error fetching set posts list data:', error);
						setLoading(false);
					});

			})
			.catch(error => {
				console.error('Error deleting lanr:', error);
				setLoading(false);
				//setOpen(false);
				// Handle error here, show message or take appropriate action
			});

	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<MuiDrawer>
			<Container>

				<Typography gutterBottom variant="h5" component="div" sx={{ marginTop: 4 }}>
					{
						(parseInt(post_type) === 2) ? "Podcast" :
						(parseInt(post_type) === 3) ? "Videos" :
						"Articles"
					}
				</Typography>
				<Typography>
					{
						(parseInt(post_type) === 2) ? "Prepare yourself to unearth profound revelations, broaden your understanding, and immerse yourself in enthralling material. Are you eager to unlock the secrets of the universe, enhance your intellect, and explore captivating narratives? Tune in now to our latest podcast episode, where we promise to intrigue, inform, and inspire you! Join us as we explore fascinating topics and engage in thought-provoking discussions. Click play and let the journey begin!" :
						(parseInt(post_type) === 3) ? "Get ready to uncover insights, expand your knowledge, and dive into captivating videos! Tune in now to our latest video series, where we promise to intrigue, inform, and inspire you! Join us as we explore fascinating topics and engage in thought-provoking discussions. Click play and let the journey begin!" :
						"Are you ready to uncover insights, expand your knowledge, and delve into captivating content? Click now to explore our latest article that promises to intrigue, inform, and inspire!"

					}
				</Typography>

				
				<div style={{position: 'relative' }}>

					<LoadingSpecificComponent loading={loading} />
				
					<Grid container spacing={2} sx={{ marginTop: 4 }}>
						<Grid item xs={12} sm={12} md={12} key={1}>
							<SearchInput setSearch={setSearch} setPage={setPage} search={search}  key={1}/>
						</Grid>
					</Grid>

					
					<Grid container spacing={2} sx={{ marginTop: 4, opacity: (loading) ? '0' : '1', height: (loading) ? '100px' : 'auto' }}>
						{/*<AudioContext.Provider value={{ currentAudio, setCurrentAudio }}>*/}
						{/*{listFilteredPosts.map((post) => {*/}
						{listPosts.map((post) => {

							//const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
							const imgPath = post.img.split('/').slice(0, -1).join('/');
							const imgName = post.img.split('/').pop();
							const imgBase = imgName.split('.').slice(0, -1).join('.');
							const imgExt = imgName.split('.').pop();
							//const imgSrc = isMobile ? `${imgPath}/${imgBase}-mobile.${imgExt}` : `${post.img}`;

							/*(post.img === "") 
								? (parseInt(post_type) === 3) ? "podcast.png" : "video.png" : 
								post.img


								if(post.img === ""){
									if(parseInt(post_type) === 3){
										imgSrc = "podcast.png";
									}
								}else{
									imgSrc = `${imgPath}/${imgBase}-mobile.${imgExt}`;
								}

								(post.img !== '') 
									?  `${imgPath}/${imgBase}-mobile.${imgExt}` : 
										(parseInt(post_type) === 3) 
											? "video.png" : 
												"video.png"

								(parseInt(post_type) === 3) ? "/uploads/media/vthumbnail.png" : ${imgPath}/${imgBase}-mobile.${imgExt};

								(post.img === '') 
								  ? (parseInt(post_type) === 3) ? "/uploads/media/vthumbnail.png" : ${imgPath}/${imgBase}-mobile.${imgExt} 
								  : `${imgPath}/${imgBase}-mobile.${imgExt}`

								 (parseInt(post_type) === 3) 
								 	? (post.img === '') ? "/uploads/media/vthumbnail.png" : `${imgPath}/${imgBase}-mobile.${imgExt}` : 
								 ${imgPath}/${imgBase}-mobile.${imgExt}; 

							*/

							//const imgSrc = `${imgPath}/${imgBase}-mobile.${imgExt}`;

							//const imgSrc = (parseInt(post_type) === 3) ? "/uploads/media/vthumbnail.png" : `${imgPath}/${imgBase}-mobile.${imgExt}`;

							/*const imgSrc = (post.img === '') 
							? (parseInt(post_type) === 3) ? "/uploads/media/vthumbnail.png" : `${imgPath}/${imgBase}-mobile.${imgExt}`
							: `${imgPath}/${imgBase}-mobile.${imgExt}`;*/

							const imgSrc = (parseInt(post_type) === 3) 
												? (post.img === "") ? "/uploads/media/vthumbnail.png" : `${imgPath}/${imgBase}-mobile.${imgExt}` 
												: `${imgPath}/${imgBase}-mobile.${imgExt}`; 

							return (
								<>
										<Grid item xs={12} sm={12} md={6} key={post.id}>
											<Card sx={{
												display: 'flex',
												flexDirection: { xs: 'column-reverse', sm: 'row' },
												cursor: "pointer",
											}}>
												<Box sx={{ display: 'flex', flex: '1', flexDirection: 'column' }}>

													<CardContent sx={{ flex: '1 0 auto', textDecoration: 'none', color: '#000000' }} component={NavLink} to={`/article/${post.id}`}>
														{
															(currentRole !== "Subscriber") ? <>
																<Stack direction="row" spacing={1}>
																	<Chip label={ (post.status === "1") ? "Draft" : "Published" } variant="outlined" size="small" />
																</Stack>
															</> : ""
														}
														<Typography component="div" variant="h6" sx={{
															display: "-webkit-box",
															WebkitLineClamp: "2",
															WebkitBoxOrient: "vertical",
															overflow: "hidden",
															'&:hover': {
																textDecoration: 'underline'
															}
														}} >
															{post.title}
														</Typography>
													</CardContent>
													<Box sx={{marginLeft: '14px'}}>
														{
															(parseInt(post_type) === 2) 
																? (post.audio_no !== null) 
																	? <><Box sx={{paddingTop:'9px', paddingRight:{xs: '14px', md: 0}}} ><MusicPlayer src={post.audio_dir} setCurrentAudio={setCurrentAudio} currentAudio={currentAudio} /></Box></> 
																: "" 
															: ""
														}
													</Box>
													<CardActions>
														<Button size="small" component={NavLink} to={`/article/${post.id}`} sx={{ textDecoration: 'none' }}>Learn More</Button>

														{
															(currentRole !== "Subscriber") ? <>

															<IconButton size="small" component={NavLink} to={`/post-new?edit=${post.id}` + (parseInt(post_type) === 2 ? "&post_type=2" : "")}> <EditIcon /> </IconButton>
															<IconButton size="small" onClick={() => handleDelete(post.id)} ><DeleteIcon /></IconButton>

															</> : ""
														}


													</CardActions>
												</Box>
												<Box component={NavLink} to={`/article/${post.id}`} sx={{ textDecoration: 'none' }}>
													
													<Box sx={{position: 'relative', opacity: (loading) ? '0' : '1'}}>
														{
															(parseInt(post_type) === 3) 
																? 
																	<>
																		<Box sx={{
																					position: 'absolute',
																					zIndex: '1',
																					width: 'calc(100% - 14px)',
																					height: 'calc(100% - 14px)',
																					display: 'flex',
																					alignItems: 'center',
																					justifyContent: 'center',
																					margin: '7px',
																					borderRadius: '11px',
																					backgroundColor: (post.img !== "") ? 'rgba(0, 0, 0, 0.16)' : 'rgba(0, 0, 0, 0)',
																					'& svg':{
																						width: '50px',
																						height: '50px',
																						fill: '#ffffff'
																					}
																				}}
																		>
																			<PlayCircleIcon />
																		</Box>
																	</> 
																: 	""
														}
														<CardMedia
															component="img"
															sx={{
																width: {
																	xs: '100%',
																	sm: (post.img !== "") ? 140 : (parseInt(post_type) === 3) ? 140 : 0
																},
																height: { xs: '100%', sm: 143 },
																padding: '7px',
																borderRadius: '19px',
																visibility: (post.img !== "") ? "visible" : (parseInt(post_type) === 3) ? "visible" : "hidden",
																display: {
																	xs: (post.img !== "") ? "block" : (parseInt(post_type) === 3) ? "block" : "none",
																	sm: (post.img !== "") ? "block" : (parseInt(post_type) === 3) ? "block" : "block"
																}
															}}
															image={imgSrc}
															alt="Live from space album cover"
														/>
													</Box>
												</Box>
											</Card>
										</Grid>

								</>
							);

						})}
						{/*</AudioContext.Provider>*/}
					</Grid>
					

					<Grid container justifyContent="center" alignItems="center" sx={{ marginTop: 4 }}>
						<Stack spacing={2}>

							{/*<Pagination count={10} />*/}

							<Pagination count={(Math.ceil(parseInt(totalRowsCount) / rowsPerPage))} page={page} onChange={handlePaginationChange} siblingCount={0} boundaryCount={1} showFirstButton showLastButton />
						</Stack>
					</Grid>

				</div>
				
			</Container>

		</MuiDrawer>
	);
}