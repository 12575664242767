export default function germanFormatCurrency(number) {
    // Ensure the number is a valid number
    if (isNaN(number)) {
      return 'Invalid number';
    }
  
    // Format the number with decimal separator and thousands separator
    const formattedNumber = number.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  
    // Add the currency symbol and handle negative numbers
    const formattedCurrency = `${formattedNumber} €`;
    return formattedCurrency;
}