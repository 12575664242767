import { Box } from '@mui/material';
import { dangerouslySetInnerHTML } from 'react-dom';
import { CardComponent } from "./CardComponent";

const ViewUpdateInformationNotice = () => {

    const cardConfig = {
      currentUser: {
        title: "Handlungsbedarf: Aktualisieren Sie Ihre Postleitzahl und Stadtinformationen",
        description: "Wir haben festgestellt, dass Ihre Postleitzahl und Stadtinformationen möglicherweise aktualisiert werden müssen. Um eine reibungslose Kommunikation und Dienstleistungserbringung zu gewährleisten, bitten wir Sie, Ihre Daten zeitnah zu aktualisieren. Bitte nutzen Sie die Funktion 'Adresse aktualisieren', um diese Änderungen vorzunehmen.",
        buttonName: "Adresse aktualisieren",
        buttonLink: "/profil",
      }
    };

    return (<>
  
            <Box sx={{
                        maxWidth: 1080, 
                        paddingBottom: 5, 
                        '& table': {
                            width: '100%',
                            maxWidth: 650,
                            borderCollapse: 'collapse',  
                        },
                        '& td, & th': {
                          border: '1px solid #dddddd',
                          padding: '8px'
                        },
                        '& div.table':{
                          overflowX: 'auto'
                        },
                        '& .space':{
                          width: '14px',
                          display: 'inline-block'
                        },
                        '& .displaynone':{
                          display: 'none'
                        } 
                    }}>
   
                    <CardComponent 
                        title={cardConfig.currentUser.title}
                        description={cardConfig.currentUser.description}
                        buttonName={cardConfig.currentUser.buttonName}
                        buttonLink={cardConfig.currentUser.buttonLink}
                        onClick={() => {}} 
                        bgColor="#12401F" 
                    />

            </Box>
    </>);
  
}

export default ViewUpdateInformationNotice;