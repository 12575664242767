import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup as MuiRadioGroup} from '@mui/material';
import React from 'react';

/**
 * Use RadioGroup as MuiRadioGroup when the function below is names as RadioGroup to prevent errors from coming.
 */

export default function RadioGroup(props){
    const {name, label, values, onChange, items} = props;

    return (
        <FormControl>
            <FormLabel>
                {label}
            </FormLabel>
            <MuiRadioGroup row 
                name={name}
                value={values}
                onChange={onChange}
            >
                {
                    items.map(
                        item => (
                            <FormControlLabel sx={{ textAlign: 'left' }} key={item.id} value={item.id} control={<Radio />} label={item.title} />
                        )
                    )
                }
            </MuiRadioGroup>
        </FormControl>
    );
}