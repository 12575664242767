import Alert from '@mui/material/Alert';

export default function MuiAlert(props){

    const { text, severity, variant, appear=true, ...other } = props;

    return (
        <>
        {
            (appear) ? 
            <Alert 
                severity={severity} 
                variant={variant || "filled"}
                {...other}
            >
                {text}
            </Alert>
            : 
            ""
        }
        </>
    );
}