import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingTable = styled('div')(({ theme }) =>
    theme.unstable_sx({
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.50)',
        zIndex: 10,
        paddingTop: '2.7em',
        top: 0,
        left: 0,
    }),
);

const LoadingSpecificComponent = ({ loading }) => {

    

    return (<>
        {
            loading ? (

                <LoadingTable>
                    <CircularProgress />
                </LoadingTable>
            ) : ""
        }
    </>);
}

export default LoadingSpecificComponent;