import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PrivateElement({ element: Component }) {
    const { currentUser, currentRole, currentRoleId } = useAuth();

    const roleId = parseInt(currentRoleId); // Parses currentRoleId as an integer

    //return currentUser ? Component : <Navigate to="/login" replace />;

    //console.log(Component);

    //console.log(Component.type.name);

    //return currentUser ? (currentRole === "Subscriber" ? null : Component) : <><Navigate to="/login" replace /></>;

    /*return currentUser ? (
        currentRole === "Subscriber" ? (
            Component.type.name === "PostEditor" ? null : Component
        ) : Component
    ) : <><Navigate to="/login" replace /></>;*/

    /*return currentUser ? (
        currentRole === "Subscriber" ? (
            ["PostEditor", "UsersPage", "ExtractorPage"].includes(Component.type.name) ? null : Component
        ) : Component
    ) : <><Navigate to="/login" replace /></>;*/

    /*return currentUser ? (
        currentRole === "Subscriber" ? (
            Component.type.name === "PostEditor" || Component.type.name === "UsersPage" || Component.type.name === "ExtractorPage" || Component.type.name === "PDFtoCSVPage" ? null : Component
        ) : currentRole === "Author" ? (
            Component.type.name === "UsersPage" || Component.type.name === "ExtractorPage" || Component.type.name === "PDFtoCSVPage" ? null : Component
        ) : Component
    ) : (
        <><Navigate to="/login" replace /></>
    );*/

    if (currentUser) {
        if (roleId === 2 || roleId === 5) { //Testmitglied (testusers) and Vollmitglied (full members) are not allowed to access this page
            if (
                Component.type.name === "PostEditor" || 
                Component.type.name === "UsersPage" || 
                Component.type.name === "ExtractorPage" || 
                Component.type.name === "PDFtoCSVPage" 
            ) {
                return null;
            } else {
                return Component;
            }
        } else {
            return Component;
        }
    } else {
        window.location.href = '/login';
        return null;
    }

}

export default PrivateElement;