import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ReactSpeedometer from 'react-d3-speedometer';
import Typography from '@mui/material/Typography';

const Speedometer = (props) => {

  const { value = 50, headerTitle = "Speedometer" } = props;

  const formattedValue = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2, // specify how many digits after the comma
    maximumFractionDigits: 2,
  }).format(value);

  return (
    <Paper>
      <Box sx={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, flexDirection: 'column' }}>
        <Typography variant="h6" component="div" fontWeight={'bold'} textAlign={'center'} sx={{paddingTop: '2em', paddingBottom: '20px'}}>
          {headerTitle}
        </Typography>
        <Box sx={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
          <ReactSpeedometer
            maxValue={100}
            value={value} // Example value
            segments={4}
            segmentColors={['#FF0000', '#FFA500', '#76B900', '#006400']}
            customSegmentStops={[0, 25, 50, 75, 100]}
            needleColor="steelblue"
            fluidWidth={true} // Enable fluid width
            currentValueText={`${formattedValue}`}
            textColor="black" // Set font color to black
          />
        </Box>
    </Box>
  </Paper>
  );
};

export default Speedometer;