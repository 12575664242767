import { Box, Grid, ListItem, ListItemText, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import LoginSignupContainer from '../components/Template/LoginSignup';
import Controls from "../components/controls/Controls";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default () => {
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [alert, setAlert] = useState({ appear: false, severity: 'error', text: '' })

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (password1 !== password2) {
      return setAlert({ appear: true, severity: 'error', text: 'Passwörter stimmen nicht überein' })
    }

    if (password1.length < 8) {
      return setAlert({ appear: true, severity: 'error', text: "Passwort muss 8 Zeichen lang sein" })
    }

    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')

    if (!token) {
      return setAlert({ appear: true, severity: 'error', text: 'Token ist ungültig oder abgelaufen' })
    }

    const res = await fetch('/api/user/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password: password1, token })
    });

    if (res.status !== 200) {
      const data = await res.json()
      return setAlert({ appear: true, severity: 'error', text: data.message })
    }

    setIsSubmitted(true)
  }

  if (isSubmitted) {
    return (
      <LoginSignupContainer hideTabs={true} header={(
        <ListItem> 
            <ListItemText primary="Passwort zurücksetzen" />
        </ListItem>
      )}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">Passwort zurückgesetzt</Typography>
          <Typography>
            <Link to="/login">Gehen Sie zum Anmelden</Link>
          </Typography>
        </Box>
      </LoginSignupContainer>
    )
  }

  return (
    <LoginSignupContainer hideTabs={true} header={(
      <ListItem> 
          <ListItemText primary="Passwort zurücksetzen" />
      </ListItem>
    )}>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Controls.Input 
              type="password"
              label="Passwort"
              name="password1"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              autoComplete="off"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Input 
              type="password"
              label="Passwort bestätigen"
              name="password2"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              autoComplete="off"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Alert sx={{ margin: '12px 0' }} severity={alert.severity} appear={alert.appear} text={alert.text} />
          </Grid>
          <Grid item xs={12}>
            <Controls.Button
              type="submit"
              text="Passwort zurücksetzen"
              size="large"
              variant="contained"
              color="primaryGreen"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </LoginSignupContainer>
  )
}