import React, { useEffect, useState, createRef, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';
import TablePaginationActions from '../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../components/SearchInput';

import MuiDrawer from '../../components/MuiDrawer';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
/* End of Dialog Box */

/*Tabs*/
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
/*End of Tabs*/

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import Button from '@mui/material/Button';
// Table Libraries

import { CSVLink, CSVDownload } from "react-csv";
import FileSaver from 'file-saver';

/*const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  canvasSize:{
    [theme.breakpoints.up(981)]: {
      height: '30vw',
      width: '100%',
    },
    [theme.breakpoints.down(981)]: {
      height: '41vw',
    },
    [theme.breakpoints.down(960)]: {
      height: '46vw',
    },
    [theme.breakpoints.down(768)]:{
      height: '59vw',
    },
    [theme.breakpoints.down(526)]:{
      height: '70vw',
    },
    [theme.breakpoints.down(413)]:{
      height: '78vw',
    },
  },
  gridCategoryCounterChart:{
    marginTop: '11px'
  },
  gridApplicationCounter:{
    [theme.breakpoints.down(600)]:{
      '& .MuiGrid-item':{
        paddingBottom: 0
      }
    }
  }
}));*/

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PDFtoCSVPage() {

    function createData(id, filename, fid, status, csv_directory, download_csv) {
        return {
            id,
            filename,
            fid,
            status,
            csv_directory, 
            download_csv
        };
    }

    //let rows = [];

    const [listPDFFiles, setPDFFiles] = useState([]);

    const [csv_data, setCSVData] = useState("");

    const [rows, setRows] = useState([]);

    const [totalRowsCount, setTotalRowsCount] = useState(0);

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [loading, setLoading] = useState(false); // State to manage loading indication

    const [search, setSearch] = React.useState("");

    const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

    const [archive, setArchive] = React.useState("");

    const [page_type, setPageType] = React.useState("pdftocsv");

    const [tabValue, setTabValue] = React.useState(0);

    //const [rows, setRows] = useState([]);
    //const [arr, setArr] = useState([])
    
    const getCSV = async (id, filename) => {
      try{

        const CSVData = await DatabaseService.getCSV(id);

        if(CSVData.ok) {
          const blob = await CSVData.blob();
          FileSaver.saveAs(blob, filename + '.csv');
        } else {
            console.error('Server responded with status: ' + CSVData.status);
        }

        console.log("CSVData");
        console.log(CSVData);
        
        /*if(CSVData){ setCSVData(CSVData[0].csv_data); }*/

      }catch(e){}
    }

    const getPDFFilesData = async (page, rowsPerPage, search, page_type, archive) => {

        try {
            const PDFFileData = await DatabaseService.getPDFFilesData((page + 1), rowsPerPage, search, page_type, archive);
            if (PDFFileData && PDFFileData.rows) {
                setPDFFiles(PDFFileData.rows);
            }

            if (PDFFileData && PDFFileData.totalRowsCount) {
                setTotalRowsCount(PDFFileData.totalRowsCount);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {

        setLoading(true); // Set loading to true when fetching data

        getPDFFilesData(page, rowsPerPage, search, page_type, archive)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching user data:', error);
                setLoading(false);
            });

    }, [page, rowsPerPage, search, page_type, archive]);

    useEffect(() => {
        // Map listAllUsers to create rows and update the state
        const updatedRows = listPDFFiles.map(item => createData(
            item.fid,
            item.filename,
            item.fid,
            item.status,
            item.csv_directory,
            item.download_csv
        ));

        // Update the rows state
        setRows(updatedRows);

        console.log("updatedRows")
        console.log(updatedRows)

    }, [listPDFFiles]); // Watch for changes in listAllUsers
    
    //console.log("csv_data")
    //console.log(csv_data)

    useEffect(() => {
      console.log(csv_data)
      if (csv_data !== "") { /*csvLink.current.link.click();*/ }
    }, [csv_data]);

    /*listPDFFiles.map((item, index) => ( rows.push(createData(item.fid, item.filename, item.fid, item.status)) ));*/

    //const getDelPDFFile =  async () => { setDelPDFFile(await DatabaseService.delPDFFile(data)); }

    const handleCSVData = async (id, filename) => {
      
      getCSV(id, filename);
      
        /*getCSV(id)  
            .then(blob => {
              FileSaver.saveAs(blob, 'data.txt');
            })
            .catch(error => console.error(error));*/

            /*.then(response => response.blob())
            .then(blob => {
                //FileSaver.saveAs(blob, 'data.csv');
                console.log("blob");
                console.log(blob);
            })
            .catch(error => console.error(error));*/
          
          //.then(result => {
              //console.log(result);
              //setOpen(false);
              //setDialogInformation({ title: "", information: result, status: "" });
              //setOpen(true); 
              /*if (result.success === true) {
                  setDialogInformation({ title: result.message, information: "You may now check the data list table.", status: "" });
              } else {
                  setDialogInformation({ title: "", information: result.message, status: "" });
              }
              setOpen(true);*/

          //});

      //console.log("csv_data");
      //console.log(csv_data);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }


    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const headCells = [
        {
            id: 'filename',
            numeric: false,
            disablePadding: true,
            label: 'File Name',
        },
        {
            id: 'csv_directory',
            numeric: false,
            disablePadding: true,
            label: 'CSV Files',
        }
    ];

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    /*Dialog Box*/
    const LoadingContainer = styled('div')(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }),
    );

const [open, setOpen] = React.useState(false);
const [dialogData, setDialogInformation] = React.useState({title:"", information:"", status:""});

const navigate = useNavigate();

const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
/*Dialog Box*/

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        paddingRight: '16px!important', 
        display: { xs: 'block', sm: 'flex' },
        paddingTop: { xs: '8px', sm: '0' },
        paddingBottom: { xs: '14px', sm: '0' },
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Convert PDF to CSV
        </Typography>
      )}


      {
        
      numSelected > 0 ? (<>
        <Tooltip title="Delete">
          <Button color="error" variant="contained" onClick={onDelete}>
            <DeleteIcon />
          </Button>
        </Tooltip>
        <Box sx={{width:'10px', padding: { xs: '3px', sm: '0' } }}></Box>
        {
            archive === "1" 
              ? 
                <>
                  <Tooltip title="Unarchive">
                    <Button color="secondary" variant="contained" onClick={()=> {onArchive(null)}}>
                      <UnarchiveIcon />
                    </Button>
                  </Tooltip>
                  </> : 
                <>
                  <Tooltip title="Archive">
                    <Button color="secondary" variant="contained" onClick={()=>{onArchive(1)}}>
                      <ArchiveIcon />
                    </Button>
                  </Tooltip>
                </>
        }
        <Box sx={{width:'10px', padding: { xs: '3px', sm: '0' } }}></Box>
        <Tooltip title="PDF to CSV"> 
          <Button variant="contained" onClick={onPDFtoCSV} sx={{minWidth: 'auto'}}>
             <PictureAsPdfIcon/> <ChevronRightIcon/> <StorageIcon />
          </Button>
        </Tooltip>

        </>
      ) : (
            <>
                <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
                          {
                            /*
                                <Tooltip title="Filter list">
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            */
                          }
            </>
      )
      
      }
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  //const [selectedFid, setFidSelected] = React.useState([]);

  const [dense, setDense] = React.useState(false);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      //const newFidSelected = rows.map((n) => n.fid);
      setSelected(newSelected);
      //setFidSelected(newFidSelected);
      return;
    }
    setSelected([]);
    //setFidSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    console.log(selected);
    console.log(event);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    
    setSelected(newSelected);
    console.log(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  /*const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );*/

  /*const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );*/

  const visibleRows = stableSort(rows, getComparator(order, orderBy));
  
  //POST Database Service
  function delPDFFile_func(data){
    return DatabaseService.delPDFFile(data)
  }

  function archiveFile_func(data, page_type, archive){
    return DatabaseService.archiveFile(data, page_type, archive);
  }

  function extractPDF_func(data){
    return DatabaseService.extractPDF(data)
  }

  function PDFtoCSV_func(data){
    return DatabaseService.PDFtoCSV(data)
  }
  //END of POST Database Service

  const onDelete = (event) => {
      
    console.log(selected);

    setDialogInformation({ information: "Deleting...", status: "loading" });
    setOpen(true);

      delPDFFile_func(selected)
          .then(response => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error('Failed to delete file');
              }
          })
          .then(result => {
              console.log(result);
              setOpen(false);
              // Update table data after successful deletion

              getPDFFilesData(page, rowsPerPage, search, page_type, archive);
              setSelected([]); // Clear selected array
          })
          .catch(error => {
              console.error('Error deleting file:', error);
              setOpen(false);
              // Handle error here, show message or take appropriate action
          });

    /*Disable Temporarily*/
    /*delPDFFile_func(selected).then(r => r.json()).then(result => {
        console.log(result);
        setOpen(false);
    });*/
    /*End of Disable Temporarily*/

    /*setTimeout(() => {
      getPDFFilesData();
    }, 100);
    
    setTimeout(()=>{
      setSelected([]);
    }, 500);*/

    /*console.log(selectedFid);

    */

    /*Dialogbox Test*/

    /*End of Dialogbox Test*/
    

  };

  const onArchive = (set_archive) => { 
 
    archiveFile_func(selected, page_type, set_archive)
          .then(response => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error('Failed to archive file');
              }
          })
          .then(result => {
              //console.log(result);
              setOpen(false);
              // Update table data after successful deletion

              getPDFFilesData(page, rowsPerPage, search, page_type, archive);
              setSelected([]); // Clear selected array
          })
          .catch(error => {
              console.error('Error archive file:', error);
              setOpen(false);
              // Handle error here, show message or take appropriate action
          });
  }

  const onExtract = (event) => {

    console.log(selected);

    setDialogInformation({ information: "Extracting...", status: "loading" });
    setOpen(true);

    extractPDF_func(selected)
        .then(r => r.json())
        .then(result => {
          console.log(result);
          //setOpen(false);
          //setDialogInformation({ title: "", information: result, status: "" });
          //setOpen(true);
            
          if (result.success === true) {
              setDialogInformation({ title: result.message, information: "You may now check the data list table.", status: "" });
          } else {
              setDialogInformation({ title: "", information: result.message, status: "" });
          }

          getPDFFilesData(page, rowsPerPage, search, page_type, archive);

          setOpen(true);
    });
  }

  const onPDFtoCSV = (event) => {

    console.log(selected);

    setDialogInformation({ information: "Converting to CSV...", status: "loading" });
    setOpen(true);

    PDFtoCSV_func(selected).then(r => r.json()).then(result => {
        console.log(result);
        //setOpen(false);
        //setDialogInformation({ title: "", information: result, status: "" });
        //setOpen(true);
      
        if (result.success === true) {
            setDialogInformation({ title: result.message, information: "PDF has been successfully converted to CSV.", status: "" });
        } else {
            setDialogInformation({ title: "", information: result.message, status: "" });
        }

        getPDFFilesData(page, rowsPerPage, search, page_type, archive);

        setOpen(true);

    });
  }

  /* Dialog Box */
  /*const handleClickOpen = () => {
      //setOpen(true);
      navigate('/');  
    };*/

  const handleRedirectToDataList = () => {
      navigate('/');
  }

  const handleClose = () => {
      setOpen(false);
  };
  /* Dialog Box */

//Table

  //=========== Upload Files


  const fileInput = createRef();
  const fn = createRef();
  const ln = createRef();

  const [records, setRecords] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    
    /*
    formData.append("fn", fn.current.value);
    formData.append("ln", ln.current.value);
    */

    for(let i=0; i<fileInput.current.files.length; i++){
      //console.log(fileInput.current.files);
      formData.append("files", fileInput.current.files[i]);
    }

    console.log(...formData);

    //formData.set("avatar", fileInput.current.files[0]);
    //console.log("test");
    //console.log(formData);

    try{
      const response = await fetch('/api/uploadfile', {
        method: "POST",
        body: formData
      });

      const parsedResponse = await response.json();

      //console.log(formData);

      setOpen(true);
      setDialogInformation({ information: "Uploading files...", status: "loading" });

      if(response.ok){
        
        getPDFFilesData(page, rowsPerPage, search, page_type, archive)
          .then(() => {
            setOpen(false);     
          })
          .catch(error => {
              console.error('Error fetching data:', error);
              setLoading(false);
          });

          fileInput.current.value = ""; //make upload input empty after uploading files

      }else{
        console.error("Some error occured");
      }

    }catch(e){
      console.error(e.message);
    }
  }

  //==== Upload Files

    const LoadingTable = styled('div')(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            zIndex: 1,
            paddingTop: '2.7em'
        }),
    );
  
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
      newValue === 0 ? setArchive("") : setArchive("1");
      setSelected([]);
    };

  return (
            <MuiDrawer>
                  <br></br>
                  <form onSubmit={onSubmit}>
                    {/*onClick={(event) => handleClick(event, row.id)}*/}
                  {
                    /*
                    <label htmlFor="fn">First Name</label><br />
                    <input type="text" name="fn" id="fn" ref={fn} /><br />

                    <label htmlFor="ln">Last name</label><br />
                    <input type="text" name="ln" id="ln" ref={ln} /><br /><br />
                    <button type="submit">Upload</button>
                    */
                  }

                    <input type="file" name="file" id="files" accept=".pdf" multiple ref={fileInput}/>

                    <button type="submit">Upload</button>

                  </form>

                  <br></br>

                  <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                          <Tab label="PDF" {...a11yProps(0)} />
                          <Tab label="Archive" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <EnhancedTableToolbar numSelected={selected.length} />
                      <TableContainer sx={{ position: 'relative' }}>

                      {
                          loading ? (

                              <LoadingTable>
                                  <div>
                                      <CircularProgress />
                                  </div>
                              </LoadingTable>
                          ) : ""
                      }

                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                              {
                                  (visibleRows.length > 0) ?
                                      visibleRows.map((row, index) => {
                                          const isItemSelected = isSelected(row.id);
                                          const labelId = `enhanced-table-checkbox-${index}`;
                                          return (
                                                <TableRow
                                                          hover
                                                          role="checkbox"
                                                          aria-checked={isItemSelected}
                                                          tabIndex={-1}
                                                          key={row.id}
                                                          selected={isItemSelected}
                                                          sx={{ cursor: 'pointer' }}
                                                          className='checkboxId'
                                                          data-id={row.id}
                                                >
                                                  <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                          'aria-labelledby': labelId,
                                                        }}
                                                        onClick={(event) => handleClick(event, row.id)}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{paddingRight: '1em'}}
                                                  >
                                                        { (row.download_csv === "1") ? row.filename + ".csv" : row.filename }
                                                  </TableCell>
                                                  <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{paddingRight: '1em'}}
                                                  >
                                                        {/*row.csv_directory*/}
                                                        {
                                                          (row.download_csv === "1") ? 
                                                            <>
                                                              {
                                                                /*
                                                                  <Button variant="contained" color="error" onClick={() => {handleCSVData(row.id)}}>Download CSV</Button>
                                                                  <CSVLink 
                                                                    data={csv_data} 
                                                                    filename="data.csv"
                                                                    className="hidden"
                                                                    ref={csvLink}
                                                                    target="_blank" 
                                                                  />
                                                               */
                                                              }
                                                                <Button
                                                                  variant="contained"
                                                                  color="error"
                                                                  sx={{ whiteSpace: 'nowrap' }}
                                                                  onClick={() => {
                                                                    handleCSVData(row.id, row.filename);
                                                                  }}
                                                                >
                                                                  Download CSV
                                                                </Button>
                                                              {
                                                                /*<CSVLink
                                                                    data={csv_data}
                                                                    filename="data.csv"
                                                                    className="hidden"
                                                                    ref={csvLink}
                                                                    target="_blank"
                                                                />*/
                                                              }
                                                            </> : ""
                                                        }
                                                        
                                                  </TableCell>
                                                      { 
                                                        /*
                                                          <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                          >
                                                          {
                                                              (row.status === "1") ? "To be extracted" : "Extracted"
                                                          }
                                                          </TableCell> 
                                                        */ 
                                                      }
                                                </TableRow>
                                          );
                                      })
                                    : loading ? (
                                          <TableRow
                                              style={{
                                                  height: '120px',
                                              }}
                                          ><TableCell colSpan={6} />
                                          </TableRow>
                                      ) : null
                              }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={parseInt(totalRowsCount)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </Paper>
                    <FormControlLabel
                      control={<Switch checked={dense} onChange={handleChangeDense} />}
                      label="Dense padding"
                    />
                  </Box>
                  {
                    /*
                      <Dialog
                          fullScreen={fullScreen}
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="responsive-dialog-title"
                      >
                    */ 
                  }
                  <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      aria-labelledby="responsive-dialog-title"
                    >
                        
            
              {
                  (dialogData.status === "") ? 
                      (dialogData.title !== "") ?  
                          <DialogTitle id="responsive-dialog-title">
                            { dialogData.title }
                          </DialogTitle> : ""
                      : ""
              }
                      <DialogContent>
                          {/*<DialogContentText>*/}
                          {/*Let Google help apps determine location. This means sending anonymous
                                              location data to Google, even when no apps are running.*/}
                            
                          {
                            /*
                              <LoadingContainer>
                                <CircularProgress sx={{ mr: 2 }} /> {dialogData.information}
                              </LoadingContainer>
                            */
                          }

                         {
                      (dialogData.status === "loading") ?
                          <LoadingContainer> <CircularProgress sx={{ mr: 2 }} /> {dialogData.information} </LoadingContainer>
                          : dialogData.information
                         }

                          {/*</DialogContentText>*/}
                      </DialogContent>

                    {
                  (dialogData.status === "") ? 
                      <DialogActions>
                          {/*<Button autoFocus onClick={handleRedirectToDataList}>
                              Redirect to datalist tables
                            </Button>*/}
                          <Button onClick={handleClose} autoFocus>
                              Close
                          </Button>
                      </DialogActions> : ""
                    }
                      

                  </Dialog>
            </MuiDrawer>
          );
}

/*export default function MainPage1() {

  const classes = useStyles();

  const [listCountAllApplications, setCountAllApplications] = useState([])

  const [listMonthlyTotalApplications, setMonthlyTotalApplications] = useState([]);

  const getDataCountAllApplications = async () => { setCountAllApplications(await DatabaseService.getData("applications_countall")); }

  const getDataMonthlyTotalApplications = async () => { setMonthlyTotalApplications(await DatabaseService.getData("monthly_totalapplications")); }

  useEffect(() => {
    getDataCountAllApplications();
    getDataMonthlyTotalApplications();

  }, []);

  const barChart = processChartData(listMonthlyTotalApplications, 'bar');

  function CategoryApplicationCounter() {
    return (
      <>
          { 
              listCountAllApplications.map((item, index) => (
                  <Grid item xs={12} md={6} lg={3} key={item.id}>
                          <CategoryCounter  
                              urlID={item.id} 
                              totalCount={item.count}
                              year={item.yr}
                              title={item.name}   
                              bgColorID={index}
                          />
                  </Grid>
              ))
          }
      </>
    );
  }

  //Chart
  function CategoryCounterChart(){
    return (
      <> 
        <Grid item xs={12}>      
          <BarChart 
            height="30vw"
            width="100%"
            labels={barChart.labels}
            datasets={barChart.datasets}
            headerTitle="Monthly Total Applications in 2021" 
            classNameCanvasSize={classes.canvasSize} 
          />               
        </Grid>
      </>
    );
  }

  return (

    <MuiDrawer>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid container item spacing={3} className={classes.gridApplicationCounter}>
            <CategoryApplicationCounter />
          </Grid>
          
          <Grid container item spacing={3} className={classes.gridCategoryCounterChart}>
              <CategoryCounterChart />
          </Grid>
        </Grid>
      </div>
    </MuiDrawer>
    
  );
}*/