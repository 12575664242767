import React, { useState, useRef } from 'react';
import Plyr from 'plyr-react';
import 'plyr/dist/plyr.css';
import { Button, IconButton, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const VideoPlayer = ({src}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);

  let videoSrc = { type: "video", sources: [
      {
        src: src,
      }
    ]
  };


  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (playerRef.current) {
      isPlaying ? playerRef.current.plyr.pause() : playerRef.current.plyr.play();
    }
  };

  return (
    <div className="video-player">
      <Plyr
        ref={playerRef}
        source={videoSrc}
        playing={isPlaying.toString()}
        options={{
          controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
        }} // Add custom Plyr options here (refer to Plyr documentation)
      />
      {/*<IconButton onClick={togglePlay}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>*/}
    </div>
  );
};

export default VideoPlayer;

/*import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { Box } from '@mui/material';

const VideoPlayer = ({ src }) => {
  const playerContainer = useRef();

  useEffect(() => {
    if (!playerContainer.current) {
      return;
    }

    const player = new Plyr(playerContainer.current);

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, []);

  return (
    <Box ref={playerContainer}>
      <video controls crossOrigin playsInline className="plyr-react plyr">
        <source src={src} type="video/mp4" />
      </video>
    </Box>
  );
};

export default VideoPlayer;*/