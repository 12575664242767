import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Chart } from 'react-google-charts';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//import Loading from '../../components/Loader';

let defaultTitle = "";

const PieChart = (props) => {

    const {data, headerTitle="Pie Chart", backgroundColor} = props;

      const options = {
        //title: headerTitle,
        fontName: 'BR Firma Medium,Roboto,Arial,sans-serif', // Set your desired font family here
        is3D: true, // This makes the chart 3D
        colors: backgroundColor, // Custom colors
        legend: { position: 'bottom', alignment: 'center' }, // Position of the legend
        titleTextStyle: {
            alignment: 'center', // Center the title
            fontSize: 18, // Optional: adjust the font size
            //bold: true // Optional: make the title bold
        },
        pieSliceText: 'label', // Show actual values instead of percentage or short numbers
        tooltip: { isHtml: true }, // Ensure the tooltip shows the full value
        slices: {
          //0: { offset: 0.1 }, // You can adjust this for other slices as needed
          0: { offset: 0 },
        },
        pieSliceTextStyle: {
          fontSize: 12, // Set the font size for better visibility
          color: 'white', // Set the text color to black
        },
        pieStartAngle: 0,
        pieEndAngle: 360,
        locale: 'de', // Set the locale to German
        //tooltip: { trigger: 'selection' }, // or use the CSS method
        chartArea: {
            top: 0,
            width: '80%',
            height: '90%',
        }
      };

    return (
        <>
            <Paper sx={{height:500}}>
                <Typography variant="h6" component="div" fontWeight={'bold'} textAlign={'center'} sx={{paddingTop: '2em', paddingBottom: '20px'}}>
                    {headerTitle}
                </Typography>
                <Box sx={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="100%"
                    data={data}
                    options={options}
                    loader={<div>Diagramm wird geladen...</div>}
                />
                </Box>
            </Paper>
            <style jsx global>{`
                .google-visualization-tooltip {
                    pointer-events: none;
                }
            `}</style>
        </>
    )
}

export default PieChart;