function calculateUnusedPotenzialAndGenutztesPotenzial(gesamtpotenzial_included, ihrPotencialIncluded) {
    let ungenutztesPotenzial = parseFloat(ihrPotencialIncluded.toFixed(2));
    //let genutztesPotenzial = parseFloat(gesamtpotenzial_included - ihrPotencialIncluded.toFixed(2));

    let genutztesPotenzial = parseFloat(gesamtpotenzial_included - ihrPotencialIncluded);


    ////genutztes Potenzial / Gesamtpotenzial (included) = percentage for speedometer
    //let speedometerPercentage = parseFloat(genutztesPotenzial / gesamtpotenzial_included);
    let speedometerPercentage = parseFloat(genutztesPotenzial / gesamtpotenzial_included)  * 100;


    genutztesPotenzial = parseFloat(genutztesPotenzial).toFixed(2);

  
    speedometerPercentage = parseFloat(speedometerPercentage).toFixed(2);
    
    if (isNaN(speedometerPercentage)) {
      speedometerPercentage = 0;
    }

    return {
      ungenutztesPotenzial,
      genutztesPotenzial,
      speedometerPercentage
    }
}

export default calculateUnusedPotenzialAndGenutztesPotenzial;