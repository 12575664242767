import { Box } from '@mui/material';
import { dangerouslySetInnerHTML } from 'react-dom';

const ViewFormOutput = ({description}) => {

    const fixEncodingErrors = (str) => {
      return str
        .replace(/Ã¼/g, 'ü')
        .replace(/Ã¤/g, 'ä')
        .replace(/Ã¶/g, 'ö')
        .replace(/Ã/g, 'ß')
        .replace(/Ã©/g, 'é')
        .replace(/Ã¨/g, 'è');
    };
    
    let fixedDescription = "";
  
    try{
      fixedDescription = fixEncodingErrors(description);
    }catch(e){
      fixedDescription = description;
    }
  
    return (<>
  
            <Box sx={{
                        maxWidth: 1080, 
                        paddingBottom: 5, 
                        '& table': {
                            width: '100%',
                            maxWidth: 650,
                            borderCollapse: 'collapse',  
                        },
                        '& td, & th': {
                          border: '1px solid #dddddd',
                          padding: '8px'
                        },
                        '& div.table':{
                          overflowX: 'auto'
                        },
                        '& .space':{
                          width: '14px',
                          display: 'inline-block'
                        },
                        '& .displaynone':{
                          display: 'none'
                        } 
                    }}>
              <div dangerouslySetInnerHTML={{ __html: fixedDescription }} />
            </Box>
    </>);
  
}

export default ViewFormOutput;