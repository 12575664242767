import { Box, Grid, ListItem, ListItemText, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import LoginSignupContainer from '../components/Template/LoginSignup';
import Controls from "../components/controls/Controls";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default () => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    await fetch('/api/user/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    });

    setIsSubmitted(true)
  }

  if (isSubmitted) {
    return (
      <LoginSignupContainer hideTabs={true} header={(
        <ListItem> 
            <ListItemText primary="Passwort vergessen" />
        </ListItem>
      )}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">E-Mail gesendet</Typography>
          <Typography>
            Wenn für {email} ein Konto vorhanden ist, erhalten Sie Anweisungen zum Zurücksetzen des Passworts.
          </Typography>
        </Box>
      </LoginSignupContainer>
    )
  }

  return (
    <LoginSignupContainer hideTabs={true} header={(
      <ListItem> 
          <ListItemText primary="Passwort vergessen" />
      </ListItem>
    )}>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Controls.Input 
              label="E-Mail"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.Button
              type="submit"
              text="Passwort zurücksetzen"
              size="large"
              variant="contained"
              color="primaryGreen"
              fullWidth
            />
          </Grid>
          <Grid container justifyContent={'center'} item xs={12}>
              <Link to="/login">Gehen Sie zum Anmelden</Link>
          </Grid>
        </Grid>
      </Box>
    </LoginSignupContainer>
  )
}