import { excludedGops } from "../utils/exclude_gops_in_potenzial_included_count";

function countIhrPotencialIncluded(rows, debug, inputCalculationData, excludedGOP = false){
    let ihrPotencialIncluded = 0;

    let testListIhrPotencial_included = "gop,ihrpotenzial_included\n";

      rows.forEach((row, index) => {
        const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        /*if(debug){
          
          console.log("ihrPotencialIncluded")
          console.log("fallzahl " + fallzahl)

          console.log("idealNumberOfTreatments")
          console.log("idealNumberOfTreatments " + idealNumberOfTreatments)

          console.log("Euro potenzial")
          console.log(row.gop)
          console.log("row.euro_potencial")
          console.log(row.euro_potencial)
          console.log("row.adjusted_euro_potencial")
          console.log(row.adjusted_euro_potencial)
        }*/


          if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
              if (parseInt(row.include_chart) === 1) {
                
                  if(excludedGOP && excludedGops.includes(row.gop)){
                    if(excludedGops.includes(row.gop)){
                      return;
                    }
                  }

                  if (typeof row.adjusted_euro_potencial === 'undefined') {
                      ihrPotencialIncluded += parseFloat(row.euro_potencial);

                      if(debug){
                        testListIhrPotencial_included += row.gop + "," + row.euro_potencial + "\n";
                      }

                  } else {
                      ihrPotencialIncluded += parseFloat(row.adjusted_euro_potencial);

                      if(debug){
                        testListIhrPotencial_included += row.gop + "," + row.adjusted_euro_potencial + "\n";
                      }
                  }

              } else {
                  ihrPotencialIncluded += 0;

                  /*if(debug){
                    testListIhrPotencial_included += row.gop + "," + 0 + "\n";
                  }*/
              }
          } else {
              ihrPotencialIncluded += 0;
              
              /*if(debug){
                testListIhrPotencial_included += row.gop + "," + 0 + "\n";
              }*/
          }
    });

    /*if(debug){
      console.log("testListIhrPotencial_included")
      console.log(testListIhrPotencial_included)
    }*/

    return ihrPotencialIncluded;
}

export default countIhrPotencialIncluded;