import styled from '@emotion/styled';
import { Button as MuiButton, makeStyles } from '@mui/material';

import React from 'react';

/*const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label:{
        textTransform: 'none'
    }
}));*/


/*const MyStyledButton = (props) => (
    <Button
      sx={{
        mx: 1, // ✔️ this shortcut is specific to the `sx` prop,
      }}
    >
      {props.children}
    </Button>
  );*/

export default function Button(props){

    const {text, size, color, variant, onClick, ...other} = props;

    //const classes = useStyles();

    /**
     * For the {...other}, you can use it if you have specific configuration
     * 
     * e.g
     *      type="submit"
     * 
     */
{/*sx={{height: (theme) => theme.spacing(0.5)}}*/}
     return (
        <MuiButton
            variant={variant || "contained" /* if variant value is empty, use contained */}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            {...other}
        >
            {text}
        </MuiButton>
     );

     /*return (
        <MuiButton
            variant={variant || "contained" }
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            {...other}
            classes={{root:classes.root, label:classes.label}}
        >
            {text}
        </MuiButton>
     );*/
}