import React, { useEffect, useState, createRef, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material/Grid';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';
import TablePaginationActions from '../../components/TablePaginationActions';

import SearchInput from '../../components/SearchInput';

import MuiDrawer from '../../components/MuiDrawer';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

//Tab
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
//End of Tab

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
/* End of Dialog Box */

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import Button from '@mui/material/Button';

//import TableEachFileData_Haeufigkeitsstatistik from './TableEachFileData_Haeufigkeitsstatistik';
import EBM_Koeffizient_EachFile from './EBM_Koeffizient_EachFile';
import EBM_Koeffizient_Compare from './EBM_Koeffizient_Compare';
// Table Libraries

import { CSVLink, CSVDownload } from "react-csv";
import FileSaver from 'file-saver';
import { CloseFullscreen } from '@mui/icons-material';

// FullScreen Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//--table style

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
//--end of table style

//End of FullScreen Dialog

export default function TableEachFileData_Haeufigkeitsstatistik({fid, quarter_state, quarter_name, quarter_year, filename, subcategories_id}) {

    // Table for FullScreen Dialog
    /*function createData1(id, id_number, number_text, anzahl, soll, abweichung, abweichung_percent, median, score, potential) {
      return { id, id_number, number_text, anzahl, soll, abweichung, abweichung_percent, median, score, potential };
    }*/

    const [rowsTableFullScreenDialog, setRowsTableFullScreenDialog] = useState([]);
    // End of Table for FullScreen Dialog

    function createData(id,
                            gop,
                            haeufigkeit_praxis_pg,
                            wert_gop_in_eur,
                            gesamtbetrag_gop_in_eur,
                            eur_je_fall,
                            eur_je_fall_pg,
                            ansatz_in_faellen,
                            leistungen_je_fall_praxis_pg,
                            ansatz_in_percent_fz_praxis_pg,
                            ansatz_in_percent_fz_pg,
                            haeufigkeit_100_faelle_praxis_pg,
                            abweichung_der_praxis_pg_in_percent,
                            ansatz_bei_praxen_in_percent) {
        return {
            id,
            gop,
            haeufigkeit_praxis_pg,
            wert_gop_in_eur,
            gesamtbetrag_gop_in_eur,
            eur_je_fall,
            eur_je_fall_pg,
            ansatz_in_faellen,
            leistungen_je_fall_praxis_pg,
            ansatz_in_percent_fz_praxis_pg,
            ansatz_in_percent_fz_pg,
            haeufigkeit_100_faelle_praxis_pg,
            abweichung_der_praxis_pg_in_percent,
            ansatz_bei_praxen_in_percent
        };
    }

    //let rows = [];

    const [listDataFromDB, setRowDataFromDB] = useState([]);

    const [csv_data, setCSVData] = useState("");

    const [rows, setRows] = useState([]);

    const [totalRowsCount, setTotalRowsCount] = useState(0);

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(200);

    const [loading, setLoading] = useState(false); // State to manage loading indication

    const [search, setSearch] = React.useState("");

    const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

    //FullScreen Dialog
    const [openFullScreenDialog, setOpenFullScreenDialog] = React.useState(false);
    //End of FullScreen Dialog

    //const [rows, setRows] = useState([]);
    //const [arr, setArr] = useState([])

    const [rows1, setRows1] = useState([]); //Fallzahlen

    /*Tabs*/
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    /*End of Tabs */


    const getFilesHaeufigkeitsstatistikEachFileData = async (page, rowsPerPage, search, fid) => {

        try {
            const JSONData = await DatabaseService.getFilesHaeufigkeitsstatistikEachFileData((page + 1), rowsPerPage, search, fid);
            if (JSONData && JSONData.rows) {
                setRowDataFromDB(JSONData.rows);
            }

            if (JSONData && JSONData.rows) {
              setRows1(JSONData.rows1);
            }

            if (JSONData && JSONData.totalCount) {
                setTotalRowsCount(JSONData.totalCount);
            }
        } catch (error) {
            console.error('Error fetching json data:', error);
        }
    }

    useEffect(() => {

        setLoading(true); // Set loading to true when fetching data

        getFilesHaeufigkeitsstatistikEachFileData(page, rowsPerPage, search, fid)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching user data:', error);
                setLoading(false);
            });

    }, [page, rowsPerPage, search]);

    useEffect(() => {
        // Map listAllUsers to create rows and update the state
        const updatedRows = listDataFromDB.map(item => createData(
            item.id,
            item.gop,
            item.haeufigkeit_praxis_pg,
            item.wert_gop_in_eur,
            item.gesamtbetrag_gop_in_eur,
            item.eur_je_fall,
            item.eur_je_fall_pg,
            item.ansatz_in_faellen,
            item.leistungen_je_fall_praxis_pg,
            item.ansatz_in_percent_fz_praxis_pg,
            item.ansatz_in_percent_fz_pg,
            item.haeufigkeit_100_faelle_praxis_pg,
            item.abweichung_der_praxis_pg_in_percent,
            item.ansatz_bei_praxen_in_percent
        ));

        // Update the rows state
        setRows(updatedRows);

        console.log("updatedRows")
        console.log(updatedRows)

    }, [listDataFromDB]); // Watch for changes in listAllUsers
    
    //console.log("csv_data")
    //console.log(csv_data)

    useEffect(() => {
      console.log(csv_data)
      if (csv_data !== "") { /*csvLink.current.link.click();*/ }
    }, [csv_data]);

    /*listDataFromDB.map((item, index) => ( rows.push(createData(item.fid, item.filename, item.fid, item.status)) ));*/

    //const getDelPDFFile =  async () => { setDelPDFFile(await DatabaseService.delPDFFile(data)); }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }


    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    /*h.id,
	h.gop,
	h.haeufigkeit_praxis_pg,
	h.wert_gop_in_eur,
	h.gesamtbetrag_gop_in_eur,
	h.eur_je_fall,
	h.eur_je_fall_pg,
	h.ansatz_in_faellen,
	h.leistungen_je_fall_praxis_pg,
	h.ansatz_in_percent_fz_praxis_pg,
	h.ansatz_in_percent_fz_pg,
	h.haeufigkeit_100_faelle_praxis_pg,
	h.abweichung_der_praxis_pg_in_percent,
	h.ansatz_bei_praxen_in_percent,*/

    const headCells = [
        {
            id: 'gop',
            numeric: false,
            disablePadding: true,
            label: 'gop',
        },
        {
            id: 'haeufigkeit_praxis_pg',
            numeric: false,
            disablePadding: true,
            label: 'haeufigkeit_praxis_pg',
        },
        {
            id: 'wert_gop_in_eur',
            numeric: false,
            disablePadding: true,
            label: 'wert_gop_in_eur',
        },
        {
            id: 'gesamtbetrag_gop_in_eur',
            numeric: false,
            disablePadding: true,
            label: 'gesamtbetrag_gop_in_eur',
        },
        {
            id: 'eur_je_fall',
            numeric: false,
            disablePadding: true,
            label: 'eur_je_fall',
        },
        {
            id: 'eur_je_fall_pg',
            numeric: false,
            disablePadding: true,
            label: 'eur_je_fall_pg',
        },
        {
            id: 'ansatz_in_faellen',
            numeric: false,
            disablePadding: true,
            label: 'ansatz_in_faellen',
        },
        {
            id: 'leistungen_je_fall_praxis_pg',
            numeric: false,
            disablePadding: true,
            label: 'leistungen_je_fall_praxis_pg',
        },
        {
            id: 'ansatz_in_percent_fz_praxis_pg',
            numeric: false,
            disablePadding: true,
            label: 'ansatz_in_percent_fz_praxis_pg',
        },
        {
            id: 'ansatz_in_percent_fz_pg',
            numeric: false,
            disablePadding: true,
            label: 'ansatz_in_percent_fz_pg',
        },
        {
            id: 'haeufigkeit_100_faelle_praxis_pg',
            numeric: false,
            disablePadding: true,
            label: 'haeufigkeit_100_faelle_praxis_pg',
        },
        {
            id: 'abweichung_der_praxis_pg_in_percent',
            numeric: false,
            disablePadding: true,
            label: 'abweichung_der_praxis_pg_in_percent',
        },
        {
            id: 'ansatz_bei_praxen_in_percent',
            numeric: false,
            disablePadding: true,
            label: 'ansatz_bei_praxen_in_percent',
        }
        
    ];

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    /*Dialog Box*/
    const LoadingContainer = styled('div')(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }),
    );

const [open, setOpen] = React.useState(false);
const [dialogData, setDialogInformation] = React.useState({title:"", information:"", status:""});

const navigate = useNavigate();

const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
/*Dialog Box*/

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        paddingRight: '16px!important', 
        display: { xs: 'block', sm: 'flex' },
        paddingTop: { xs: '8px', sm: '0' },
        paddingBottom: { xs: '14px', sm: '0' },
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {/*Title Here*/}
        </Typography>
      )}


      {
        
        numSelected > 0 ? (<>
          <Tooltip title="Delete">
            <Button color="error" variant="contained" onClick={onDelete}>
              <DeleteIcon />
            </Button>
          </Tooltip>
          </>
        ) : (
              <>
                  <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
              </>
        )
      
      }
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  //const [selectedFid, setFidSelected] = React.useState([]);

  const [dense, setDense] = React.useState(false);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      //const newFidSelected = rows.map((n) => n.fid);
      setSelected(newSelected);
      //setFidSelected(newFidSelected);
      return;
    }
    setSelected([]);
    //setFidSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    console.log(selected);
    console.log(event);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    
    setSelected(newSelected);
    console.log(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  /*const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );*/

  /*const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );*/

  const visibleRows = stableSort(rows, getComparator(order, orderBy));
  
  //POST Database Service
  function deleteData_func(data){
    return DatabaseService.delHaeufigkeitsstatistikEachFileData(data)
  }

  /*function extractPDF_func(data){
    return DatabaseService.extractPDF(data)
  }*/

  /*function PDFtoCSV_func(data){
    return DatabaseService.PDFtoCSV(data)
  }*/

  /*function CONtoCSV_func(data){
    return DatabaseService.CONtoCSV(data)
  }*/

  function CSVComparison_func(data){
    return DatabaseService.getKundeListComparison(data);
  }
  //END of POST Database Service

  const onDelete = (event) => {
      
    console.log(selected);

    setDialogInformation({ information: "Deleting...", status: "loading" });
    setOpen(true);

      deleteData_func(selected)
          .then(response => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error('Failed to delete file');
              }
          })
          .then(result => {
              console.log(result);
              setOpen(false);
              // Update table data after successful deletion

              getFilesHaeufigkeitsstatistikEachFileData(page, rowsPerPage, search, fid);
              setSelected([]); // Clear selected array
          })
          .catch(error => {
              console.error('Error deleting file:', error);
              setOpen(false);
              // Handle error here, show message or take appropriate action
          });

    /*Disable Temporarily*/
    /*deleteData_func(selected).then(r => r.json()).then(result => {
        console.log(result);
        setOpen(false);
    });*/
    /*End of Disable Temporarily*/

    /*setTimeout(() => {
      getFilesHaeufigkeitsstatistikEachFileData();
    }, 100);
    
    setTimeout(()=>{
      setSelected([]);
    }, 500);*/

    /*console.log(selectedFid);

    */

    /*Dialogbox Test*/

    /*End of Dialogbox Test*/
    

  };

  /*const onExtract = (event) => {
    console.log(selected);
    setDialogInformation({ information: "Extracting...", status: "loading" });
    setOpen(true);
    extractPDF_func(selected)
        .then(r => r.json())
        .then(result => {
          console.log(result);
          //setOpen(false);
          //setDialogInformation({ title: "", information: result, status: "" });
          //setOpen(true);
          if (result.success === true) {
              setDialogInformation({ title: result.message, information: "You may now check the data list table.", status: "" });
          } else {
              setDialogInformation({ title: "", information: result.message, status: "" });
          }
          getFilesHaeufigkeitsstatistikEachFileData(page, rowsPerPage, search, fid);
          setOpen(true);
    });
  }*/

  const handleRedirectToDataList = () => {
      navigate('/');
  }

  const handleClose = () => {
      setOpen(false);
  };
  /* Dialog Box */

//Table

  //=========== Upload Files

  function CloseFullscreenDialog(){
    setOpenFullScreenDialog(false);
  }

  //Table for FullScreen Dialog

  /*const rowsTableForFullScreenDialog = [
    createData1('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData1('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData1('Eclair', 262, 16.0, 24, 6.0),
    createData1('Cupcake', 305, 3.7, 67, 4.3),
    createData1('Gingerbread', 356, 16.0, 49, 3.9),
  ];*/

  //End of Table for FullScreen Dialog

  //==== Upload Files

    const LoadingTable = styled('div')(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            zIndex: 1,
            paddingTop: '2.7em'
        }),
    );
  
  return (
            <>
                  <Typography variant="p" component="h2" sx={{paddingBottom: '0.5em'}}>
                    {quarter_state.name} {quarter_name.name} {quarter_year} 
                  </Typography>

                  {/* Fallzahlen */}
                  <TableContainer component={Paper} sx={{maxWidth: 800}}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={5}>
                            Fallzahlen
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>kurativ</TableCell>
                          <TableCell align="right">mutterschaftsvorsorge</TableCell>
                          <TableCell align="right">frueherkennung</TableCell>
                          <TableCell align="right">sonstige_hilfen</TableCell>
                          <TableCell align="right">impfungen</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows1.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.kurativ}
                            </TableCell>
                            <TableCell align="right">{row.mutterschaftsvorsorge}</TableCell>
                            <TableCell align="right">{row.frueherkennung}</TableCell>
                            <TableCell align="right">{row.sonstige_hilfen}</TableCell>
                            <TableCell align="right">{row.impfungen}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                  <br/>

                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ '& .MuiTabs-scroller': { overflowX: 'auto!important' } }}>
                        <Tab label="CSV-Dateien" value="1" />
                        <Tab label="EBM Koeffizient" value="2" />
                        <Tab label="EBM Koeffizient Compare" value="3" />
                        <Tab label="Median of all files of one quarter" value="4" />
                        <Tab label="Median of all files of on year basis" value="5" />
                        <Tab label="Median of all files of all files of a state (KV)" value="6" />
                        <Tab label="Median of all files" value="7" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 0 }}>
                      <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                          <EnhancedTableToolbar numSelected={selected.length} />
                          <TableContainer sx={{ position: 'relative' }}>

                          {
                              loading ? (

                                  <LoadingTable>
                                      <div>
                                          <CircularProgress />
                                      </div>
                                  </LoadingTable>
                              ) : ""
                          }

                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              size={dense ? 'small' : 'medium'}
                            >
                              <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                              />
                              <TableBody>
                                  {
                                      (visibleRows.length > 0) ?
                                          visibleRows.map((row, index) => {
                                              const isItemSelected = isSelected(row.id);
                                              const labelId = `enhanced-table-checkbox-${index}`;
                                              return (
                                                    <TableRow
                                                              hover
                                                              role="checkbox"
                                                              aria-checked={isItemSelected}
                                                              tabIndex={-1}
                                                              key={row.id}
                                                              selected={isItemSelected}
                                                              sx={{ cursor: 'pointer' }}
                                                              className='checkboxId'
                                                              data-id={row.id}
                                                    >
                                                      <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                              'aria-labelledby': labelId,
                                                            }}
                                                            onClick={(event) => handleClick(event, row.id)}
                                                        />
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.gop}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.haeufigkeit_praxis_pg}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.wert_gop_in_eur}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.gesamtbetrag_gop_in_eur}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.eur_je_fall}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.eur_je_fall_pg}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.ansatz_in_faellen}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.leistungen_je_fall_praxis_pg}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.ansatz_in_percent_fz_praxis_pg}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.ansatz_in_percent_fz_pg}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.haeufigkeit_100_faelle_praxis_pg}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.abweichung_der_praxis_pg_in_percent}
                                                      </TableCell>
                                                      <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                      >
                                                            {row.ansatz_bei_praxen_in_percent}
                                                      </TableCell>
                                                    </TableRow>
                                              );
                                          })
                                        : loading ? (
                                              <TableRow
                                                  style={{
                                                      height: '120px',
                                                  }}
                                              ><TableCell colSpan={6} />
                                              </TableRow>
                                          ) : null
                                  }
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 200]}
                            component="div"
                            count={parseInt(totalRowsCount)}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </Paper>
                        <FormControlLabel
                          control={<Switch checked={dense} onChange={handleChangeDense} />}
                          label="Dense padding"
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 0 }}><EBM_Koeffizient_EachFile fid={fid} /></TabPanel>
                    <TabPanel value="3" sx={{ p: 0 }}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={quarter_name.id.toString()} quarter_year={quarter_year.toString()} filename={filename} fid={fid} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={quarter_name.id.toString()} quarter_year={quarter_year.toString()} filename={filename} fid={fid} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={quarter_name.id.toString()} quarter_year={quarter_year.toString()} filename={filename} fid={fid} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={quarter_name.id.toString()} quarter_year={quarter_year.toString()} filename={filename} fid={fid} subcategories_id={subcategories_id} />
                          </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="4" sx={{ p: 0 }}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={quarter_name.id.toString()} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={quarter_name.id.toString()} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={quarter_name.id.toString()} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={quarter_name.id.toString()} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="5" sx={{ p: 0 }}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={quarter_year.toString()} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={quarter_year.toString()} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={quarter_year.toString()} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={quarter_year.toString()} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="6" sx={{ p: 0 }}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={quarter_state.id.toString()} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="7" sx={{ p: 0 }}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                          <Grid item xs={3}>
                            <EBM_Koeffizient_Compare quarter_state={0} quarter_name={0} quarter_year={0} filename={""} fid={0} subcategories_id={subcategories_id} />
                          </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext> 

                  {/*Full Screen Dialog*/}
                  <Dialog
                    fullScreen
                    open={openFullScreenDialog}
                    onClose={CloseFullscreenDialog}
                    TransitionComponent={Transition}
                  >
                    <AppBar sx={{ position: 'relative' }}>
                      <Toolbar>
                        <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                          Comparison Results
                        </Typography>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={CloseFullscreenDialog}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Toolbar>
                    </AppBar>

                    <Box sx={{ p: 2 }}> 
                      <Paper sx={{ width: '100%', mb: 2 }}>
                        <TableEachFileData_Haeufigkeitsstatistik />
                      </Paper>
                    </Box>
                  </Dialog>

                  {/*Progress Dialog*/}
                  <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      aria-labelledby="responsive-dialog-title"
                    >
                        
            
                    {
                        (dialogData.status === "") ? 
                            (dialogData.title !== "") ?  
                                <DialogTitle id="responsive-dialog-title">
                                  { dialogData.title }
                                </DialogTitle> : ""
                            : ""
                    }
                      <DialogContent>
                          {/*<DialogContentText>*/}
                          {/*Let Google help apps determine location. This means sending anonymous
                                              location data to Google, even when no apps are running.*/}
                            
                          {
                            /*
                              <LoadingContainer>
                                <CircularProgress sx={{ mr: 2 }} /> {dialogData.information}
                              </LoadingContainer>
                            */
                          }

                         {
                            (dialogData.status === "loading") ?
                                <LoadingContainer> <CircularProgress sx={{ mr: 2 }} /> {dialogData.information} </LoadingContainer>
                                : dialogData.information
                         }

                          {/*</DialogContentText>*/}
                      </DialogContent>

                    {
                        (dialogData.status === "") ? 
                            <DialogActions>
                                {/*<Button autoFocus onClick={handleRedirectToDataList}>
                                    Redirect to datalist tables
                                    </Button>*/}
                                <Button onClick={handleClose} autoFocus>
                                    Close
                                </Button>
                            </DialogActions> : ""
                    }
                  </Dialog>
            </>
          );
}