export default function countIhrPotencialIncluded(rows, inputCalculationData){
    let ihrPotencialIncluded = 0;

    rows.forEach((row, index) => {
        
        const anzahl = parseInt(inputCalculationData[`anzahl_${row.ziffer}`]);

        /*console.log("anzahl");
        console.log(anzahl);*/

        const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        if (anzahl > 0 && anzahl <= idealNumberOfTreatments) {

            ihrPotencialIncluded += parseFloat(row.ihr_potenzial);

        }else{

            ihrPotencialIncluded += 0;
        
        }
    });
    
    /*let testListIhrPotencial_included = "gop,ihrpotenzial_included\n";

      rows.forEach((row, index) => {
        const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

          if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
              if (parseInt(row.include_chart) === 1) {
                  
                  if (typeof row.adjusted_euro_potencial === 'undefined') {
                      ihrPotencialIncluded += parseFloat(row.euro_potencial);

                      if(debug){
                        testListIhrPotencial_included += row.gop + "," + row.euro_potencial + "\n";
                      }

                  } else {
                      ihrPotencialIncluded += parseFloat(row.adjusted_euro_potencial);

                      if(debug){
                        testListIhrPotencial_included += row.gop + "," + row.adjusted_euro_potencial + "\n";
                      }
                  }

              } else {
                  ihrPotencialIncluded += 0;

                  if(debug){
                    testListIhrPotencial_included += row.gop + "," + 0 + "\n";
                  }
              }
          } else {
              ihrPotencialIncluded += 0;
              
              if(debug){
                testListIhrPotencial_included += row.gop + "," + 0 + "\n";
              }
          }
    });*/

    return ihrPotencialIncluded;
  }

  //let ihrPotencialIncluded = countIhrPotencialIncluded(rows1);
  //setTotalIhrPotencialIncluded(ihrPotencialIncluded)