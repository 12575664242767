import Speedometer from '../../../components/charts/Speedometer';
import calculateUngenutzPotenzialAndGenutztesPotenzial from "../calculations/calculateUngenutzPotenzialAndGenutztesPotenzial";

export default function SpeedometerFilter(props){
    
    const { total_ihr_potenzial_included, gesamtpotenzial_included } = props;

    let result = calculateUngenutzPotenzialAndGenutztesPotenzial(total_ihr_potenzial_included, gesamtpotenzial_included);

    /*console.log("speedometerPercentage")
    console.log(result.speedometerPercentage)*/

    return (<>
      <Speedometer value={result.speedometerPercentage} headerTitle="Ihr GynSuccess-GOÄ-Score" />
    </>);
}