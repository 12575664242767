import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const RenderSubMenu = ({ menu, parentOpenMenus, onMenuToggle, depth = 0 }) => {
  const location = useLocation();
  
  useEffect(() => {
    const savedState = localStorage.getItem('openMenus');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      if (JSON.stringify(parsedState) !== JSON.stringify(parentOpenMenus)) {
        onMenuToggle(parsedState);
      }
    }
  }, []);

  const isPathActive = (path) => {
    return location.pathname === path;
  };

  const toggleMenu = (key) => {
    const newOpenMenus = {
      ...parentOpenMenus,
      [key]: !parentOpenMenus[key],
    };
    localStorage.setItem('openMenus', JSON.stringify(newOpenMenus));
    onMenuToggle(newOpenMenus);
  };

  const isMenuActive = (item) => {
    if (isPathActive(item.path)) return true;
    if (item.subMenu) {
      return item.subMenu.some(subItem => isMenuActive(subItem));
    }
    return false;
  };

  function filterMenuItems(items) {
    return items
      .filter(item => !item.hide)
      .map(item => ({
        ...item,
        subMenu: item.subMenu ? filterMenuItems(item.subMenu) : []
      }));
  }
  
  const filteredMenu = filterMenuItems(menu);

  return (
    <ul style={{ 
      paddingLeft: depth * 5, 
      listStyleType: 'none',
      margin: 0
    }}>
      {filteredMenu.map((item) => {
        
        const isActive = isMenuActive(item);
        const isOpen = parentOpenMenus[item.key];
        
        return (
          <li key={item.key} style={{ marginBottom: '4px' }}>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: '8px'
            }}>
              <NavLink
                to={item.path}
                style={({ isActive }) => ({
                  textDecoration: 'none',
                  color: isActive ? '#1976d2' : '#333',
                  flex: 1,
                  padding: '6px 0',
                })}
              >
                {item.label}
              </NavLink>
              
              {item.subMenu?.length > 0 && (
                <div 
                  onClick={() => toggleMenu(item.key)}
                  style={{ 
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '4px'
                  }}
                >
                  {isOpen ? (
                    <ExpandLess style={{ fontSize: 20 }} />
                  ) : (
                    <ExpandMore style={{ fontSize: 20 }} />
                  )}
                </div>
              )}
            </div>
            
            {item.subMenu?.length > 0 && isOpen && (
              <RenderSubMenu
                menu={item.subMenu}
                parentOpenMenus={parentOpenMenus}
                onMenuToggle={onMenuToggle}
                depth={depth + 1}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const NestedMenu = ({ menu }) => {
  const [openMenus, setOpenMenus] = useState(() => {
    const savedState = localStorage.getItem('openMenus');
    return savedState ? JSON.parse(savedState) : {};
  });

  return (
    <RenderSubMenu
      menu={menu}
      parentOpenMenus={openMenus}
      onMenuToggle={setOpenMenus}
      depth={0}
    />
  );
};

export default NestedMenu;