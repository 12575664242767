import Speedometer from '../../../components/charts/Speedometer';
import calculateUnusedPotenzialAndGenutztesPotenzial from '../calculations/calculateUnusedPotenzialAndGenutztesPotenzial';

const SpeedometerFilter = (props) => {
    
    const { gesamtpotenzial_included, ihrPotencialIncluded } = props;

    let result = calculateUnusedPotenzialAndGenutztesPotenzial(gesamtpotenzial_included, ihrPotencialIncluded);

    return (<>
      <Speedometer value={result.speedometerPercentage} headerTitle="Ihr GynSuccess-Score" />
    </>);
}

export default SpeedometerFilter;