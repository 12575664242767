import React, { useEffect, useState, createRef } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material/Grid';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';

import FormGroup from '@mui/material/FormGroup';
import { Pagination } from '@mui/material';
import LoadingSpecificComponent from '../../components/LoadingSpecificComponent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import TablePaginationActions from '../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../components/SearchInput';

import SelectOptionInput from '../../components/SelectOptionInput';

import TextField from '@mui/material/TextField';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
/* End of Dialog Box */

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import Button from '@mui/material/Button';
import { display } from '@mui/system';
import { FileDownloadDoneOutlined } from '@mui/icons-material';
// Table Libraries

const FilesList = ({ selectedFile, setSelectedFile, handleToggle, setLoading, subcategories_id }) => {

  const [listFileData, setFileListData] = useState([]);

  const [search, setSearch] = React.useState("");

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [alert, setAlert] = useState({ text: '', appear: false, severity: '' });

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  //const fileInput = createRef();
  //const fileInput = useRef();

  const onSubmit = async (e) => {
      e.preventDefault();

      /*const formData = new FormData();

      try {

          console.log(fileInput);

          if (fileInput.current.value !== "") {
              for (let i = 0; i < fileInput.current.files.length; i++) {
                  formData.append("audios", fileInput.current.files[i]);
              }

              try {

                  setLoading(true);

                  const response = await fetch('/api/upload_audio_file', {
                      method: "POST",
                      body: formData,
                      cache: 'no-store', // Bypass cache
                  });

                  if (!response.ok && response.status !== 304) {

                      throw new Error(`HTTP error! status: ${response.status}`);

                  } else if (response.status === 304) {
                      console.log('File has not been modified');

                      // Handle the 304 status here
                  } else {
                      // Handle the successful response here

                      fileInput.current.value = ""; // Clear the input field after successful upload

                      console.log(fileInput)

                      getFilesListData(page, rowsPerPage, search)
                          .then(() => {

                              setAlert({ text: "Audio has been added", appear: true, severity: 'success' });

                              // Set a timeout to change 'appear' to false after 5 seconds (5000 milliseconds)
                              setTimeout(() => {
                                  setAlert(prevState => ({ ...prevState, appear: false }));
                              }, 5000);
                              setLoading(false)

                          })
                  }

              } catch (error) {
                  console.error('Fetch Error:', error);
              }
          }

      } catch (e) {
          console.error('Fetch Error:', e);
      }*/
  }

  const getFilesListData = async (page, rowsPerPage, search, subcategories_id, mode) => {

      try {
          const fileListData = await DatabaseService.getEBMKoeffizientenFilestructure_FilesList(page, rowsPerPage, search, subcategories_id, mode);

          console.log("subcategories_id");
          console.log(subcategories_id);

          console.log("fileListData");
          console.log(fileListData);

          if (fileListData && fileListData.rows) {
              setFileListData(fileListData.rows);
          }
          
          if (fileListData && fileListData.totalCount) {
              setTotalRowsCount(fileListData.totalCount);
          } else {
              setTotalRowsCount(0);
          }


      } catch (error) {
          console.error('Error fetching audio list data:', error);
      }
  }

  const onDeleteFile = async (e) => {
      e.preventDefault();

      /*console.log(selectedFile);

      setLoading(true); // Set loading to true when fetching data

      DatabaseService.delFile(selectedFile.id)
          .then(response => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error('Failed to delete audio');
              }
          })
          .then(result => {
              console.log(result);

              setSelectedFile({...selectedFile, clicked: false });

              getFilesListData(page, rowsPerPage, search)
                  .then(() => {
                      
                      setLoading(false)

                      setAlert({ text: "Audio has been deleted", appear: true, severity: 'success' });

                      // Set a timeout to change 'appear' to false after 5 seconds (5000 milliseconds)
                      setTimeout(() => {
                          setAlert(prevState => ({ ...prevState, appear: false }));
                      }, 5000);

                  })
          })
          .catch(error => {
              console.error('Error deleting audio:', error);

              setAlert({ text: "Error deleting audio", appear: true, severity: 'error' });

              // Set a timeout to change 'appear' to false after 5 seconds (5000 milliseconds)
              setTimeout(() => {
                  setAlert(prevState => ({ ...prevState, appear: false }));
              }, 5000);

              setLoading(false)
              // Handle error here, show message or take appropriate action
          });*/
  }

  useEffect(() => {

      setLoading(true); // Set loading to true when fetching data
      getFilesListData(page, rowsPerPage, search, subcategories_id, 'pick_mode')
          .then(() => setLoading(false))
          .catch(error => {
              console.log('Error fetching set audio list data:', error);
              setLoading(false);
          });

  }, [page, rowsPerPage, search, setLoading, subcategories_id]);

  useEffect(() => {
      if (page === 0) {
          setPage(1);
      }

  }, [page]);

  const handleChange = (event, value) => {

      console.log(value)
      setPage(value);

  };

  return (
      <>
          <Grid container spacing={3}>
              <Grid item xs={6}>
                  <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
              </Grid>

              { /*selectedFile.clicked && (
                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      <IconButton sx={{ padding: '6px' }} onClick={onDeleteFile}>
                          <DeleteIcon />
                      </IconButton>
                  </Grid>
              )*/ }
          </Grid>

          <FormGroup sx={{ overflowX: 'auto' }}>
              {listFileData.map((item, index) => (
                  <FormControlLabel
                      key={item.fid}
                      control={<Checkbox
                          checked={selectedFile[item.fid] || false}
                          onChange={() => {
                              handleToggle(item.fid, item.directory + item.filename, item.filename, item.filetype)
                          }}
                          inputProps={{ 'aria-label': `select ${item.id}` }}
                      />}
                      label={item.filename} // replace with the property from your data
                  />
              ))}
          </FormGroup>

          <Pagination count={(Math.ceil(parseInt(totalRowsCount) / rowsPerPage))} page={page} onChange={handleChange} />
      </>
  );
}

const DialogFileListComponent = ({ openFileListDialog, handleCloseFileList, selectedFile, setSelectedFile, handleToggleFileList, subcategories_id }) => {

  const [loading, setLoading] = useState(false); // State to manage loading indication

  return (<>

      <Dialog
          open={openFileListDialog}
          onClose={handleCloseFileList}
          aria-labelledby="responsive-dialog-title"
      >
          <DialogTitle id="responsive-dialog-title">
              File List
          </DialogTitle>
          <DialogContent sx={{ position: 'relative' }}>

              <LoadingSpecificComponent loading={loading} />

              <FilesList 
                    selectedFile={selectedFile} 
                    setSelectedFile={setSelectedFile} 
                    handleToggle={handleToggleFileList} 
                    setLoading={setLoading} 
                    subcategories_id={subcategories_id} 
              />

          </DialogContent>
          <DialogActions>
              <Button autoFocus onClick={handleCloseFileList}>
                  Close
              </Button>
          </DialogActions>
      </Dialog>

  </>);
}

const FileListSection = ({ file_name, subcategories_id, handleFileList, handleClearFile }) => (
  <>
      
        <TextField
              label="Filename"
              id="filename"
              defaultValue="Small"
              value={file_name}
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
             
      <Button variant="contained" onClick={handleFileList} >Choose File</Button>
      <Button variant="contained" onClick={handleClearFile} >Clear File</Button>

      {
        subcategories_id
      }
  </>
);

export default function EBM_Koeffizient_Compare({quarter_state, quarter_name, quarter_year, filename, fid, subcategories_id}) {

  function createData(
                      id,
                      gop,
                      ebm_koeffizient) {
    return {
      id,
      gop,
      ebm_koeffizient
    };
  }

  /*let rows = [
                createData('1','01/00','01100','GRÃœN','5','5','0,29','0,31','196,0','196,0','980,0',''),
                createData('2','01/00','01102','ROT','1','1','0,06','0,05','101,0','101,0','101,0',''),
                createData('3','01/00','08220','ROSAROT','1.430','1.430','81,53','66,47','24,0','24,0','34.320,0','')
             ];*/
  
  //let rows = [];

  const [listDataFromDB, setRowDataFromDB] = useState([]);

  const [rows, setRows] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(200);

  const [loading, setLoading] = useState(false); // State to manage loading indication

  const [search, setSearch] = React.useState("");

  const [searchQuarterState, setSearchQuarterState] = React.useState(quarter_state);

  const [searchQuarterName, setSearchQuarterName] = React.useState(quarter_name);

  const [searchQuarterYear, setSearchQuarterYear] = React.useState(quarter_year);

  const [listQuarterState, setListQuarterState] = React.useState([]);

  const [listQuarterName, setListQuarterName] = React.useState([]);

  const [listQuarterYear, setListQuarterYear] = React.useState([]);

  const [file_name, setFilename] = React.useState(filename);

  const [file_id, setFileID] = React.useState(fid);

  const [openFileListDialog, setOpenFileListDialog] = useState(false);

  const [selectedFile, setSelectedFile] = React.useState({ array: false, clicked: '', filename_dir: '', id: '', filetype: '' });
  
  const [subcategoriesID, setSubcategoriesID] = React.useState(subcategories_id);

  const handleToggleFileList = (id, dir, filename, filetype) => {
    setSelectedFile({ [id]: !selectedFile[id], clicked: !selectedFile[id], filename_dir: dir, id: id, filetype: filetype });
    //setPostData({ ...postData, file_dir: dir, audio_filename: filename, audio_no: parseInt(id) });
    console.log("selectedFile");
    console.log(selectedFile);
    //console.log("postData");
    //console.log(postData);
    
    setFileID(id);
    setFilename(filename);

    console.log("id")
    console.log(id);

    console.log("filename")
    console.log(filename)
  }

  useEffect(() => {
    
    if(selectedFile.clicked !== ''){
      setFileID((selectedFile.clicked) ? file_id : 0);
      setFilename((selectedFile.clicked) ? file_name : "");
    }
    
  }, [selectedFile]);

  const handleCloseFileList = () => {
    setOpenFileListDialog(false);
  }
    
  const handleFileList = () => {
    setOpenFileListDialog(true);
  }

  const handleClearFile = () => {
    setFileID(0);
    setFilename("");
  }

  //const getEBMKoeffizientCompare =  async () => { setRowDataFromDB(await DatabaseService.getGemeinschaftspraxis()); }

    const getEBMKoeffizientCompare = async (page, rowsPerPage, search, quarter_state, quarter_name, quarter_year, fid) => {

      /*console.log("quarter_state");
      console.log(quarter_state.id.toString());
      console.log("quarter_name");
      console.log(quarter_name.id.toString());
      console.log("quarter_year");
      console.log(quarter_year);*/

        try {
            //const JSONData = await DatabaseService.getEBMKoeffizientCompare((page + 1), rowsPerPage, search, quarter_state.id, quarter_name.id, quarter_year.year);

            const JSONData = await DatabaseService.getEBMKoeffizientCompare((page + 1), rowsPerPage, search, quarter_state, quarter_name, quarter_year, fid);

            if (JSONData && JSONData.rows) {
                setRowDataFromDB(JSONData.rows);
            }

            if (JSONData && JSONData.quarterStateRows) {
              setListQuarterState(JSONData.quarterStateRows);
            }

            if (JSONData && JSONData.quarterNameRows) {
              setListQuarterName(JSONData.quarterNameRows);
            }

            if (JSONData && JSONData.quarterYearRows) {
              setListQuarterYear(JSONData.quarterYearRows);
            }

            if (JSONData && JSONData.totalCount) {
                setTotalRowsCount(JSONData.totalCount);
            }
            //setRowDataFromDB(await DatabaseService.getGemeinschaftspraxis());
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const getSubcategoryID = async (quarter_state, quarter_name, quarter_year) => {

        try {
          const JSONData = await DatabaseService.getEBMKoeffizientenFilestructure(quarter_state, quarter_name, quarter_year);

            /*console.log("JSONData")
            console.log(JSONData)

            console.log("JSONData.rows.length")
            console.log(JSONData.rows.length)*/

            if(JSONData && JSONData.rows.length === 1){
              //console.log("JSONData.rows[0].sub_page_quarters[0].subcategory_id")
              //console.log(JSONData.rows[0].sub_page_quarters[0].subcategory_id)
              setSubcategoriesID(JSONData.rows[0].sub_page_quarters[0].subcategory_id);
            }else{
              setSubcategoriesID(0);
            }

            //setRowDataFromDB(await DatabaseService.getGemeinschaftspraxis());
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }


  useEffect(() => {
    
      setLoading(true); // Set loading to true when fetching data

      getEBMKoeffizientCompare(page, rowsPerPage, search, searchQuarterState, searchQuarterName, searchQuarterYear, file_id)
          .then(() => setLoading(false))
          .catch(error => {
              console.log('Error fetching data:', error);
              setLoading(false);
          });

          console.log("quarter_state")
          console.log(quarter_state)
          console.log("quarter_name")
          console.log(quarter_name)
          console.log("quarter_year")
          console.log(quarter_year)

  }, [page, rowsPerPage, search, searchQuarterState, searchQuarterName, searchQuarterYear, file_id]);

  useEffect(() => {
    
    getSubcategoryID(searchQuarterState, searchQuarterName, searchQuarterYear);

  }, [searchQuarterState, searchQuarterName, searchQuarterYear]);

  useEffect(() => {

      // Map listAllUsers to create rows and update the state
      const updatedRows = listDataFromDB.map(item => createData(
          item.id,
          item.gop,
          item.ebm_koeffizient
      ));

      // Update the rows state
      setRows(updatedRows);

  }, [listDataFromDB]);

  /*listDataFromDB.map((item, index) => (
      //rows.push(createData((index+1), item.filename, item.fid))
      //createData('1','01/00','01100','GRÃœN','5','5','0,29','0,31','196,0','196,0','980,0','')
      rows.push(createData(item.id, item.lg, item.gop_snr, item.art, item.faele_gop, item.haeufigkeit_gop, item.haeufigkeit_praxis, item.haeufigkeit_verg, item.wertigkeit, item.wertigkeit_nach, item.gesamtvolumen))
  ));*/

  //const getDelPDFFile =  async () => { setDelPDFFile(await DatabaseService.delPDFFile(data)); }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'gop',
    numeric: false,
    label: 'GOP',
    disablePadding: true,
  },
  {
    id: 'ebm_koeffizient',
    numeric: false,
    label: 'EBM Koeffizient',
    disablePadding: true,
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{width: '0', padding: '0 0 0 0'}}>
          {/*<Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />*/}
          <Box sx={{padding: '1.5em 0 1.5em 1em'}}></Box>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        paddingRight: '16px!important', 
        display: { xs: 'block', sm: 'flex' },
        paddingTop: { xs: '8px', sm: '0' },
        paddingBottom: { xs: '14px', sm: '0' },
        flexDirection: 'column'
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          
          {/*Gemeinschaftspraxis*/}

        </Typography>
      )}


      {
        
      numSelected > 0 ? (<>
        <Tooltip title="Delete">
             {/*<Button color="error" variant="contained" onClick={onDelete}>
                <DeleteIcon />
            </Button>*/}
        </Tooltip>
        </>
      ) : (
            <>     
              <SelectOptionInput 
                value={searchQuarterState} 
                setValue={setSearchQuarterState} 
                label_name={"State"} 
                list={listQuarterState} 
              />
              
              <SelectOptionInput 
                value={searchQuarterName} 
                setValue={setSearchQuarterName} 
                label_name={"Quarter"} 
                list={listQuarterName} 
              />
                    
              <SelectOptionInput 
                value={searchQuarterYear} 
                setValue={setSearchQuarterYear} 
                label_name={"Year"} 
                list={listQuarterYear} 
              />

              <FileListSection fid={fid} file_name={file_name} quarter_state={searchQuarterState} handleFileList={handleFileList} handleClearFile={handleClearFile} />

              <br />
              <SearchInput setSearch={setSearch} setPage={setPage} search={search} />

          </> 
      )
      
      }
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/*Dialog Box*/
const LoadingContainer = styled('div')(({ theme }) =>
    theme.unstable_sx({
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center'
    }),
);

const [open, setOpen] = React.useState(false);
const [dialogInformation, setDialogInformation] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
/*Dialog Box*/

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);

    //console.log(newSelected);

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  const visibleRows = stableSort(rows, getComparator(order, orderBy));

  /*function deleteData_func(data){
    return DatabaseService.delHaeufigkeitsstatistikEachFileData(data)
  }

  const onDelete = (event) => {
    
    console.log(selected);
    
    setOpen(true);
    setDialogInformation("Deleting...");

    deleteData_func(selected)
         .then(response => {
             if (response.ok) {
                 return response.json();
             } else {
                 throw new Error('Failed to delete data');
             }
         })
         .then(result => {
             console.log(result);
             setOpen(false);
             // Update table data after successful deletion

             getEBMKoeffizientCompare(page, rowsPerPage, search, quarter_state, quarter_name, quarter_year);
             setSelected([]); // Clear selected array
         })
         .catch(error => {
             console.error('Error deleting data:', error);
             setOpen(false);
             // Handle error here, show message or take appropriate action
         });
  };*/

  /* Dialog Box */
  /*const handleClickOpen = () => {
    setOpen(true);
  };*/

  const handleClose = () => {
    //setOpen(false);
  };
  /* Dialog Box */

//Table

  //=========== Upload Files

  //==== Upload Files

    const LoadingTable = styled('div')(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            zIndex: 1,
            paddingTop: '2.7em'
        }),
    );

  
  return (
            <>
                  <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer sx={{ position: 'relative' }}>

                          {
                              loading ? (

                                  <LoadingTable>
                                      <div>
                                          <CircularProgress />
                                      </div>
                                  </LoadingTable>
                              ) : ""
                          }


                        <Table
                          sx={{ minWidth: 'unset' }}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody sx={{minWidth: 'unset'}}>
                          {
                              (visibleRows.length > 0) ?
                                  visibleRows.map((row, index) => {
                                  const isItemSelected = isSelected(row.id);
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      hover
                                      onClick={(event) => {/*handleClick(event, row.gop)*/}}
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.id}
                                      selected={isItemSelected}
                                      sx={{ cursor: 'pointer' }}
                                      className='checkboxId'
                                      data-id={row.id}
                                    >
                                      <TableCell padding="checkbox" sx={{width: '0', padding: '0 0 0 0'}}>
                                        {/*<Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                        />*/}
                                        <Box sx={{padding: '1.5em 0 1.5em 17px'}}></Box>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                        sx={{paddingRight: '1em'}}
                                      >
                                        {row.gop}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                        sx={{paddingRight: '1em'}}
                                      >
                                        {row.ebm_koeffizient}
                                      </TableCell>
                                    </TableRow>
                                      );
                                  })
                                : loading ? (
                                          <TableRow
                                              style={{
                                                  height: '120px',
                                              }}
                                          ><TableCell colSpan={6} />
                                          </TableRow>
                                    ) : null
                          }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 200]}
                        component="div"
                        count={parseInt(totalRowsCount)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </Paper>
                    <FormControlLabel
                      control={<Switch checked={dense} onChange={handleChangeDense} />}
                      label="Dense padding"
                    />
                  </Box>

                  <DialogFileListComponent
                      openFileListDialog={openFileListDialog}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      handleCloseFileList={handleCloseFileList}
                      handleToggleFileList={handleToggleFileList}
                      subcategories_id={subcategoriesID}
                  />

                  <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                  >
                      {/*<DialogTitle id="responsive-dialog-title">
                          "Use Google's location service?"
                      </DialogTitle>*/}
                      <DialogContent>
                            {/*<DialogContentText>*/}
                             {/*Let Google help apps determine location. This means sending anonymous
                              location data to Google, even when no apps are running.*/}

                              <LoadingContainer>
                                    <CircularProgress sx={{mr:2}} /> {dialogInformation}
                              </LoadingContainer>

                            {/*</DialogContentText>*/}
                      </DialogContent>
                        {/*<DialogActions>
                          <Button autoFocus onClick={handleClose}>
                              Disagree
                          </Button>
                          <Button onClick={handleClose} autoFocus>
                              Agree
                          </Button>
                      </DialogActions>*/}
                  </Dialog>
            </>
          );
}