import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { dangerouslySetInnerHTML } from 'react-dom';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  textTransform: 'none',
  fontSize: '20px',
  padding: '0.3em 1em'
}));

const LightTypographyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'BR Firma Light', 
  lineHeight: '1.8em'
}));



export const CardComponent = (props) => {
    
    const {title, description, buttonName, buttonLink, onClick, bgColor="#006400", openInNewTab = false } = props;

    const StyledCard = styled(Card)(({ theme }) => ({
      backgroundColor: bgColor, // Dark green background
      color: 'white',
      borderRadius: '16px',
      padding: theme.spacing(3),
      zIndex: 1,
      position: 'relative'
    }));

    return (
      <StyledCard>
        <CardContent>
          <Typography variant="h4" component="h2" gutterBottom sx={{mb: (description !== "") ? '0.35em' : 0 }}>
            {title}
          </Typography>
          
          {
            (description !== "") ? <Box sx={{ mb: 2 }}>
                                      <LightTypographyText variant="p" paragraph sx={{fontWeight: '300'}}>
                                        <div dangerouslySetInnerHTML={{ __html: description }} />              
                                      </LightTypographyText>
                                    </Box> : (buttonName !== "") ? (<br/>) : ""
          }

          {
            (buttonName !== "") ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                      <StyledButton variant="contained" component={NavLink} to={buttonLink} target={openInNewTab ? "_blank" : "_self"}>
                                        {buttonName}
                                      </StyledButton>
                                  </Box> : ""
          }

        </CardContent>
      </StyledCard>
    );
}