import { excludedGops } from "../utils/exclude_gops_in_potenzial_included_count";

function countGesamtpotenzialIncluded(rows, debug, inputCalculationData, excludedGOP = false){
    let gesamtpotenzial_included = 0;
    
    let testListgesamtpotenzial_included = "gop,gesamtpotenzial_included\n";

    rows.forEach((row, index) => {
        const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        //const idealNumberOfTreatments = (row.exclude_scheine === 1) ? parseFloat(row.adjusted_ideal_number_of_treatments).toFixed(0) : parseFloat(row.ideal_number_of_treatments).toFixed(0);

        if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
            if (parseInt(row.include_chart) === 1) {
              
              if(excludedGOP && excludedGops.includes(row.gop)){
                if(excludedGops.includes(row.gop)){
                  return;
                }
              }

              if(row.euro_potencial_mutterschaftsvorsorge_ziffern !== 0){
                gesamtpotenzial_included += row.euro_potencial_mutterschaftsvorsorge_ziffern;

                if(debug){
                  testListgesamtpotenzial_included += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                }
                
              }else{
                gesamtpotenzial_included += row.euro_potencial;

                if(debug){
                  testListgesamtpotenzial_included += row.gop + "," + row.euro_potencial + "\n";
                }

              }

                /*if (typeof row.adjusted_euro_potencial === 'undefined') {
                  gesamtpotenzial_included += parseFloat(row.euro_potencial);
                } else {
                  gesamtpotenzial_included += parseFloat(row.adjusted_euro_potencial);
                }*/

            } else {
                gesamtpotenzial_included += 0;

                /*if(debug){
                  testListgesamtpotenzial_included += row.gop + "," + 0 + "\n";
                }*/
            }
        } else {
           gesamtpotenzial_included += 0;

            /*if(debug){
              testListgesamtpotenzial_included += row.gop + "," + 0 + "\n";
            }*/
        }
    });

    /*if(debug){
      console.log("testListgesamtpotenzial_included")
      console.log(testListgesamtpotenzial_included)
    }*/

    return gesamtpotenzial_included;
}

export default countGesamtpotenzialIncluded;