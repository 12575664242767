export const userSalutationList = [
    { id: '', title: 'keine Angabe' },
    { id: 'frau', title: 'Frau' },
    { id: 'herr', title: 'Herr' },
];
  
export const userTitleList = [
    { id: '', title: 'keine Angabe' },
    { id: 'drMed', title: 'Dr. med.' },
    { id: 'diplMed', title: 'Dipl. -Med.' },
    { id: 'drDrMed', title: 'Dr. Dr. med.' },
    { id: 'pdDrMed', title: 'PD Dr. med.' },
    { id: 'profDrMed', title: 'Prof. Dr. med.' },
  ];
  