import { Box } from "@mui/material";
import { styled } from "@mui/system";
import LockIcon from '@mui/icons-material/Lock';
import Button from "@mui/material/Button";
import { CardComponent } from "./CardComponent";

const MembershipBox = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        height: '100%',
        position: 'absolute'
    }),
);

const Background = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        color: 'white',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top:0,
        zIndex: 0
    }),
);

const ContentBox = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        position: 'relative',
        zIndex: 10,
        margin: '0 auto',
        width: '700px',
        marginTop: '43px'
    }),
);

const LockIconStyle = styled(LockIcon)(({ theme }) =>
    theme.unstable_sx({
        width: '204px',
        height: '204px'
    })
);

const ButtonStyle = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        color: '#616568',
        
    })
);

/*
export const MembershipComponent = (props) => {
    const { content, userStatus } = props;

    return <>{ 
                (userStatus) ? 
                  <></>      
                : <>
                    <MembershipBox>
                        <ContentBox>{ content }</ContentBox>
                        <Background />
                    </MembershipBox>
                  </> 
            }</>  
}*/

export const ContentComponent = (props) => {
    const { content, userStatus } = props;

    return <>{ 
                (userStatus === 1) 
                    ? content 
                    : <><Box sx={{filter: 'blur(5px)'}}>
                            <Box sx={{ backgroundColor: 'rgba(223, 233, 233, 0.3)' }}>0000</Box>
                        </Box></> 
            }</>  
}

const LockComponent = (props) => {
    const { content, onClick } = props;

    /*return <>
        <Box sx={{ backgroundColor: 'rgba(223, 233, 233, 0.3)' }}>{ content }</Box>
    </>*/

    return <>
                <Box sx={{textAlign: 'center'}}>
                    <ButtonStyle onClick={onClick} ><LockIconStyle /></ButtonStyle>
                </Box>
            </>
}

const AnalysisInfoComponent = (props) => {
    const { content } = props;

    return <>
                <CardComponent 
                    title="Werden Sie volles Mitglied und schalten Sie alle Funktionen unseres EBM-Abrechnungskompasses frei!" 
                    description={
                                    `Der Zugang in die Welt von GynSuccess<br />
                                    <ul>
                                    <li>Unser Leistungsversprechen: Ihr möglicher Mehrumsatz (EBM, GOÄ) gleicht Ihre 6 Monats-Einstiegsmitgliedschaft mindestens aus. Sie gehen kein finanzielles Risiko ein!</li>
                                    <li>Einmaliges Starterpaket: Analyse, Gutachten, persönliche Beratung und Coaching</li>
                                    <li>Live Teilnahme an GynSuccess Webinaren</li>
                                    <li>Voller Zugriff auf alle Bonusinhalte</li>
                                    <li>Monatlicher Boxenstopp: persönlicher Coachingtermin – Ihre Ziele sind Programm – passgenau und nutzbringend</li>
                                    <li>Ab dem 6. Monat jederzeit monatlich kündbar</li>
                                    </ul>
                                `} 
                    buttonName="Jetzt Mitglied werden" 
                    buttonLink="https://www.gynsuccess.de/mitglied-werden/" 
                    onClick={() => {}} 
                    bgColor="#12401F" 
                    openInNewTab={true}
                />
            </>


}

const MembershipComponent = (props) => {
    const { userStatus, onClick } = props;
    
    let content = <></>

    if(userStatus === 2) {
        content = <LockComponent content={content} onClick={onClick} />  
    }

    return (<>
                {
                    (userStatus !== 1)?
                        <MembershipBox>
                            <ContentBox>
                                {/*<CardComponent content={content} onClick={onClick} />*/}
                                
                                <Box>
                                    {content}
                                </Box>

                            </ContentBox>
                            <Background />
                        </MembershipBox> : <></>
                }
            </>)

}

export const MembershipStatus = (props) => {
    
    const { content, userStatus, onClick } = props;

    return (<>
        <MembershipComponent userStatus={userStatus} onClick={onClick} />
    </>)
}

export const MembershipStatusDanke = (props) => {

    const { userStatus, onClick } = props;

    const bookingLinkForFullMembers = "https://outlook.office365.com/owa/calendar/GynSuccess@gynsuccess.de/bookings/s/t7m_re7Tnk-ApZC-gNdINw2";

    const bookingLinkForTestUsers = "https://outlook.office365.com/owa/calendar/GynSuccess@gynsuccess.de/bookings/s/y8aJWxj3KkS0c4kPZPfqgQ2";

    let content = <></>;

    content = <>
                <CardComponent 
                    title="Vielen Dank für die Eingabe Ihrer Daten! Bitte vereinbaren Sie ein Beratungsgespräch mit uns, bei dem wir gemeinsam Ihre Daten analysieren und auswerten können." 
                    description="" 
                    buttonName="Jetzt Beratungsgespräch vereinbaren!" 
                    buttonLink={(userStatus === 3) ? bookingLinkForFullMembers : bookingLinkForTestUsers}
                    onClick="" 
                    bgColor="#12401F" 
                    openInNewTab={true}
                />
                <br/>
              </>

    return (<>
        {
            (userStatus === 3 || userStatus === 2) ? content : ""
        }
    </>)
}