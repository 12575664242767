class DebugService {
    constructor() {
        this.debugMode = this.isDebugMode();
    }

    isDebugMode() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('debug') === 'true';
    }

    log(message) {
        if (this.debugMode) {
            console.log(message);
        }
    }
}

const debugService = new DebugService();

export default debugService;