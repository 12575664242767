import React, { useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, TableSortLabel, Toolbar, Typography } from '@mui/material';

const ViewCONData = (props) => {

    const { data } = props;

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const filteredData = data.filter(item => 
        item.id.toLowerCase().includes(search.toLowerCase()) || 
        item.occurrences.toString().includes(search)
    );

    const sortedData = filteredData.sort((a, b) => {
        if (orderBy === 'id') {
            const aId = isNaN(a.id) ? a.id : parseInt(a.id, 10);
            const bId = isNaN(b.id) ? b.id : parseInt(b.id, 10);
            return (order === 'asc' ? aId - bId : bId - aId);
        } else {
            return (order === 'asc' ? a.occurrences - b.occurrences : b.occurrences - a.occurrences);
        }
    });

    const visibleRows = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ width: '100%' }}>
             <Paper sx={{ maxWidth: '500px', mb: 2, margin: '0 auto' }}>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        paddingRight: '16px!important',
                        display: { xs: 'block', sm: 'flex' },
                        paddingTop: { xs: '8px', sm: '0' },
                        paddingBottom: { xs: '14px', sm: '0' },
                    }}
                >
                    <Typography
                        sx={{ flex: '0 13 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        CON-Daten
                    </Typography>
                    <TextField
                        label="Suchen"
                        variant="outlined"
                        value={search}
                        size="small"
                        onChange={handleSearchChange}
                    />
                </Toolbar>
                <TableContainer sx={{ position: 'relative' }}>
                    <Table aria-labelledby="tableTitle" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={orderBy === 'id' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'id'}
                                        direction={orderBy === 'id' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'id')}
                                    >
                                        EBM-Ziffer
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={orderBy === 'occurrences' ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === 'occurrences'}
                                        direction={orderBy === 'occurrences' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'occurrences')}
                                    >
                                        Zählen
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows.length > 0 ? visibleRows.map((item) => (
                                <TableRow hover key={item.id}>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.occurrences}</TableCell>
                                </TableRow>
                            )) : (
                                <TableRow style={{ height: '120px' }}>
                                    <TableCell colSpan={2} align="center">
                                        Keine Daten gefunden
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 200]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Zeilen pro Seite"
                />
            </Paper>
        </Box>
    );
};

export default ViewCONData;