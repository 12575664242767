import React, { useEffect, useState, createRef, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import * as DatabaseService from '../../services/DatabaseService';
import MuiDrawer from '../../components/MuiDrawer';
// Table Libraries
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
// Table Libraries

import TextField from '@mui/material/TextField';

import germanFormatCurrency from '../../utils/germanFormatCurrency';

import filterAndSortRows from './utils/sortFilteredRows';

import { IconButton, Autocomplete, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import TableSeparateStyle from './styles/TableSeparateStyle';

import calculateFieldWithFactor from './calculations/calculateFieldWithFactor';
import GOAE_Factor_Table_Result from './components/GOAE_Factor_Table_Result';
function GOAE_FactorTable(props) {

    const { 
                    rows1, 
                    title1, 
                    title2
    } = props;
    
    const [fields, setFields] = useState(Array(15).fill({ ziffer: '', anzahl: 1, faktor: 1, hasTyped: false }));
    const [showFactorAndAmount, setShowFactorAndAmount] = useState(false);
    const exemptionNumbers = ['298', '420']; // Replace with actual exemption GOÄ numbers
    const [hasTyped, setHasTyped] = useState(false);
    
    const handleInputChange = (index, event) => {
        const newFields = [...fields];
        newFields[index] = { ...newFields[index], ziffer: event.target.value };
        setFields(newFields);
    };
    
    const handleSelectChange = (index, event) => {
        const newFields = [...fields];
        newFields[index] = { ...newFields[index], anzahl: event.target.value };
        setFields(newFields);
    };

    const handleInputFactorChange = (index, event) => {
        let value = event.target.value;
        const regex = /^[0-9,.]*$/;
    
        if (regex.test(value)) {
            value = value.replace(/,/g, '.').replace(/\./g, ','); // Replace commas with dots and dots with commas
            const newFields = [...fields];
            newFields[index] = { 
                ...newFields[index], 
                faktor: newFields[index].hasTyped ? value : value.replace(/^1+/, ''), 
                hasTyped: true 
            };
        
            setFields(newFields);
        }
    };

    const addInputField = () => {
        setFields([...fields, { ziffer: '', anzahl: 1, faktor: 1, hasTyped: false }]);
    };

    const removeInputField = (index) => {
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
    };

    const calculateAmount = () => {
        setShowFactorAndAmount(true);
    };

    //console.log(fields);

    const options = [1, 2, 3]; // Define your options array here
 
    return (<>
        <Grid container spacing={2}>
            <Grid item xs={6} sx={{position: 'relative'}}>
                <TableSeparateStyle>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="simple table">
                            <TableHead>
                                {(title1 !== "" && title2 !== "") && (
                                    <TableRow>
                                        <TableCell className="TableCell">{title1}</TableCell>
                                        <TableCell className="TableCell">{title2}</TableCell>
                                        <TableCell className="TableCell">Faktor</TableCell>
                                        <TableCell className="TableCell">Betrag</TableCell>
                                        <TableCell className="TableCell">Aktion</TableCell>
                                    </TableRow>
                                )}
                            </TableHead>
                            <TableBody>
                                {fields.map((field, index) => {
                                        const tableRows = [];
                                        tableRows.push(
                                            <TableRow key={index}>
                                                <TableCell width={"30%"} className="TableCell">
                                                    <Autocomplete
                                                        options={rows1.map(row => row.ziffer)}
                                                        getOptionLabel={(option) => option}
                                                        value={field.ziffer || null}
                                                        onChange={(event, newValue) => handleInputChange(index, { target: { value: newValue } })}
                                                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" fullWidth />}
                                                    />
                                                </TableCell>
                                                <TableCell width={"30%"} className="TableCell">
                                                    <Select
                                                        value={field.anzahl}
                                                        onChange={(event) => handleSelectChange(index, { target: { value: event.target.value } })}
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        sx={{ textAlign: 'center' }}>
                                                        {options.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell width={"20%"} className="TableCell">
                                                    <TextField
                                                        value={field.faktor}
                                                        onChange={(event) => handleInputFactorChange(index, event)}
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        InputProps={{
                                                            inputProps: {
                                                                style: { textAlign: 'center' }
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell width={"20%"} className="TableCell">{germanFormatCurrency(calculateFieldWithFactor(rows1, field))}</TableCell>
                                                <TableCell width={"10%"} className="TableCell">
                                                    <IconButton variant="contained" onClick={() => removeInputField(index)}><DeleteIcon /></IconButton>
                                                </TableCell>
                                            </TableRow>)
                                        if (index === fields.length - 1) {
                                            const totalBetrag = fields.reduce((acc, field) => acc + calculateFieldWithFactor(rows1, field), 0);
                                            tableRows.push(
                                                <TableRow key={`total-${index}`}>
                                                    <TableCell className="TableCell" sx={{borderRight:"0!important"}}><Box sx={{p:'17px'}}></Box></TableCell>
                                                    <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                                    <TableCell className="TableCell" sx={{borderLeft:"0!important", borderRight:"0!important"}}></TableCell>
                                                    <TableCell className="TableCell">{germanFormatCurrency(totalBetrag)}</TableCell>
                                                    <TableCell className="TableCell"></TableCell>
                                                </TableRow>);
                                        }
                                        return tableRows;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableSeparateStyle>
            </Grid>
            {showFactorAndAmount && (
                <Grid item xs={6} sx={{position: 'relative'}}>
                    <GOAE_Factor_Table_Result />
                </Grid>
            )}
        </Grid>
        <br />
        
        <Button variant="contained" onClick={()=>{ addInputField() }}>+ Eingabefeld hinzufügen</Button> &nbsp;
        <Button variant="contained" onClick={()=>{ calculateAmount() }}>Mit optimaler Abrechnung vergleichen</Button>

    </>);
}

function FieldWithFactor(props) {
  
  const { 
          rows, 
          debug
        } = props;


  const [rows1, setRows1] = useState([]);

  useEffect(() => {

    const filteredRows = filterAndSortRows(rows);

    setRows1(filteredRows); 

  }, [rows]);

  return (
    <>
        <Typography variant="h5" component="h1" gutterBottom sx={{color: '#014017'}}>
            Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor bei der Schwangerschaftserstuntersuchung ein:  
        </Typography>

        <br />

        
        <GOAE_FactorTable 
            rows1={rows1}
            setRows1={setRows1}
            title1="GOÄ-Ziffer"
            title2="Anzahl"
        />
         
    </>
  );
}

export default function GOÄ_Abrechnungskompass() {

  function createData(
                      id,
                      ziffer,
                      coefficient, 
                      factor,
                      title,
                      description,
                      punktzahl,
                      main_value
                    ) {
    return {
        id,
        ziffer,
        coefficient,
        factor,
        title,
        description,
        punktzahl,
        main_value
    };
  }

  const location = useLocation();

  const [debug, setDebug] = useState(false);

  const [listDataFromDB, setRowDataFromDB] = useState([]);

  const [rows, setRows] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const getGOÄ_ZifferAbrechnungskompassData = async () => {

    //console.log("inputCalculationData")
    //console.log(inputCalculationData)

    try {
        const JSONData = await DatabaseService.getGOÄ_Abrechnungskompass();
        if (JSONData && JSONData.rows) {
          setRowDataFromDB(JSONData.rows); // Set the user data array to listAllUsers
        }

        if (JSONData && JSONData.totalCount) {
            setTotalRowsCount(JSONData.totalCount); // Set the user data array to listAllUsers
            //setRowsPerPage(JSONData.totalCount)
        }

    } catch (error) {
        console.error('Error fetching data:', error);
    }

    //setLoading(false); // Set loading to false after data is fetched
  }

  useEffect(() => {
    
      getGOÄ_ZifferAbrechnungskompassData()
        .then(() => {})
        .catch(error => {
            console.log('Error fetching data:', error);
        });
    
  }, []);

  useEffect(() => {
    
    // Map listAllUsers to create rows and update the state
    const updatedRows = listDataFromDB.map(item => createData(
        item.id,
        item.ziffer,
        item.coefficient,
        item.factor,
        item.title,
        item.description,
        item.punktzahl,
        item.main_value
    ));

    // Update the rows state
    setRows(updatedRows);

  }, [listDataFromDB]);

  useEffect(() => {

    if(new URLSearchParams(location.search).get('debug') !== null) {
      setDebug(true);  
    }

  }, [])

  return (
            <MuiDrawer>
                  
                  <FieldWithFactor 
                        rows={rows} 
                        debug={debug}
                   />
        
            </MuiDrawer>
          );
}