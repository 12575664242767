function calculateTotalNoTrapPotenzialAndGenutztesPotenzial(ihrpotenzial_notrap, gesamtpotenzial_notrap) {
    
    let ungenutztesPotenzial = parseFloat(ihrpotenzial_notrap.toFixed(2));
      
    let genutztesPotenzial = parseFloat(gesamtpotenzial_notrap - ihrpotenzial_notrap.toFixed(2));

    genutztesPotenzial = parseFloat(genutztesPotenzial).toFixed(2);

    /*if(debug){
      console.log("calculateTotalNoTrapPotenzialAndGenutztesPotenzial")
      console.log("ihrpotenzial_notrap " + ihrpotenzial_notrap)
      console.log("gesamtpotenzial_notrap " + gesamtpotenzial_notrap)
    }*/

    return {
      ungenutztesPotenzial,
      genutztesPotenzial
    }
}

export default calculateTotalNoTrapPotenzialAndGenutztesPotenzial;