import { styled } from '@mui/material';

const Container = styled('div')(({ theme }) =>
	theme.unstable_sx({
		maxWidth: "992px",
		margin: "0 auto",
		"& img.MuiCardMedia-media": {
			transform: "translateZ(1px)"
		}
	}),
);

export default Container;