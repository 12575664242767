export default function filterAndSortInputRows(rows, filterCriteria){
    return rows?.filter((row) => filterCriteria.includes(row.ziffer)) 
      .sort((a, b) => {
        const aIsNumber = !isNaN(a.ziffer);
        const bIsNumber = !isNaN(b.ziffer);
        
        if (aIsNumber && bIsNumber) {
          return a.ziffer - b.ziffer;
        }
        if (aIsNumber) return -1;
        if (bIsNumber) return 1;
  
        return a.ziffer.localeCompare(b.ziffer);
      });
  };