import React from 'react';
/*import {
    compose,
    spacing,
    palette,
    styleFunctionSx,
    ThemeProvider
  } from "@mui/system";*/
import { AppBar, styled, createTheme } from '@mui/material';
import { ListItem, ListItemText, Paper } from '@mui/material';
import { css } from '@emotion/react';
///import { ListItem, ListItemText, Paper } from '@material-ui/core';
///import AppBar from '@material-ui/core/AppBar';
//import Videofile from '../../components/assets/videos/video_x264_003.mp4';
import BGimg from '../../components/assets/images/background.svg';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';

const UserFormWrap = styled('div')({
    position: 'absolute',
    left:0,
    top:0,
    width: '100%',
    display: 'flex',
    minHeight: '100%'
  });

const UserForm = styled('div')(({ theme }) =>
    theme.unstable_sx({
        padding: theme.spacing(2),
        maxWidth: "550px",
        margin: "0 auto",
        display: "flex",
        alignItems: "center",
        verticalAlign: "middle",
        '& .userFormContainer':{
            position: "relative",
        },
        '& .MuiFormControl-root':{
            width: '100%'
        },
        '& .MuiButtonBase-root:not(.MuiRadio-root):not(.MuiCheckbox-root):not(.MuiIconButton-root)':{
            width: '100%',
            margin: "0 0"
        },
        '& .MuiTypography-root':{
            paddingBottom: "6px",
            paddingTop: "4px"
        },
        '& .MuiButton-containedSizeLarge':{
            padding: '15px 22px'
        },
        '& .MuiAlert-root':{
            marginTop: theme.spacing(2)
        },
    }),
);

const StyleTab = styled('div')(({ theme }) =>
    theme.unstable_sx({
        '& .MuiBox-root':{
            padding: theme.spacing(3),
        }
    }),
);

const FormMenus = styled('div')(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        '& a':{
            display: 'flex',
            width: '100%'
        },
        '& a.active .MuiListItem-root:before':{
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '3px',
            background: '#7A2856',
            left: 0,
            bottom: 0,
        },
        '& a.currentPage .MuiButtonBase-root:before':{
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '3px',
            background: '#7A2856',
            left: 0,
            bottom: 0,
        },
        '& a:active, a':{
            color: '#fff',
            textDecoration: 'none'
        },
        '& .MuiListItemText-root .MuiTypography-root':{
            paddingTop: 0,
            paddingBottom: 0,
            whiteSpace: 'nowrap'
        },
        '& .MuiButtonBase-root':{
            //minWidth: '160px',
            width: '100%',
            textAlign: 'center'
        },
        '& .MuiButtonBase-root:hover':{
            backgroundColor: 'transparent'
        }
    }),
);

const LoginBackground = styled('div')(({ theme }) =>
    theme.unstable_sx({
        position: "fixed",
        right: 0,
        bottom: 0,
        minWidth: "100%",
        minHeight: "100%",
        
        
        '&:before':{
            content: '""',
            width: "100%",
            height: "100%",
            backgroundColor: "#5d5d5dc2",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1
        },
        '& .bgImage':{
            backgroundImage: `url(${BGimg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: 'center',
            filter: "blur(19px)",
            height: "100%",
            width: "100%",
            position: 'absolute'
        }
    }),
);



export default function LoginSignup(props) {

    return ( 
        <>
            <div>
                <LoginBackground>
                    {/* <video autoPlay={true} muted loop >
                        <source src={Videofile} type="video/mp4" />
                    </video> */}
                    <div className="bgImage"></div>  
                </LoginBackground>

                <UserFormWrap>
                    <UserForm>
                        <Paper className="userFormContainer" component="div">
                            <StyleTab>
                                <AppBar color="primaryGreen" position="static">
                                    <FormMenus>
                                        {!props.header && (
                                            <NavLink to="/login" exact="true">
                                                <ListItem> 
                                                    <ListItemText primary="Anmelden" />
                                                </ListItem>
                                            </NavLink>
                                        )}
                                        {!props.header && (
                                            <NavLink to="/signup" exact="true">
                                                <ListItem>
                                                    <ListItemText primary="Registrieren" />
                                                </ListItem>
                                            </NavLink>
                                        )}
                                        {props.header && props.header}
                                    </FormMenus>
                                </AppBar>
                                <Box>
                                    { props.children }
                                </Box>
                            </StyleTab>
                        </Paper>
                    </UserForm>
                </UserFormWrap>
            </div>
        </>
    );
}