import React, { useEffect, useState, createRef } from 'react';

import { useNavigate } from 'react-router-dom';

//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';

import TablePaginationActions from '../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../components/SearchInput';

import MuiDrawer from '../../components/MuiDrawer';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';

import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
/* End of Dialog Box */

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// Table Libraries

import Controls from '../../components/controls/Controls';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Spreadsheet, {
  createFormulaParser,
  Matrix,
  CellBase,
} from "react-spreadsheet";

import { ConstructionOutlined, ContactlessOutlined } from '@mui/icons-material';

const SpreadsheetDiv = styled('div')(({ theme }) =>
    theme.unstable_sx({
        overflow: 'auto',
        zIndex: '99999',
        height: 'calc(100vh - 206px)',
    }),
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EBM_Spreadsheet() {
  
    function createData(id, id_number, anzahl, soll, abweichung, abweichung_percent, median, score, potential) {
      return {
        id,
        id_number,
        anzahl,
        soll,
        abweichung,
        abweichung_percent,
        median,
        score,
        potential
      };
    }

    const [listAllKundenData, setKundenData] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [loading, setLoading] = useState(false); // State to manage loading indication
    const [search, setSearch] = React.useState("");

    const [listAllKundenDataSpreadsheet, setKundeDataSpreadsheet] = useState([]);

    //Spreadsheet 
    const columnLabels = ["Ziffer", "Anzahl"];
    //const rowLabels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

    let dataArray = [{ value: "" }, { value: "" }];

    const [data, setSpreadsheetData] = useState([
      dataArray,
      dataArray,
    ]);

    const [scheine, setScheine] = useState(0);

    const [alldata, setAllData] = useState({datatable: [], scheine: 0}); //This will be inserted to the database
    
    const [alert, setAlert] = useState({ text: '', appear: false, severity: '' });

    useEffect(() => {
        setAllData({ ...alldata, datatable: processArray(data), scheine: scheine });
    }, [data]);

    useEffect(() => {
      setAllData({ ...alldata, datatable: processArray(data), scheine: scheine });
    }, [scheine]);

     useEffect(() => {
      
      //getAllBerechnungsdatenBayernDataSpreadsheet(totalRowsCount)

      setSpreadsheetData(listAllKundenDataSpreadsheet);


    }, [listAllKundenDataSpreadsheet]);
    //End of Spreadsheet

    // Fetch data and update the state
    const getKundeData = async (page, rowsPerPage, search) => {
      try{

        const kundeData = await DatabaseService.getKundeData((page + 1), rowsPerPage, search);

        if(kundeData && kundeData.rows){
          setKundenData(kundeData.rows);
        }

        if(kundeData && kundeData.scheine){
          setScheine(kundeData.scheine);
        }

        if(kundeData && kundeData.totalCount){
            setTotalRowsCount(kundeData.totalCount);
        }

      }catch(error){
        console.error('Error fetching data:', error);
      }
    }
    
    const getAllKundeDataSpreadsheet = async (rowsPerPage) => {
      try{

        const kundeDataSpreadsheet = await DatabaseService.getKundeDataSpreadsheet(rowsPerPage);

        if(kundeDataSpreadsheet && kundeDataSpreadsheet.rows){
          setKundeDataSpreadsheet(kundeDataSpreadsheet.rows);
        }

        if(kundeDataSpreadsheet && kundeDataSpreadsheet.scheine){
          setScheine(kundeDataSpreadsheet.scheine);
        }

      }catch(error){
        console.error('Error fetching data:', error);
      }
    }

    useEffect(() => {

      setLoading(true); // Set loading to true when fetching data

      getKundeData(page, rowsPerPage, search)
          .then(() => setLoading(false))
          .catch(error => {   
              console.error('Error fetching data:', error);
              setLoading(false);
          });

    }, [page, rowsPerPage, search]);

    useEffect(() => {
      // Map listAllKundenData to create rows and update the state
      const updatedRows = listAllKundenData.map(item => createData(
        item.id,
        item.id_number,
        item.anzahl,
        item.soll,
        item.abweichung,
        item.abweichung_percent,
        item.median,
        item.score,
        item.potential
      ));

      // Update the rows state
      setRows(updatedRows);

    }, [listAllKundenData]); // Watch for changes in listAllKundenData

    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
          return -1;
      }
      if (b[orderBy] > a[orderBy]) {
          return 1;
      }
      return 0;
    }

    function getComparator(order, orderBy) {
      return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) {
              return order;
          }
          return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

    const headCells = [
      {
          id: 'id_number',
          numeric: false,
          disablePadding: true,
          label: 'Ziffer',
      },
      {
          id: 'anzahl',
          numeric: false,
          disablePadding: true,
          label: 'Anzahl',
      },
      {
          id: 'soll',
          numeric: false,
          disablePadding: true,
          label: 'Soll',
      },
      {
          id: 'abweichung',
          numeric: false,
          disablePadding: true,
          label: 'Abweichung',
      },
      {
          id: 'abweichung_percent',
          numeric: false,
          disablePadding: true,
          label: 'Abweichung %',
      },
      {
          id: 'median',
          numeric: false,
          disablePadding: true,
          label: 'Median',
      },
      {
          id: 'score',
          numeric: false,
          disablePadding: true,
          label: '',
      },
      {
          id: 'potential',
          numeric: false,
          disablePadding: true,
          label: '€-Potential',
      }
    ];

    function EnhancedTableHead(props) {
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
          props;
      const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
      };

      return (
          <TableHead>
              <TableRow>
                  <TableCell padding="checkbox" sx={{width: '0', padding: '0 0 0 0'}}>
                      {/*<Checkbox
                          color="primary"
                          indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={rowCount > 0 && numSelected === rowCount}
                          onChange={onSelectAllClick}
                          inputProps={{
                              'aria-label': 'select all desserts',
                          }}
                      />*/}
                      <Box sx={{padding: '1.5em 0 1.5em 1em'}}></Box>
                  </TableCell>
                  {headCells.map((headCell) => (
                      <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? 'right' : 'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection={orderBy === headCell.id ? order : false}
                      >
                          <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? order : 'asc'}
                              onClick={createSortHandler(headCell.id)}
                          >
                              {headCell.label}
                              {orderBy === headCell.id ? (
                                  <Box component="span" sx={visuallyHidden}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </Box>
                              ) : null}
                          </TableSortLabel>
                      </TableCell>
                  ))}
              </TableRow>
          </TableHead>
      );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected, scheine } = props;
    
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                paddingRight: '16px!important',
                display: { xs: 'block', sm: 'flex' },
                paddingTop: { xs: '8px', sm: '0' },
                paddingBottom: { xs: '14px', sm: '0' },
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
              <>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                        <Box sx={{paddingTop:'15px'}}>
                          Edit Data <Button variant="outlined" onClick={handleClickOpenSpreadsheet}><EditIcon /></Button>
                        </Box>
                        <Box sx={{padding:'10px 0 10px 0'}}>
                          <Typography>Scheine: {scheine}</Typography>
                        </Box>
                </Typography>
                
              </>
            )}


            {

                numSelected > 0 ? (<>
                    
                    <Tooltip title="Delete">
                        {/*<Button color="error" variant="contained" onClick={onDelete}>
                            <DeleteIcon />
                        </Button>*/}
                    </Tooltip>
                    
                </>
                ) : (
                    <>
                        <Box sx={{width:'10px', padding: { xs: '3px', sm: '0' } }}></Box>
                        <SearchInput setSearch={setSearch} setPage={setPage} search={search} />

                            {/*<Tooltip title="Filter list">
                          <IconButton>
                              <FilterListIcon />
                          </IconButton>
                      </Tooltip>*/}
                    </>
                )
            }
        </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);

  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    
    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
    }
    setSelected(newSelected);
    
  };

  const handleChangePage = (event, newPage) => {
    //console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = stableSort(rows, getComparator(order, orderBy));

    /*const getUsersData = async (page, rowsPerPage, search) => {

      //const usersData = await DatabaseService.getAllUsersData();
      //setUsersData(usersData);

      try {
          const usersData = await DatabaseService.getAllUsersData((page + 1), rowsPerPage, search);
          if (usersData && usersData.rows) {
              setUsersData(usersData.rows); // Set the user data array to listAllUsers
          }

          if (usersData && usersData.totalCount) {
              setTotalRowsCount(usersData.totalCount); // Set the user data array to listAllUsers
          }

          /*if (usersData && usersData.search) {
              setSearchInput(usersData.search); // Set the user data array to listAllUsers
          }*/

    /*  } catch (error) {
          console.error('Error fetching user data:', error);
      }

      //setLoading(false); // Set loading to false after data is fetched
    }*/

    /*Dialog Box*/
    const LoadingContainer = styled('div')(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }),
    );

    const [open, setOpen] = React.useState(false); //Fullscreen Dialog
    const [openDialogLoading, setOpenDialogLoading] = React.useState(false);
    const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });

    const DialogOutput = ({ fullScreen, open, dialogData, handleClose, handleRedirectToDataList }) => {

      const DialogAllContent = ({ dialogData }) => {
          if (dialogData.status === "loading") {
              return (<>
                  <DialogContent>
                      <LoadingContainer> <CircularProgress sx={{ mr: 2 }} /> {dialogData.information} </LoadingContainer>
                  </DialogContent>
              </>);
          } 
      }

      return (
        <>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
          >

            <DialogAllContent dialogData={dialogData} />
                  
          </Dialog>
        </>
      );
    };
    /* Dialog Box */

    const handleClickOpenSpreadsheet = () => { //Button to open Edit Data Fullscreen spreadsheet
      
      setDialogInformation({ title: "", information: "Processing...", status: "loading" });
      setOpenDialogLoading(true);

      getAllKundeDataSpreadsheet(totalRowsCount)
        .then(result => {
            setOpenDialogLoading(false);             
        })
        .catch(error => {
            console.error('Error retrieving data:', error);
            setOpen(false);
            // Handle error here, show message or take appropriate action
        });

      //console.log("listAllKunde");
      //console.log(listAllKunde);

      setOpen(true);

    };

    const handleClose = () => {
        getKundeData(page, rowsPerPage, search);
        setOpen(false);
    };

    /* Dialog Box */

    /*Alert*/
    const handleCloseAlert = (event, reason) => {

      if (reason === 'clickaway') {
        return;
      }

      setAlert({ ...alert, appear: false });
  };
    /*End of Alert*/

    //Table

    function insertKunde_func(data) {
      return DatabaseService.insertKunde(data);
    }

    async function onInserttoDB(data){
      
      setDialogInformation({ title: "", information: "Processing...", status: "loading" });
      setOpenDialogLoading(true);

      insertKunde_func(data)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to add data');
          }
        })
        .then(result => {
          //console.log(result);

          setAlert(
            {
              text: result.message,
              appear: true,
              severity: 'success'
            }
          );

          setOpenDialogLoading(false);

          setTimeout(() => {
            setAlert({ ...alert, appear: false });
          }, 2000);
                        
      })
      .catch(error => {
          console.error('Error adding data:', error);
          setOpen(false);
          // Handle error here, show message or take appropriate action
      });
    } 

    function AddColumn() {
        setSpreadsheetData(data => data.map(row => [...row, {value: ""}]));
    }

    function AddRow() {
        setSpreadsheetData(data => [...data, dataArray
                                  
                        ]
                );
    }

    function RemoveColumn() {
        setSpreadsheetData(data => data.map(row => row.slice(0, -1)));
    }

    function RemoveRow() {
        setSpreadsheetData(data => data.slice(0, -1));
    }

    function SaveData(){ /*Button*/

        setAlert({ ...alert, appear: false });
        setOpenDialogLoading(false);

        console.log(data);
        console.log(data.toString());
        console.log(JSON.stringify(data));
        console.log("scheine");
        console.log(scheine);

        let newArray = processArray(data);

        console.log(newArray);
        console.log(JSON.stringify(newArray));

        setAllData({ ...alldata, datatable: processArray(data), scheine: scheine });

        //setAllData(prevState => ({ ...prevState, datatable: newArray, scheine: scheine }));

        console.log("alldata")
        console.log(alldata)


        onInserttoDB(alldata);
    }

    function changeScheine(e){
      setScheine( e.target.value );
    }

    const onChange = (newData) => {
      if (JSON.stringify(newData) !== JSON.stringify(data)) {
          setSpreadsheetData(newData);
      }
    };
  
  function processArray(data){
    let newArray = data.map(subArray => 
      subArray.map(obj => 
        obj === undefined ? "" : (obj.value !== undefined ? obj.value : "")
      )
    );
    return newArray;
  }
  
  const LoadingTable = styled('div')(({ theme }) =>
    theme.unstable_sx({
        position:'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.50)',
        zIndex: 1,
        paddingTop: '2.7em'
    }),
);

    return (
        <MuiDrawer>

            <br></br>
            <Typography variant="div" component="h2">
                EBM Kunde
            </Typography>
            <Box sx={{paddingTop: '3px'}} />
            <Typography variant="div" component="h3">
                Hochrechnug der EBM-Ziffern im Vergleich zum Fachgruppendurschnitt
            </Typography>
            <Box sx={{paddingBottom: '10px'}} />

            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} scheine={scheine} />
                <TableContainer sx={{ position:'relative' }}>
                        { /*Table Wrap*/}

                        {
                            loading ? (

                                <LoadingTable>
                                    <div>
                                        <CircularProgress />
                                    </div>
                                </LoadingTable>
                            ) : ""
                        }

                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                    <TableBody>
                                    {
                                        (visibleRows.length > 0) ?
                                            visibleRows.map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                        className='checkboxId'
                                                        data-id={row.id}
                                                    >
                                                        <TableCell padding="checkbox" sx={{width: '0', padding: '0 0 0 0'}}>
                                                            {/*<Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                onClick={(event) => handleClick(event, row.id)}
                                                            />*/}
                                                            <Box sx={{padding: '1.5em 0 1.5em 17px'}}></Box>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.id_number}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.anzahl}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.soll}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.abweichung}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.abweichung_percent}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.median}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.score}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                            sx={{paddingRight: '1em'}}
                                                        >
                                                            {row.potential}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        : loading ? (
                                                <TableRow
                                                    style={{
                                                        height: '120px',
                                                    }}
                                                ><TableCell colSpan={6} />
                                                </TableRow>
                                            ) : null
                                    }
                                    </TableBody>
                            </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={parseInt(totalRowsCount)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
              </Paper>
              <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                />
            </Box>

            

            {/*<Spreadsheet
            data={data}
            onChange={setSpreadsheetData}
            columnLabels={columnLabels}
            rowLabels={rowLabels}
            />*/}
            
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Edit Data
                  </Typography>
                  <Button autoFocus color="inherit" onClick={SaveData}>
                    save
                  </Button>
                </Toolbar>
              </AppBar>

              <Box sx={{ p: 2 }}>
                <Box>
                  {/*<Button variant="contained" onClick={AddColumn}>Add Column</Button>*/}
                  <Button variant="contained" onClick={AddRow}>Add Row</Button>
                  {/*<Button variant="contained" onClick={RemoveColumn}>Remove Column</Button>*/}
                  <Button variant="contained" onClick={RemoveRow}>Remove Row</Button>
                  {/*<Button variant="contained" onClick={SaveData}>Save Data</Button>*/}
                  {/*<Button variant="contained" onClick={CalculateSUM}>Calculate SUM</Button>*/}
                </Box>

                <Box sx={{marginTop: '10px'}}>
                  <TextField
                    required
                    margin="dense"
                    id="scheine"
                    label="Scheine"
                    name="scheine"
                    type="text"
                    variant="outlined"
                    size='small'
                    onChange={changeScheine}
                    value={scheine}
                  />
                </Box>


                <SpreadsheetDiv>
                  <Spreadsheet
                                  data={data}
                                  onChange={onChange}
                                  columnLabels={columnLabels}
                  />
                </SpreadsheetDiv>
              </Box>
              
              {/*<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Controls.Alert severity={alert.severity} text={alert.text} />
              </Snackbar>*/}
              {/*<Box>
              <Snackbar open={true} autoHideDuration={6000}>
                <Controls.Alert severity={alert.severity} text={alert.text} />
              </Snackbar>
              </Box>*/}
            </Dialog>

              <Snackbar open={alert.appear} autoHideDuration={6000}>
                <Alert
                  onClose={handleCloseAlert}
                  severity={alert.severity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {alert.text}
                </Alert>
              </Snackbar>

              <DialogOutput
                fullScreen={false} // Set the value according to your requirement
                open={openDialogLoading}
                dialogData={dialogData}
              />
        </MuiDrawer>
    );
}