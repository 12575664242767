function countGesamtpotenzialNoTrap(rows, debug){
  let gesamtpotenzial_notrap = 0;
    
    let testListgesamtpotenzial_notrap = "gop,gesamtpotenzial_notrap\n";
    
    rows.forEach((row, index) => {
        //const fallzahl = parseInt(inputCalculationData[`fallzahl_${row.gop}`]);
        
        //const idealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);

        //const idealNumberOfTreatments = (row.exclude_scheine === 1) ? parseFloat(row.adjusted_ideal_number_of_treatments).toFixed(0) : parseFloat(row.ideal_number_of_treatments).toFixed(0);

        //if (fallzahl > 0 && fallzahl <= idealNumberOfTreatments) {
            if (parseInt(row.include_chart) === 1) {
                
              if (row.gop !== '01773' && row.gop !== '01774') {  

                if(row.exclude_scheine === 1){

                    /*if(row.euro_potencial_mutterschaftsvorsorge_ziffern !== 0) {
                      gesamtpotenzial_notrap += parseFloat(row.euro_potencial);

                      if(debug){
                        testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial + "\n";
                      }

                    }else{
                      gesamtpotenzial_notrap += parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern);
                      
                      if(debug){
                        testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                      }
                    }*/

                    //'01771'

                    gesamtpotenzial_notrap += parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern);

                    if(debug){
                      testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                    }

                }else{
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial);
                  
                  if(debug){
                    testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial + "\n";
                  }
                }

                /*if(row.euro_potencial_mutterschaftsvorsorge_ziffern !== 0){
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern);

                  if(debug){
                    testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial_mutterschaftsvorsorge_ziffern + "\n";
                  }

                }else{
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial);

                  if(debug){
                    testListgesamtpotenzial_notrap += row.gop + "," + row.euro_potencial + "\n";
                  }

                }*/
              }else{
                gesamtpotenzial_notrap += 0;

                if(debug){
                  testListgesamtpotenzial_notrap += row.gop + "," + 0 + "\n";
                }
              }
                /*if (typeof row.adjusted_euro_potencial === 'undefined') {
                  gesamtpotenzial_notrap += parseFloat(row.euro_potencial);
                } else {
                  gesamtpotenzial_notrap += parseFloat(row.adjusted_euro_potencial);
                }*/

            } else {
              gesamtpotenzial_notrap += 0;
              
              if(debug){
                testListgesamtpotenzial_notrap += row.gop + "," + 0 + "\n";
              }
            }
        //} else {
        //   gesamtpotenzial_NoTrap += 0;
        //}
    });

    if(debug){
      //console.log("testListgesamtpotenzial_notrap")
      //console.log(testListgesamtpotenzial_notrap)
    }

    return gesamtpotenzial_notrap;
}

export default countGesamtpotenzialNoTrap;