import React, { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectOptonDiv = styled('div')(({ theme }) =>
    theme.unstable_sx({
        display: 'inline-flex',
        position: 'relative',
        '& .MuiInputBase-input': {
            padding: '6.5px 14px',
            paddingRight: '41px',
        },
        '& .MuiInputBase-root': {
            borderColor: 'rgba(25, 118, 210, 0.5)'
        },
        '& .MuiButtonBase-root': {
            position: 'absolute',
            right: 0,
            minWidth: '42px',
            border: '0!important',
            padding: '6px 0px',
            borderRadius: '0 4px 4px 0'
        },
        label: {
            top: "-3px",
            '&[data-shrink="true"]': {
                // Add your styles here for the label when data-shrink is true
                top: 0,
            },
        },
    }),
);

const SelectOptionInput = ({ value, setValue, label_name, list }) => {
    
    /**
    
        {
            id: 1,
            name: 'John',
        }

     */

       

    /*const list =  [
        {
          "id": 1,
          "name": "Berlin"
        },
        {
          "id": 2,
          "name": "Bayern"
        }
    ];*/

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    /*const [searchTerm, setSearchTerm] = useState(search);

    // useCallback to memoize the handleChange function
    const handleChange = useCallback((event) => {
        setSearchTerm(event.target.value);
    }, []);

    // useCallback to memoize the handleSearch function
    const handleSearch = useCallback((e) => {
        e.preventDefault();
        // Perform search logic here

        setSearch(searchTerm);
        setPage(0);
    }, [searchTerm, setSearch, setPage]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearch(searchTerm);
            setPage(0);
        }
    };*/

    return (
        <>
            <SelectOptonDiv>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">{label_name}</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={value}
                        label={label_name}
                        onChange={handleChange}
                    >
                        <MenuItem value="0">
                            <em>None</em>
                        </MenuItem>

                        {list.map((item) => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}

                        {/*<MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>*/}
                    </Select>
                </FormControl>
            </SelectOptonDiv>
        </>
    );
};

export default SelectOptionInput;