import PieChart from "../../../components/charts/PieChart";

import calculateTotalNoTrapPotenzialAndGenutztesPotenzial from "../calculations/calculateTotalNoTrapPotenzialAndGenutztesPotenzial";

import germanFormatCurrency from "../../../utils/germanFormatCurrency";

const PieChartFilter2 = (props) => {

    const { gesamtpotenzial_notrap, ihrpotenzial_notrap } = props;

    let result = calculateTotalNoTrapPotenzialAndGenutztesPotenzial(ihrpotenzial_notrap, gesamtpotenzial_notrap);

    /*if(debug){
      console.log("ungenutztesPotenzial");
      console.log(result.ungenutztesPotenzial);
      console.log("genutztesPotenzial");
      console.log(result.genutztesPotenzial);
    }*/

    let dataName = ["Ungenutztes Potenzial", "Genutztes Potenzial"];
  
    let headerTitle = `Gesamtpotenzial: ${germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))}`;

    //Ø Potenzial bei den freien Leistungen

    return (<>

              <PieChart 
                        headerTitle={headerTitle}
                        data={
                              [
                                [dataName[0],dataName[1], { role: 'tooltip', type: 'string' }],
                                [dataName[0], result.ungenutztesPotenzial, dataName[0] + ": \n" + germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))],
                                [dataName[1], parseFloat(result.genutztesPotenzial), dataName[1] + ": \n" + germanFormatCurrency(parseFloat(result.genutztesPotenzial))],
                              ]
                            }
                        backgroundColor={['#7A2856','#006400']} 
                />


    </>);

  }

  export default PieChartFilter2;