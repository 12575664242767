import { TextField, Button, Grid, Typography, Paper, Box, Checkbox, Alert, Slider, styled } from '@mui/material';
import React, { useState, useEffect, createRef, useRef } from 'react';
import Controls from './controls/Controls';

import IconButton from '@mui/material/IconButton';

/*AUDIO PLAYER*/
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import LinearProgress from '@mui/material/LinearProgress';
/*END OF AUDIO PLAYER*/

import { useAuth } from '../contexts/AuthContext';

const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

//const AudioContext = React.createContext();

const MusicPlayer = (props) => {

    //const { currentAudio, setCurrentAudio } = React.useContext(AudioContext);

    //const { currentAudio, setCurrentAudio_func } = useAuth();

    const { src, player_type, currentAudio, setCurrentAudio } = props;

    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Add this line
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);

    const [isFileMissing, setIsFileMissing] = useState(false); // Add this state

    const handlePlayPause = () => {

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();

            try{
                setCurrentAudio(audioRef.current);
            }catch(e){}

            //setCurrentAudio_func(audioRef.current);
        }
        setIsPlaying(!isPlaying);

        //console.log("currentAudio")
        //console.log(currentAudio)

    };

    const handleStop = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
    };

    const handleSliderChange = (event, newValue) => {
        audioRef.current.currentTime = newValue;
        setCurrentTime(newValue);
    };

    const handleVolumeChange = (event, newValue) => {
        audioRef.current.volume = newValue;
        setVolume(newValue);
    };

    useEffect(() => {

        // Add this event listener to handle errors
        audioRef.current.addEventListener('error', () => {
            //console.log('Audio file not found. Loading fallback audio.');
            //audioRef.current.src = 'fallback_audio.mp3'; // replace with your fallback audio file

            // Set the file missing state to true
            setIsFileMissing(true);
        });

        audioRef.current.addEventListener('loadeddata', () => {
            try {
              setIsLoading(false); // Set loading to false when the audio is loaded
            } catch (e) {  }
        });

        audioRef.current.addEventListener('timeupdate', () => {
          try {
            setCurrentTime(audioRef.current.currentTime);
          } catch (e) { setCurrentTime(0); }
        });

        audioRef.current.addEventListener('loadedmetadata', () => {
          try {
            setDuration(audioRef.current.duration);
          } catch (e) { setDuration(0); }
        });

        // Add this event listener to reset the audio after it has ended
        audioRef.current.addEventListener('ended', () => {
            try {
            audioRef.current.currentTime = 0; // Reset the current time to 0
            //setIsLoading(true); // Set loading to true
            setIsPlaying(false);
            } catch (e) {  }
        });

    }, []);

    useEffect(() => {
        if (currentAudio && currentAudio !== audioRef.current) {
            audioRef.current.pause();
            //audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }, [currentAudio]);


    return (

        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{display:'none'}}>
                    {console.log("src")}
                    {console.log(src)}
                    <audio ref={audioRef} src={src}/>
                </Grid>
                <Grid item xs={12} sx={{paddingTop: '0!important'}}>

                {
                    isLoading
                        ?
                        <>
                            { isFileMissing
                                ? <Alert severity="warning">Apologies, audio currently unavailable. Stay tuned for updates.</Alert>
                                : <Box sx={{padding: '20px 0'}}><LinearProgress/></Box>
                            }
                        </>
                        :
                        <>
                            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: {xs: 'column-reverse', sm: 'row'}}}>
                                <Button onClick={handlePlayPause} variant="contained" sx={{minWidth: '37px', padding: '0', height: '37px'}}>
                                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                </Button>
                                <VolumeDownIcon />
                                <Slider
                                    value={volume}
                                    onChange={handleVolumeChange}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    sx={{width: '100px', marginLeft: '10px'}}
                                />
                                <VolumeUpIcon />
                                <Typography component={'div'} sx={{whiteSpace: 'nowrap', alignItems: 'center', justifyContent: 'center', display: 'inline-flex', fontSize: '13px', margin: {xs: '10px 0 10px 0', sm: '0 17px 0 15px'}}}>{formatTime(currentTime) + " / " + formatTime(duration)}</Typography>
                                <Slider
                                    value={currentTime}
                                    onChange={handleSliderChange}
                                    max={duration}
                                    sx={{padding: '0!important'}}
                                />
                            </Box>
                        </>
                } {/* Show a loading indicator when the audio is loading */}
                </Grid>
            </Grid>
        </Box>


    );
};

export default MusicPlayer;