import PieChart from '../../../components/charts/PieChart';
import calculateUngenutzPotenzialAndGenutztesPotenzial from "../calculations/calculateUngenutzPotenzialAndGenutztesPotenzial";
import germanFormatCurrency from '../../../utils/germanFormatCurrency';

export default function PieChartFilter(props){

    const { total_ihr_potenzial_included, gesamtpotenzial_included } = props;

    let result = calculateUngenutzPotenzialAndGenutztesPotenzial(total_ihr_potenzial_included, gesamtpotenzial_included);

    /*if(debug){
      console.log("ungenutztesPotenzial");
      console.log(result.ungenutztesPotenzial);
      console.log("genutztesPotenzial");
      console.log(result.genutztesPotenzial);
    }*/

    let dataName = ["Ungenutztes Potenzial", "Genutztes Potenzial"];
  
    let headerTitle = `€-Potenzial bei Ihren GOÄ-Ziffern: ${germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))}`;

    return (<>

              <PieChart 
                        headerTitle={headerTitle}
                        data={
                              [
                                [dataName[0],dataName[1], { role: 'tooltip', type: 'string' }],
                                [dataName[0], parseFloat(result.ungenutztesPotenzial), dataName[0] + ": \n" + germanFormatCurrency(parseFloat(result.ungenutztesPotenzial))],
                                [dataName[1], parseFloat(result.genutztesPotenzial), dataName[1] + ": \n" + germanFormatCurrency(parseFloat(result.genutztesPotenzial))],
                              ]
                            }
                        backgroundColor={['#7A2856','#006400']} 
                />


    </>);
}