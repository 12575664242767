export default function checkDeviationPercentStatus(row_deviation_percent_status){

    let transparent = '#00000000', white = '#ffffff', lightgreen = '#90EE90', yellow = '#FFFF00', lightcoral = '#F08080', darkred = '#8B0000';

    let backgroundColor = transparent;
    let color = 'inherit';
    let color_href = '#1976D2'

    if(typeof row_deviation_percent_status !== 'undefined'){
        if(row_deviation_percent_status === "white"){
            backgroundColor = white;
        }else if(row_deviation_percent_status === "lightgreen"){
            backgroundColor = lightgreen;
        }else if(row_deviation_percent_status === "yellow"){
            backgroundColor = yellow;
        }else if(row_deviation_percent_status === "lightcoral"){
          backgroundColor = lightcoral;
          color = white;
          color_href = white;
        }else if(row_deviation_percent_status === "darkred"){
            backgroundColor = darkred;
            color = white;
            color_href = white;
        }
    }
  
    return { backgroundColor, color, color_href };
 
  }